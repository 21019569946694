import { memo } from "react";
import { useRecoilValue } from "recoil";
import { OPEN } from "../../../common/constants/general.constant";
import { RaceStatusType } from "../../../common/types/general.type";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import { AtomSelectedThemeData } from "../../../store/theme/theme.store";
import RaceTimer from "./race.timer";
import styles from "./raceStatus.module.scss";

type Props = {
  isCanceled?: any;
  status: RaceStatusType | string;
  mtp?: string;
};

const RaceStatus = memo(({ status, mtp, isCanceled }: Props) => {
  const t = useTranslation(LKeys.generals);
  const theme = useRecoilValue(AtomSelectedThemeData);

  return (
    <div className={styles.statusWrapper}>
      {/* {t(status)} {JSON.stringify(isCanceled ?? "")} */}
      {status === OPEN ? (
        <RaceTimer mtp={mtp} />
      ) : (
        <span
          style={{ color: theme ? theme[status] : "" }}
          className={styles.status}
        >
          {t(status)}
        </span>
      )}
    </div>
  );
});
RaceStatus.displayName = "RaceStatus";
export default RaceStatus;
