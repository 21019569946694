import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AtomTransactionBetOption,
  AtomTransactionDates,
  AtomTransLazyParams,
  AtomWagerTransactions,
} from "../../../store/transaction/transaction.store";
import Tab from "../../ui/tabs/tab.ui";
import TransactionTable from "./TransactionTable/transactionTable.component";
import styles from "./transaction.module.scss";
import DatePicker from "../../elements/datepicker/DatePicker.element";
import styled from "styled-components";
import { OddsTypes } from "../../../common/constants/pools.constant";
import { TransactionApiService } from "../../../common/services/api/transactionApi.service";
import { useCallback, useEffect, useState } from "react";
import { AtomAuthUser } from "../../../store/auth/auth.store";
import { ImArrowRight } from "react-icons/im";
import { Paginator, PaginatorPageStateEvent } from "primereact/paginator";
import { ColumnsDTO, LazyLoadParams } from "../../../common/types/general.type";
import TransactionDialog from "./transactionDialog/transactionDialog.component";
import { WagersDTO } from "../../../common/models/transaction.model";
import { COLS } from "../../../common/constants/columns.constant";
import useTranslation from "../../../store/locale/useTranslation";
import { LKeys } from "../../../store/locale/locale.data";
import { TransactionDTO } from "../../../common/types/transactionTypes.type";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import Loader from "../../elements/loader/loader.element";
import AbsLoader from "../../elements/absLoader/absLoader.element";

type ModalTypes = "row" | null;

const TransactionComponent = () => {
  const [dates, setDates] = useRecoilState(AtomTransactionDates);
  const [betOption, setBetOption] = useRecoilState(AtomTransactionBetOption);
  const user = useRecoilValue(AtomAuthUser);
  const [lazyParams, setLazyParams] = useRecoilState(AtomTransLazyParams);
  const [modalToOpen, setModalToOpen] = useState<ModalTypes>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState<{
    cols: ColumnsDTO[];
    data: any;
    header: string;
  }>({ cols: [], data: [], header: "" });
  const setWagers = useSetRecoilState(AtomWagerTransactions);
  const tGenerals = useTranslation(LKeys.generals);
  const { getTransactions } = TransactionApiService();

  const onTabChange = (_betOption: string) => {
    setBetOption(_betOption);
    // fetchWagers(lazyParams, _betOption);
  };
  

  const handlePagination = (event: PaginatorPageStateEvent) => {
    let values: LazyLoadParams = {
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
      totalRecords: lazyParams.totalRecords,
    };
    setLazyParams(values);
    // fetchTransactionData(values);
    fetchWagers(values);
  };

  const DialogBody = () => {
    return (
      <Table value={dialogData.data} className={styles.myTable}>
        {dialogData.cols.map((col) => {
          return (
            <Column
              key={col.field}
              field={col.field}
              header={tGenerals(col.header)}
            />
          );
        })}
      </Table>
    );
  };

  const onPayout = (rowData: TransactionDTO, e: any) => {
    e.stopPropagation();
    let poolPayoutData = [];
    for (const selId in rowData.poolPayout) {
      const odds = rowData.poolPayout[selId] ?? 0;
      poolPayoutData.push({
        selection: selId,
        odds: odds?.toFixed(2),
      });
    }
    setDialogData({
      cols: COLS.Payout,
      data: poolPayoutData,
      header: tGenerals("Pools Payout"),
    });
    // setDisplayPayOutData(true);
    setShowDialog(true);
  };

  const fetchWagers = useCallback(
    (_lazyParams?: LazyLoadParams, _betOption?: string, _dates?: Date[]) => {

   
      let x = _betOption ?? betOption;
      
      
      
      if (user?.clientId) {
        getTransactions({
          betOption: x,
          dates: _dates ?? dates,
          user,
          lazyParams: _lazyParams ?? lazyParams,
          setWagers,
          setLazyParams,
          setIsLoading,
          setIsTableLoading,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.clientId, betOption]
  );

  const onRowClick = (e: WagersDTO) => {
    setIsOpen(true);
    setModalToOpen("row");
    setDialogData({
      cols: [],
      data: e,
      header: "Transaction Details",
    });
  };

  const onDateChange = (v: Date[]) => setDates(v);

  useEffect(() => {
    fetchWagers();
  }, [fetchWagers]);

  return (
    <BodyBackground className={styles.transWrp}>
      <div className={styles.tabWrp}>
        <Tab
          tabName={OddsTypes}
          selectedTabs={betOption}
          onChange={onTabChange}
        />
        <div className={styles.dateWrapper}>
          <DatePicker value={dates} onChange={onDateChange} />
          <IconButton
            onClick={() => {
              setIsLoading(true);
              fetchWagers(lazyParams, betOption, dates);
            }}
          >
            {isLoading ? <Loader show roundLoader /> : <ImArrowRight />}
          </IconButton>
        </div>
      </div>
      <AbsLoader show={isTableLoading} />
      <TransactionTable onRowClick={onRowClick} onPayout={onPayout} />
      <Paginator
        className={styles.paginate}
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={lazyParams.totalRecords}
        rowsPerPageOptions={[15, 30, 45]}
        onPageChange={handlePagination}
      ></Paginator>
      {modalToOpen === "row" && (
        <TransactionDialog
          hideDialog={() => setModalToOpen(null)}
          {...dialogData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <Dialog
        header={dialogData.header}
        // footer={
        //   <div className="dialogFooter">
        //     <button
        //       className={styles.primaryBtn}
        //       onClick={() => setShowDialog(false)}
        //       // label={tGenerals("Close")}
        //     />
        //   </div>
        // }
        visible={showDialog}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        onHide={() => setShowDialog(false)}
        draggable={false}
        className={styles.dialogWrapper}
        headerClassName={"dialogHeader"}
        contentClassName={"dialogContent"}
        dismissableMask
      >
        <DialogBody />
      </Dialog>
    </BodyBackground>
  );
};

export default TransactionComponent;

const IconButton = styled.button`
  background-color: ${(p) => p.theme.bodyContrast};
  padding: 0.8rem;
  color: ${(p) => p.theme.textContrast};
  border-radius: 5px;
  text-align: center;
  border: none;
  border-radius: var(--radius-default);
`;

const BodyBackground = styled.div`
  background-color: ${(p) => p.theme.bodyContrastLight};
  border-radius: 10px;
`;

const Table = styled(DataTable)`
  .p-datatable-table {
    thead > tr > th {
      background-color: ${(p) => p.theme.bodyContrastDark};
      color: ${(p) => p.theme.textSecondary};
    }
    tbody > tr {
      background-color: ${(p) => p.theme.bodySecondaryDark};
      color: ${(p) => p.theme.primaryText};
    }
  }
`;
