import { useEffect, useState } from "react";
import styles from "../../styles/pageStyles/auth.module.scss";
import styled from "styled-components";
import Dropdown from "../../components/elements/dropdown/currencyDropdown.element";
import { ElementChangeEvent } from "../../common/types/general.type";
import { CurrencyList } from "../../common/data/currency.data";
import { FaFlag } from "react-icons/fa";
import logo from "../../assets/logos/FullTextLogo.png";
import { doc, setDoc } from "@firebase/firestore";
import { Auth, Firestore } from "../../common/configs/firebase.config";
import { LS_KEYS, NODES } from "../../common/constants/general.constant";
import { UserDTO } from "../../common/models/auth.model";
import {
  _deleteStorage,
  _getStorage,
} from "../../common/helpers/storage.helper";
import { showToast } from "../../common/helpers/debugging.helper";
import { useNavigate } from "react-router";
import { ROUTES } from "../../common/routes/default.routes";
import { signOut } from "@firebase/auth";
import { useRecoilState } from "recoil";
import { AtomAuthUser } from "../../store/auth/auth.store";
import Loader from "../../components/elements/loader/loader.element";
import { Helmet } from "react-helmet";

const CurrencySelectPage = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>("");
  const [user, setUser] = useRecoilState(AtomAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.currency) navigate(ROUTES.ALL_RACES);
  }, [navigate, user]);

  const onGetStarted = () => {
    const result = _getStorage(LS_KEYS.auth, true);
    if (!result) {
      showToast("Authentication not found! Please try again!");
      signOut(Auth)
        .then((res) => {
          navigate(ROUTES.LOGIN);
          _deleteStorage(LS_KEYS.auth);
        })
        .catch((err) => {
          console.error(err);
        });
      return;
    }
    const user = Object.fromEntries(result);
    setIsLoading(true);

    const db = doc(Firestore, `${NODES.CLIENTS}/${user.uid}`);
    const _userObj: UserDTO = {
      name: user.username,
      email: user.email ?? "",
      phoneNumber: user.phoneNumber ?? "",
      photoURL: user.photoURL ?? "",
      clientId: user.uid,
      currency: selectedCurrency,
      balance: 10000,
      status: "ACTIVE",
    };
    setDoc(db, _userObj)
      .then(() => {
        setUser(_userObj);
        navigate(ROUTES.ALL_RACES);
        setIsLoading(false);
      })

      .catch((err) => {
        console.error(err);
        showToast(err?.message ?? "Something is wrong!", "error");
        setIsLoading(false);
      });
  };

  const handleChange = (val: ElementChangeEvent) => {
    setSelectedCurrency(val.data.currency);
  };

  return (
    <>
      <Helmet>
        <title>Currency Select - Ashva Racing</title>
      </Helmet>
      <div className={styles.wrapper}>
        <img className={styles.logo} src={logo} alt="ashva_racing_logo" />
        <H1 className={styles.title}>Select your currency and Get Started</H1>
        <div className={styles.currencyFormWrapper}>
          <Dropdown
            name="currency"
            selectedValue={selectedCurrency}
            onChange={handleChange}
            options={[CurrencyList]}
            placeholder="Select Currency"
            icon={<FaFlag />}
          />
          <div style={{ textAlign: "left" }}>
            <Button onClick={onGetStarted}>
              {isLoading ? <Loader show size={10} /> : "Get Started"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrencySelectPage;

const H1 = styled.h1`
  color: ${(p) => p.theme.text};
`;

const Button = styled.button`
  background: ${(p) => p.theme.bodyContrast};
  white-space: nowrap;
  padding: 1rem;
  color: ${(p) => p.theme.textContrast};
  text-align: center;
  min-height: 3rem;
  border: none;
  border-radius: 0.5rem;
  margin-top: 1rem;
  @media screen and (max-width: 991px) {
    font-size: 0.7rem;
  }
`;
