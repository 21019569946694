import Card from "../../ui/card/card.component";
import BetTab from "./betTab/betTab.component";
import BetSlipBody from "./betTabBody/betSlip.body";
import OpenBetsBody from "./betTabBody/openBets.body";
import styles from "./betSlipComponent.module.scss";

const BetSlipComponent = () => {
  return (
    <Card style={{ overflow: "hidden" }}>
      <BetTab />
      <div className={styles.slipBody}>
        <BetSlipBody />
        <OpenBetsBody />
      </div>
    </Card>
  );
};

export default BetSlipComponent;

// * Styled components
