import { off, onValue } from "firebase/database";
import { ReactNode, useRef, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LS_KEYS, NODES } from "../../common/constants/general.constant";
import { LOG } from "../../common/helpers/debugging.helper";
import { _getStorage } from "../../common/helpers/storage.helper";
import { RaceSnapDTO } from "../../common/models/race.mode";
import { Ref_RaceSnaps } from "../../common/services/nodes.service";
import { RacesService } from "../../common/services/races.service";
import { UpcRacesDTO } from "../../common/types/raceUI.type";
import { AtomBetCards, AtomOpenBets } from "../bets/bets.store";
import { AtomRaceSnaps, AtomUpcRaces } from "../races/races.store";
import { BetAPIService } from "../../common/services/api/betApi.service";
import { AtomAuthUser } from "../auth/auth.store";
import { doc, getDoc } from "firebase/firestore";
import { Auth, Firestore } from "../../common/configs/firebase.config";
import { AtomGlobalNotification } from "./global.store";
import { NotificationDTO } from "../../common/models/notification.model";
interface GlobalProviderProps {
  children: ReactNode;
}
const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const setRaceSnaps = useSetRecoilState(AtomRaceSnaps); // All races snaps for header carousel
  const setUpcRaces = useSetRecoilState(AtomUpcRaces); // Upcoming races according to event types
  const setBetCards = useSetRecoilState(AtomBetCards);
  const setOpenBets = useSetRecoilState(AtomOpenBets);
  const setNotification = useSetRecoilState(AtomGlobalNotification);

  const intervalRef = useRef<any>(null);
  const user = useRecoilValue(AtomAuthUser);
  const refNotify = useRef<any>(null);

  const refRaceSnap = useRef<any>(null);
  const { getRaceSnapObj } = RacesService();
  const { getOpenBets } = BetAPIService();

  const getOpenBetCards = useCallback(() => {
    if (!user) return;
    LOG("OPEN BETS API CALLED", "API");
    getOpenBets(user)
      .then((_bets) => {
        setOpenBets(_bets);
      })
      .catch((err) => {
        console.error(err);
        setOpenBets([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      intervalRef.current = setInterval(() => {
        getOpenBetCards();
      }, 4000);
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // * Get bet cards from localStorage at initialization.
  const getBetCards = useCallback(() => {
    LOG("getBetCards", "INFO");
    const _cards = _getStorage(LS_KEYS.bets, true);
    const _betCards = Object.fromEntries(_cards ?? []);
    setBetCards(_betCards);
  }, [setBetCards]);

  // * Listen for race snaps at init level
  const getRaceSnapOnce = useCallback(() => {
    if (refRaceSnap.current) off(refRaceSnap.current);
    refRaceSnap.current = Ref_RaceSnaps();
    LOG("getRaceSnapOnce", "API");
    onValue(
      refRaceSnap.current,
      (res) => {
        let _races: RaceSnapDTO[] | null = null;
        let _upc: UpcRacesDTO | undefined = undefined;
        if (res.exists()) {
          const { _raceSnaps, _upcRaces } = getRaceSnapObj(res.val());
          _races = _raceSnaps;
          _upc = _upcRaces;
        }

        setRaceSnaps(_races);
        setUpcRaces(_upc);
      },
      (err) => {
        console.error(err);
        setRaceSnaps(null);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRaceSnapObj, setRaceSnaps]);

  const getGlobalNotification = useCallback(() => {
    if (refNotify.current) refNotify.current();

    const docRef = doc(Firestore, NODES.NOTIFY, NODES.GLOBAL_HEADER);
    getDoc(docRef)
      .then((res) => {
        if (res.exists()) {
          setNotification(res.data() as NotificationDTO);
        } else {
          setNotification(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGlobalNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Auth.currentUser) {
      getRaceSnapOnce();
      getBetCards();
      getOpenBetCards();
    }
    // getGlobalNotification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBetCards, getOpenBetCards, getRaceSnapOnce]);

  return <>{children}</>;
};

export default GlobalProvider;
