import { ChangeEvent } from "react";
import styled from "styled-components";
import { ElementChangeEvent } from "../../../common/types/general.type";
import styles from "./_inputs.module.scss";

type Props = {
  label?: string;
  placeholder?: string;
  onChange?: (e: ElementChangeEvent) => void;
  name?: string;
  value: string | number;
  suffix?: string;
  className?: string;
  disabled?: boolean;
};

const BetAmtInput = ({ className, ...props }: Props) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (value !== "" && isNaN(Number(value))) return;
    props.onChange && props.onChange({ data: value, name });
  };
  return (
    <InputWrapper
      className={`${styles.className ?? ""} ${
        props.disabled ? "disabled" : ""
      } ${styles.betAmtInputWrapper}`}
    >
      {props.label && <Label className={styles.label}>{props.label}</Label>}
      <Input
        className={`${styles.input} ${styles.amtInput}`}
        type="text"
        onChange={onInputChange}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
      />
      {props.suffix && (
        <Suffix className={`${styles.suffix} suffix`}>{props.suffix}</Suffix>
      )}
    </InputWrapper>
  );
};

export default BetAmtInput;

const InputWrapper = styled.div`
  border: ${(p) => p.theme.borderPrimary};
  transition: 0.3s;
  margin-right: 0.5rem;
  &:not(&.disabled):hover,
  &:not(&.disabled):focus,
  &:not(&.disabled):focus-within {
    border: ${(p) => p.theme.borderHover};
    label {
      color: ${(p) => p.theme.activeText};
    }
  }
  &.disabled {
    .suffix {
      color: #cac9c9;
    }
  }
`;

const Input = styled.input``;

const Label = styled.label`
  font-size: 0.65rem;
  color: ${(p) => p.theme.textSecondary};
`;

const Suffix = styled.span`
  background: ${(p) => p.theme.bodyContrastLight};
  color: ${(p) => p.theme.textContrast};
`;
