import { atom } from "recoil";
import { __BetToAmounts } from "../../common/models/bet.model";
import { WagersDTO } from "../../common/models/transaction.model";
import { __BetCardDTO } from "../../common/types/dynamic.type";

// * State to store tab value for bet slip .. pending bets or open bets
export const AtomBetTab = atom<"betSlip" | "openBets">({
  key: "atomBetTab",
  default: "betSlip",
});

// * State to store bet cards for bet slip
export const AtomBetCards = atom<__BetCardDTO>({
  key: "atomBetCards",
  default: {},
});

// * State to show and hide bet slip
export const AtomShowBetSlip = atom<boolean>({
  key: "atomShowBetSlip",
  default: false,
});

// * State to store open bets or pending bets.
export const AtomOpenBets = atom<WagersDTO[]>({
  key: "atomOpenBets",
  default: [],
});

export const AtomBetAmounts = atom<__BetToAmounts>({
  key: "atomBetAmounts",
  default: {},
});
//! To re-render betSlip to update reference while other bets are in processing.
export const AtomReRender = atom<Date>({
  key: "atomReRender",
  default: new Date(),
});
