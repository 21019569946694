import styled from "styled-components";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import styles from "./tab.module.scss";

interface TabsProps {
  tabName: string[];
  selectedTabs: string | null;
  onChange?: (val: string) => void;
}
const Tab = ({ ...props }: TabsProps) => {
  const t = useTranslation(LKeys.generals);
  return (
    <TabWrapper className={styles.tabWrapper}>
      {props.tabName.map((tab) => {
        return (
          <Button
            className={props.selectedTabs === tab ? "active" : ""}
            key={tab}
            onClick={() => props.onChange && props.onChange(tab)}
          >
            {t(tab)}
          </Button>
        );
      })}
    </TabWrapper>
  );
};

export default Tab;

// * Styled Components
const TabWrapper = styled.div`
  margin-left: 0.5rem;
  background: ${(p) => p.theme.bodySecondary};
  border-radius: var(--radius-default);
`;

const Button = styled.button`
  background: transparent;
  padding: 0.8rem 1rem;
  color: ${(p) => p.theme.text};
  border-radius: var(--radius-default);
  border: none;
  &:hover {
    /* background: ${(p) => p.theme.bodyContrastLight}; */
    color: ${(p) => p.theme.textContrast};
  }
  &.active {
    position: relative;
    color: ${(p) => p.theme.textContrast};
    background: ${(p) => p.theme.bodyContrast};
    border: none;
    font-weight: 600;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(50%, 0);
      width: 50%;
      height: 4px;
      background: ${(p) => p.theme.active};
    }
    /* &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: ${(p) => p.theme.bodyContrast};
    } */
  }
  @media screen and (max-width: 480px) {
    font-size: 0.85rem !important;
  }
`;
