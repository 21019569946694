import { memo } from "react";
import { getTimeObj } from "../../../common/helpers/dateTime.helper";
import { useState, useRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { AtomSelectedThemeData } from "../../../store/theme/theme.store";
import styles from "./raceStatus.module.scss";
import styled from "styled-components";
import { OPEN } from "../../../common/constants/general.constant";
import useTranslation from "../../../store/locale/useTranslation";
import { LKeys } from "../../../store/locale/locale.data";

type Props = {
  mtp?: string;
};

interface TimeState {
  timeStr: string;
  isNormal: boolean;
  isNegative: boolean;
  isNearToJump: boolean;
}

export const SelectRaceTimer = memo(({ mtp }: Props) => {
  const [time, setTime] = useState<TimeState | null>(() => getTimeObj(mtp));
  const intervalRef = useRef<any>(null);
  const theme = useRecoilValue(AtomSelectedThemeData);
  const t = useTranslation(LKeys.generals);

  // Start interval for timer
  useEffect(() => {
    const startTimer = () => {
      const timeObj = getTimeObj(mtp);
      setTime(timeObj);
    };

    if (mtp) {
      intervalRef.current = setInterval(() => {
        startTimer();
      }, 1000);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mtp]);

  if (!time) return null;
  return (
    <SelectRaceTimerContainer
      className={styles.selectRaceTimerContainer}
      style={{
        border: `1px solid ${
          time?.isNearToJump
            ? theme?.nearMtp
            : time?.isNormal
            ? theme?.farMtp
            : theme?.postMtp
        }`,
      }}
    >
      <div className={styles.statusWrapper}>{t(OPEN)}</div>
      <span
        style={{
          background: time?.isNearToJump
            ? theme?.nearMtp
            : time?.isNormal
            ? theme?.farMtp
            : theme?.postMtp,
        }}
        className={`${styles.selectRaceTimer} `}
      >
        {time.timeStr}
      </span>
    </SelectRaceTimerContainer>
  );
});

SelectRaceTimer.displayName = "SelectRaceTimer";
export default SelectRaceTimer;

const SelectRaceTimerContainer = styled.div`
  border-radius: var(--radius-default);
  border: ${(p) => p.theme.borderHighlight};
  color: ${(p) => p.theme.textContrast};
`;
