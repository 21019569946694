import { ColumnsDTO } from "../types/general.type";

export const TransactionColumn: ColumnsDTO[] = [
  {
    field: "timestamp",
    header: "time",
    width: "10%",
    sortable: true,
  },
  // { field: "localDate", header: "raceDate", width: "7.69%" },
  // { field: "province", header: "Country", width: "4.69%" },
  { field: "trackName", header: "tracks", width: "7.69%", sortable: true },
  // { field: "eventType", header: "Event", width: "3.69%" },
  { field: "raceNo", header: "race_number", width: "6.38%", sortable: true },
  // { field: "transactionId", header: "transactionId", width: "18.09%" },
  { field: "poolType", header: "pool_type", width: "2.69%", sortable: true },
  // { field: "payOut", header: "payOut", width: "7.69%" },
  // { field: "odds", header: "Odds", width: "7.69", },
  { field: "betStatus", header: "bet_status", width: "7.69%", sortable: true },
  {
    field: "totalInvested",
    header: "invested",
    width: "7.69%",
    sortable: true,
  },
  // { field: "refundAmt", header: "Refund", width: "6%" },
  // { field: "totalWinnings", header: "PNL", width: "6%" },
  { field: "returnPnl", header: "pnl", width: "6%", sortable: true },
];

const Payout: ColumnsDTO[] = [
  { field: "selection", header: "Selection", width: "2%" },
  { field: "odds", header: "Odds", width: "2%" },
];

export const COLS = {
  Payout,
};
