import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import LandscapeAd from "../../components/Ads/landscape.ad";
import RaceSnapCard from "../../components/shared/headers/raceSnapHeader/raceSnap.card";
import { AtomRaceSnaps } from "../../store/races/races.store";

const NextToRacePage = () => {
  const races = useRecoilValue(AtomRaceSnaps);

  return (
    <>
      <Helmet>
        <title>Next Races - Ashva Racing</title>
      </Helmet>
      <LandscapeAd />
      <RaceSnapWrp>
        {races?.map((race, index) => {
          return <RaceSnapCard race={race} key={index} />;
        })}
      </RaceSnapWrp>
    </>
  );
};

export default NextToRacePage;

const RaceSnapWrp = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
