import { ROUTES } from "../routes/default.routes";
import { IoHome } from "react-icons/io5";
import { MdHelpOutline } from "react-icons/md";
// import { MdOutlineOndemandVideo } from "react-icons/md";
import { RxLapTimer } from "react-icons/rx";
import { BiTransfer } from "react-icons/bi";
import { FaMoneyCheck } from "react-icons/fa";

import { IconType } from "react-icons";
interface Menu {
  route: string;
  localeKey: string;
  Icon?: IconType;
  sprite?: string;
  breakPoint?: number; // max breakpoint
}

// * Menu list for sidebar card.
export const NavCardMenu: Menu[] = [
  {
    route: ROUTES.ALL_RACES,
    localeKey: "allRaces",
    Icon: IoHome,
  },
  {
    route: ROUTES.TH_RACING,
    localeKey: "thRacing",
    sprite: "et32 TH-white",
  },
  {
    route: ROUTES.HS_RACING,
    localeKey: "hsRacing",
    sprite: "et32 HS-white",
  },
  {
    route: ROUTES.DO_RACING,
    localeKey: "doRacing",
    sprite: "et32 DO-white",
  },
  {
    route: ROUTES.HELP,
    localeKey: "helpCenter",
    Icon: MdHelpOutline,
  },
  // {
  //   route: ROUTES.DEMO,
  //   localeKey: "demoVideos",
  //   Icon: MdOutlineOndemandVideo,
  // },
  {
    route: ROUTES.UPCOMING_RACES,
    localeKey: "upcomingRaces",
    Icon: RxLapTimer,
    breakPoint: 991,
  },
  {
    route: ROUTES.TRANSACTIONS,
    localeKey: "transactions",
    Icon: BiTransfer,
    breakPoint: 991,
  },
];

// * Menu list for navigation header
export const NavigationMenu: Menu[] = [
  { route: ROUTES.ALL_RACES, localeKey: "allRaces" },
  { route: ROUTES.UPCOMING_RACES, localeKey: "upcomingRaces" },
  { route: ROUTES.TRANSACTIONS, localeKey: "transactions" },
  { route: ROUTES.HELP, localeKey: "helpCenter" },
];

//* Menu list for mobile header
export const NavTabMenu: Menu[] = [
  {
    route: ROUTES.NEXT_TO_RACE,
    localeKey: "upcomingRaces",
    Icon: RxLapTimer,
  },
  {
    route: ROUTES.ALL_RACES,
    localeKey: "allRaces",
    Icon: IoHome,
  },
  {
    route: ROUTES.BET_SLIP,
    localeKey: "betSlip",
    Icon: FaMoneyCheck,
  },
  {
    route: ROUTES.TRANSACTIONS,
    localeKey: "transactions",
    Icon: BiTransfer,
  },
  {
    route: ROUTES.HELP,
    localeKey: "helpCenter",
    Icon: MdHelpOutline,
  },
];

// * Data for days menu
export enum Days {
  yesterday = "yesterday",
  today = "today",
  // tomorrow = "tomorrow",
}
export const DaysMenu = Object.values(Days);
