import styles from "./pzoneLogo.module.scss";
import logo from "../../../assets/logos/pzoneFullLogo.png";

type Props = {};

const PzoneLogo = (props: Props) => {
  return (
    <div className={styles.logoWrapper}>
      <a
        href="https://www.protocolzone.com"
        target="_blank"
        className={styles.logoWidth}
        rel="noreferrer"
      >
        <img src={logo} alt="Protocol Zone Pvt. Ltd." />
      </a>
    </div>
  );
};

export default PzoneLogo;
