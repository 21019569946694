import format from "date-fns/format";
import { API, API_URLS, getApiHeader } from "../../configs/api.config";
import { DATE_FORMATS } from "../../constants/dateTime.constant";
import { InitLazyParams } from "../../constants/general.constant";
import {
  formatTransactionTime,
  numberToDate,
} from "../../helpers/dateTime.helper";
import { LOG } from "../../helpers/debugging.helper";
import { toRaceKey } from "../../helpers/value.helper";
import { UserDTO } from "../../models/auth.model";
import {
  WagerPaginationDTO,
  WagersDTO,
  WagersPayloadDTO,
} from "../../models/transaction.model";
import { LazyLoadParams } from "../../types/general.type";

interface GetTransactionsParams {
  dates: Date[];
  user: UserDTO;
  lazyParams: LazyLoadParams;
  betOption: string;
  setWagers: (wagers: WagersDTO[] | null | undefined) => void;
  setLazyParams: (p: LazyLoadParams) => void;
  setIsLoading: (val: boolean) => void;
  setIsTableLoading: (val: boolean) => void;
}

export const TransactionApiService = () => {
  // * Manipulate pagination response for wagers
  const manageWagersResponse = (
    data: WagerPaginationDTO,
    params: GetTransactionsParams
  ) => {
    let _page = { ...data };
    // Total records only fetched for first page

    let _wagersData = [] as WagersDTO[];
    for (const wager of _page.data) {
      let raceKey = toRaceKey(wager.raceKey?.toString() ?? "");
      wager.raceKey = raceKey;
      wager.localDate = numberToDate(raceKey.localDate.toString());
      wager.trackName = raceKey.trackName;
      wager.raceNo = raceKey.raceNo;
      wager.totalRefunds = Number(wager.totalRefunds?.toFixed(2));
      const winnings = Number(wager.totalWinnings);
      const success = Number(wager.totalInvested);
      wager.totalWinnings = Number(winnings.toFixed(2));

      wager.totalInvested = Number(success.toFixed(2));
      if (wager.betStatus === "CONFIRMED" || wager.betStatus === "WAITING") {
        wager.returnPnl = 0;
      } else {
        wager.returnPnl = Number((winnings - success).toFixed(2));
      }

      let timeStr = format(
        new Date(Number(wager.timestamp)),
        DATE_FORMATS.yyyyMMddHHmmss
      );

      wager.formattedTime = formatTransactionTime(
        timeStr,
        wager.timezone,
        DATE_FORMATS.ddMMyyyy_hhmmss_DASH
      );

      // let timeStr = format(
      //   new Date(Number(wager.timeStamp)),
      //   DATE_FORMATS.ddMMyyyy_hhmmss_DASH
      // );

      // wager.formattedTime = timeStr;
      _wagersData.push(wager);
    }

    params.setWagers(_wagersData);
    if (_page.totalRecords)
      params.setLazyParams({
        ...params.lazyParams,
        totalRecords: _page.totalRecords,
      });
    //   } else {
    //     setWagerTransactions([]);
    //     setTotalRecords(0);
    //     setLazyParams({ ...InitLazyParams });
    //   }
  };

  // * Create payload for wagers api.
  const getWagerPayLoad = (params: GetTransactionsParams) => {
    const startDate = Number(
      format(params.dates[0] ?? new Date(), DATE_FORMATS.yyyyMMdd)
    );
    const endDate = Number(
      format(
        params.dates[1] ?? params.dates[0] ?? new Date(),
        DATE_FORMATS.yyyyMMdd
      )
    );
    const wagersPayLoad: WagersPayloadDTO = {
      customerId: params.user.clientId,
      startDate,
      endDate,
      historyType: "BET",
      pageNo: params.lazyParams.page,
      size: params.lazyParams.rows,
      betOptions: params.betOption,
    };
    return wagersPayLoad;
  };

  const getTransactions = async (params: GetTransactionsParams) => {
    const { user, setWagers, setLazyParams, setIsLoading, setIsTableLoading } =
      params;
    // debugger;
    try {
      const headers = getApiHeader(user);
      const payload = getWagerPayLoad(params);
      setIsTableLoading(true);
      const res = await API.post(API_URLS.transactions, payload, { headers });
      LOG("Wagers API CAlled", "API");

      setIsLoading(false);
      setIsTableLoading(false);
      if (res.data) {
        manageWagersResponse(res.data, params);
      } else {
        setWagers([]);
        setLazyParams({ ...InitLazyParams });
      }
    } catch (error) {
      console.error(error);
      setWagers(null);
      setIsLoading(false);
      setIsTableLoading(false);
      throw error;
    }
  };
  return { getTransactions };
};
