import { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { NegotiateBarTemplate } from "../../../../../common/helpers/templates/notifications.helper";
import { BetCardDTO } from "../../../../../common/models/bet.model";
import { ElementChangeEvent } from "../../../../../common/types/general.type";
import { AtomBetCards } from "../../../../../store/bets/bets.store";
import { LKeys } from "../../../../../store/locale/locale.data";
import useTranslation from "../../../../../store/locale/useTranslation";
import GoBetChips from "../../../../shared/chips/bet.chips";
import InlineLoader from "../../../../ui/loaders/inline.loader";
import SnackBar from "../../../../ui/snackBar/snackbar.ui";
import BetInput from "./betInput/bet.input";
import styles from "./_betCards.module.scss";

type Props = {
  betCard: BetCardDTO;
  placeBet: (bet: BetCardDTO) => void;
  onRemoveCard: () => void;
  isActive: boolean;
};

const WinPlaceBetCard = ({
  betCard,
  placeBet,
  onRemoveCard,
  ...props
}: Props) => {
  const tTracks = useTranslation(LKeys.tracks);
  const tGeneral = useTranslation(LKeys.generals);
  const tProvinces = useTranslation(LKeys.countries);
  const tRunners = useTranslation(LKeys.runners);
  const { bet, processing } = betCard;
  const { trackName, eventType, province, raceNo } = bet.raceKey;
  const [betCards, setBetCards] = useRecoilState(AtomBetCards);
  const [error, setError] = useState<string>("");
  const isFirst = useRef(true);

  useEffect(() => {
    const _amt = Number(bet.singleBetAmt ?? 0);
    if (_amt < bet.minBetAmount)
      setError("Minimum bet amount is " + bet.minBetAmount?.toFixed(2));
    else setError("");

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bet.singleBetAmt]);

  // * Handling change event in bet amount
  const handleAmtChange = ({ data }: ElementChangeEvent) => {

    let _pendingBets = { ...betCards };
    let _bet = { ...(betCards[bet.betKey]?.bet ?? {}) };
    _bet.singleBetAmt = data;
    _pendingBets[_bet.betKey] = { bet: _bet, processing: false };
    setBetCards(_pendingBets);
  };

  // * Single place bet
  const onPlaceBet = (toNegotiate?: boolean) => {
    let _betCard = { ...betCard };
    let _bet = { ..._betCard.bet };

    if (toNegotiate && _bet.betResponse) {
      _bet.selectionOdds = _bet.betResponse.negotiatedOdds;
      _bet.winPlaceOdds = _bet.selectionOdds[bet.selId];
      //   let pr = Number(_bet.singleBetAmt ?? 0) * _bet.winPlaceOdds;
      //   setPotentialReturn(pr);
    }

    delete _bet.betResponse;
    _betCard.bet = _bet;
    placeBet({ ..._betCard, processing: true });
  };

  const onBetChipChange = (chip: number) => {
    let _pendingBets = { ...betCards };
    let _bet = { ...(betCards[bet.betKey]?.bet ?? {}) };
    if (isFirst.current) {
      isFirst.current = false;
      _bet.singleBetAmt = chip.toString();
    } else
      _bet.singleBetAmt = (Number(_bet.singleBetAmt ?? 0) + chip).toString();
    _pendingBets[_bet.betKey] = { bet: _bet, processing: false };

    setBetCards(_pendingBets);
  };
  if (!props.isActive) return null;
  return (
    <BetCard className={styles.betCardWrapper}>
      <InlineLoader
        isVisible={processing}
        loadingText={tGeneral("processing")}
      />
      <BetCardHeader className={styles.betCardHeader}>
        <div className={styles.raceInfo}>
          <span className={`et32 ${eventType}-active`}></span>
          <div className={"pl05"}>
            <span className={styles.trackName}>{tTracks(trackName)}</span>
            <Labels className={styles.provinceInfo}>
              {tProvinces(province)} | {tGeneral("race")}{" "}
              <strong>{raceNo}</strong>
            </Labels>
          </div>
        </div>
        <div>
          <button onClick={onRemoveCard} className={styles.closeBtn}>
            <FaTimes />
          </button>
        </div>
      </BetCardHeader>

      {bet.resStatus && (
        <Status
          className={`${styles.statusWrapper} ${bet.resStatus} ${
            bet.betStatus !== "SUCCESS" ? "error" : "ok"
          }`}
        >
          {bet.resStatus}
        </Status>
      )}

      {!bet.resStatus && error && <Error>{error}</Error>}
      <GoBetChips onChange={onBetChipChange} />
      <div>
        <div className={styles.runnerInfo}>
          <SelId className={styles.selId}>{bet.selId}</SelId>{" "}
          {tRunners(bet.runnerName ?? "-")}
        </div>
        <div className={styles.betFooter}>
          <div className={styles.detailWrapper}>
            <BetInput
              onChange={handleAmtChange}
              value={bet.singleBetAmt ?? ""}
              label={tGeneral("betAmount")}
              disabled={bet.resStatus === "SUCCESS" ? true : false}
            />
          </div>
          <div className={`${styles.detailWrapper} ${styles.small}`}>
            <span>{tGeneral("pool")}</span>
            <Data>{tGeneral(bet.poolKey.poolType)}</Data>
          </div>
          <div className={`${styles.detailWrapper} ${styles.small}`}>
            <span>{tGeneral("odds")}</span>
            <Data className={styles.oddsWrapper}>
              {bet.winPlaceOdds ?? "-"}
            </Data>
          </div>

          <div style={{ display: "flex" }}>
            <BetButton
              onClick={() => onPlaceBet(false)}
              className={styles.betBtn}
              disabled={bet.resStatus === "SUCCESS" ? true : false || !bet.singleBetAmt ? true : false}
            >
              {tGeneral("bet")}
            </BetButton>
          </div>
        </div>
      </div>
      <SnackBar
        isShow={bet.betResponse?.negotiateId ? true : false}
        severity="warning"
      >
        {NegotiateBarTemplate(tGeneral, bet, onRemoveCard, () =>
          onPlaceBet(true)
        )}
      </SnackBar>
    </BetCard>
  );
};

export default WinPlaceBetCard;

// * Styled components
const Error = styled.div`
  color: ${(p) => p.theme.danger};
  background: ${(p) => p.theme.bodyContrast};
  font-size: 0.65rem;
  padding: 0.35rem;
  border-bottom: ${(p) => p.theme.borderContrast};
`;
const Status = styled.div`
  background: ${(p) => p.theme.bodyContrast};
  font-size: 0.65rem;
  padding: 0.35rem;
  border-bottom: ${(p) => p.theme.borderContrast};
  &.error,
  &.FAILED {
    color: ${(p) => p.theme.danger};
  }
  &.SUCCESS {
    color: ${(p) => p.theme.success};
  }
`;
const Data = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
  width: 100%;
  border-radius: var(--radius-default);
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  text-align: center;
  border-right: 0px solid ${(p) => p.theme.bodyContrastLight};
`;
const BetButton = styled.button`
  background: ${(p) => p.theme.warning};
  color: ${(p) => p.theme.warningText};
  padding: 0.5rem;
  &:disabled {
    color: ${(p) => p.theme.textDull};
  }
  /* margin-left: 3px; */
`;
const SelId = styled.span`
  background: ${(p) => p.theme.bodyContrast};
`;
const BetCard = styled.div`
  background: ${(p) => p.theme.bodyContrastLight};
  box-shadow: ${(p) => p.theme.shadowDefault};
  border: ${(p) => p.theme.borderContrast};
`;

const BetCardHeader = styled.div`
  /* border-top-left-radius: var(--radius-default);
  border-top-right-radius: var(--radius-default); */
  background: ${(p) => p.theme.bodyContrastDark};
`;

const Labels = styled.div`
  color: ${(p) => p.theme.textSecondary};
`;
