import { FaBox } from "react-icons/fa";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import styles from "./_errors.module.scss";

type Props = {
  errorKey: string;
  className?: string;
};

const NoData = (props: Props) => {
  const t = useTranslation(LKeys.errors);
  return (
    <div className={`${styles.noDataWrapper} ${props.className}`}>
      <FaBox style={{ marginRight: "0.75rem" }} size={20} /> {t(props.errorKey)}
    </div>
  );
};

export default NoData;
