import React from "react";
import styled from "styled-components";
import styles from "./switch.module.scss";

type Props = {
  label?: string;
  value: boolean;
  onChange?: (x: boolean) => void;
};

const Switch = (props: Props) => {
  return (
    <span className={styles.switchWrapper}>
      <SwitchButton
        onClick={() => props.onChange && props.onChange(!props.value)}
      >
        <SwitchCircle
          className={props.value ? "active" : "inactive"}
        ></SwitchCircle>
      </SwitchButton>
      {props.label && <label>{props.label}</label>}
    </span>
  );
};

export default Switch;

// * Styled Component
const SwitchButton = styled.button`
  border-radius: 2rem;
  position: relative;
  width: 45px;
  height: 20px;
  border: none;
  @media screen and (max-width: 480px) {
    width: 35px !important;
  }
`;

const SwitchCircle = styled.span`
  position: absolute;
  display: inline-block;
  top: 2.5px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transition: all 0.3s;
  background: ${(p) => p.theme.bodyContrastLight};
  &.active {
    right: 2.5px;
    /* transform: translate(-20%, 18%); */
  }
  &.inactive {
    left: 2.5px;
    /* transform: translate(20%, 18%); */
  }
`;
