import React from "react";
import styled from "styled-components";
import styles from "./footer.module.scss";
import LOGOLight from "../../../assets/logos/LogoLight.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../common/routes/default.routes";
import { FaHome } from "react-icons/fa";
import { MdHelpOutline, MdLocalPhone, MdPrivacyTip } from "react-icons/md";
import { format } from "date-fns";

export interface FooterProps {
  title: string;
}

export const Footer = () => {
  const onScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <FooterWrapper className={styles.footer}>
      <div className={styles.footWrapper}>
        <div className={styles.imgWrapper}>
          <img src={LOGOLight} alt="logo" />
        </div>
        <div className={styles.mainWrapper}>
          <div className={styles.ChildWrapper}>
            <TitleWrapper>
              <FaHome style={{ marginRight: "0.5rem" }} size={20} /> All Racings
            </TitleWrapper>
            <div className={styles.linkWrp}>
              <NavLink to={ROUTES.TH_RACING} onClick={onScroll}>
                Thoroughbred
              </NavLink>
              <NavLink to={ROUTES.HS_RACING} onClick={onScroll}>
                Harness
              </NavLink>
              <NavLink to={ROUTES.DO_RACING} onClick={onScroll}>
                Greyhounds
              </NavLink>
            </div>
          </div>
          <div className={styles.ChildWrapper}>
            <TitleWrapper>
              <MdHelpOutline style={{ marginRight: "0.5rem" }} size={20} /> Help
              & Support
            </TitleWrapper>
            <div className={styles.linkWrp}>
              <NavLink to={ROUTES.HELP} onClick={onScroll}>Getting Started</NavLink>
              {/* <NavLink to={ROUTES.HS_RACING}>FAQ</NavLink> */}
              {/* <NavLink to={ROUTES.DEMO}>Demo</NavLink> */}
            </div>
          </div>
          <div className={styles.ChildWrapper}>
            <TitleWrapper>
              <MdLocalPhone style={{ marginRight: "0.5rem" }} size={20} />
              Customer Support
            </TitleWrapper>
            <div className={styles.linkWrp}>
              <LinkTag
                href={"https://www.protocolzone.com/talk-to-us"}
                target="_blank"
              >
                Contact Us
              </LinkTag>
              <div>Raise a Query</div>
            </div>
          </div>
          <div className={styles.ChildWrapper}>
            <TitleWrapper>
              <MdPrivacyTip style={{ marginRight: "0.5rem" }} size={20} />
              Legal
            </TitleWrapper>
            <div className={styles.linkWrp}>
              <NavLink to={ROUTES.POLICY_PRIVACY_POLICY}>
                Privacy Policy
              </NavLink>
              <NavLink to={ROUTES.POLICY_TERMS_AND_USE}>Terms & Rules</NavLink>
            </div>
          </div>
        </div>
      </div>
      <CopyRightWrp className={styles.copyrightWrp}>
        Copyright &copy; {format(new Date(), "yyyy")}
        <PzoneSiteLink href="https://www.protocolzone.com/" target={"_blank"}>
          Protocolzone Private Limited.
        </PzoneSiteLink>
      </CopyRightWrp>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  color: ${(p) => p.theme.textContrast};
  background-color: ${(p) => p.theme.bodyContrast};
`;
const CopyRightWrp = styled.div`
  color: ${(p) => p.theme.textContrast};
  background: ${(p) => p.theme.bodyContrastDark};
`;

const NavLink = styled(Link)`
  line-height: 2;
  font-size: 0.9rem;
  color: ${(p) => p.theme.textContrast};
  &:hover {
    color: ${(p) => p.theme.textSecondary};
  }
  @media screen and (max-width: 590px) {
    font-size: 0.67rem;
  }
`;

const LinkTag = styled.a`
  line-height: 2;
  font-size: 0.9rem;
  color: ${(p) => p.theme.textContrast};
  &:hover {
    color: ${(p) => p.theme.textSecondary};
  }
  @media screen and (max-width: 590px) {
    font-size: 0.67rem;
  }
`;

const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
  color: ${(p) => p.theme.textSecondary};
  @media screen and (max-width: 590px) {
    font-size: 0.9rem;
  }
`;

const PzoneSiteLink = styled.a`
  padding-left: 0.2rem;
  color: ${(p) => p.theme.textContrast};
  &:hover {
    color: ${(p) => p.theme.textSecondary};
  }
`;
