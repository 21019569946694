import { atom, selector } from "recoil";
import {
  AggregatorDTO,
  InitDTO,
  UserDTO,
} from "../../common/models/auth.model";
import {
  AtomBetAmounts,
  AtomBetCards,
  AtomBetTab,
  AtomOpenBets,
  AtomShowBetSlip,
} from "../bets/bets.store";
import { AtomSidebar } from "../global/global.store";
import {
  AtomRaceNumbers,
  AtomSelectedDate,
  AtomTrackRaces,
  AtomUpcFilter,
  AtomUpcRaces,
} from "../races/races.store";
import {
  AtomLiveOdds,
  AtomPoolNames,
  AtomPoolsData,
} from "../races/selectedRace.store";
import {
  AtomHorseSelection,
  AtomIsBoxed,
  AtomRunnerPosition,
} from "../selection/selection.store";
import {
  AtomTransactionBetOption,
  AtomTransactionDates,
  AtomTransLazyParams,
  AtomWagerTransactions,
} from "../transaction/transaction.store";

export const AtomAuthUser = atom<UserDTO | null | undefined>({
  key: "atomUsers",
  default: undefined,
});

export const AtomAggregatorObject = atom<AggregatorDTO | undefined | null>({
  key: "poolRates",
  default: undefined, // undefined is loading state.
});

export const AtomInitObj = atom<InitDTO | null | undefined>({
  key: "AtomInitObj",
  default: undefined,
});

export const SelectorResetAllAtoms = selector({
  key: "resetAllAtoms",
  get: () => {},
  set: ({ reset }) => {
    reset(AtomAuthUser);
    reset(AtomBetTab);
    reset(AtomBetCards);
    reset(AtomShowBetSlip);
    reset(AtomOpenBets);
    reset(AtomBetAmounts);
    reset(AtomSidebar);
    reset(AtomSelectedDate);
    reset(AtomTrackRaces);
    reset(AtomUpcRaces);
    reset(AtomUpcFilter);
    reset(AtomRaceNumbers);
    reset(AtomLiveOdds);
    reset(AtomPoolNames);
    reset(AtomPoolsData);
    reset(AtomIsBoxed);
    reset(AtomHorseSelection);
    reset(AtomRunnerPosition);
    reset(AtomTransactionBetOption);
    reset(AtomTransactionDates);
    reset(AtomTransLazyParams);
    reset(AtomWagerTransactions);
  },
});
