import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import LandscapeAd from "../../components/Ads/landscape.ad";
import UpcRaceCards from "../../components/pages/upcRaces/upcRaceCards.component";
import { AtomUpcRaces } from "../../store/races/races.store";

const DORacingPage = () => {
  const upcRaces = useRecoilValue(AtomUpcRaces);

  return (
    <>
      <Helmet>
        <title>Greyhound Races - Ashva Racing</title>
      </Helmet>
      <div>
        <LandscapeAd />
        <UpcRaceCards
          races={upcRaces?.DO}
          title={"DO"}
          // isShow={filter.eventTypes.includes("TH")}
        />
      </div>
    </>
  );
};

export default DORacingPage;
