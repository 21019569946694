import { useEffect, useState } from "react";
import styled from "styled-components";
import { POOLS } from "../../../../common/constants/pools.constant";
import {
  getPoolName,
  sortExoticsObj,
} from "../../../../common/helpers/pool.helper";
import { RaceDetailsDTO } from "../../../../common/models/race.mode";
import { __PoolTypeDTO } from "../../../../common/types/dynamic.type";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import styles from "./exoticsPayoff.module.scss";

type Props = {
  poolsData: __PoolTypeDTO;
  race: RaceDetailsDTO;
};

interface ExoticType {
  poolType: string;
  winners: string[];
  payoffs: number[];
}
const ExoticsPayOff = ({ poolsData, race }: Props) => {
  const [exotics, setExotics] = useState<ExoticType[]>([]);
  const t = useTranslation(LKeys.generals);

  useEffect(() => {
    let _tempPools = { ...poolsData };
    delete _tempPools[POOLS.WIN];
    delete _tempPools[POOLS.PLACE];
    delete _tempPools[POOLS.SHOW];

    let _exotics = sortExoticsObj(_tempPools);

    setExotics(_exotics);
    return () => {};
  }, [poolsData]);

  const ExoticTable = () => {
    if (!exotics.length) return null;

    return (
      <Table className={styles.exoticsTable}>
        <Thead>
          <tr>
            <th>{t("betTypes")}</th>
            <th>{t("winners")}</th>
            <th>{t("payOut")}</th>
          </tr>
        </Thead>
        <tbody>
          {exotics.map((exotic) => {
            return (
              <Tr key={exotic.poolType}>
                {/* <td>{poolTranslate(exotic.poolType)}</td> */}
                <td>
                  {t(
                    getPoolName(exotic.poolType, race.raceKey?.province ?? "")
                  )}
                </td>
                <td>
                  <div className={styles.flexCol}>
                    {exotic.winners.map((winners) => {
                      return (
                        <WinnerWrapper
                          key={winners}
                          className={styles.borderWrapper}
                        >
                          <Winner className={styles.winnerWrapper}>
                            {winners.split("*").map((winner) => {
                              return (
                                <div className={"winners"} key={winner}>
                                  {winner}{" "}
                                </div>
                              );
                            })}
                          </Winner>
                        </WinnerWrapper>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <div className={styles.flexCol}>
                    {exotic.payoffs.map((val, index) => {
                      return (
                        <WinnerWrapper
                          className={styles.borderWrapper}
                          key={index}
                        >
                          <Winner className={styles.winnerWrapper}>
                            <div className={"winners"}>{val?.toFixed(2)}</div>
                          </Winner>
                        </WinnerWrapper>
                      );
                    })}
                  </div>
                </td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return <div>{ExoticTable()}</div>;
};

export default ExoticsPayOff;

// * Styled components
const Table = styled.table`
  border: ${(p) => p.theme.borderPrimary};
`;
const Thead = styled.thead`
  background: ${(p) => p.theme.bodySecondary};
  color: ${(p) => p.theme.text};
  font-size: 0.75rem;
  tr > th {
    text-align: left;
    padding: 0.25rem;
    &:not(:last-child) {
      border-right: ${(p) => p.theme.borderPrimary};
    }
    &:first-child {
      width: 10rem;
    }
    &:last-child {
      width: 8rem;
    }
  }
`;

const WinnerWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: ${(p) => p.theme.borderPrimary};
  }
`;
const Winner = styled.div`
  background: ${(p) => p.theme.bodySecondary};
  .winners {
    display: inline-block;
    padding: 0rem 0.5rem;
    &:not(:last-child) {
      border-right: ${(p) => p.theme.borderContrast};
    }
  }
`;

const Tr = styled.tr`
  td {
    border-bottom: ${(p) => p.theme.borderPrimary};
    &:first-child {
      border-right: ${(p) => p.theme.borderPrimary};
      padding-left: 0.5rem;
    }
    &:last-child {
      border-left: ${(p) => p.theme.borderPrimary};
    }
  }
  &:nth-child(odd) {
    td {
      background: ${(p) => p.theme.rowOdd};
    }
  }
  &:nth-child(even) {
    td {
      background: ${(p) => p.theme.rowEven};
    }
  }
`;
