import { CSSProperties } from "react";

interface ShimmerProps {
  style?: CSSProperties;
  className?: string;
}

const ShimmerLoaders = (props: ShimmerProps) => {
  return (
    <div
      className={`card br animate ${props.className ?? ""}`}
      style={{
        ...props.style,
      }}
    >
      <div className="wrapper">
        <div className="animate profilePic din"></div>
        {/* <div
          className={`${styles.animate} ${styles.comment} ${styles.w80} ${styles.br}`}
        ></div> */}
        {/* <div
          className={`${styles.animate} ${styles.comment} ${styles.br}`}
        ></div>
        <div
          className={`${styles.animate} ${styles.comment} ${styles.br}`}
        ></div> */}
      </div>
    </div>
  );
};
export default ShimmerLoaders;
