import { BsSunFill } from "react-icons/bs";
import { IoMdMoon } from "react-icons/io";
import styled from "styled-components";
import styles from "./switch.module.scss";

type Props = {
  value: boolean;
  onChange?: (val: boolean) => void;
};
export const ThemeSwitch = (props: Props) => {
  return (
    <div>
      <BtnWrapper
        onClick={() => props.onChange && props.onChange(!props.value)}
      >
        {props.value ? (
          <IoMdMoon size={20} className={styles.moon} />
        ) : (
          <BsSunFill className={styles.sun} size={20} />
        )}
      </BtnWrapper>
    </div>
  );
};

export default ThemeSwitch;

const BtnWrapper = styled.button`
  background-color: transparent;
  border: none;
`;
