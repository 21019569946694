import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import { RecoilRoot } from "recoil";
import "./index.scss";
import "./styles/_framework.scss";
import "./assets/sprites/eventType/_eventType.scss";
import "./assets/sprites/province/_province.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "react-multi-carousel/lib/styles.css"; //multi-carousel

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>
);
