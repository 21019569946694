import { useEffect, useRef, useState } from "react";
import { FaGlobe } from "react-icons/fa";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { DATA, supportedLocale } from "../../../store/locale/locale.data";
import { AtomLocaleData } from "../../../store/locale/locale.store";
import { LocaleDTO } from "../../../store/locale/locale.type";
import styles from "./lanChangerDropdown.module.scss";

const LanChangerDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [locale, setLocale] = useRecoilState(AtomLocaleData);
  const handleChangeState = (value: LocaleDTO) => {
    localStorage.setItem("locale", value.code);
    setLocale({
      locale: value.code,
      data: DATA[value.code],
    });
    setIsOpen(false);
  };
  useEffect(() => {
    const handleHideDropdown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };
    const handleClickOutside = (event: Event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={`${styles.localeChangerWrapper}`} ref={wrapperRef}>
      <Wallet onClick={() => setIsOpen(!isOpen)}>
        <LocaleLabel>{locale.locale.toUpperCase()}</LocaleLabel>
        <IconWrp className={styles.langIcon}>
          <FaGlobe />
        </IconWrp>
      </Wallet>
      {isOpen && (
        <div className={styles.localeOptionsWrapper}>
          {supportedLocale.map((locale: any) => {
            return (
              <span
                className={styles.btnWrp}
                onClick={() => handleChangeState(locale)}
              >
                <LocalOption className={styles.localeOption}>
                  {/* <i></i> */}
                  <span
                    className={`flag-32 flag-${locale.province.toLowerCase()}`}
                  ></span>
                  {locale.language}
                </LocalOption>
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanChangerDropdown;
const Wallet = styled.div`
  border-radius: var(--radius-default);
  border: ${(p) => p.theme.borderHighlight};
  display: flex;
  justify-content: center;
  /* align-items: center; */
  cursor: pointer;
  &:hover {
    background: ${(p) => p.theme.active};
  }
`;

const LocaleLabel = styled.button`
  color: ${(p) => p.theme.textContrast};
  background: transparent;
  padding: 0.5rem;
  min-width: 2rem;
  font-weight: 600;
  border: none;
  display: inline-flex;
`;

const LocalOption = styled.span`
  background: ${(p) => p.theme.bodyContrastDark};
  color: ${(p) => p.theme.textSecondary};
  &:hover {
    background: ${(p) => p.theme.active};
  }
`;

const IconWrp = styled.div`
  background: ${(p) => p.theme.active};
  border-left: 1px solid ${(p) => p.theme.bodyContrastDark};
  padding: 0 0.5rem;
`;
