import { RaceSnapDTO } from "../../../common/models/race.mode";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import NoData from "../../shared/errors/noData.error";
import Title from "../../shared/title/title.component";
import UpcRaceCard from "./upcRace.card";
import UpcRaceLoader from "./upcRaceLoader/upcRace.loader";
import styles from "./upcRaces.module.scss";

type Props = {
  races?: RaceSnapDTO[];
  title: string;
  isShow?: boolean;
};

const UpcRaceCards = (props: Props) => {
  const t = useTranslation(LKeys.generals);

  //   if (!props.isShow) return null;
  if (props.races === undefined) return <UpcRaceLoader />;

  return (
    <div className={styles.raceCardsWrapper}>
      <Title>
        <span className={`et32 ${props.title}-active`}> </span>
        <span>
          {t(props.title)} {t("races")}
        </span>
      </Title>
      {props.races?.length === 0 && <NoData errorKey={"noRaces"} />}
      {props.races?.length > 0 && (
        <div className={styles.cards}>
          {props.races.map((r, index) => {
            return <UpcRaceCard race={r} key={index} />;
          })}
        </div>
      )}
    </div>
  );
};

export default UpcRaceCards;
