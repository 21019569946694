import { useRecoilValue } from "recoil";
import { DATA } from "./locale.data";
import { AtomLocaleData } from "./locale.store";

const useTranslation = (parentKey: string) => {
  const localeData = useRecoilValue(AtomLocaleData); // State to store selected locale data
  // * Locale function that returns value for language
  const t = (childKey?: string) => {
    const parent: any = localeData.data[parentKey];
    if (!childKey) return "";
    if (parent && childKey in parent) {
      return parent[childKey];
    }
    if (DATA.en[parentKey]) return DATA.en[parentKey][childKey] ?? childKey;
    return childKey;
  };

  return t;
};

export default useTranslation;
