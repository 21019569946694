import styled from "styled-components";
import Card from "../../../ui/card/card.component";
import TipsImage from "../../../../assets/images/TipsImage.png";
import { useRecoilValue } from "recoil";
import {
  AtomSelectedRaceTips,
  AtomSelectedRaceTrips,
  AtomSelectedSilks,
} from "../../../../store/races/selectedRace.store";
import useTranslation from "../../../../store/locale/useTranslation";
import { LKeys } from "../../../../store/locale/locale.data";
import styles from "./raceTipComponent.module.scss";
import {
  AmericanCode,
  AustraliaCode,
  EuropeCode,
} from "../../../../common/helpers/templates/silkColors.helper";
import { AtomSelectedRace } from "../../../../store/races/races.store";
import jokey from "../../../../assets/images/default_silk.png";

export interface RaceTipComponentProps {
  title: string;
}

export const RaceTipComponent = () => {
  const tips = useRecoilValue(AtomSelectedRaceTips);
  const trips = useRecoilValue(AtomSelectedRaceTrips);
  const tRunners = useTranslation(LKeys.runners);
  const tGenerals = useTranslation(LKeys.generals);
  const silks = useRecoilValue(AtomSelectedSilks);
  const race = useRecoilValue(AtomSelectedRace);

  const TipHeaderTemplate = () => {
    return (
      <TipHead>
        <img src={TipsImage} height={25} width={25} alt="PzoneLogo" />

        <div className="pl05">{tGenerals("expertTips")}</div>
      </TipHead>
    );
  };

  const getDogBackground = (selId: string) => {
    if (!race?.raceKey) return "white";
    let _codes = EuropeCode;
    if (race.raceKey.province) {
      if (race.raceKey.province?.match(/^(US)$/)) {
        _codes = AmericanCode;
      } else if (race.raceKey.province.match(/^(AU|NZ)$/)) {
        _codes = AustraliaCode;
      }
    }
    const _color = _codes[selId]?.color ?? "White";
    return _color;
  };

  const getSortedTips = () => {
    return Object.entries(tips ?? {}).sort((a, b) => {
      return b[1] - a[1];
    });
  };

  if (!tips) return null;
  return (
    <Card headerTemplate={TipHeaderTemplate}>
      <div className={styles.tipHeaders}>
        <span>{tGenerals("runners")}</span>
        <span>{tGenerals("winProbability")}</span>
      </div>

      <div className="pb1">
        {getSortedTips().map((entry) => {
          const selId = entry[0];
          const _runner = trips?.find((x) => x.raceTripKey.selId === selId);
          return (
            <ProbCard key={selId}>
              <div className={styles.runnerInfo}>
                <div
                  style={{
                    background:
                      race?.raceKey?.eventType === "DO"
                        ? getDogBackground(selId)
                        : "#3F487A88",
                  }}
                  className={styles.silkWrapper}
                >
                  {race?.raceKey.eventType !== "DO" && (
                    <img src={silks[selId] ?? jokey} alt={""} />
                  )}
                </div>
                <strong>{selId}. </strong> &nbsp; {tRunners(_runner?.horseName)}
              </div>
              <div className="text-right bold">
                {tips[selId] ? (tips[selId] * 100).toFixed(2) + "%" : "-"}
              </div>
            </ProbCard>
          );
        })}
      </div>
    </Card>
  );
};

export default RaceTipComponent;

const ProbCard = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
  margin: 0.5rem;
  border-radius: var(--radius-default);
  display: grid;
  align-items: center;
  grid-template-columns: 80% 20%;
  padding: 0.5rem 0.5rem;
  font-size: 0.85rem;
`;

const TipHead = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 1.1rem;
  display: flex;

  background: ${(p) => p.theme.bodyContrastDark};
`;
