import { doc, getDoc } from "firebase/firestore";
import { ReactNode, useEffect, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Auth, Firestore } from "../../common/configs/firebase.config";
import { LS_KEYS, NODES } from "../../common/constants/general.constant";
import { UserDTO } from "../../common/models/auth.model";
import { AtomAggregatorObject, AtomAuthUser, AtomInitObj } from "./auth.store";
import { useCallback } from "react";
import { User } from "@firebase/auth";
import { ClientAPIService } from "../../common/services/api/clientApi.service";
import { LOG, showError } from "../../common/helpers/debugging.helper";
import { _setStorage } from "../../common/helpers/storage.helper";
import LottieLoader from "../../components/elements/loader/lottie.loader";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../common/routes/default.routes";

interface AuthProviderProps {
  children: ReactNode;
}
const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setAuthUser] = useRecoilState(AtomAuthUser);
  const { getInitInfo, getPoolRates } = ClientAPIService();
  const setInitObj = useSetRecoilState(AtomInitObj);
  const setAggregatorObj = useSetRecoilState(AtomAggregatorObject);
  const location = useLocation();
  const navigate = useNavigate();
  const _auth = useRef<any>(null);
  useEffect(() => {
    if (_auth.current) _auth.current();
    _auth.current = Auth.onAuthStateChanged((user) => {
      const path = location.pathname;
      // console.log(path);
      if (!user && !path.startsWith("/auth")) {
        navigate(ROUTES.LOGIN);
      } else if (user && path.startsWith("/auth")) {
        navigate(ROUTES.ALL_RACES);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // const pathName = useLocation().pathname;
  // const navigate = useNavigate();

  // useEffect(() => {
  //   debugger;
  //   if (user !== undefined) {
  //     if (!user && !pathName.startsWith("/auth")) navigate(ROUTES.LOGIN);
  //     else if (user && pathName.startsWith("/auth")) navigate(ROUTES.ALL_RACES);
  //   }
  // }, [pathName, navigate, user]);

  // useEffect(() => {
  //     if(user) {
  //       LOU.identify(userId, { ...userProperties });
  //     }

  // }, [user])

  const getInitAggregatorInfo = useCallback(
    (user: UserDTO) => {
      if (!user?.clientId) return;
      LOG("Get Init Info", "API");
      getInitInfo({ user })
        .then((res) => {
          setInitObj(res);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // * Get aggregator information for pool rates and min bet amount
  const getAggregatorPoolRates = useCallback(
    (user: UserDTO) => {
      if (!user?.clientId) return;
      getPoolRates({ user })
        .then((res) => {
          LOG("Get PoolRates Info", "API");
          setAggregatorObj(res);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [getPoolRates, setAggregatorObj]
  );

  // * Get Users Data on initialization of the app.
  const getUser = useCallback((user: User) => {
    if (!user) return;
    const docRef = doc(Firestore, `${NODES.CLIENTS}/${user.uid}`);
    getDoc(docRef)
      .then((res) => {
        if (res.exists()) {
          let _user = res.data() as UserDTO;
          _user.photoURL = user.photoURL ?? _user.photoURL ?? null;
          setAuthUser(_user);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTokenRefresh = useCallback(() => {
    setInterval(() => {
      Auth.currentUser
        ?.getIdToken()
        .then((res) => {
          // console.log({ res });
          _setStorage(LS_KEYS.token, res);
        })
        .catch((err) => {
          console.error({ err });
          showError(err);
          // TODO: navigate to login
        });
    }, 10 * 60 * 1000);
  }, []);

  useEffect(() => {
    if (user) {
      getInitAggregatorInfo(user);
      getAggregatorPoolRates(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged((user) => {
      if (user?.uid) getUser(user);
    });
    startTokenRefresh();
    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user === undefined) return <LottieLoader />;
  if (user === null) return <>User Data Not Found!</>;
  return <div>{children}</div>;
};

export default AuthProvider;

// * Styled components
