import { useRecoilValue } from "recoil";
import { RaceTripDTO } from "../../../../../common/models/raceTrip.model";
import { LKeys } from "../../../../../store/locale/locale.data";
import useTranslation from "../../../../../store/locale/useTranslation";
import { AtomSelectedSilks } from "../../../../../store/races/selectedRace.store";
import default_silk from "../../../../../assets/images/default_silk.png";
import styles from "./tripInfo.module.scss";
import { getValidValue } from "../../../../../common/helpers/validation.helper";
import styled from "styled-components";
import { RaceKeyDTO } from "../../../../../common/models/race.mode";
import {
  AmericanCode,
  AustraliaCode,
  EuropeCode,
} from "../../../../../common/helpers/templates/silkColors.helper";

type Props = {
  trip: RaceTripDTO;
  raceKey?: RaceKeyDTO;
};

const TripInfo = ({ trip, raceKey }: Props) => {
  const tGenerals = useTranslation(LKeys.generals);
  const tJockeys = useTranslation(LKeys.jockeys);
  const tTrainers = useTranslation(LKeys.jockeys);
  const silks = useRecoilValue(AtomSelectedSilks);
  const { horseName, age, trainerName, jockeyName, breedType } = trip;
  const { selId } = trip.raceTripKey;

  const getMetaData = (label: string, data: string | number) => {
    if (!getValidValue(data)) return null;
    return (
      <span className={styles.infoWrapper}>
        <Label htmlFor={label}>{tGenerals(label)}</Label> :{" "}
        <Span>{data?.toString().replace(/_/, " ") ?? " NONE "}</Span>
      </span>
    );
  };

  const getDogBackground = () => {
    if (!raceKey) return "white";
    let _codes = EuropeCode;
    if (raceKey.province) {
      if (raceKey.province?.match(/^(US)$/)) {
        _codes = AmericanCode;
      } else if (raceKey.province.match(/^(AU|NZ)$/)) {
        _codes = AustraliaCode;
      }
    }
    const _color = _codes[selId]?.color ?? "White";
    return _color;
  };

  return (
    <div className={`${styles.horseInfo} `}>
      <div
        style={{
          background:
            raceKey?.eventType === "DO" ? getDogBackground() : "white",
        }}
        className={styles.silkWrapper}
      >
        {raceKey?.eventType !== "DO" && (
          <img src={silks[selId] ?? default_silk} alt={selId} />
        )}
      </div>
      <div>
        <div
          className={`${styles.horseNameWrp} ${
            trip.scratched ? styles.scr : ""
          }`}
        >
          {selId}. {tGenerals(horseName)}
        </div>
        <div className={styles.runnerInfo}>
          <div>
            {getMetaData("rider", tJockeys(jockeyName)?.toLowerCase())}
            {getMetaData("Age", age)}
          </div>
          <div>
            {getMetaData("trainer", tTrainers(trainerName)?.toLowerCase())}
            {getMetaData("breed", breedType?.toLowerCase())}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripInfo;

// * Styled components
const Label = styled.label`
  /* color: ${(p) => p.theme.bodyContrastLight}; */
  color: ${(p) => p.theme.textTernary};
`;

const Span = styled.span`
  color: ${(p) => p.theme.textTernaryDark};
`;
