import { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { Severity } from "../../../common/types/general.type";
import { AtomSelectedThemeData } from "../../../store/theme/theme.store";
import styles from "./snackbar.module.scss";

type Props = {
  severity?: Severity;
  children?: ReactNode;
  isShow?: boolean;
  isRelative?: boolean;
};

const SnackBar = (props: Props) => {
  const theme = useRecoilValue(AtomSelectedThemeData);
  if (!props.isShow) return null;
  return (
    <div
      style={{
        background: theme && theme[props.severity ?? "primary"],
        color:
          theme &&
          theme[`${props.severity ? props.severity + "Text" : "primaryText"}`],
      }}
      className={`${styles.snackBarWrapper} ${
        props.isRelative ? styles.relative : styles.absolute
      }`}
    >
      {props.children}
    </div>
  );
};

export default SnackBar;
