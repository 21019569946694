import { RiArrowUpSFill } from "react-icons/ri";
import styled from "styled-components";
import { RaceDetailsDTO } from "../../../../../common/models/race.mode";
import { RaceTripDTO } from "../../../../../common/models/raceTrip.model";
import styles from "./MultiRaceTripDetail.module.scss";

type Props = {
  trip: RaceTripDTO;
  race: RaceDetailsDTO | null | undefined;
  showDetail: boolean;
};

const MultiRaceTripDetail = (props: Props) => {
  const Detail = ({ label, data }: any) => {
    return (
      <div className={styles.detailWrapper}>
        <label className={styles.detailLabel}>{label}</label>
        <span className={styles.detailData}>{data}</span>
      </div>
    );
  };

  return (
    <>
      <Body className={props.showDetail ? styles.show : styles.Hide}>
        <div className={styles.detailCard}>
          <ArrowUp className={styles.arrowIcon}>
            <RiArrowUpSFill size={40} />
          </ArrowUp>
          <div className={styles.detailCardHeader}>Runner Details : </div>
          <div className={styles.detailCardBody}>
            <Detail label={"Horse"} data={props.trip.horseName} />
            <Detail label={"Jockey"} data={props.trip.jockeyName} />
            <Detail label={"Trainer"} data={props.trip.trainerName} />
            <Detail label={"Barrier Position"} data={props.trip.barrierPos} />
            <Detail label={"Age"} data={props.trip.age} />
            <Detail label={"Sex"} data={props.trip.sex} />
            <Detail label={"Carried Weight"} data={props.trip.carriedWeight} />
            <Detail label={"Claiming Price"} data={props.trip.claimingPrice} />
          </div>
        </div>
      </Body>
    </>
  );
};

export default MultiRaceTripDetail;

const Body = styled.div`
  color: ${(p) => p.theme.textTernary};
  background: ${(p) => p.theme.bodyContent};
`;
const ArrowUp = styled.div`
  color: ${(p) => p.theme.bodyContent};
`;
