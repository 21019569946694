import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Firebase config variables in env.local file
// * Global Odds Database.
// const firebaseConfig = {
//   apiKey: process.env.NEXT_PUBLIC_ASHVA_API_KEY,
//   authDomain: process.env.NEXT_PUBLIC_ASHVA_AUTH_DOMAIN,
//   databaseURL: process.env.NEXT_PUBLIC_ASHVA_DATABASE_URL,
//   storageBucket: process.env.NEXT_PUBLIC_ASHVA_STORAGE_BUCKET,
//   projectId: process.env.NEXT_PUBLIC_ASHVA_PROJECT_ID,
//   messagingSenderId: process.env.NEXT_PUBLIC_ASHVA_MESSAGING_SENDER_ID,
//   appId: process.env.NEXT_PUBLIC_ASHVA_APP_ID,
//   measurementId: process.env.NEXT_PUBLIC_ASHVA_MEASUREMENT_ID,
// };

// * Racing Ashva Database

const firebaseConfig = {
  apiKey: "AIzaSyAw9F6D1N7PAqQhAXbCIam1bTxwPS5E9l8",
  authDomain: "demo.ashva.app",
  databaseURL:
    "https://racing-ashva-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "racing-ashva",
  storageBucket: "racing-ashva.appspot.com",
  messagingSenderId: "849735824732",
  appId: "1:849735824732:web:034360a5b2bb1454767d99",
  measurementId: "G-4J3FS0H3XL",
};

// // * Go Racing Database.
// * Go Racing Database.
// const firebaseConfig = {
//   apiKey: "AIzaSyDOCXNQ17nXQDqlRsIx3ZIhdtZpy6C0yKA",
//   authDomain: "global-racing-53b64.firebaseapp.com",
//   databaseURL: "https://global-racing-53b64-default-rtdb.firebaseio.com",
//   projectId: "global-racing-53b64",
//   storageBucket: "global-racing-53b64.appspot.com",
//   messagingSenderId: "275459376407",
//   appId: "1:275459376407:web:a769275df6d49e1fff6352",
// };

// * Test demo dipesh Database.
// const firebaseConfig = {
//   apiKey: "AIzaSyC7q5XDxiK86ijA4MVpypOpxtf7tEbIcyE",
//   authDomain: "testapp-fab0b.firebaseapp.com",
//   databaseURL: "https://testapp-fab0b.firebaseio.com",
//   projectId: "testapp-fab0b",
//   storageBucket: "testapp-fab0b.appspot.com",
//   messagingSenderId: "127295312527",
//   appId: "1:127295312527:web:5868b137aa2a47577756fa",
// };

const app = initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcruzYkAAAAALep2qx1258LOdwLGok244FEzttn"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

// getToken(appCheck)
//   .then((res) => {
//     console.log("SUCCESS APP CHECK");
//   })
//   .catch((err) => {
//     console.error("App Check Error", err);
//   });

export const RTDatabase = getDatabase(app);
export const Auth = getAuth(app);
export const Firestore = getFirestore(app);
export const Storage = getStorage(app);
