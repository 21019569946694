import { format } from "date-fns";
import { Link } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import { ROUTES } from "../../../common/routes/default.routes";
import styles from "./footer.module.scss";

type Props = {
  fixed?: boolean;
};

const PolicyFooter = (props: Props) => {
  const year = format(new Date(), "yyyy");

  const style: CSSProperties = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  };

  return (
    <Footer style={props.fixed ? style : {}} className={styles.policyFooter}>
      <LinkWrapper>
        Copyright &copy; {year} &nbsp;
        <A className={styles.link} href="https://www.protocolzone.com">
          Protocolzone Private Limited.
        </A>
      </LinkWrapper>
      <LinkWrapper className={styles.linksWrapper}>
        <Link className={styles.link} to={ROUTES.PRIVACY_POLICY}>
          Privacy Policy
        </Link>
        |
        <Link className={styles.link} to={ROUTES.TERMS_OF_USE}>
          Terms of Use
        </Link>
      </LinkWrapper>
    </Footer>
  );
};

export default PolicyFooter;

const A = styled.a`
  color: ${(p) => p.theme.textSecondary};
  text-decoration: underline;
`;
const LinkWrapper = styled.div`
  color: ${(p) => p.theme.textSecondary};
`;
const Footer = styled.footer`
  background: ${(p) => p.theme.bodyContrast};
`;
