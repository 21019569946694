import { useEffect, useRef, useState } from "react";
import { FaWallet } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  AtomAggregatorObject,
  AtomAuthUser,
} from "../../../store/auth/auth.store";
import styles from "./walletBalance.module.scss";

const WalletBalance = () => {
  const user = useRecoilValue(AtomAuthUser);
  const aggregator = useRecoilValue(AtomAggregatorObject);
  const prevBalance = useRef(0);
  const [render, setRender] = useState(0);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current === false && aggregator?.balance) {
      prevBalance.current = Number(aggregator.balance ?? 0);
      isMounted.current = true;
    }
  }, [aggregator?.balance]);

  useEffect(() => {
    if (isMounted.current) {
      const _currentBalance = Number(aggregator?.balance ?? 0);
      if (prevBalance.current > _currentBalance) {
        setRender(-1);
      } else if (prevBalance.current < _currentBalance) {
        setRender(1);
      }
      prevBalance.current = _currentBalance;
      setTimeout(() => {
        setRender(0);
      }, 1000);
    }
  }, [aggregator?.balance]);

  return (
    <Wallet className={styles.walletWrapper}>
      <div
        className={`${styles.balanceWrapper} ${
          render === -1 ? styles.negative : render === 1 ? styles.positive : ""
        }`}
      >
        <FaWallet className={styles.icon} style={{ marginRight: "0.5rem" }} />
        {aggregator?.balance?.toLocaleString("en-IN") ?? 0}
      </div>
      <IconWrapper className={styles.iconWrp}>
        {/* <IoWalletSharp size={15} /> */}
        {user?.currency}
      </IconWrapper>
    </Wallet>
  );
};

export default WalletBalance;

// * Styled Components
const Wallet = styled.div`
  border-radius: var(--radius-default);
  border: ${(p) => p.theme.borderHighlight};
`;

const IconWrapper = styled.div`
  background: ${(p) => p.theme.activeText};
`;
