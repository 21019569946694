import { useRecoilValue } from "recoil";
import { AtomUpcRaces } from "../../../store/races/races.store";
import UpcRaceCards from "./upcRaceCards.component";
import styles from "./upcRaces.module.scss";

const UpcRaces = () => {
  const upcRaces = useRecoilValue(AtomUpcRaces);

  return (
    <div className={styles.racesWrapper}>
      <UpcRaceCards
        races={upcRaces?.TH}
        title={"TH"}
        // isShow={filter.eventTypes.includes("TH")}
      />
      <UpcRaceCards
        races={upcRaces?.HS}
        title={"HS"}
        // isShow={filter.eventTypes.includes("HS")}
      />
      <UpcRaceCards
        races={upcRaces?.DO}
        title={"DO"}
        // isShow={filter.eventTypes.includes("DO")}
      />
      {/* <UpcRaceCards
        isShow={true}
        races={upcRaces.RM}
        title={t("upcomingRaces")}
      /> */}
    </div>
  );
};

export default UpcRaces;
