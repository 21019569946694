import { LazyLoadParams } from "../types/general.type";

export const CLOSED = "CLOSED";
export const RESULTED = "RESULTED";
export const OPEN = "OPEN";
export const CANCELLED = "CANCELLED";
export const NON_DISPLAY = "NON_DISPLAY";

export const EventTypes = ["TH", "HS", "DO"];

export enum Sidebar {
  navSideMenu = "navSideMenu",
  betSideBar = "betSideBar",
}

export const LS_KEYS = {
  bets: "bets",
  token: "$tk",
  auth: "_aUsr",
};

export const NODES = {
  CLIENTS: "CLIENTS", // for live database
  GLOBAL_HEADER: "GLOBAL_HEADER",
  NOTIFY: "NOTIFY",
  // CLIENTS: "DEMO_CLIENT", // for development-purpose
};

export const DigitToWords: any = {
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
};

export const InitLazyParams: LazyLoadParams = {
  first: 0,
  rows: 10,
  page: 1,
  totalRecords: 0,
};

export const PreventOutSideClasses = {
  betSlipToggle: "betSlipToggle",
};
