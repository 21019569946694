import { DATA } from "./locale.data";
import { atom, selector } from "recoil";

export const AtomLocalSelector = selector({
  key: "atomLocalSelector",
  get: ({ get }) => {
    const locale: any = localStorage.getItem("locale");
    return {
      locale: locale ?? "en",
      data: DATA[locale ?? "en"],
    };
  },
});

// * state for locale data for selected locale.
export const AtomLocaleData = atom<any>({
  key: "atomLocaleData",
  default: AtomLocalSelector,
});
