import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useResetRecoilState } from "recoil";
import SelectedRaceComponent from "../../components/pages/selectedRace/selectedRace.component";
import { AtomSelectedRace } from "../../store/races/races.store";
import SelectedRaceProvider from "../../store/races/selectedRace.provider";
import { AtomSelectedRaceTips } from "../../store/races/selectedRace.store";

const SelectedRacePage = () => {
  const resetTrips = useResetRecoilState(AtomSelectedRaceTips);
  const resetRace = useResetRecoilState(AtomSelectedRace);
  
  useEffect(() => {
    return () => {
      resetRace();
      resetTrips();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Ashva Racing</title>
      </Helmet>
      <SelectedRaceProvider>
        <SelectedRaceComponent />
      </SelectedRaceProvider>
    </>
  );
};

export default SelectedRacePage;
