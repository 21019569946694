import { FaCheck, FaTimes } from "react-icons/fa";
import ElevatedButton from "../../../components/ui/buttons/elevated.button";
import { BetRequestDTO } from "../../models/bet.model";

export const NegotiateBarTemplate = (
  translate: (childKey: string) => any,
  bet: BetRequestDTO,
  onReject: () => void,
  onAccept: () => void
) => (
  <div>
    <div>
      {translate("negotiateMessage")}
      <b>
        {bet.betResponse?.negotiatedOdds && (
          <em>{bet.betResponse?.negotiatedOdds[bet.selId]}?</em>
        )}
      </b>
    </div>
    <div className="text-right">
      <ElevatedButton
        className="mr05"
        severity="danger"
        size="xs"
        onClick={onReject}
      >
        <FaTimes /> {translate("reject")}
      </ElevatedButton>
      <ElevatedButton severity="success" size="xs" onClick={onAccept}>
        <FaCheck /> {translate("accept")}
      </ElevatedButton>
    </div>
  </div>
);
