import { RaceKeyDTO } from "../../common/models/race.mode";
import {
  __SelectionOddsDTO,
  __FavoriteDTO,
  __MultiSelectionDTO,
} from "../../common/types/dynamic.type";
import { atom } from "recoil";
import { RaceTripDTO } from "../../common/models/raceTrip.model";

export const AtomLegsRacesTrips = atom<{ [leg: string]: RaceTripDTO[] }>({
  key: "atomLegsRacesTrips",
  default: {},
});

export const AtomMultiWinLiveOdds = atom<{ [leg: string]: __SelectionOddsDTO }>(
  {
    key: "atomWinLiveOdds",
    default: {},
  }
);

export const AtomMultiLegFav = atom<__FavoriteDTO>({
  key: "atomMultiLegFav",
  default: {},
});

export const AtomMultiBetSelection = atom<__MultiSelectionDTO>({
  key: "atomMultiBetSelection",
  default: {},
});

export const AtomMultiBetCombo = atom<string[]>({
  key: "atomMultiBetcombo",
  default: [],
});

export const AtomAllLegs = atom<RaceKeyDTO[]>({
  key: "atomMultiRaceKeys",
  default: [],
});

export const AtomSelectedRaceNo = atom<number>({
  key: "atomSelectedRaceNo",
  default: 0,
});
