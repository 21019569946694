import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { getRacePath } from "../../../../common/helpers/routing.helper";
import { RaceSnapDTO } from "../../../../common/models/race.mode";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import { AtomSelectedRace } from "../../../../store/races/races.store";
import Tooltips from "../../../ui/tooltip/tooltip.ui";
import RaceStatus from "../../raceStatus/raceStatus.component";
import styles from "./raceSnapHeader.module.scss";

type Props = {
  race: RaceSnapDTO;
};

const RaceSnapCard = ({ race }: Props) => {
  const { eventType, raceNo, province, trackName } = race.raceKey;
  const tCountries = useTranslation(LKeys.countries);
  const tTracks = useTranslation(LKeys.tracks);
  const selectedRace = useRecoilValue(AtomSelectedRace);
  return (
    <RaceCard
      to={getRacePath(race?.raceKey)}
      className={`${styles.raceSnapCard} ${
        race.rk === selectedRace?.raceKeyString ? "active" : ""
      }  `}
    >
      <div className={styles.infoWrapper}>
        <span className={`et48 ${eventType}-active`}></span>
        <div className={styles.trackInfo}>
          <span className={styles.trackName}>
            <Tooltips
              info={tTracks(trackName)}
              trackName={tTracks(trackName)}
            />
          </span>
          <span className={styles.raceNo}>&nbsp;R{raceNo}</span>
          <div className={styles.footerWrapper}>
            <div className={styles.provinceInfo}>
              <span className={`flag-16 flag-${province.toLowerCase()}`}></span>
              <span>{tCountries(province)}</span>
            </div>
            <RaceStatus isCanceled={race.cl} status={race.rs} mtp={race.mtp} />
          </div>
        </div>
      </div>
    </RaceCard>
  );
};

export default RaceSnapCard;

// * Styled components
const RaceCard = styled(Link)`
  /* height: calc(var(--height-header) - 0.5rem); */
  background: linear-gradient(
    ${(p) => p.theme.bodyContrast},
    ${(p) => p.theme.bodyContrast}
  );
  margin: 0.25rem;
  border-radius: var(--radius-default);
  color: ${(p) => p.theme.textContrast};

  &:hover {
    box-shadow: 0 0 0 2px ${(p) => p.theme.body},
      0 0 0 4px ${(p) => p.theme.active}, 0 1px 2px 0 ${(p) => p.theme.body};
  }
  &.active {
    box-shadow: 0 0 0 2px ${(p) => p.theme.body},
      0 0 0 4px ${(p) => p.theme.active}, 0 1px 2px 0 ${(p) => p.theme.body};
    background: ${(p) => p.theme.selectedContrastBody};
  }
  @media screen and (min-width: 591px) {
    height: calc(var(--height-header) - 0.5rem);
  }
`;
