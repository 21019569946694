import chalk from "chalk";
import { toast, ToastOptions, TypeOptions } from "react-toastify";

// * Action wise colors of log.
const getAction = (action: string) => {
  let _action = chalk.black(" " + action + " ");
  switch (action) {
    case "API":
      return chalk.bgBlueBright(_action);
    case "SOCKET":
      return chalk.bgGreenBright(_action);
    case "OPEN":
      _action = chalk.bgBlueBright(_action);
      break;
    case "CLOSED":
      _action = chalk.bgRed(_action);
      break;
    case "MESSAGE":
      _action = chalk.bgCyan(_action);
      break;
    case "INFO":
      return chalk.bgGreenBright(_action);
    default:
      _action = chalk.bgYellowBright(_action);
      break;
  }
  return _action;
};

// * Console log template for unify
export const LOG = (
  msg: any,
  action: "API" | "ERROR" | "CRUD" | "INFO" | "SOCKET" = "INFO"
) => {
  const _msg = typeof msg !== "object" ? msg : JSON.stringify(msg);
  console.log(`${getAction(action)}: ${chalk.blueBright(_msg)}`);
};

const toastOptions: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

// * Toastify template to show toasts .
export const showToast = (message: string, type?: TypeOptions) => {
  toast(message, { ...toastOptions, type: type ?? "info" });
};

export const showError = (err: any) => {
  const message = err?.message ?? "Something went wrong! Please try again!";
  console.error(err);
  toast(message, { ...toastOptions, type: "error" });
};
