import { IconType } from "react-icons";
import { FaFlagCheckered } from "react-icons/fa";
import { GoCalendar, GoClock } from "react-icons/go";
import { WiDaySunnyOvercast } from "react-icons/wi";
import { RiPinDistanceLine, RiRouteFill } from "react-icons/ri";
import styled from "styled-components";
import { RaceDetailsDTO } from "../../../../common/models/race.mode";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import styles from "./raceMetaData.module.scss";
import { getValidValue } from "../../../../common/helpers/validation.helper";

type Props = {
  race: RaceDetailsDTO | null | undefined;
};

const RaceMetaData = ({ race }: Props) => {
  const tGeneral = useTranslation(LKeys.generals);
  const Details = (
    label: string,
    data: any,
    Icon: IconType,
    postUnit?: string
  ) => {
    const _data = getValidValue(data);
    if (!_data) return null;
    return (
      <div className={styles.details}>
        <span className={styles.iconWrapper}>
          <Icon size={20} />
        </span>
        <div className={styles.dataWrapper}>
          <Label className={styles.label}>{tGeneral(label)}</Label>
          <span className={styles.data}>
            {_data} {postUnit}
          </span>
        </div>
      </div>
    );
  };

  if (!race) return null;
  return (
    <MetaWrapper className={styles.metaWrapper}>
      {Details(
        "raceType",
        race.raceType?.replace(/&amp;/g, " & "),
        FaFlagCheckered
      )}
      {Details("date", race.localDate, GoCalendar)}
      {Details("time", race.localPostTime, GoClock)}
      {Details("distance", race.distance, RiPinDistanceLine, "m")}
      {Details("trackCondition", race.trackCondition, RiRouteFill)}
      {Details("weather", race.weather, WiDaySunnyOvercast)}
    </MetaWrapper>
  );
};

export default RaceMetaData;

const MetaWrapper = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
  color: ${(p) => p.theme.textContrast};
`;

const Label = styled.label`
  color: ${(p) => p.theme.textSecondary};
`;
