import React from "react";
import ShimmerLoaders from "../../../../ui/shimmerLoaders/shimmerLoader.ui";
import styles from "./raceNumberLoader.module.scss";

const RaceNumberLoader = () => {
  const style = {
    width: 180,
    height: 50,
  };
  return (
    <div>
      <div className={styles.headerWrp}>
        {Array(10)
          .fill("")
          .map((i) => (
            <ShimmerLoaders key={i} style={style} />
          ))}
      </div>
    </div>
  );
};

export default RaceNumberLoader;
