import styles from "../../styles/pageStyles/auth.module.scss";
import { AiOutlineGoogle } from "react-icons/ai";
import { FaApple, FaFacebookF, FaUser } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
import { BsFillTelephoneFill } from "react-icons/bs";
import AuthInput from "../../components/elements/authInput/input.element";
import styled from "styled-components";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  // OAuthProvider,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  UserCredential,
} from "firebase/auth";
import { Auth, Firestore } from "../../common/configs/firebase.config";
import { useState } from "react";
import { ElementChangeEvent } from "../../common/types/general.type";
import CountryDropdown from "../../components/elements/countryDropdown/countryDropdown.element";

import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../common/routes/default.routes";
import { doc, getDoc } from "firebase/firestore";
import { useSetRecoilState } from "recoil";
import { AtomAuthUser } from "../../store/auth/auth.store";
import { UserDTO } from "../../common/models/auth.model";
import Loader from "../../components/elements/loader/loader.element";
import { LS_KEYS, NODES } from "../../common/constants/general.constant";
import { _setStorage } from "../../common/helpers/storage.helper";
import { CountryPhoneCodes } from "../../common/data/countryCode.data";
import logo from "../../assets/logos/FullTextLogo.png";
import { showError } from "../../common/helpers/debugging.helper";
import useWindowSize from "../../hook/useWindowSize";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const setUser = useSetRecoilState(AtomAuthUser);
  const [username, setUsername] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isVerify, setIsVerify] = useState<any>(false);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState<"otp" | "login" | null>(null);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const handleCountry = (val: ElementChangeEvent) => {
    setSelectedCountry(val.data.dial_code);
  };

  // * To write user dto in firebase
  // const createUser = (result: UserCredential) => {
  //   const db = doc(Firestore, `${NODES.CLIENTS}/${result.user.uid}`);
  //   const _userObj: UserDTO = {
  //     name: username ? username : result.user.displayName ?? "",
  //     email: result.user.email ?? "",
  //     phoneNumber: result.user.phoneNumber ?? "",
  //     photoURL: result.user.photoURL ?? "",
  //     clientId: result.user.uid,
  //     currency: selectedCurrency,
  //     balance: 10000,
  //     status: "ACTIVE",
  //   };
  //   setDoc(db, _userObj)
  //     .then(() => setUser(_userObj))

  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getUser = (_userResult: UserCredential) => {
    const path = _userResult.user.uid;
    const docRef = doc(Firestore, `${NODES.CLIENTS}/${path}`);
    getDoc(docRef).then((res) => {
      if (res.exists()) {
        let _user = res.data() as UserDTO;
        _user.photoURL = _userResult.user.photoURL;
        setUser(_user);
        navigate(width > 591 ? ROUTES.ALL_RACES : ROUTES.NEXT_TO_RACE);
      } else {
        navigate(ROUTES.CURRENCY);
        let _res = { ..._userResult };
        let _user = {
          ..._res.user,
          username: username === "" ? _res.user.displayName : username,
        };
        _setStorage(LS_KEYS.auth, _user);
      }
    });
  };

  // * On sign in with google
  const onGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(Auth, provider)
      .then(async (result) => {
        if (result.user) {
          result.user
            .getIdToken()
            .then((res) => {
              getUser(result);
              _setStorage(LS_KEYS.token, res);
            })
            .catch((err) => {
              console.error(err);
            });
        }
        // if (credential) _setStorage(LS_KEYS.token, credential.idToken);
        // navigate(ROUTES.ALL_RACES);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const onFacebookSignIn = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(Auth, provider)
      .then((result) => {
        getUser(result);
        // navigate(ROUTES.ALL_RACES);
      })
      .catch((error) => {
        showError(error);
      });
  };

  // const onAppleLogin = () => {
  //   const provider = new OAuthProvider("apple.com");
  //   signInWithPopup(Auth, provider)
  //     .then((result) => {
  //       getUser(result);
  //       // navigate(ROUTES.ALL_RACES);
  //     })
  //     .catch((error) => {
  //       console.error(error);

  //       showError(error);
  //     });
  // };

  const onGetOtp = () => {
    if (phoneNumber.length < 6) {
      showError({ message: "Please enter valid phone number!" });
      return;
    }
    setLoader("otp");
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: setIsVerify,
      },
      Auth
    );
    const code = selectedCountry;
    const phoneNum = code + phoneNumber;
    const appVerifier = (window as any).recaptchaVerifier;
    signInWithPhoneNumber(Auth, phoneNum, appVerifier)
      .then((confirmationResult) => {
        (window as any).confirmationResult = confirmationResult;

        setLoader(null);
      })
      .catch((error) => {
        setLoader(null);
        showError(error);
      });
  };

  const onLogin = () => {
    if (!otp) {
      showError({
        message: "Please enter One Time Password sent to your mobile number!",
      });
      return;
    }
    // setIsLoading(true);
    setLoader("login");
    (window as any).confirmationResult
      .confirm(otp)
      .then((result: any) => {
        getUser(result);
        // navigate(ROUTES.ALL_RACES);
        setLoader(null);
      })
      .catch((error: any) => {
        setLoader(null);
        if (error.code === "auth/invalid-verification-code") {
          showError({
            message: "One Time Password did not match! Please try again!",
          });
          return;
        }
        showError(error);
      });
  };

  return (
    <LoginWrapper className={styles.sectionWrapper}>
      <Helmet>
        <title>Log In - Ashva Racing</title>
      </Helmet>
      <img className={styles.logo} src={logo} alt="ashva_racing_logo" />
      <H1 className={styles.title}>Get Demo of Ashva Racing</H1>

      <div className={styles.socialsWrapper}>
        <button className={styles.socialButton} onClick={onGoogleSignIn}>
          <AiOutlineGoogle size={30} />
        </button>
        <button className={styles.socialButton} onClick={onFacebookSignIn}>
          <FaFacebookF size={25} />
        </button>
        <button
          disabled
          style={{ cursor: "unset" }}
          className={styles.socialButton}
        >
          <FaApple size={30} />
        </button>
      </div>

      <h4>OR</h4>

      <TextWrapper>Get started with your contact details</TextWrapper>

      <div className={styles.formWrapper}>
        <AuthInput
          name="name"
          placeholder="Name"
          Icon={<FaUser />}
          onChange={(e) => setUsername(e.data)}
        />

        <div className={styles.numberWrp}>
          <CountryDropdown
            name="country"
            options={[CountryPhoneCodes]}
            selectedValue={selectedCountry}
            onChange={handleCountry}
            placeholder="Code"
          />

          <AuthInput
            name="number"
            placeholder="Phone Number"
            Icon={<BsFillTelephoneFill />}
            type="text"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onGetOtp();
              }
            }}
            value={phoneNumber}
            onChange={(e) => {
              if (e.data.length <= 10) setPhoneNumber(e.data);
            }}
          />

          <Button disabled={phoneNumber.length < 6} onClick={onGetOtp}>
            {loader === "otp" ? <Loader show size={8} /> : "GET OTP"}
          </Button>
        </div>

        <div
          id="recaptcha-container"
          data-sitekey="6LcsaxsdAAAAAEBn0sPDCEncnU9564MisyRuDzD_"
          data-callback="sendForm"
          data-size="visible"
          className={styles.captcha}
        ></div>
        {isVerify && (
          <AuthInput
            name="otp"
            placeholder="Enter OTP"
            Icon={<BsFillTelephoneFill />}
            onChange={(e) => setOtp(e.data)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onLogin();
              }
            }}
          />
        )}
      </div>
      {isVerify && (
        <Button style={{ minWidth: "10rem" }} onClick={onLogin}>
          {loader === "login" ? <Loader show size={10} /> : "LOG IN"}
        </Button>
      )}
    </LoginWrapper>
  );
};

export default LoginPage;

const H1 = styled.h1`
  color: ${(p) => p.theme.text};
`;

const LoginWrapper = styled.div`
  background: ${(p) => p.theme.body};
  color: ${(p) => p.theme.text};
  padding: 2rem 0;
`;

const Button = styled.button`
  background: ${(p) => p.theme.bodyContrast};
  white-space: nowrap;
  padding: 1rem;
  color: ${(p) => p.theme.textContrast};
  text-align: center;
  min-height: 3rem;
  border: none;
  border-radius: var(--radius-default);
  &:disabled {
    /* background: ${(p) => p.theme.bodySecondary}; */
    color: ${(p) => p.theme.textDull};
    cursor: auto;
  }
  @media screen and (max-width: 991px) {
    font-size: 0.8rem;
  }
`;

const TextWrapper = styled.p`
  padding-top: 1rem;
`;
