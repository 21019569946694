import { Helmet } from "react-helmet";
import styled from "styled-components";
import BetTab from "../components/pages/betSlipComponents/betTab/betTab.component";
import BetSlipBody from "../components/pages/betSlipComponents/betTabBody/betSlip.body";
import OpenBetsBody from "../components/pages/betSlipComponents/betTabBody/openBets.body";

const BetSlipPage = () => {
  return (
    <>
      <Helmet>
        <title>Bet Slip - Ashva Racing</title>
      </Helmet>
      <PageWrapper>
        <BetTab />
        <div>
          <BetSlipBody />
          <OpenBetsBody />
        </div>
      </PageWrapper>
    </>
  );
};

export default BetSlipPage;

const PageWrapper = styled.div`
  background: ${(p) => p.theme.bgBetSlip};
  color: ${(p) => p.theme.textContrast};
`;
