import { Helmet } from "react-helmet";
import styled from "styled-components";
import styles from "../../styles/pageStyles/legal/termsAnduse.module.scss";

export const TermsAndUsePage = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Rules - Ashva Racing</title>
      </Helmet>
      <div className={styles.termsAndUseWrapper}>
        <MainTitle className={styles.title}>Terms of Use</MainTitle>
        <div className={styles.detailsWrapper}>
          <p>
            Welcome to our application. These are our terms for use of the
            website and app which you may access in several ways, including but
            not limited to the web, tablets, smartphones and apps. These Terms &
            Conditions (terms) apply whenever you access the Site, regardless of
            how you access the Site.
          </p>
          <h2>Use</h2>
          <p>
            The Publisher, as set out at the end of these terms (Ashva.app or
            Protocolzone private limited from here on referred to as Ashva), is
            the owner and operator of this Site and App Each user of this Site
            (User or you) agrees to be bound by these terms. Your use of and
            access to this Site indicates your acceptance of and agreement with
            these terms, as they exist at the time of use. Ashva may amend these
            terms at any time by posting changes on the Site or our app. If you
            use the Site or app (other than to review the amended terms) you
            will be deemed to have accepted the new terms. Ashva will include on
            the Site and the app the date on which it last made amendments to
            these terms.
          </p>
          <h2>Content and functionality of the Site or App.</h2>
          <p>
            Ashva.app may alter, suspend or discontinue the Site or app and/or
            its functionality and/or the content made available through the Site
            at any time, with or without notice to Users, and, to the full
            extent permitted by law and subject to the remainder of these Terms
            and Conditions, Ashva will not be liable to any User or to any third
            party should it exercise such rights.
          </p>
          <p>
            In some circumstances the Site or App will allow Users to access and
            download certain content onto your device(s). Some such content may
            not be available at all times in the future for subsequent download
            and Ashva will have no liability to you in such circumstances
            subject to the remainder of these terms.
          </p>
          <h2>Intellectual Property</h2>
          <p>
            All intellectual property in relation to content on this Site or the
            app belongs to Ashva or its licensors, advertisers or affiliates.
            You obtain no interest in that intellectual property. All content on
            this Site is protected by international copyright and other
            intellectual property laws. You may not do anything which interferes
            with or breaches those laws or the intellectual property rights in
            the content.
          </p>
          <p>
            You may download and view content or print a copy of material on
            this Site for personal, non-commercial use provided you do not
            modify the content in any way (including any copyright notice). All
            rights not expressly granted under these terms of use are reserved
            by Ashva. Unless expressly stated otherwise, you are not permitted
            to copy, or republish anything you find on the Site without the
            copyright or trademark owners’ permission.
          </p>
          <p>
            The logos of the Site are trademarks of Ashva or its related bodies
            corporate. Other trademarks may be displayed on the Site from time
            to time. These may belong to third parties. Nothing displayed on the
            Site should be construed as granting any licence or right of use of
            any logo, trademark or masthead displayed on the Site, without the
            express written permission of the relevant owner.
          </p>
          <h2>Liability & Indemnity</h2>
          <p>
            Ashva does not exclude any rights and remedies in respect of goods
            or services under the Indian Consumer Law or equivalent State or
            Territory legislation which cannot be excluded, restricted or
            modified. Ashva otherwise excludes all rights, remedies, guarantees,
            conditions and warranties in respect of goods or services from your
            use of the Site whether based in statute, common law or otherwise to
            the extent permitted by law. To the fullest extent permitted by law,
            the following provisions apply:
          </p>
          <div className={styles.noContentWrp}>
            <li className={styles.li}>
              Ashva does not warrant the accuracy of the content which you
              access through this Site or app. The content is provided to you
              "as is'' and on an "as available" basis and on the condition that
              you undertake all responsibility for assessing the accuracy of the
              content and rely on it at your own risk. All content which you
              access through this Site may be changed at Ashva sole discretion
              and without notice.
            </li>
            <li className={styles.li}>
              Ashva will have no responsibility or liability in relation to any
              loss or damage that you incur, including damage to your software
              or hardware, arising from your use of or access to this Site or
              app.
            </li>
            <li className={styles.li}>
              Ashva does not warrant that functions of our services or which you
              access the app or Site, such as hyperlinks, will be uninterrupted
              or error free, that defects will be corrected, that there will be
              no technical problems which prevent or unreasonably delay delivery
              of the Site or any content which ought to be accessible through
              the Site, or that Ashva or the server that makes it available, are
              free of viruses or bugs.
            </li>
          </div>
          <p>
            Where permitted under local law, liability of Ashva, for any breach
            of a term or condition implied by law is limited at Ashva
            discretion, to the supply of any service again or the payment for
            the cost of having any service supplied again. However, Ashva cannot
            limit its liability in this manner if you establish that it would
            not be fair or reasonable for Ashva to do so.
          </p>
          <h2>Third Party Content & Links</h2>
          <p>
            This Site may include third party content which is subject to that
            third party's terms and conditions of use. Nothing on this Site
            should be construed as granting any licence or right for you to use
            that content.
          </p>
          <p>
            This Site may include links to third party sites which are not
            related to Ashva and in relation to which Ashva has no control or
            interest. The appearance of those links on this Site does not
            indicate any relationship between Ashva and that third party or any
            endorsement by Ashva of that third party, its site or the products
            or services which it is advertising.
          </p>
          <h2>Contributing Content</h2>
          <p>
            When you submit content to Ashva in any format, including any text,
            photographs, graphics, video or audio, you grant Ashva a
            non-exclusive, royalty-free, perpetual licence to publish, transmit
            or otherwise use that content in our published materials, now and in
            the future and to attribute you, using the details you have provided
            to us, as the author/provider of that content.
          </p>
          <p>
            Ashva reserves the right not to use the content you submit. You
            warrant that you have all of the necessary rights, including
            copyright, in the content you contribute, that your content is not
            defamatory and that it does not infringe any law.
          </p>
          <p>
            You indemnify Ashva against any and all legal fees, damages and
            other expenses that may be incurred by Ashva as a result of a breach
            of the above warranty.
          </p>
          <p>
            You waive any moral rights in your contribution for the purposes of
            its submission to and use by Ashva in accordance with these terms.
          </p>
          <h2>General</h2>
          <p>
            Users must not use this site for any purpose or in any way which is
            unlawful. These terms shall be governed by and interpreted in
            accordance with the laws of India.
          </p>
          <p>
            By "Site" or “app” we mean the features and services we make
            available, including through our website at www.ashva.app, and any
            other website branded or co-branded websites (including sub-domains,
            international versions, and mobile versions); (b) our branded apps;
            and (c) other media, devices or networks now existing or later
            developed.
          </p>
          <h2>WARNING: GAMBLING INVOKES RISK</h2>
          <p>
            For the avoidance of doubt, we are not responsible for your gambling
            losses. Any monetary investment based on information displayed on
            the site (or affiliated sites) is at the risk of the investor.
          </p>
          <p>
            All information relating to race fields, including horse numbers,
            scratching’s, odds and statistics should be checked with an official
            source.
          </p>
          <p>
            Use of this website is restricted to users that are 18 years or
            older.
          </p>
          <p>
            If anyone brings a claim against us related to your actions or your
            content on Site, you will indemnify and hold us harmless from and
            against all damages, losses, and expenses of any kind (including
            reasonable legal fees and costs) related to such claim.
          </p>
          <h2>Third-Parties</h2>
          <p>
            We do not accept responsibility or liability of any kind for any
            loss resulting from the incorrect or non-operation of any feature(s)
            within your 3rd party account. It is your responsibility to make
            sure that your access to the third-party account, and your access to
            and use of the information available from it is not illegal or
            prohibited by any laws which may apply to you.
          </p>
          <p>
            We advise you to take your own precautions to ensure that the
            process that you have in place for accessing your 3rd party account
            does not expose your computer system to the risk of damage from
            viruses, malicious computer code or other forms of interference. We
            do not accept responsibility for any interference or damage to your
            computer system which arises in connection with your use of our Web
            Site or any linked website.
          </p>
          <p>
            You will be required to enter your 3rd party agency's login details
            to utilise their service. Raw password information is never stored
            by Ashva.
          </p>
          <p>
            Upon logging into the 3rd party agency through our app, a Session ID
            is generated by that agency. This Session ID is then used to perform
            different operations of that third party services on behalf of the
            user.
          </p>
          <p>
            When placing your wagers we do not make any representations or
            warranties for any errors or delays that may occur in data
            transmission or that the information will be uninterrupted or
            timely, for example a request or submission that has 'timed out'.
          </p>
          <p>
            Whilst this site provides information and odds from agencies in good
            faith, it cannot be held responsible for any errors or omissions on
            the site as we are entirely reliant on the accuracy of information
            from third party sources, namely the agencies.
          </p>
          <p>
            The responsibility lies with the end user to ensure that they are
            satisfied that the Odds they have selected and the value of the
            wager are correct.
          </p>
          <p>
            We do not accept responsibility or liability of any kind for any
            loss resulting from any action taken or not taken, or your reliance
            on any information or material that is posted on or available from
            our Site.
          </p>
          <p>
            We accept no responsibility for any wager that may be placed and
            that you acknowledge that there is no assurance of the acceptance or
            accuracy of any wager in respect of any race placed through our
            website.
          </p>
          <p>
            We accept no responsibility for information shared by you publicly
            or privately on the website using our app, including any errors or
            omissions with shared information. You must take responsibility to
            ensure that your sharing settings are appropriate for your
            circumstances.
          </p>
          <p>
            You are responsible for ensuring that all persons who have access to
            our app through your internet connection or equipment are aware of
            these Terms, and that they comply with them.
          </p>
          <p>
            We expressly note that we do not warrant any 3rd party taking or
            cancelling or refunding cost utilised with third party or agencies.
          </p>
        </div>
      </div>
    </>
  );
};
export default TermsAndUsePage;

const MainTitle = styled.div`
  border-bottom: ${(p)=>p.theme.borderSecondary};
`