import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { OPEN } from "../../../../common/constants/general.constant";
import { RaceDetailsDTO } from "../../../../common/models/race.mode";

import { ROUTES } from "../../../../common/routes/default.routes";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import { AtomSelectedThemeData } from "../../../../store/theme/theme.store";

import styles from "./trackRaceCard.module.scss";

interface TrackRaceCardProps {
  race: RaceDetailsDTO;
}

const TrackRaceCard: FC<TrackRaceCardProps> = memo(({ race }) => {
  const { localDate, raceNo, eventType, province, trackName } = race.raceKey;
  const t = useTranslation(LKeys.generals);
  const theme = useRecoilValue(AtomSelectedThemeData);

  const RaceStatus = (
    <span
      className={styles.status}
      style={{ color: theme ? theme[race.raceStatus + "_LIGHT"] : "" }}
    >
      {race.raceStatus === OPEN
        ? race?.localPostTime ?? t(race.raceStatus)
        : race.rr
        ? race.rr
        : t(race.raceStatus)}
    </span>
  );

  return (
    <RaceButton
      className={styles.trackRaceCard}
      to={`${ROUTES.RACES}/${localDate}/${province}/${trackName}/${eventType}/${raceNo}`}
    >
      <>
        <div className={styles.raceNo}>
          <span>{t("race")} </span>
          <span>{race.raceKey.raceNo}</span>
        </div>
        {RaceStatus}
      </>
    </RaceButton>
  );
});

TrackRaceCard.displayName = "TrackRaceCard";
export default TrackRaceCard;

// * Styled components
const RaceButton = styled(Link)`
  background: ${(p) => p.theme.bodyContrastLight};
  color: ${(p) => p.theme.textContrast};
  box-shadow: ${(p) => p.theme.shadowDefault};
`;
