import { Helmet } from "react-helmet";
import styled from "styled-components";
import styles from "../../styles/pageStyles/legal/privacyPolicy.module.scss";

export const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Ashva Racing</title>
      </Helmet>
      <div className={styles.policyWrapper}>
        <MainTitle className={styles.title}>Privacy Policy</MainTitle>
        <div className={styles.detailsWrapper}>
          <p>
            This Privacy Policy applies to information which identifies you, or
            from which you can reasonably be identified, such as your name or
            email address ("personal information"). This Policy describes how we
            handle the personal information we collect when we provide our
            services. Our services include: advertising, websites, mobile sites,
            newsletter subscriptions, applications ("apps"), and widgets,
            competitions, and customer surveys (collectively, "Company
            Services").This Privacy Policy explains how we (ashva.app from here
            on referred to as Ashva) collect, use and disclose information about
            you.
          </p>
          <p>
            This Privacy Policy otherwise applies to information about anyone
            who interacts with us, including advertisers, subscribers and people
            who use Company Services (both registered and unregistered users).
          </p>
          <h2>PERSONAL INFORMATION</h2>
          <p>
            We collect personal information to provide Company Services and for
            our business operations. If you choose not to provide the
            information we request from you, we may not be able to provide you
            with the services you require. We describe the main types of
            personal information we collect and the main reasons why we collect
            that information below.
          </p>
          <div>
            <p>
              Registration Information is the information you provide to Ashva
              when you register for or acquire or use a Company Service. This
              may include information you provide us to:
            </p>
            <ul className={styles.ui}>
              <li className={styles.li}>Name</li>
              <li className={styles.li}>Date of birth</li>
              <li className={styles.li}>Gender</li>
              <li className={styles.li}>Photographs or images</li>
              <li className={styles.li}>Personally submitted preferences</li>
              <li className={styles.li}>Location data</li>
              <li className={styles.li}>IP Address</li>
              <li className={styles.li}>
                Other Identity verification information (i.e. email address,
                phone numbers)
              </li>
              <li className={styles.li}>
                Correspondence to and from you such as emails and live chat
              </li>
              <li className={styles.li}>Your application for employment.</li>
            </ul>
          </div>
          <p>
            You are able to use some Company Services without providing any
            personal information (for example when browsing our digital products
            as a casual user).
          </p>
          <p>
            Information from third party Social Media. If you access or log-in
            to a Company Service through a third party social media service or
            connect a Company Service to your social media profile, we may also
            include information from that social media service. This may
            include: your user name for that service; any information or content
            you have permitted the social media service to share with us (such
            as your profile picture(s), email address, followers or friends
            lists); and any other information you have made public (including
            other posts you make using your social media profile). We do not
            collect your social media profile password. When you access Company
            Services through your social media profile, or when you connect a
            Company Service to your social media profile, you authorise us to
            collect and handle your personal information in accordance with this
            Privacy Policy.
          </p>
          <h2>USE OF INFORMATION</h2>
          <p>
            We use the information we collect about you to provide Company
            Services to you. As part of our service to you we may use your
            personal information: to provide our services and products to you;
            to fulfil administrative functions associated with these services
            (for example payments & account management); to measure and improve
            Company Services and their individual features; to improve your
            experience by delivering content (including editorial, marketing and
            advertising) we think you will find relevant and interesting; to
            send you information via email or push notification; for other
            marketing and client relationship purposes; to allow you to comment
            on content, participate in discussion, and participate in
            competitions or rewards programs; to provide you with customer
            support; and to respond to your enquiries.
          </p>
          <p>
            We use the information we collect about you for the following
            additional purposes:
          </p>
          <p>
            To allow social sharing functionality. If you log in with or connect
            a social media profile with your Company Services account, we may
            share your personal information (including your user name, picture,
            tweets, likes and posts) with other Company Services users and your
            friends or followers linked to your social media profile. We may
            also share the same information with the social media service
            provider.
          </p>
          <p>
            By logging in with or connecting your Company Services account with
            a social media profile, you authorise us to share your personal
            information with the social media service provider, other users and
            your friends or followers. You understand that the social media
            service provider may handle this information in accordance with its
            own privacy policy. If you do not want your personal information
            shared in this way, please do not connect your social media profile
            with your Company Services account and do not participate in social
            sharing on Company Services.
          </p>
          <p>
            To provide co-branded services and features. We may offer co-branded
            services, such as competitions or other promotions together with a
            third party ("Co-Branded Services"). When this happens, we may share
            the information you submit in connection with the Co-Branded Service
            with the third party, or with our service providers who assist us to
            host Co-Branded Services. The third party’s use of your information
            will be governed by their own privacy policy. We will obtain your
            consent to share your personal information with the third party. If
            you do not provide this consent, you may not be able to access that
            co-branded service.
          </p>
          <p>
            To contact you. From time to time, we may send you promotional
            materials or other information which we think may interest you. For
            example, we may send you an invitation to participate in various
            activities (such as customer surveys) or special offers.
          </p>
          <p>
            To share with our Company Affiliates. Company may share your
            information with our related companies located around the world. If
            we share your information with our related companies, they can use
            your information in accordance with their Privacy Policy.
          </p>
          <p>
            To share with business partners. Company may share your information
            with business partners so they can send you marketing
            communications. This will only happen if you have expressly agreed
            to this.
          </p>
          <p>
            To protect the rights of the Company and others. We may share your
            information with other third parties where we are lawfully entitled
            to do so. This includes when we have a reasonable basis to believe
            that sharing your information is necessary to:
          </p>
          <p>
            protect, enforce or defend the legal rights, privacy, safety, or
            property of Company, our related companies or their employees,
            agents and contractors (including enforcement of our agreements and
            our terms of use); protect the safety, privacy, and security of
            users of the Company Services or members of the public; protect
            against fraud or for risk management purposes; comply with the law
            or legal process in any country; or respond to requests from public
            and government authorities.
          </p>
          <p>
            To complete a merger or sale of assets. If Company sells all or part
            of its business or assets, we may disclose your information to the
            party or parties involved in the sale transaction. We may also do
            this if Company is involved in a merger or transfer of all or a
            material part of its business. We may disclose your information
            prior to the sale, transfer or merger so that the party or parties
            involved can consider the transaction and complete any due
            diligence.
          </p>
          <p>We will delete the user data on request within a 30 days.</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;

const MainTitle = styled.div`
  border-bottom: ${(p)=>p.theme.borderSecondary};
`