import axios from "axios";
import { LS_KEYS } from "../constants/general.constant";
import { _getStorage } from "../helpers/storage.helper";
import { UserDTO } from "../models/auth.model";

// * Global API call config.
export const API = axios.create({
  baseURL: "https://demoapi.ashva.app/",
  // baseURL: "http://192.168.90.224:8989/",
});

// * Modules for backend apis.
export const MOD = {
  racing: "racing",
  bet: "bet",
};

// * Backend API URLs
export const API_URLS = {
  init: MOD.racing + "/init",
  placeBet: MOD.racing + "/place-bet",
  validate: MOD.racing + "/validate",
  openWagers: MOD.racing + "/open-wagers",
  transactions: MOD.racing + "/transactions",
};

// * API Header
export const getApiHeader = (user: UserDTO) => {
  const token = _getStorage(LS_KEYS.token);
  const id = "e1KkXYvQ4rs88Wk";

  return {
    AgentId: id,
    SessionId: token,
    RefId: id,
    AggregatorId: id,
    OperatorId: id,
    ClientId: user.clientId,
  };
};
