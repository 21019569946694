import { Helmet } from "react-helmet";
import LandscapeAd from "../components/Ads/landscape.ad";
import TrackRaces from "../components/pages/trackRaces/trackRaces.component";

const AllRacesPage = () => {
  return (
    <>
      <Helmet>
        <title>All Races - Ashva Racing</title>
      </Helmet>
      <div>
        <LandscapeAd />
        <TrackRaces />
      </div>
    </>
  );
};

export default AllRacesPage;
