import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { NavTabMenu } from "../../../../common/data/menu.data";
import { ROUTES } from "../../../../common/routes/default.routes";
import DotNotification from "../../../ui/notifications/dot.notification";
import styles from "./navTabMenuHeader.module.scss";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import { AtomBetCards } from "../../../../store/bets/bets.store";
export interface NavTabMenuHeaderProps {
  title: string;
}

export const NavTabMenuHeader = () => {
  const path = useLocation().pathname;
  const betCards = useRecoilValue(AtomBetCards);

  // const t = useTranslation(LKeys.generals);
  return (
    <NavMenuWrp className={`${styles.navTabMenuHeader}`}>
      {NavTabMenu.map(({ route, localeKey, Icon }) => {
        if (route === ROUTES.BET_SLIP)
          return (
            <NavLink
              key={route}
              to={route}
              className={path === route ? "active" : ""}
            >
              <DotNotification
                isActive={path === route}
                icon={BsCreditCard2FrontFill}
                number={Object.keys(betCards ?? {}).length}
                size={24}
              />
              {/* <div className={styles.textWrp}>{t(localeKey)}</div> */}
            </NavLink>
          );
        return (
          <NavLink
            key={route}
            to={route}
            className={path === route ? "active" : ""}
          >
            <IconWrp>{Icon ? <Icon /> : <div></div>}</IconWrp>
            {/* <div className={styles.textWrp}>{t(localeKey)}</div> */}
          </NavLink>
        );
      })}
    </NavMenuWrp>
  );
};

export default NavTabMenuHeader;

const NavMenuWrp = styled.div`
  border-bottom: ${(p) => p.theme.borderHighlight};
  border-top: 1px solid ${(p) => p.theme.activeAlpha};
  
  background: ${(p) => p.theme.bodyContrast};
`;

const IconWrp = styled.div`
  color: ${(p) => p.theme.textContrast};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 50%;
`;

const NavLink = styled(Link)`
  color: ${(p) => p.theme.textContrast};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; */
  padding: 0.5rem 0.25rem;
  width: 100%;
  height: 100%;
  &:hover {
    background: ${(p) => p.theme.btnHoverDark};
  }
  &.active {
    background: ${({ theme }) => theme.active};
  }
`;
