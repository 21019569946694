import { useEffect, useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { OPEN } from "../../../../common/constants/general.constant";
import { getRacePath } from "../../../../common/helpers/routing.helper";
import { ROUTES } from "../../../../common/routes/default.routes";
import { __PzoneCodeAndRaces } from "../../../../common/types/dynamic.type";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import {
  AtomAllTrackList,
  AtomSelectedRace,
} from "../../../../store/races/races.store";
import styles from "./trackSelector.module.scss";

type Props = {};

const TrackSelector = (props: Props) => {
  const [show, setShow] = useState(false);
  const tTracks = useTranslation(LKeys.tracks);
  const tProvinces = useTranslation(LKeys.countries);
  const tGeneral = useTranslation(LKeys.generals);
  const race = useRecoilValue(AtomSelectedRace);
  const tracks = useRecoilValue(AtomAllTrackList);
  const dropRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Hide the target dropdown.
    const onOutsideClick = (e: any) =>
      dropRef.current && !dropRef.current.contains(e.target) && setShow(false);

    window.addEventListener("click", onOutsideClick, true);
    return () => {
      window.removeEventListener("click", onOutsideClick, true);
    };
  }, []);

  const onTrackChange = (track: __PzoneCodeAndRaces) => {
    setShow(false);

    for (const raceNo in track.races) {
      const _race = track.races[raceNo];
      if (!_race) continue;
      if (_race.raceStatus === OPEN) {
        const _path = getRacePath(_race.raceKey);
        navigate(_path);
        return;
      }
    }
    let _races = Object.values(track.races);
    if (_races.length) {
      const _path = getRacePath(_races[0].raceKey);
      navigate(_path);
      return;
    }
    navigate(ROUTES.ALL_RACES);
  };

  // if (race === undefined) <ShimmerLoader />;
  if (!race) return null;
  const { eventType, trackName, province, raceNo } = race.raceKey;
  return (
    <div ref={dropRef} className={styles.selectorWrapper}>
      <SelectorBtn
        onClick={() => setShow(!show)}
        className={styles.selectorBtn}
      >
        <div>
          <span className={`et48 ${eventType}-active `}></span>
        </div>

        <div className={styles.trackInfo}>
          <span className={styles.trackName}>{tTracks(trackName)}</span>
          <FaCaretDown />
          <div>
            <span>{tProvinces(province)}</span> |{" "}
            <span>
              {tGeneral("race")}-{raceNo}
            </span>
          </div>
        </div>
      </SelectorBtn>
      <OptionWrappers
        className={`${styles.trackOptionsWrapper} ${show ? styles.show : ""}`}
      >
        {tracks.map((track) => {
          const { eventType, trackName, province } = track.pzoneCode;
          return (
            <OptionBtn
              className={styles.optionBtn}
              onClick={() => onTrackChange(track)}
              key={track.pzoneCode.key}
            >
              <span className={`et32 ${eventType}-white mr05`}></span>
              {tTracks(trackName)}
              <span
                className={`flag-16 flag-${province.toLowerCase()} ml05`}
              ></span>
            </OptionBtn>
          );
        })}
      </OptionWrappers>
    </div>
  );
};

export default TrackSelector;

// * Styled Components
const SelectorBtn = styled.button`
  color: ${(p) => p.theme.textContrast};
`;

const OptionWrappers = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
`;

const OptionBtn = styled.button`
  color: ${(p) => p.theme.textContrast};
  &:hover {
    background: ${(p) => p.theme.btnHoverDark};
  }
`;
