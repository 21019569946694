import { SyncLoader, CircleLoader } from "react-spinners";
import styles from "./loader.module.scss";
interface LoaderProps {
  size?: number;
  color?: string;
  show?: boolean;
  className?: string;
  roundLoader?: boolean;
  LoaderType?: any;
}

const Loader = ({
  size,
  color,
  show,
  roundLoader,
  LoaderType,
  ...props
}: LoaderProps) => {
  if (!show) return null;
  return (
    <div className={styles.wrapper}>
      {roundLoader ? (
        <CircleLoader
          color={color ?? "#FFFFFF"}
          speedMultiplier={0.75}
          loading
          size={size ?? 20}
        />
      ) : (
        <SyncLoader
          color={color ?? "#FFFFFF"}
          speedMultiplier={0.75}
          loading
          size={size ?? 15}
        />
      )}
    </div>
  );
};

export default Loader;
