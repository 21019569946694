import React from "react";
import PrivacyPolicyPage from "../legal/privacyPolicy.page";

type Props = {};

const PolicyPrivacyPage = (props: Props) => {
  return (
    <div>
      <PrivacyPolicyPage />
    </div>
  );
};

export default PolicyPrivacyPage;
