import React, { useState } from "react";
import styled from "styled-components";
import { RaceDetailsDTO } from "../../../common/models/race.mode";
import { RaceTripDTO } from "../../../common/models/raceTrip.model";
import {
  __FavoriteDTO,
  __MultiSelectionDTO,
  __SelectionOddsDTO,
} from "../../../common/types/dynamic.type";
import TripInfo from "../raceTrips/raceTripsRow/tripInfo/tripInfo.component";
import MultiRaceTripSelector from "./MultiRaceTripSelector/MultiRaceTripSelector";
import styles from "./MultiRaceTripsRow.module.scss";
import MultiRaceTripDetail from "./MultiRaceTripsTable/MultiRaceTripsDetail/MultiRaceTripsDetails";

type Props = {
  trip: RaceTripDTO;
  race: RaceDetailsDTO | null | undefined;
  odds: __SelectionOddsDTO;
  raceNo: number;
  setSelectionObj?: (val: __MultiSelectionDTO) => void;
  selectionObj?: any;
  onBetClick: () => void;
  fav: __FavoriteDTO;
};

const MultiRaceTripsRow = ({
  trip,
  race,
  odds,
  raceNo,
  onBetClick,
  fav,
}: Props) => {
  const [showDetail, setShowDetail] = useState(false);
  const handleDetailShow = () => setShowDetail(!showDetail);

  return (
    <>
      <TableRow className={styles.rowGrid} onClick={handleDetailShow}>
        <div className={styles.rowDiv}>
          <TripInfo raceKey={race?.raceKey} trip={trip} />
          <MultiRaceTripSelector
            onBetClick={onBetClick}
            trip={trip}
            race={race}
            raceNo={raceNo}
            odds={odds}
            fav={fav}
          />
        </div>
        <div className={styles.detailsDiv}>
          <MultiRaceTripDetail
            trip={trip}
            race={race}
            showDetail={showDetail}
          />
        </div>
      </TableRow>
    </>
  );
};

export default MultiRaceTripsRow;

const TableRow = styled.div`
  &:nth-child(odd) {
    background: ${(p) => p.theme.rowOdd};
  }
  &:nth-child(even) {
    background: ${(p) => p.theme.rowEven};
  }
`;
