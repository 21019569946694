import { useRecoilValue } from "recoil";
import { POOLS } from "../../../../../../common/constants/pools.constant";
import {
  Boxed,
  SuperfectaBoxes,
} from "../../../../../../common/helpers/selection.helper";
import { isDisableSelection } from "../../../../../../common/helpers/validation.helper";
import { RaceTripDTO } from "../../../../../../common/models/raceTrip.model";
import { __Selection } from "../../../../../../common/types/dynamic.type";
import { Position } from "../../../../../../common/types/raceUI.type";
import { AtomSelectedRace } from "../../../../../../store/races/races.store";
import { AtomIsBoxed } from "../../../../../../store/selection/selection.store";
import styles from "./_selectors.module.scss";

type Props = {
  trip: RaceTripDTO;
  selection: __Selection;
  onPosSelection: (checked: boolean, pos: Position, pool: string) => void;
};

const SuperfectaSelector = ({ trip, selection, ...props }: Props) => {
  const race = useRecoilValue(AtomSelectedRace);
  const isBoxed = useRecoilValue(AtomIsBoxed);
  const { selId } = trip.raceTripKey;

  // * On selection of runner checkbox
  const onPosSelection = (checked: boolean, pos: Position) =>
    props.onPosSelection(checked, pos, POOLS.SUPERFECTA);

  return (
    <>
      {isBoxed ? (
        <Boxed
          value={selection["boxed"][selId]}
          disabled={isDisableSelection(trip, race)}
          onPosition={onPosSelection}
        />
      ) : (
        <SuperfectaBoxes
          className={styles.selectionBox}
          first={selection["first"][selId]}
          second={selection["second"][selId]}
          third={selection["third"][selId]}
          fourth={selection["fourth"][selId]}
          disabled={isDisableSelection(trip, race)}
          onPosition={onPosSelection}
        />
      )}
    </>
  );
};

export default SuperfectaSelector;
