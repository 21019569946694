import "./App.scss";
import "@js-joda/timezone";
import ThemeWrapper from "../store/theme/theme.provider";
import GlobalLayout from "../layouts/global.layout";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "../common/routes/default.routes";
import IndexPage from "../pages/index.page";
import AllRacesPage from "../pages/allRaces.page";
import LoginPage from "../pages/auth/login.page";
import THRacingPage from "../pages/racing/thRacing.page";
import HSRacingPage from "../pages/racing/hsRacing.page";
import DORacingPage from "../pages/racing/doRacing.page";
import HelpCenterPage from "../pages/help/helpCenter.page";
import DemoPage from "../pages/help/demo.page";
import SelectedRacePage from "../pages/selectedRace/selectedRace.page";
import UpcRacingPage from "../pages/racing/upcRacing.page";
import TransactionsPage from "../pages/transactions.page";
import BetSlipPage from "../pages/betSlip.page";
import NextToRacePage from "../pages/racing/nextToRace.page";
import CurrencySelectPage from "../pages/currency-select/currencySelect.page";
import AuthLayout from "../layouts/auth.layout";
import { ToastContainer } from "react-toastify";
import PrivacyPolicyPage from "../pages/legal/privacyPolicy.page";
import TermsAndUsePage from "../pages/legal/termsAndUse.page";
import { useEffect } from "react";
import DocsLayout from "../layouts/docs.layout";
import PolicyPrivacyPage from "../pages/policies/policyPrivacy.page";

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log = () => {};
      // console.warn = () => {};
      console.info = () => {};
    }

    return () => {};
  }, []);

  return (
    <ThemeWrapper>
      <ToastContainer />
      <Routes>
        <Route element={<DocsLayout />}>
          <Route path={ROUTES.PRIVACY_POLICY} element={<PolicyPrivacyPage />} />
          <Route path={ROUTES.TERMS_OF_USE} element={<TermsAndUsePage />} />
        </Route>
        <Route element={<GlobalLayout />}>
          <Route path={ROUTES.ALL_RACES} element={<AllRacesPage />} />
          <Route path={ROUTES.UPCOMING_RACES} element={<UpcRacingPage />} />
          <Route path={ROUTES.TH_RACING} element={<THRacingPage />} />
          <Route path={ROUTES.HS_RACING} element={<HSRacingPage />} />
          <Route path={ROUTES.DO_RACING} element={<DORacingPage />} />
          <Route
            path={`${ROUTES.RACES}/:dt/:pn/:tn/:rt/:rn`}
            element={<SelectedRacePage />}
          />
          <Route path={ROUTES.TRANSACTIONS} element={<TransactionsPage />} />
          <Route path={ROUTES.HELP} element={<HelpCenterPage />} />
          <Route path={ROUTES.DEMO} element={<DemoPage />} />
          <Route path={ROUTES.BET_SLIP} element={<BetSlipPage />} />
          <Route path={ROUTES.NEXT_TO_RACE} element={<NextToRacePage />} />
          <Route
            path={ROUTES.POLICY_PRIVACY_POLICY}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={ROUTES.POLICY_TERMS_AND_USE}
            element={<TermsAndUsePage />}
          />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.CURRENCY} element={<CurrencySelectPage />} />
        </Route>
        <Route path={ROUTES.INDEX} element={<IndexPage />} />
      </Routes>
    </ThemeWrapper>
  );
}

export default App;
