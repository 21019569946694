import { FC } from "react";
import { useRecoilValue } from "recoil";
import { TransactionColumn } from "../../../../common/constants/columns.constant";
import { RESULTED } from "../../../../common/constants/general.constant";
import { RaceKeyDTO } from "../../../../common/models/race.mode";
import { WagersDTO } from "../../../../common/models/transaction.model";
import { TransactionDTO } from "../../../../common/types/transactionTypes.type";
import { ColumnBodyOptions } from "../../../../common/types/ui.types";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import { AtomWagerTransactions } from "../../../../store/transaction/transaction.store";
import PrimeTable from "../../../elements/table/primeTable.element";
import styles from "./transactionTable.module.scss";

interface ITableProps {
  onRowClick: (e: WagersDTO) => void;
  onPayout: (rowData: TransactionDTO, e: any) => void;
}
const TransactionTable: FC<ITableProps> = ({ onRowClick, onPayout }) => {
  const wagers = useRecoilValue(AtomWagerTransactions);
  const tTracks = useTranslation(LKeys.tracks);
  const tGenerals = useTranslation(LKeys.generals);

  const StatusTemplate = (rowData: TransactionDTO) => {
    let { betStatus, poolPayout } = rowData;
    if (betStatus === RESULTED) {
      if (Object.keys(poolPayout ?? {}).length > 0) {
        return (
          <button
            onClick={(e: any) => onPayout(rowData, e)}
            className={styles.modelOpenBtn}
          >
            {betStatus}
          </button>
        );
      }
    }
    return <span>{betStatus}</span>;
  };
  const ColumnBody = (
    rowData: TransactionDTO,
    { field }: ColumnBodyOptions
  ) => {
    const { eventType, province, raceNo, trackName } =
      rowData.raceKey as RaceKeyDTO;

    switch (field) {
      case "timestamp":
        return <>{rowData.formattedTime}</>;
      case "trackName":
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              <span className={`flag-16 flag-${province.toLowerCase()}`}></span>{" "}
              {tTracks(trackName)} - {eventType}
            </span>
          </>
        );
      case "raceNo":
        return <div className={styles.raceNo}>R {raceNo}</div>;
      case "poolType":
        return <span>{tGenerals(rowData.poolType)}</span>;
      case "betStatus":
        return StatusTemplate(rowData);
      case "totalInvested":
        return <div className="text-center">{rowData.totalInvested}</div>;
      case "returnPnl":
        const pnl = rowData[field];
        return (
          <div
            className={`${
              pnl < 0 ? styles.negative : pnl > 0 ? styles.positive : ""
            } text-center`}
          >
            {pnl ?? "-"}
          </div>
        );
      default:
        return <span>{rowData[field]}</span>;
    }
  };

  return (
    <>
      <PrimeTable
        columns={TransactionColumn}
        data={wagers ?? []}
        ColumnBody={ColumnBody}
        onRowClick={onRowClick}
      />
    </>
  );
};

export default TransactionTable;
