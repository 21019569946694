import { NON_DISPLAY } from "../constants/general.constant";
import { POOLS } from "../constants/pools.constant";
import { __PoolTypeDTO } from "../types/dynamic.type";

interface GetPoolsObjParam {
  poolObj: __PoolTypeDTO;
}

export const PoolsService = () => {
  const sortPools = (poolList: string[]) => {
    let _sorted: string[] = [];
    for (const pool of poolList) {
      // if (isMulti(pool)) continue; // TODO uncomment to hide multi pools
      switch (pool) {
        case POOLS.WIN_PLACE:
          _sorted[0] = pool;
          break;
        case POOLS.DOUBLE:
          _sorted[1] = pool;
          break;
        case POOLS.QUINELLA:
          _sorted[2] = pool;
          break;
        case POOLS.SWINGER:
          _sorted[3] = pool;
          break;
        case POOLS.EXACTA:
          _sorted[4] = pool;
          break;
        case POOLS.TRIFECTA:
          _sorted[5] = pool;
          break;
        case POOLS.SUPERFECTA:
          _sorted[6] = pool;
          break;
        case POOLS.PICK_3:
          _sorted[7] = pool;
          break;
        case POOLS.PICK_4:
          _sorted[8] = pool;
          break;
      }
    }
    return _sorted;
  };

  // * update pools list according to firebase data.
  const getPoolsObj = ({ poolObj }: GetPoolsObjParam) => {
    let _poolsData = { ...poolObj };
    let _poolList: string[] = []; // Pool list to be showed on pools tab.
    for (const poolName in poolObj) {
      // if (process.env.SHOW_MULTI === "false" && isMulti(poolName)) continue;
      const _pool = poolObj[poolName];
      if (_pool.poolStatus === NON_DISPLAY) {
        delete _poolsData[poolName];
        continue;
      }

      if (
        process.env.NEXT_PUBLIC_SHOW_FIXED === "false" &&
        poolName.startsWith("FO_")
      ) {
        delete _poolsData[poolName];
        continue;
      }

      if (poolName.match(/^(WIN|PLACE|FO_WIN|FO_PLACE)$/)) {
        if (!_poolList.includes(POOLS.WIN_PLACE))
          _poolList.push(POOLS.WIN_PLACE);
        continue;
      }
      _poolList.push(poolName);
    }
    let _sortedPoolList = sortPools(_poolList);
    let res = _sortedPoolList.filter((val: any) => {
      return val;
    });
    return res;
  };
  return { getPoolsObj };
};
