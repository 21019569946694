import styles from "./_ads.module.scss";
import useWindowSize from "../../hook/useWindowSize";
import { Images } from "../../common/data/image.data";
import Carousel from "react-multi-carousel";

const LandscapeAd = () => {
  const resize = useWindowSize();

  const responsive = {
    desktop: {
      breakpoint: { max: 2560, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 590 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 590, min: 320 },
      items: 1,
    },
  };
  return (
    <div className={styles.adWrapper}>
      <a
        href="https://www.protocolzone.com/"
        rel="noopener noreferrer"
        target={"_blank"}
      >
        <Carousel
          responsive={responsive}
          ssr={true}
          draggable={false}
          slidesToSlide={1}
          autoPlay={true}
          autoPlaySpeed={4000}
          transitionDuration={500}
          infinite={true}
          arrows={false}
        >
          {Images.map((image, index) => {
            return (
              <div key={index}>
                {resize.width > 590 ? (
                  <img src={image.desktopSrc} className={styles.img} alt="" />
                ) : (
                  <img src={image.mobileSrc} className={styles.img} alt="" />
                )}
              </div>
            );
          })}
        </Carousel>
      </a>
    </div>
  );
};

export default LandscapeAd;
// 07acc1
