import { Helmet } from "react-helmet";
import TransactionComponent from "../components/pages/transactionComponents/transaction.component";

const TransactionsPage = () => {
  return (
    <>
      <Helmet>
        <title>Transactions - Ashva Racing</title>
      </Helmet>
      <TransactionComponent />
    </>
  );
};

export default TransactionsPage;
