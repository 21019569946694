import { off, onValue } from "firebase/database";
import { useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { RESULTED } from "../../../common/constants/general.constant";
import { LOG } from "../../../common/helpers/debugging.helper";
import { isMulti } from "../../../common/helpers/pool.helper";
import { RaceDetailsDTO, RaceKeyDTO } from "../../../common/models/race.mode";
import { RaceTripDTO } from "../../../common/models/raceTrip.model";
import {
  Ref_AllRaceRunners,
  ref_WinAllLiveOdds,
} from "../../../common/services/nodes.service";
import {
  __FavoriteDTO,
  __SelectionOddsDTO,
} from "../../../common/types/dynamic.type";
import useSelected from "../../../hooks/useSelectedPool";
import {
  AtomAllLegs,
  AtomLegsRacesTrips,
  AtomMultiLegFav,
  AtomMultiWinLiveOdds,
} from "../../../store/bets/multiBet.store";

import {
  AtomPoolsData,
  AtomSelectedRaceTrips,
} from "../../../store/races/selectedRace.store";
import { AtomIsBoxed } from "../../../store/selection/selection.store";
import AbsLoader from "../../elements/absLoader/absLoader.element";
import CancelledRace from "../../shared/errors/cancelledRace.error";
import Switch from "../../ui/switch/switch.ui";
import MultiRaceTripsTable from "../DoubleComponents/MultiRaceTripsTable/MultiRaceTripsTable";
import PoolsTab from "../selectedRace/poolsTab/pools.tab";
import RaceTipsInline from "../selectedRace/raceTipComponent/raceTipsInline.component";
import RaceTripsTable from "./raceTrips.table";

type Props = {};

const RaceTripsComponent = (props: Props) => {
  const { isExotics, race, pool } = useSelected();
  const raceTrips = useRecoilValue(AtomSelectedRaceTrips);
  const poolsData = useRecoilValue(AtomPoolsData);
  const refRunners = useRef<any>(null);
  const refLiveOdds = useRef<any>(null);
  const [isBoxed, setIsBoxed] = useRecoilState(AtomIsBoxed);

  const setLegsTrips = useSetRecoilState(AtomLegsRacesTrips);
  const setWinLiveOdds = useSetRecoilState(AtomMultiWinLiveOdds);
  const setMultiLegFav = useSetRecoilState(AtomMultiLegFav);
  const legs = useRef<{ [l: string]: RaceTripDTO[] }>({});
  const legOdds = useRef<{ [l: string]: __SelectionOddsDTO }>({});

  const setAllLegs = useSetRecoilState(AtomAllLegs);

  useEffect(() => {
    if (pool) {
      onPoolChange(pool);
    } else offListeners();
    return () => offListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool]);

  const onPoolChange = (pool: string) => {
    if (!race?.raceKey) return;
    const _pool = poolsData[pool];
    if (!_pool || !isMulti(pool)) return;
    let _raceNos = _pool.otherLegs.split(",");
    let _raceKeys: RaceKeyDTO[] = [];
    for (const _raceNumber of _raceNos) {
      if (_pool && isMulti(pool) && race) {
        let _rk2: RaceKeyDTO = {
          ...race.raceKey,
          raceNo: Number(_raceNumber),
        };

        _raceKeys.push(_rk2);
      }
    }

    let _allRaceKeys: RaceKeyDTO[] = [];
    let _selectedRace = race?.raceKey;
    _allRaceKeys = [_selectedRace, ..._raceKeys];

    setAllLegs(_allRaceKeys);
    fetchLegRunners(_allRaceKeys);
  };

  const offListeners = () => {
    if (refRunners.current) off(refRunners.current);
    if (refLiveOdds.current) off(refLiveOdds.current);
  };

  const fetchLegRunners = async (raceKeys: RaceKeyDTO[]) => {
    LOG("FETCH MULTI RACES", "API");
    if (refRunners.current) off(refRunners.current);
    if (!race?.raceKey) return;
    refRunners.current = Ref_AllRaceRunners(race?.raceKey);

    let scratchRunners: any = [];
    onValue(
      refRunners.current,
      (res) => {
        let runnerDetails = res.val();
        let _temp: any = {};
        legs.current = {};
        for (const raceKey of raceKeys) {
          let _raceNo = raceKey.raceNo;
          _temp[_raceNo] = Object.values(runnerDetails[_raceNo]);
          let _runners: RaceTripDTO[] = _temp[_raceNo];
          const scrRunners = _runners
            .filter((x) => x.scratched)
            .map((x) => x.raceTripKey.selId);
          scratchRunners.push(scrRunners);
        }
        legs.current = _temp;
       
        fetchLiveOdds(raceKeys, scratchRunners);
        setLegsTrips(legs.current);
      },
      (err) => {
        console.error(err, "---------err");
      }
    );
  };

  const fetchLiveOdds = (raceKeys: RaceKeyDTO[], scrRunners: string[]) => {
    LOG("FETCH LIVE ODDS FOR MULTIRACES", "API");
    // try {
      // for (const refKey in refLiveOdds.current) {
      //   const ref = refLiveOdds.current[refKey];
      //   if (ref) off(ref);
      // }

      // if(refLiveOdds.current) off(refLiveOdds.current)
      if (!race?.raceKey) return;
      refLiveOdds.current = ref_WinAllLiveOdds(race.raceKey);
      legOdds.current = {};
      let fav: __FavoriteDTO = {};
      onValue(
        refLiveOdds.current,
        (res) => {
          const _oddsData = res.val() ?? ({} as __SelectionOddsDTO);
          for (const key of raceKeys) {
            let _odds = _oddsData[key.raceNo];
            const poolOdds = _odds["DEFAULT"];
            if (poolOdds) {
              for (const pool in poolOdds) {
                if (pool !== "WIN" || !poolOdds["WIN"]) continue;
                let _prevOdds = { ...legOdds.current };
                let _pool = poolOdds["WIN"];
                _prevOdds[key.raceNo] = _pool;
                legOdds.current = _prevOdds;
              }
              const selIdToOdds = legOdds.current[key.raceNo];
              let _min = Number.MAX_VALUE,
                _fav: string | null = null;
              for (const sel in selIdToOdds) {
                const odds = selIdToOdds[sel];
                if (scrRunners.includes(sel)) continue;
                if (odds && odds <= _min) {
                  _fav = sel;
                  _min = odds;
                }
              }
              fav = { ...fav, [key.raceNo]: _fav };
            }
          }
          setWinLiveOdds(legOdds.current);
          setMultiLegFav(fav);
        },
        (err) => {
          console.log(err);
        }
      );
    // } catch (error) {}
  };

  if (race?.cancelled) return <CancelledRace />;
  if (raceTrips === undefined) return <AbsLoader show />;
  if (!raceTrips) return <>No Trips Available</>; // TODO : use i18n
  if (race?.raceStatus === RESULTED) return null;
  return (
    <div>
      <PoolsTab onPoolChange={() => {}} />
      <RaceTipsInline />
      {isExotics && (
        <TableCaption>
          <Switch label="Boxed" onChange={setIsBoxed} value={isBoxed} />
        </TableCaption>
      )}
      {pool && isMulti(pool) ? (
        <MultiRaceTripsTable />
      ) : (
        <RaceTripsTable raceTrips={raceTrips} />
      )}
    </div>
  );
};

export default RaceTripsComponent;

// * Styled components
const TableCaption = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  background: ${(p) => p.theme.bodySecondary};
`;
