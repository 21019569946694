import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { DATE_FORMATS } from "../../../../common/constants/dateTime.constant";
import { Days, DaysMenu } from "../../../../common/data/menu.data";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import {
  AtomRaceSnaps,
  AtomSelectedDate,
} from "../../../../store/races/races.store";
import styles from "./trackRacesHeader.module.scss";

type Props = {
  provinces: string[];
  onProvinceClick: (p: string) => void;
};

const TrackRacesHeader = ({ provinces, onProvinceClick }: Props) => {
  const races = useRecoilValue(AtomRaceSnaps);
  const tGenerals = useTranslation(LKeys.generals);
  const tProvinces = useTranslation(LKeys.countries);
  const [selectedDate, setSelectedDate] = useRecoilState(AtomSelectedDate);
  const [selectedDay, setSelectedDay] = useState(Days.today);
  useEffect(() => {
    let current = new Date(new Date().getTime());
    const yesterDay = format(
      current.setDate(current.getDate() - 1),
      DATE_FORMATS.yyyyMMdd
    );
    const selected = format(selectedDate, DATE_FORMATS.yyyyMMdd);

    if (selected === yesterDay) setSelectedDay(Days.yesterday);
    else setSelectedDay(Days.today);
  }, [selectedDate]);

  const onDayClick = (day: Days) => {
    const _today = new Date();
    let current = new Date(_today.getTime());
    if (day === Days.yesterday) current.setDate(_today.getDate() - 1);
    //  else if (day === Days.tomorrow) current.setDate(_today.getDate() + 1); // for tomorrow day
    setSelectedDate(current);
  };

  return (
    <SliderWrapper
      className={styles.sliderWrapper}
      style={{
        top:
          races && races.length > 0
            ? "calc(var(--height-header) + 5px)"
            : "0rem",
      }}
    >
      <div>
        {provinces.map((p) => {
          return (
            <SliderButton
              onClick={() => onProvinceClick(p)}
              className={styles.provinceBtn}
              key={p}
            >
              <div className={`${styles.btnChild} btnChild`}>
                <span className={`flag-16 flag-${p.toLowerCase()}`}></span>
                <span>{tProvinces(p)}</span>
              </div>
            </SliderButton>
          );
        })}
      </div>
      <div>
        {DaysMenu.map((day) => {
          return (
            <SliderButton
              key={day}
              onClick={() => onDayClick(day)}
              className={`${styles.provinceBtn} ${
                selectedDay === day ? "active" : ""
              }`}
            >
              <div className={`${styles.btnChild} btnChild`}>
                <span>{tGenerals(day)}</span>
              </div>
            </SliderButton>
          );
        })}
      </div>
    </SliderWrapper>
  );
};

export default TrackRacesHeader;

// * Styled Components
const SliderWrapper = styled.div`
  background: ${(p) => p.theme.active};
`;
const SliderButton = styled.button`
  color: ${(p) => p.theme.textContrast};
  margin-right: 0.25rem;
  &:hover,
  &.active {
    background: ${(p) => p.theme.bodyContrast};
    box-shadow: 2px 2px 3px #12312344;
    .btnChild {
      color: ${(p) => p.theme.textContrast};
    }
  }
`;
