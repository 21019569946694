import { BiRightArrowAlt } from "react-icons/bi";
import styled from "styled-components";
import { Blogs } from "../../common/data/blogs.data";
import styles from "./welcomeNote.module.scss";

export interface WelcomeNoteProps {
  title: string;
}

export const WelcomeNote = () => {
  return (
    <div className={styles.welcomeWrapper}>
      {Blogs.map((blog) => (
        <SecondChildWrp key={blog.id}>
          <div className={styles.childWrp}>
            <h3 className="capitalized">{blog.title.toLowerCase()}</h3>
            <p>{blog.description}</p>
            <BtnWrp href={blog.href} target="_blank">
              More Details
              {/* <HiOutlineArrowLongRight
                  size={20}
                  style={{ marginLeft: "0.3rem" }}
                /> */}
              <BiRightArrowAlt size={16} className="ml025" />
            </BtnWrp>
          </div>
        </SecondChildWrp>
      ))}
    </div>
  );
};

export default WelcomeNote;

const BtnWrp = styled.a`
  display: inline-flex;
  align-items: center;

  color: ${(p) => p.theme.textContrast};
  padding: 0.15rem 0.5rem;
  margin-top: 0.5rem;
  border: none;
  border-radius: var(--radius-default);
  /* border-radius: 2rem; */
  background-color: ${(p) => p.theme.active};
  font-size: 0.65rem;
`;

const SecondChildWrp = styled.div`
  &:not(:last-child) {
    /* padding: 0.8rem; */
    border-bottom: ${(p) => p.theme.borderPrimary};
  }
`;
