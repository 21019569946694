const getGradient = (c1: string, c2: string, c3: string) => {
  return `repeating-linear-gradient(
          -45deg,
          ${c1},
          ${c2} 5px,
          ${c3} 5px,
          #333 5px,
          #333 5px,
          #333 6px   
    )`;
};

export const EuropeCode: {
  [selId: string]: { color: string; striped?: boolean };
} = {
  "1": { color: "red" },
  "2": { color: "blue" },
  "3": { color: "white" },
  "4": { color: "black" },
  "5": { color: "orange" },
  "6": { color: getGradient("black", "white", "") },
  "7": { color: "red" },
  "8": { color: "blue" },
  "9": { color: "white" },
  "10": { color: "black" },
  "11": { color: "orange" },
  "12": { color: getGradient("black", "white", "") },
  "13": { color: "red" },
  "14": { color: "white" },
  "15": { color: "black" },
};

export const AustraliaCode: {
  [selId: string]: { color: string; striped?: boolean };
} = {
  "1": { color: "red" },
  "2": { color: getGradient("black", "white", "") },
  "3": { color: "white" },
  "4": { color: "blue" },
  "5": { color: "yellow" },
  "6": { color: "green" },
  "7": { color: "black" },
  "8": { color: "pink" },
  "9": { color: getGradient("green", "white", "") },
  "10": { color: getGradient("blue", "white", "red") },
  "11": { color: "red" },
  "12": { color: getGradient("black", "white", "") },
  "13": { color: "white" },
  "14": { color: "blue" },
  "15": { color: "yellow" },
};

export const NewZealandCode: {
  [selId: string]: { color: string; striped?: boolean };
} = {
  "1": { color: "red" },
  "2": { color: getGradient("black", "white", "") },
  "3": { color: "white" },
  "4": { color: "blue" },
  "5": { color: "yellow" },
  "6": { color: "green" },
  "7": { color: "black" },
  "8": { color: "pink" },
  "9": { color: getGradient("green", "white", "") },
  "10": { color: getGradient("blue", "white", "red") },
  "11": { color: "red" },
  "12": { color: getGradient("black", "white", "") },
  "13": { color: "white" },
  "14": { color: "blue" },
  "15": { color: "yellow" },
};

export const AmericanCode: {
  [selId: string]: { color: string; striped?: boolean };
} = {
  "1": { color: "red" },
  "2": { color: "blue" },
  "3": { color: "white" },
  "4": { color: "green" },
  "5": { color: "black" },
  "6": { color: "yellow" },
  "7": { color: getGradient("green", "white", "") },
  "8": { color: getGradient("black", "yellow", "") },
  "9": { color: "red" },
  "10": { color: "blue" },
  "11": { color: "white" },
  "12": { color: "green" },
  "13": { color: "black" },
  "14": { color: "yellow" },
  "15": { color: getGradient("green", "white", "") },
};
