import { Outlet } from "react-router";
import styled from "styled-components";
import PolicyFooter from "../components/shared/footer/policy.footer";
import PolicyHeader from "../components/shared/headers/policyHeader/policy.header";
import styles from "./_layouts.module.scss";
type Props = {};

const DocsLayout = (props: Props) => {
  return (
    <Wrapper className={styles.layoutWrapper}>
      <PolicyHeader />
      <DocsWrapper className={styles.docsWrapper}>
        <Outlet />
      </DocsWrapper>
      <PolicyFooter />
    </Wrapper>
  );
};

export default DocsLayout;

const Wrapper = styled.div`
  background: ${(p) => p.theme.body};
`;

// * Styled component
const DocsWrapper = styled.main`
  max-width: 80%;
  margin: auto;
  padding: 0.5rem;
  background: ${(p) => p.theme.bodyContent};
`;
