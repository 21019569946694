import { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import styles from "./tooltip.module.scss";

interface TooltipsProps {
  trackName: string;
  info: any;
  style?: CSSProperties;
  maxWidth?: number | string;
}

const Tooltips = (props: TooltipsProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    if (textRef.current?.offsetWidth && textRef.current?.scrollWidth) {
      setIsOverflown(
        textRef.current?.offsetWidth < textRef.current?.scrollWidth
      );
    }
    return () => {};
  }, []);

  return (
    <div className={styles.tooltip}>
      <div
        className={styles.trackName}
        ref={textRef}
        style={{ maxWidth: props.maxWidth }}
      >
        {props.trackName}
      </div>
      {isOverflown && (
        <Tip className={styles.bottom}>
          <p>{props.info}</p>
          <i></i>
        </Tip>
      )}
    </div>
  );
};

export default Tooltips;

const Tip = styled.div`
  background: ${(p) => p.theme.bodySecondary};
  color: ${(p) => p.theme.text};
`;
