import { Fragment, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { LS_KEYS } from "../../../../common/constants/general.constant";
import { LOG } from "../../../../common/helpers/debugging.helper";
import { isEmptyObj } from "../../../../common/helpers/object.helper";
import { isExotics, isMulti } from "../../../../common/helpers/pool.helper";
import { _setStorage } from "../../../../common/helpers/storage.helper";
import { BetCardDTO, BetRequestDTO } from "../../../../common/models/bet.model";
import { BetAPIService } from "../../../../common/services/api/betApi.service";
import { __BetCardDTO } from "../../../../common/types/dynamic.type";
import {
  AtomAggregatorObject,
  AtomAuthUser,
} from "../../../../store/auth/auth.store";
import {
  AtomBetAmounts,
  AtomBetCards,
  AtomBetTab,
  AtomReRender,
} from "../../../../store/bets/bets.store";
import ExoticsBetCard from "./betCards/exotics.betCard";
import WinPlaceBetCard from "./betCards/winPlace.betCard";
import styles from "./betSlip.module.scss";
import { RiPagesLine } from "react-icons/ri";
import ElevatedButton from "../../../ui/buttons/elevated.button";
import styled from "styled-components";
import useTranslation from "../../../../store/locale/useTranslation";
import { LKeys } from "../../../../store/locale/locale.data";

const BetSlipBody = () => {
  const user = useRecoilValue(AtomAuthUser);
  const betTab = useRecoilValue(AtomBetTab);
  const [betCards, setBetCards] = useRecoilState(AtomBetCards);
  const { placeBet } = BetAPIService();
  const _betCards = useRef<__BetCardDTO>({ ...betCards });
  const betAmount = useRecoilValue(AtomBetAmounts);
  const [aggregatorObj, setAggregatorObj] =
    useRecoilState(AtomAggregatorObject);
  const [reRender, setReRender] = useRecoilState(AtomReRender);
  const resetBets = useResetRecoilState(AtomBetCards);
  const t = useTranslation(LKeys.generals);
  const tErrors = useTranslation(LKeys.errors);

  useEffect(() => {
    _betCards.current = betCards;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRender]);

  // * Update betCards state
  const updateBetCards = (
    betKey: string,
    bet: BetRequestDTO,
    processing: boolean,
    response?: string
  ) => {
    _betCards.current = {
      ..._betCards.current,
      [betKey]: {
        bet,
        processing,
        response,
      },
    };
    setBetCards(_betCards.current);
  };

  // * Handle bet object for the amounts and selections.
  const manageBetObject = (_bet: BetRequestDTO) => {
  
    if (!isExotics(_bet.poolKey.poolType) && !isMulti(_bet.poolKey.poolType)) {
      _bet.betAmount = Number(_bet.singleBetAmt ?? 0);
      _bet.selections = { [_bet.selId]: _bet.betAmount };
    } else {
      let _amounts = { ...betAmount };
      _bet.betAmount = Number(_amounts[_bet.betId]?.stake) ?? 0;
      let _sels = {} as any;
      for (const key in _bet.selections) {
        _sels[key] = _amounts[_bet.betId]?.stakePerCombo;
      }
      _bet.selections = _sels;
    }
    _bet.customerId = user?.clientId ?? "";
    _bet.isSubmitted = true;
    return _bet;
  };

  // * On Place single bet
  const placeSingleBet = (betCard: BetCardDTO) => {
  
    if (!user) return;

    const { bet } = betCard;
    if (bet.betResponse) return;
    const { betKey } = bet;
    LOG(`Placing bet for ${betKey} `, "INFO");

    let _bet = { ...bet };
    _bet = manageBetObject(_bet);

    placeBet(_bet, user)
      .then((res) => {
        LOG("PLACE BET API CALLED", "API");

        _bet.betResponse = res;
        _bet.resStatus = res.errors
          ? res.errors
          : res.betStatus ?? "No Status Found!";
        if (res.negotiateId) {
          LOG("Negotiate ID: " + res.negotiateId, "ERROR");
          updateBetCards(betKey, _bet, false, _bet.resStatus);
          return;
        }

        if (res.errors || res.betStatus === "FAILED") {
          _bet.isSubmitted = false;
          delete _bet.betResponse;
          updateBetCards(betKey, _bet, false, _bet.resStatus);
          return;
        }

        updateBetCards(betKey, _bet, false, _bet.resStatus);

        if (res.customerBalance && aggregatorObj) {
          const newObj = { ...aggregatorObj, balance: res.customerBalance };
          setAggregatorObj(newObj);
        }
        // ! Remove card after 2 seconds.
        setTimeout(() => {
          LOG("InTime OUt Bet Card Removed", "CRUD");
          let _cards: any = { ..._betCards.current };
          delete _cards[betKey];
          _betCards.current = _cards;
          _setStorage(LS_KEYS.bets, _cards);
          setBetCards(() => _cards);
        }, 2000);
      })
      .catch((err) => {
        let error = "Something Went Wrong!";
        if (err?.message) {
          error = err.message;
        }
        if (err.response?.data?.message) error = err.response?.data?.message;
        _bet.resStatus = error;
        delete _bet.betResponse;
        _bet.isSubmitted = false;
        updateBetCards(betKey, _bet, false, _bet.betResponse);
      });
  };

  // * On CLick of place bet button
  const onPlaceBet = (betCard: BetCardDTO) => {
   
    const { betKey } = betCard.bet;
    if (betCard.bet?.betResponse) return;
    const _cards = { ...betCards, [betKey]: betCard };
    _betCards.current = _cards;
    setBetCards(() => _cards);
    placeSingleBet(betCard);
  };

  // * On remove of bet card.
  const onRemoveCard = (betKey: string) => {
    let _betCards = { ...betCards };
    delete _betCards[betKey];
    setBetCards(_betCards);
    _setStorage(LS_KEYS.bets, _betCards);
  };

  // * On Clear All Bets
  const onClear = () => {
    resetBets();
    setReRender(new Date());
    localStorage.removeItem(LS_KEYS.bets);
  };

  // * On place all bets
  const placeAllBets = () => {
    for (const betKey in betCards) {
      const betCard = betCards[betKey];

      // * Continue loop if bet is in procession mode.
      const _card = _betCards.current[betKey];
      if (_card && (_card.processing || _card.bet.betResponse)) {
        continue;
      }

      // * Send bet card to the api to place that bet.
      placeSingleBet(betCard);
      // * Store bet in current reference and the cards state to be rendered for processing.
      _betCards.current = {
        ..._betCards.current,
        [betKey]: { ...betCard, processing: true },
      };
      setBetCards(_betCards.current);
    }
  };

  if (betTab === "openBets") return null;

  return (
    <>
      <div className="p05">
        {isEmptyObj(betCards) ? (
          <div className={styles.noBetSlipWrp}>
            <RiPagesLine size={25} />
            <div className={styles.text1}>{tErrors("emptyBetSlip")}</div>
            {/* <div className={styles.text2}>
              Checkout today's racing & bet now!
            </div> */}
          </div>
        ) : (
          Object.values(betCards ?? {}).map((card, index) => {
            const { poolKey } = card.bet;
            const _isExotics =
              isExotics(poolKey.poolType) || isMulti(poolKey.poolType);
            if (_isExotics === null) return null;
            return (
              <Fragment key={index}>
                <ExoticsBetCard
                  onRemoveCard={() => onRemoveCard(card.bet.betKey)}
                  betCard={card}
                  placeBet={onPlaceBet}
                  isActive={_isExotics}
                />
                <WinPlaceBetCard
                  onRemoveCard={() => onRemoveCard(card.bet.betKey)}
                  betCard={card}
                  placeBet={onPlaceBet}
                  isActive={!_isExotics}
                />
              </Fragment>
            );
          })
        )}
      </div>

      {!isEmptyObj(betCards) && (
        <Footer className={styles.slipFooter}>
          <ElevatedButton
            onClick={onClear}
            size="sm"
            severity="primary"
            label={t("clear_all")}
          />
          <ElevatedButton
            size="sm"
            onClick={placeAllBets}
            severity="warning"
            label={t("placeAllBets")}
          />
        </Footer>
      )}
    </>
  );
};

export default BetSlipBody;

const Footer = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
  padding: 0.25rem;
  text-align: right;
  button {
    margin: 0 0.25rem;
    font-size: 0.8rem !important;
  }
`;
