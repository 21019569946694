import { Outlet } from "react-router-dom";
import styled from "styled-components";
import NewBetSlipComponent from "../components/pages/newBetSlipComponent/newBetSlipComponent";
import NavigationHeader from "../components/shared/headers/navigationHeader/navigation.header";
import NavTabMenuHeader from "../components/shared/headers/navTabMenuHeader/navTabMenuHeader";
import RaceSnapHeader from "../components/shared/headers/raceSnapHeader/raceSnap.header";
import NavCard from "../components/shared/navCard/nav.card";
import RaceTipComponent from "../components/pages/selectedRace/raceTipComponent/raceTipComponent";
import Card from "../components/ui/card/card.component";
import WelcomeNote from "../components/welcomeNote/welcomeNote";
import AuthProvider from "../store/auth/auth.provider";
import GlobalProvider from "../store/global/global.provider";
import RacesProvider from "../store/races/races.provider";
import { GLWrapper } from "../styled-components/layoutComponents/globalLayout.styled";
import styles from "./_layouts.module.scss";
import Footer from "../components/shared/footer/footer";
import NotificationHeader from "../components/shared/headers/notificationHeader/notification.header";

// * Global Layout
const GlobalLayout = () => {
  const HeaderTemplate = () => {
    return <HeadTemp>Ashva Blogs</HeadTemp>;
  };

  return (
    <AuthProvider>
      <GlobalProvider>
        <RacesProvider>
          <GLWrapper>
            <div className={styles.layoutWrapper}>
              <NotificationHeader />
              <NavigationHeader />
              <NavTabMenuHeader />
              <RaceSnapHeader />
              <main className={styles.contentWrapper}>
                <div className={styles.col}>
                  <Card style={{ overflow: "hidden" }}>
                    <NavCard />
                  </Card>
                  <Card
                    style={{ marginTop: "0.5rem" }}
                    headerTemplate={HeaderTemplate}
                  >
                    <WelcomeNote />
                  </Card>
                </div>

                <Content>
                  {/* <LandscapeAd /> */}
                  <Outlet />
                </Content>

                <div className={styles.col}>
                  {/* <BetSlipComponent /> */}
                  <RaceTipComponent />
                  {/* <div className={styles.imgWrapper}>
                    <img src={sideBanner} referrerPolicy="no-referrer" alt="" />
                  </div> */}
                  <NewBetSlipComponent />
                </div>
              </main>
              <Footer />
            </div>
          </GLWrapper>
        </RacesProvider>
      </GlobalProvider>
    </AuthProvider>
  );
};

export default GlobalLayout;

const Content = styled.main`
  background: ${(p) => p.theme.bodyContent};
  /* background: #dfe8f4; */
  border-radius: var(--radius-default);
  transition: var(--transition-default);
`;

const HeadTemp = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.5rem 1.1rem;
  background: ${(p) => p.theme.bodyContrastLight};
`;
