import { useEffect, useRef, useState } from "react";
import { RiPagesLine } from "react-icons/ri";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { isEmptyObj } from "../../../common/helpers/object.helper";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import { AtomTrackRaces } from "../../../store/races/races.store";
import Title from "../../shared/title/title.component";
import TrackRaceCard from "./trackRaceCard/trackRace.card";
import styles from "./trackRaces.module.scss";
import TrackRacesHeader from "./trackRacesHeader/trackRaces.header";
import TrackRacesLoader from "./trackRacesLoader/trackRacesLoader.component";

const TrackRaces = () => {
  const provinceRefs = useRef<{ [province: string]: HTMLDivElement }>({});
  const trackRaces = useRecoilValue(AtomTrackRaces);
  const tTracks = useTranslation(LKeys.tracks);
  const tProvinces = useTranslation(LKeys.countries);
  const [provinces, setProvinces] = useState<string[]>([]);

  useEffect(() => {
    setProvinces(Object.keys(trackRaces ?? {}));
  }, [trackRaces]);

  // * On click of province slide to the tracks starting point of clicked province
  const onProvinceClick = (p: string) => {
    let el = provinceRefs.current[p];
    if (el) {
      window.scrollTo({
        top: el.offsetTop - 140,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  // * Province template for track name
  const ProvinceTemplate = (province: string) => (
    <div className={styles.province}>
      <span className={`flag-16 flag-${province.toLowerCase()}`}></span>
      <span>{tProvinces(province)}</span>
    </div>
  );
  // * Template for tracks display
  const TracksTemplate = (province: string) => {
    if (trackRaces === undefined) return <h3>Loading...</h3>;
    if (trackRaces === null) return <h3>Data Not Found!</h3>;
    const trackNames = Object.keys(
      trackRaces ? trackRaces[province] ?? {} : {}
    );
    const _trackToRaces = trackRaces[province] ?? {};
    let thRaces: string[] = [];
    let hsRaces: string[] = [];
    let doRaces: string[] = [];
    for (const trackName of trackNames) {
      let split = trackName.split("-");
      if (split[2] === "TH") {
        thRaces.push(trackName);
      } else if (split[2] === "HS") {
        hsRaces.push(trackName);
      } else if (split[2] === "DO") {
        doRaces.push(trackName);
      }
    }

    const sortedTracks = thRaces.sort().concat(hsRaces.sort(), doRaces.sort());

    return (
      <div>
        {sortedTracks.map((track) => {
          const races = _trackToRaces[track];
          const _pCode = track.split("-");
          const _et = _pCode[2];
          const _track = _pCode[1];

          return (
            <div key={track}>
              <div className={styles.trackName}>
                <Title endTemplate={() => ProvinceTemplate(province)}>
                  <span className={`et32 ${_et}-active`}></span>
                  {tTracks(_track)}
                </Title>
              </div>
              <div className={styles.raceList}>
                {Object.values(races).map((race) => {
                  return <TrackRaceCard key={race.raceKeyString} race={race} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  // if (typeof trackRaces === "object" && isEmptyObj(trackRaces))
  // return <NoData className="noTrackRaces">NoDataMsg</NoData>;
  if (trackRaces === undefined) return <TrackRacesLoader />;
  if (trackRaces === null) return <h2>No Data Found!</h2>;
  return (
    <>
      <TrackRacesHeader
        onProvinceClick={onProvinceClick}
        provinces={provinces}
      />
      <div className={styles.trackRacesWrapper}>
        {typeof trackRaces === "object" && isEmptyObj(trackRaces) && (
          <NoData className="noTrackRaces">
            <RiPagesLine size={45} />
            <h5>Tracks data not available</h5>
          </NoData>
        )}
        {provinces.map((p) => {
          return (
            <ProvinceTracksWrapper
              ref={(el) => {
                if (el) provinceRefs.current[p] = el;
              }}
              key={p}
              className={styles.provinceTracksWrapper}
            >
              <div className={styles.separator}></div>
              {TracksTemplate(p)}
            </ProvinceTracksWrapper>
          );
        })}
      </div>
    </>
  );
};

export default TrackRaces;

// * Styled components
const NoData = styled.div`
  background: ${(p) => p.theme.bodyContent};
  /* border-radius: var(--radius-default); */
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 600;
`;
const ProvinceTracksWrapper = styled.div`
  &:not(:first-child) {
    border-top: ${(p) => p.theme.borderHighlight};
  }
`;
