import styled from "styled-components";
import useTranslation from "../../../store/locale/useTranslation";
import { LKeys } from "../../../store/locale/locale.data";
import { NavCardMenu } from "../../../common/data/menu.data";
import { Link, useLocation } from "react-router-dom";
import styles from "./nav.card.module.scss";
import useWindowSize from "../../../hook/useWindowSize";
import { useSetRecoilState } from "recoil";
import { AtomSidebar } from "../../../store/global/global.store";

const NavCard = () => {
  const path = useLocation().pathname;
  const t = useTranslation(LKeys.generals);
  const resize = useWindowSize();
  const setShow = useSetRecoilState(AtomSidebar);

  return (
    <>
      {NavCardMenu.map(({ route, localeKey, Icon, sprite, breakPoint }) => {
        if (breakPoint && breakPoint < resize.width) return null;
        return (
          <Link key={route} to={route} onClick={() => setShow(null)}>
            <NavLink className={path === route ? "active" : ""}>
              {Icon ? (
                <span className={styles.iconsWrp}>
                  <Icon size={24} />
                </span>
              ) : (
                <span className={styles.iconsWrp}>
                  <span className={`${sprite}`}></span>
                </span>
              )}
              {t(localeKey)}
            </NavLink>
          </Link>
        );
      })}
    </>
  );
};

export default NavCard;

// * Styled components
const NavLink = styled.div`
  color: ${(p) => p.theme.textContrast};
  border-bottom: ${(p) => p.theme.borderPrimary};
  display: flex;
  align-items: center;
  padding: 0.5rem;
  &:hover {
    background: ${(p) => p.theme.btnHoverDark};
  }
  &.active {
    background: ${({ theme }) => theme.active};
  }
`;
