import { ReactNode } from "react";
import styles from "./navSideBar.module.scss";
import LOGOLight from "../../../../assets/logos/LogoLight.png";
import { CgClose } from "react-icons/cg";
import styled from "styled-components";

interface NavbarProps {
  show: boolean;
  setShow: (val: string | null) => void;
  children: ReactNode;
  isLogo?: boolean;
  position?: "left" | "right";
}

const NavSideBar = ({
  show,
  setShow,
  children,
  isLogo,
  ...props
}: NavbarProps) => {
  // const ref = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div
        className={`${show && styles.backdrop}`}
        onClick={() => setShow && setShow(null)}
      ></div>
      <SideBarWrp
        style={{ [props.position ?? "left"]: "0", backgroundColor: "#262A53" }}
        className={`${styles.navbar} ${show ? styles.active : ""}`}
      >
        <SideHeadWrp className={styles.headWrp}>
          {isLogo && (
            <div className={styles.logoWrap}>
              <img src={LOGOLight} height={40} alt="AshvaBetsLogo" />
            </div>
          )}
          <div onClick={() => setShow && setShow(null)}>
            <CgClose
              size={20}
              cursor={"pointer"}
              className={styles.closeIcnWrp}
            />
          </div>
        </SideHeadWrp>
        {children}
      </SideBarWrp>
    </div>
  );
};

export default NavSideBar;

const SideBarWrp = styled.div`
  background: ${(p) => p.theme.bodyContrast};
`;
const SideHeadWrp = styled.div`
  border-bottom: ${(p) => p.theme.borderPrimary};
`;
