import { ref, query } from "@firebase/database";
import { ref as storageRef } from "@firebase/storage";
import { RTDatabase, Storage } from "../configs/firebase.config";
import { toRaceKeyStr } from "../helpers/value.helper";
import { RaceKeyDTO } from "../models/race.mode";

export const Ref_RunnerDetails = (raceKey: RaceKeyDTO) => {
  return ref(
    RTDatabase,
    `${raceKey.localDate}/runnerDetails/${raceKey.pzoneCode}/${raceKey.raceNo}`
  );
};

export const Ref_AllRaceRunners = (raceKey: RaceKeyDTO) => {
  return ref(
    RTDatabase,
    `${raceKey.localDate}/runnerDetails/${raceKey.pzoneCode}`
  );
};

// export const Ref_LegRunners = (
//   raceKey: RaceKeyDTO,
//   raceNo: string | number
// ) => {
//   return ref(
//     RTDatabase,
//     `${raceKey.localDate}/runnerDetails/${raceKey.pzoneCode}/${raceNo}`
//   );
// };

// export const Ref_WinOdds = (raceKey: RaceKeyDTO) => {
//   return ref(
//     RTDatabase,
//     `${raceKey.localDate}/liveOdds/${raceKey.pzoneCode}/${raceKey.raceNo}/DEFAULT/WIN`
//   );
// };

export const Ref_LiveOdds = (raceKey: RaceKeyDTO) => {
  return ref(
    RTDatabase,
    `${raceKey.localDate}/liveOdds/${raceKey.pzoneCode}/${raceKey.raceNo}/DEFAULT`
  );
};


export const ref_WinLiveOdds = (raceKey: RaceKeyDTO) => {
  return ref(
    RTDatabase,
    `${raceKey.localDate}/liveOdds/${raceKey.pzoneCode}/${raceKey.raceNo}/DEFAULT/WIN`
  );
};

export const ref_WinAllLiveOdds = (raceKey: RaceKeyDTO) => {
  return ref(
    RTDatabase,
    `${raceKey.localDate}/liveOdds/${raceKey.pzoneCode}`
  );
};


export const Ref_RaceTips = (raceKey: RaceKeyDTO) => {
  const raceKeyStr = toRaceKeyStr(raceKey);
  return ref(RTDatabase, `tips/${raceKey.localDate}/${raceKeyStr}`);
};

export const Ref_RaceSnaps = () => {
  // return query(ref(RTDatabase, "/raceSnap/"), limitToFirst(10));
  return query(ref(RTDatabase, "/raceSnap/"));
};

// export const Ref_RaceSnaps_Limit = () => {
//   return query(ref(RTDatabase, "/raceSnap/"), limitToLast(1));
// };

export const Ref_Pools = (raceKey: RaceKeyDTO) => {
  const { raceNo, pzoneCode, localDate } = raceKey;
  return ref(RTDatabase, `${localDate}/pools/${pzoneCode}/${raceNo}/DEFAULT`);
};
export const Ref_RaceDetails = (rk: RaceKeyDTO) => {
  return ref(
    RTDatabase,
    `${rk.localDate}/raceDetails/${rk.pzoneCode}/${rk.raceNo}`
  );
};

export const Ref_AllRaces = (date: string) => {
  return ref(RTDatabase, `${date}/raceDetails/`);
  // return query(ref(RTDatabase, `${date}/raceDetails/`), limitToFirst(25));
};

// export const Ref_TracksDetails = (date: string) => {
//   return ref(RTDatabase, `${date}/raceDetails/`);
// };

export const Ref_TrackRaceNumbers = (rk: any) => {
  return ref(RTDatabase, `${rk.localDate}/raceDetails/${rk.pzoneCode}`);
};

export const Ref_SilkObj = (raceKey: RaceKeyDTO) => {
  return storageRef(
    Storage,
    `${raceKey.localDate}/${raceKey.pzoneCode}/${raceKey.raceNo}`
  );
};
