/* eslint-disable no-useless-escape */
import styled from "styled-components";
import { WagersDTO } from "../../../../../common/models/transaction.model";
import { LKeys } from "../../../../../store/locale/locale.data";
import useTranslation from "../../../../../store/locale/useTranslation";
import styles from "./_betCards.module.scss";

type Props = {
  wager: WagersDTO;
};

const OpenMultiBetCard = ({ wager: bet }: Props) => {
  const tCountries = useTranslation(LKeys.countries);
  const tGeneral = useTranslation(LKeys.generals);

  return (
    <div className={styles.exoticsCard}>
      <div className={styles.openBetsBody}>
        <OpenBetCard className={styles.openBetsCard}>
          <CardHeader className={styles.cardHeader}>
            <div className={styles.iconWrap}>
              {/* <img
                className={styles.icon}
                src={`/assets/icons/raceTypes/${bet.raceKey.eventType}.png`}
                alt={"racetype"}
                height={40}
                width={40}
              /> */}
              <span className={`et32 ${bet.eventType}-active`}></span>
            </div>
            <div className={styles.headerText}>
              <div className={styles.header}>{bet.trackName}</div>
              <BottomHeader className={styles.countryWin}>
                <span className={styles.country}>
                  {tCountries(bet.province)}
                </span>
                &nbsp; |
                <span className={styles.WinLose}>
                  {" "}
                  {tGeneral(bet.poolType)}
                </span>
              </BottomHeader>
            </div>
          </CardHeader>

          <div className={styles.exoticsCardBody}>
            <div className={styles.section1}>
              <span>
                <span className={styles.label}>{tGeneral("selection")} </span>
                {bet.combinations?.map((sel, index) => {
                  return (
                    <div className={styles.selsWrapper} key={index}>
                      <span className={styles.index}>{"L "}{index + 1}:  </span> {" "}
                      <span className={styles.sels}>
                       {" "} {JSON.stringify(sel).replace(/[\[\]"']+/g, " ")}
                      </span>
                    </div>
                  );
                })}
              </span>
            </div>

            <Section2 className={styles.section2}>
              <div className={styles.itemWrapper}>
                <div className={`${styles.heading} ${styles.headingAmount}`}>
                  {tGeneral("amount")}
                </div>
                <div className={`${styles.item} ${styles.amount}`}>
                  {bet.totalInvested}
                </div>
              </div>
              <div className={styles.itemWrapper}>
                <div className={`${styles.heading} ${styles.headingCostCombo}`}>
                  {tGeneral("costPerCombo")}
                </div>
                <div className={`${styles.item} ${styles.costCombo}`}>
                  {bet.selectionAmt}
                </div>
              </div>
              <div className={styles.itemWrapper}>
                <div className={`${styles.heading} ${styles.headingPoolType}`}>
                  {tGeneral("poolType")}
                </div>
                <div className={`${styles.item} ${styles.poolType}`}>
                  {bet.poolType}
                </div>
              </div>
            </Section2>
          </div>
        </OpenBetCard>
      </div>
    </div>
  );
};

export default OpenMultiBetCard;

const Section2 = styled.div`
  border-top: ${(p) => p.theme.borderContrastLight};
`;

const OpenBetCard = styled.div`
  background: ${(p) => p.theme.bodyContrast};
`;

const CardHeader = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
`;

const BottomHeader = styled.div`
  color: ${(p) => p.theme.textSecondary};
`;
