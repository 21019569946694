import { Helmet } from "react-helmet";
import LandscapeAd from "../../components/Ads/landscape.ad";

import UpcRaces from "../../components/pages/upcRaces/upcRaces.component";

type Props = {};

const UpcRacingPage = (props: Props) => {
  //   const raceSnaps = useRecoilValue(AtomRaceSnaps);
  //   const [upcRaces, setUpcRaces] = useRecoilState(AtomUpcRaces);
  //   const resetUpc = useResetRecoilState(AtomUpcRaces);

  //   const manageRaces = useCallback(() => {
  //     LOG("Manage Races Effect ", "INFO");
  //     let _provinces = new Set();
  //     if (raceSnaps) {
  //       for (const race of raceSnaps) {
  //         _provinces.add(race.raceKey.province);
  //       }
  //       setUpcRaces({ ...upcRaces, RM: raceSnaps });
  //       setProvinces(Array.from(_provinces) as string[]);
  //     } else {
  //       provinces.length && setProvinces([]);
  //       resetUpc();
  //     }

  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [raceSnaps]);

  //   useEffect(() => manageRaces(), [manageRaces]);
  return (
    <>
      <Helmet>
        <title>Upcoming Races - Ashva Racing</title>
      </Helmet>
      <div>
        <LandscapeAd />
        <UpcRaces />
      </div>
    </>
  );
};

export default UpcRacingPage;
