export const _setStorage = (key: string, data: any) => {
  if (typeof data === "object") {
    data = JSON.stringify(Object.entries(data));
  }
  localStorage.setItem(key, data);
};

export const _getStorage = (key: string, isObj?: boolean) => {
  const _data = localStorage.getItem(key);
  if (isObj && typeof _data === "string") {
    return JSON.parse(_data);
  }
  return _data;
};

export const _deleteStorage = (key: string) => {
    localStorage.removeItem(key);
}
