import { DATE_FORMATS } from "../constants/dateTime.constant";
import { NON_DISPLAY } from "../constants/general.constant";
import { formatZonedDateTime } from "../helpers/dateTime.helper";
import { isValidRaceSnap } from "../helpers/validation.helper";
import { toPzoneCode, toRaceKey, toRaceKeyStr } from "../helpers/value.helper";
import { RaceSnapDTO } from "../models/race.mode";
import {
  __PzoneCodeAndRaces,
  __RaceDetailsDTO,
  __RaceSnapDTO,
  __TrackRacesDTO,
} from "../types/dynamic.type";
import { UpcRacesDTO } from "../types/raceUI.type";

export const RacesService = () => {
  const sortRaceSnaps = (_races: RaceSnapDTO[]) => {
    const _sorted =
      _races?.sort((a, b) => {
        const aMtp = Number(a.mtp);
        const bMtp = Number(b.mtp);
        if (aMtp === 0) return 1;
        if (bMtp === 0) return 1;
        if (aMtp < bMtp) return -1;
        if (aMtp > bMtp) return 1;
        return 0;
      }) ?? null;
    return _sorted;
  };

  // * Manage all races snaps according to front end need.
  const getRaceSnapObj = (raceSnaps: __RaceSnapDTO) => {
    let _raceSnaps: RaceSnapDTO[] = [];
    let _upcRaces: UpcRacesDTO = {
      DO: [],
      HS: [],
      TH: [],
    };
    for (const key in raceSnaps) {
      const _race = raceSnaps[key];
      if (!isValidRaceSnap(_race)) continue;
      // * Push in all race snaps
      _race.mtp = formatZonedDateTime(_race.pt, _race.t);
      _race.raceKey = toRaceKey(_race.rk);
      _raceSnaps.push(_race);
      // * Push in upcRaces for filtered as event types
      _upcRaces[_race.raceKey.eventType].push(_race);
    }
    const _snapSort = sortRaceSnaps(_raceSnaps);
    const _sortedUpc: UpcRacesDTO = {
      DO: sortRaceSnaps(_upcRaces.DO),
      HS: sortRaceSnaps(_upcRaces.HS),
      TH: sortRaceSnaps(_upcRaces.TH),
    };
    return { _raceSnaps: _snapSort, _upcRaces: _sortedUpc };
  };

  // * Manage races data at the time of data fetch at initialization
  const manageInitialRaces = (races: __RaceDetailsDTO) => {
    for (const raceNo in races) {
      const _race = races[raceNo];
      const { raceStatus, postTime, timezoneId, raceKey } = _race;
      if (raceStatus === NON_DISPLAY || !raceKey || !postTime || !timezoneId) {
        delete races[raceNo];
        continue;
      }
      if (_race.rr)
        _race.rr = _race.rr
          .slice(1, _race.rr.length - 1)
          .split(",")
          .join("-")
          .replace(/ /g, "")
          .replace(/]-/, ",");
      _race.mtp = formatZonedDateTime(postTime, timezoneId);
      _race.raceKeyString = toRaceKeyStr(_race.raceKey);
      _race.raceKey = toRaceKey(_race.raceKeyString);
      _race.localPostTime = formatZonedDateTime(
        postTime,
        timezoneId,
        DATE_FORMATS.HHmm_colon
      );
    }

    if (!Object.keys(races).length) return null;
    return races;
  };

  // * Make track code to races object for all races.
  const makeTrackRacesObj = (trackRaces: __TrackRacesDTO) => {
    let _trackRaces: any = {};
    let _tracks: __PzoneCodeAndRaces[] = [];

    for (const pZoneCode in trackRaces) {
      const province = pZoneCode.split("-")[0];
      const _races: any = trackRaces[pZoneCode];
      const races = manageInitialRaces(_races);
      if (races) {
        _trackRaces[province] = {
          ..._trackRaces[province],
          [pZoneCode]: races,
        };
        _tracks.push({
          pzoneCode: toPzoneCode(pZoneCode),
          races: _races,
        });
      }
    }

    return { _trackRaces, _tracks };
  };

  return { makeTrackRacesObj, manageInitialRaces, getRaceSnapObj };
};
