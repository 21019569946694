import { memo } from "react";
// import { NonResizableCols } from "../../../common/data/options.data";
// import Loader from "../../elements/loader/loader.element";
import styles from "./primeTable.module.scss";
import Loader from "../../elements/loader/loader.element";
import { ColumnsDTO } from "../../../common/types/general.type";
import {
  ColumnBodyOptions,
  PrimeTableClickDTO,
} from "../../../common/types/ui.types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import { WagersDTO } from "../../../common/models/transaction.model";
import useTranslation from "../../../store/locale/useTranslation";
import { LKeys } from "../../../store/locale/locale.data";

interface PrimeTableProps {
  data: any[];
  columns: ColumnsDTO[];
  rows?: number;
  ColumnBody?: (data: any, options: ColumnBodyOptions) => React.ReactNode;
  onRowClick?: (e: WagersDTO) => void;
  onRowSelect?: (e: PrimeTableClickDTO) => void;
  selectionMode?: "single";
  isLoading?: boolean;
}

const PrimeTable = memo((props: PrimeTableProps) => {
  const tGenerals = useTranslation(LKeys.generals);

  return (
    <div className={styles.tableDiv}>
      <Loader show={props.isLoading} className={styles.loader} />
      <Table
        value={props.data}
        resizableColumns
        columnResizeMode="fit"
        showGridlines
        className={!props.onRowSelect ? `primeTable` : "primeTable rowHover"}
        // paginator={props.data.length > 15}
        // paginatorClassName={`${
        //   theme === "lightBlue" ? styles.lightPage : styles.darkPage
        // }`}

        paginatorClassName={styles.page}
        onRowClick={(e) =>
          props.onRowClick && props.onRowClick(e.data as WagersDTO)
        }
        emptyMessage={
          props.data.length === 0 && !props.isLoading ? (
            <div className={styles.noData}>
              {tGenerals("no_data_available")}
            </div>
          ) : (
            <div className={styles.noData}></div>
          )
        }
        onSelectionChange={props.onRowSelect}
        rows={props.rows ?? 15}
        selectionMode={props.onRowSelect && "single"}
        removableSort
        responsiveLayout="scroll"
        sortField="timestamp"
        sortOrder={-1}
        loading={props.isLoading}
      >
        {props.columns.map((col, key) => {
          // const isResize = !NonResizableCols.includes(col.field);
          return (
            <Column
              key={key}
              body={props.ColumnBody}
              //   resizeable={isResize}
              field={col.field}
              header={tGenerals(col.header)}
              style={col.style}
              sortable={col.sortable}
            />
          );
        })}
      </Table>
    </div>
  );
});

PrimeTable.displayName = "PrimeTable";
export default PrimeTable;

const Table = styled(DataTable)`
  .p-datatable-table {
    thead > tr > th {
      background: ${(p) => p.theme.bodyContrast};
      color: ${(p) => p.theme.textContrast};
      font-size: 0.9rem;
      padding: 0.5rem;
      border: ${(p) => p.theme.borderPrimary};
    }
    tbody > tr {
      cursor: pointer;
      &:nth-child(odd) {
        background: ${(p) => p.theme.rowOdd};
      }
      &:nth-child(even) {
        background: ${(p) => p.theme.rowEven};
      }
      td {
        padding: 0.5rem;
      }
    }
  }
  .p-datatable-tbody > tr {
    color: ${(p) => p.theme.text};
    td {
      border: ${(p) => p.theme.borderPrimary};
    }
  }
  @media screen and (max-width: 590px) {
    .p-datatable-table {
      thead > tr > th {
        font-size: 0.75rem;
      }
      tbody > tr > td {
        font-size: 0.75rem;
        color: ${(p) => p.theme.text};
      }
    }
  }
`;
