import styles from "./loader.module.scss";
import Lottie from "react-lottie";
import * as animationData from "../../../assets/animations/Horse.json";
import styled from "styled-components";
type Props = {};

const LottieLoader = (props: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <BG className={styles.lottieLoaderWrapper}>
      <Lottie options={defaultOptions} height={150} width={150} speed={1.25} />
      <h5>Please wait...</h5>
    </BG>
  );
};

export default LottieLoader;

const BG = styled.div`
  background: ${(p) => p.theme.body};
`;
