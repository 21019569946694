import { Fragment, useEffect, useState } from "react";
import { FaCaretDown, FaCaretUp, FaSort } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { NON_DISPLAY } from "../../../../../common/constants/general.constant";
import { POOLS } from "../../../../../common/constants/pools.constant";
import { sortRaceTrips } from "../../../../../common/helpers/object.helper";
import { LKeys } from "../../../../../store/locale/locale.data";
import useTranslation from "../../../../../store/locale/useTranslation";
import { AtomPoolColsForOdds } from "../../../../../store/pools/pools.store";
import {
  AtomLiveOdds,
  AtomPoolsData,
  AtomSelectedRaceTrips,
} from "../../../../../store/races/selectedRace.store";
import styles from "./tripHeader.module.scss";

interface SortDTO {
  mode: 0 | 1 | -1;
  field: string;
}

const WinPlaceHeader = () => {
  const liveOdds = useRecoilValue(AtomLiveOdds);
  const t = useTranslation(LKeys.generals);
  const [poolCols, setPoolCols] = useRecoilState(AtomPoolColsForOdds);
  const pools = useRecoilValue(AtomPoolsData);
  const [sort, setSort] = useState<SortDTO>({ mode: 0, field: "" }); // sort mode for showing accenting  and descending;
  const [raceTrips, setRaceTrips] = useRecoilState(AtomSelectedRaceTrips);

  useEffect(() => {
    let _cols: string[] = [];
    for (const pool in liveOdds) {
      const _poolData = pools[pool];
      if (!_poolData) continue;
      if (_poolData.poolStatus === NON_DISPLAY) continue;

      let index = 0;
      switch (pool) {
        case POOLS.FO_PLACE:
          index = 1;
          break;
        case POOLS.WIN:
          index = 2;
          break;
        case POOLS.PLACE:
          index = 3;
          break;
      }
      _cols[index] = pool;
    }

    setPoolCols(_cols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pools, liveOdds]);

  // Sorting as runner number wise
  const sortRunners = (_sort: SortDTO) => {
    if (!raceTrips) return;
    let _trips = [...raceTrips];
    if (_sort.mode === 0) {
      _trips = sortRaceTrips(raceTrips, -1);
    } else {
      _trips = sortRaceTrips(raceTrips, _sort.mode);
    }
    setRaceTrips(_trips);
    setSort(_sort);
  };

  const sortOdds = (_sort: SortDTO, pool: string) => {
    if (!raceTrips) return;
    let _trips = [...raceTrips];
    if (_sort.mode === 0) {
      _trips = sortRaceTrips(_trips, -1);
    } else {
      _trips = _trips.sort((a, b) => {
        let _revMode = _sort.mode === -1 ? 1 : -1;
        const _odds = liveOdds[pool];
        if (!_odds) return 0;
        let o1 = Number(_odds[a.raceTripKey.selId] ?? 0);
        let o2 = Number(_odds[b.raceTripKey.selId] ?? 0);
        return o1 < o2 ? _sort.mode : o1 > o2 ? _revMode : 0;
      });
    }
    setRaceTrips(_trips);
    setSort(_sort);
  };

  // On click of sort
  const onOddsSort = (field: string) => {
    let _sort = { ...sort };
    let _mode: 0 | -1 | 1 = -1;

    if (_sort.field === field) {
      if (_sort.mode === 0) _mode = -1;
      else if (_sort.mode === -1) _mode = 1;
      else _mode = 0;
    }
    _sort = { field, mode: _mode };
    field === "runners" ? sortRunners(_sort) : sortOdds(_sort, field);
  };

  // * Template for sort button
  const sortButton = (label: string) => (
    <SortButton
      onClick={() => onOddsSort(label)}
      className={`${styles.poolHeaderBtn} ${
        sort.field === label && sort.mode !== 0 ? "active" : ""
      }`}
      key={label}
    >
      {t(label)}
      <span className={styles.sortIcon}>
        {sort.field === label && sort.mode === -1 ? (
          <FaCaretDown size={13} />
        ) : sort.field === label && sort.mode === 1 ? (
          <FaCaretUp size={13} />
        ) : (
          <FaSort size={12} />
        )}
      </span>
    </SortButton>
  );

  return (
    <TableHeader className={styles.tableHeader}>
      <div>{sortButton("runners")}</div>
      <div
        className={styles.poolHeader}
        style={{
          gridTemplateColumns: `repeat(${poolCols.length}, var(--width-selection))`,
        }}
      >
        {poolCols.map((p) => {
          if (!liveOdds || !liveOdds[p]) return <div key={p}></div>;
          return <Fragment key={p}>{sortButton(p)}</Fragment>;
        })}
      </div>
    </TableHeader>
  );
};

export default WinPlaceHeader;

// * Table Styled Components
const TableHeader = styled.div`
  button {
    color: ${(p) => p.theme.text};
  }
`;

const SortButton = styled.button`
  &.active {
    color: ${(p) => p.theme.active};
  }
`;
