import styles from "./_errors.module.scss";
import { TbMoodEmpty } from "react-icons/tb";
import useTranslation from "../../../store/locale/useTranslation";
import { LKeys } from "../../../store/locale/locale.data";
type Props = {};

const NoRaceData = (props: Props) => {
  const tErrors = useTranslation(LKeys.errors);

  return (
    <div className={styles.noRaceDataWrapper}>
      <div>
        <TbMoodEmpty size={40} />
      </div>
      <div>{tErrors("noRaceData")}</div>
    </div>
  );
};

export default NoRaceData;
