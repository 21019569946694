import styles from "./_betCards.module.scss";
import { WagersDTO } from "../../../../../common/models/transaction.model";
import useTranslation from "../../../../../store/locale/useTranslation";
import { LKeys } from "../../../../../store/locale/locale.data";
import styled from "styled-components";

type Props = {
  wager: WagersDTO;
};

const OpenWinPlaceBetCard = ({ wager: bet }: Props) => {
  const tCountries = useTranslation(LKeys.countries);
  const tGeneral = useTranslation(LKeys.generals);
  const tTracks = useTranslation(LKeys.tracks);

  return (
    <div className={styles.openBetsBody}>
      <OpenBetCard className={styles.openBetsCard}>
        <CardHeader className={styles.cardHeader}>
          <div className={styles.iconWrap}>
            <span className={`et32 ${bet.eventType}-active`}></span>
          </div>
          <div className={styles.headerText}>
            <div className={styles.header}>{`${tTracks(
              bet.raceKey.trackName
            )} - R${bet.raceKey.raceNo}`}</div>
            <div className={styles.countryWin}>
              <span className={styles.country}>
                {tCountries(bet.province)} |
              </span>
              <span className={styles.WinLose}> {tGeneral(bet.poolType)}</span>
            </div>
          </div>
        </CardHeader>

        <div className={styles.cardBody}>
          <div className={styles.itemWrapper}>
            <div className={`${styles.heading} ${styles.headingRunner}`}>
              {tGeneral("runners")}
            </div>
            <div className={`${styles.item} ${styles.runner}`}>
              {bet.combinations}
            </div>
          </div>
          <div className={`${styles.itemWrapper} ${styles.center}`}>
            <div className={`${styles.heading} ${styles.headingAmount}`}>
              {tGeneral("amount")}
            </div>
            <div className={`${styles.item} ${styles.amount}`}>
              {bet.totalInvested}
            </div>
          </div>
          <div className={`${styles.itemWrapper} ${styles.right}`}>
            <div className={`${styles.heading} ${styles.headingPoolType}`}>
              {tGeneral("poolType")}
            </div>
            <div className={`${styles.item} ${styles.poolType}`}>
              {tGeneral(bet.poolType)}
            </div>
          </div>
        </div>
      </OpenBetCard>
    </div>
  );
};

export default OpenWinPlaceBetCard;

const OpenBetCard = styled.div`
  background: ${(p) => p.theme.bodyContrast};
`;

const CardHeader = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
`;
