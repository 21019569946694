import { atom } from "recoil";
import { RaceTripDTO } from "../../common/models/raceTrip.model";
import {
  __LiveOddsDTO,
  __PoolTypeDTO,
  __RaceTipsDTO,
} from "../../common/types/dynamic.type";

// * State to store live odds of runners for selected race.
export const AtomLiveOdds = atom<__LiveOddsDTO>({
  key: "atomLiveOdds",
  default: {},
});

// * State to store list of pool Names to display in tab. e.g WIN/PLACE pool
export const AtomPoolNames = atom<string[]>({
  key: "atomPoolList",
  default: [],
});

// * State to store selected pool from pool names
export const AtomSelectedPoolName = atom<string | null>({
  key: "atomSelectedPoolName",
  default: null,
});

// * State to store selected pool data from firebase
export const AtomPoolsData = atom<__PoolTypeDTO>({
  key: "atomPoolsData",
  default: {},
});

// * State to store race trips for selected race.
export const AtomSelectedRaceTrips = atom<RaceTripDTO[] | null | undefined>({
  key: "atomRaceTrips",
  default: undefined,
});

// * State to store silks details for selected race runners.
export const AtomSelectedSilks = atom<{ [k: string]: string }>({
  key: "atomSelectedSilks",
  default: {},
});

// * State to store selected race runner tips
export const AtomSelectedRaceTips = atom<__RaceTipsDTO | null>({
  key: "atomSelectedTips",
  default: null,
});
