import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import {
  AtomBetCards,
  AtomBetTab,
  AtomOpenBets,
  AtomShowBetSlip,
} from "../../../../store/bets/bets.store";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import AnimatedBadge from "../../../ui/AnimatedBadge/AnimatedBadge";

type Props = {};

const BetTab = (props: Props) => {
  const t = useTranslation(LKeys.generals);
  const [tab, setTab] = useRecoilState(AtomBetTab);
  const betCards = useRecoilValue(AtomBetCards);
  const setShow = useSetRecoilState(AtomShowBetSlip);
  const openBets = useRecoilValue(AtomOpenBets);

  const changeTab = (tab: "betSlip" | "openBets") => {
    setTab(tab);
    setShow(true);
  };

  return (
    <Tab>
      <button
        onClick={() => changeTab("betSlip")}
        className={`tabBtn ${tab === "betSlip" && "active"}`}
      >
        {t("betSlip")}
        <AnimatedBadge
          data={Object.keys(betCards).length}
          style={{ marginLeft: "0.5rem" }}
          size="sm"
        />
      </button>
      <button
        onClick={() => changeTab("openBets")}
        className={`tabBtn ${tab === "openBets" && "active"}`}
      >
        {t("openBets")}
        <AnimatedBadge
          data={openBets?.length ?? 0}
          style={{ marginLeft: "0.5rem" }}
          size="sm"
        />
      </button>
    </Tab>
  );
};

export default BetTab;

// * Styled component for bet tab
const Tab = styled.div`
  background: ${(p) => p.theme.bgBetSlip};
  padding: 0.5rem 0.5rem 0 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 4px solid ${(p) => p.theme.active};
  .tabBtn {
    background: transparent;
    border: none;
    padding: 0.5rem 0.5rem;
    border-top-left-radius: var(--radius-default);
    border-top-right-radius: var(--radius-default);
    color: ${(p) => p.theme.textContrast};
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background: ${(p) => p.theme.active};
    }
    &:not(&.active):hover {
      background: ${(p) => p.theme.btnHoverDark};
    }
  }
`;
