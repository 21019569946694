import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import LandscapeAd from "../../components/Ads/landscape.ad";
import UpcRaceCards from "../../components/pages/upcRaces/upcRaceCards.component";
import { AtomUpcRaces } from "../../store/races/races.store";

const HSRacingPage = () => {
  const upcRaces = useRecoilValue(AtomUpcRaces);

  return (
    <>
      <Helmet>
        <title>Harness Races - Ashva Racing</title>
      </Helmet>
      <div>
        <LandscapeAd />
        <UpcRaceCards races={upcRaces?.HS} title={"HS"} />
      </div>
    </>
  );
};

export default HSRacingPage;
