import { useRecoilState, useRecoilValue } from "recoil";
import {
  POOLS,
  POOL_SELECTION_NUM,
} from "../../../../../common/constants/pools.constant";
import { RaceTripDTO } from "../../../../../common/models/raceTrip.model";
import { Position } from "../../../../../common/types/raceUI.type";
import useSelected from "../../../../../hooks/useSelectedPool";
import { AtomLiveOdds } from "../../../../../store/races/selectedRace.store";
import { AtomHorseSelection } from "../../../../../store/selection/selection.store";
import ExactaSelector from "./poolWiseSelectors/exacta.selector";
import QuinellaSelector from "./poolWiseSelectors/quinella.selector";
import SuperfectaSelector from "./poolWiseSelectors/superfecta.selector";
import SwingerSelector from "./poolWiseSelectors/swinger.selector";
import TrifectaSelector from "./poolWiseSelectors/trifecta.selector";
import styles from "./tripSelectors.module.scss";

type Props = {
  trip: RaceTripDTO;
};

const ExoticsSelector = ({ trip }: Props) => {
  const { pool, isBoxed } = useSelected();
  const [selection, setSelection] = useRecoilState(AtomHorseSelection);
  const { selId } = trip.raceTripKey;
  const liveOdds = useRecoilValue(AtomLiveOdds);

  // * On select of position checkbox fill the object for horse selection.
  const onPosSelection = (checked: boolean, pos: Position, pool: string) => {
    let _selection = { ...selection };
    let _poolSelection = { ..._selection[pool] };
    let _selectedPos = { ..._poolSelection[pos] };
    let _boxedPos = { ..._poolSelection["boxed"] };

    if (!checked && _selection && _selectedPos[selId]) {
      delete _selectedPos[selId];
      delete _boxedPos[selId];
    } else {
      _selectedPos[selId] = checked;
      _boxedPos[selId] = checked;
    }
    _poolSelection[pos] = _selectedPos;
    _poolSelection["boxed"] = _boxedPos;
    _selection[pool] = _poolSelection;
    setSelection(_selection);
  };

  const PoolWiseSelectors = () => {
    switch (pool) {
      case POOLS.QUINELLA:
        return (
          <QuinellaSelector
            selection={selection.QUINELLA}
            onPosSelection={onPosSelection}
            trip={trip}
          />
        );
      case POOLS.SWINGER:
        return (
          <SwingerSelector
            selection={selection.SWINGER}
            onPosSelection={onPosSelection}
            trip={trip}
          />
        );
      case POOLS.EXACTA:
        return (
          <ExactaSelector
            selection={selection.EXACTA}
            onPosSelection={onPosSelection}
            trip={trip}
          />
        );
      case POOLS.TRIFECTA:
        return (
          <TrifectaSelector
            selection={selection.TRIFECTA}
            onPosSelection={onPosSelection}
            trip={trip}
          />
        );
      case POOLS.SUPERFECTA:
        return (
          <SuperfectaSelector
            selection={selection.SUPERFECTA}
            onPosSelection={onPosSelection}
            trip={trip}
          />
        );

      default:
        return null;
    }
  };

  // * Template to show valid odds under checkbox.
  const OddsTemplate = () => {
    if (!liveOdds || trip.scratched) return "-";
    const _odds = liveOdds["WIN"] ? liveOdds["WIN"][selId] : "-";
    return <span className={styles.odds}>{_odds}</span>;
  };

  return (
    <div className={`py025`}>
      <div
        className={styles.exoticsSelection}
        style={{
          gridTemplateColumns: `repeat(${
            isBoxed ? 1 : POOL_SELECTION_NUM[pool ?? 2]
          }, 40px)`,
        }}
      >
        {PoolWiseSelectors()}
      </div>
      <div className="text-right pr05">{OddsTemplate()}</div>
    </div>
  );
};

export default ExoticsSelector;


