import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  CLOSED,
  OPEN,
  RESULTED,
} from "../../../../common/constants/general.constant";
import { getRacePath } from "../../../../common/helpers/routing.helper";
import { useHorizontalScroll } from "../../../../hook/useHorizontalSCroll";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import {
  AtomRaceNumbers,
  AtomSelectedRace,
} from "../../../../store/races/races.store";
import { AtomSelectedThemeData } from "../../../../store/theme/theme.store";
import RaceNumberLoader from "./raceNumberLoader/raceNumberLoader.module";

const RaceNumbers = () => {
  const raceNumbers = useRecoilValue(AtomRaceNumbers);
  const scrollRef = useHorizontalScroll();
  const t = useTranslation(LKeys.generals);
  const theme = useRecoilValue(AtomSelectedThemeData);
  const race = useRecoilValue(AtomSelectedRace);
  if (raceNumbers === undefined) return <RaceNumberLoader />;

  return (
    <NumbersWrapper ref={scrollRef}>
      {raceNumbers.map((r) => {
        return (
          <NumberLink
            className={r.raceKeyString === race?.raceKeyString ? "active" : ""}
            key={r.raceKeyString}
            to={getRacePath(r.raceKey)}
          >
            <span>
              {t("race")} {r.raceKey.raceNo}
            </span>
            <div
              style={{
                color: theme
                  ? r.raceKeyString === race?.raceKeyString &&
                    r.raceStatus === CLOSED
                    ? theme.bodyContrast
                    : theme[r.raceStatus]
                  : "",
                fontWeight: "500",
              }}
            >
              {r.raceStatus === RESULTED
                ? r.rr
                : r.raceStatus === OPEN
                ? r?.localPostTime ?? t("OPEN")
                : t(r.raceStatus)}
            </div>
          </NumberLink>
        );
      })}
    </NumbersWrapper>
  );
};

export default RaceNumbers;

// * Styled components
const NumbersWrapper = styled.div`
  background: ${(p) => p.theme.bodyContrastLight};
  padding: 0.5rem;
  display: flex;
  align-items: center;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NumberLink = styled(Link)`
  text-align: center;
  min-height: 2.8rem;
  /* background: linear-gradient(
    ${(p) => p.theme.bodyContrast},
    ${(p) => p.theme.bodyContrast}
  ); */
  background: ${(p) => p.theme.bodyContrast};
  color: ${(p) => p.theme.textContrast};
  border-radius: var(--radius-default);
  margin-right: 0.5rem;
  min-width: 6rem;
  padding: 0.5rem;
  display: inline-block;
  font-size: 0.75rem;
  &.active {
    background: ${(p) => p.theme.active};
    font-weight: 600;
    /* box-shadow: 0 0 0 2px ${(p) => p.theme.bodyContrastLight},
      0 0 0 4px ${(p) => p.theme.active}; */

    box-shadow: 0 2px 4px #111434;
  }
`;
