import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    // specifics
    bgBetSlip: string;
    // Text
    text: string;
    textContrast: string;
    textSecondary: string;
    textTernary: string;
    textTernaryDark: string;
    textDull: string;
    bodyContent: string;
    // Severity
    primary?: string;
    primaryText?: string;
    warning?: string;
    warningText?: string;
    danger?: string;
    dangerText?: string;
    success?: string;
    successText?: string;
    // Loader
    loaderBackdrop?: string;
    // Body
    body: string;
    bodyContrast: string;
    bodyContrastLight: string;
    bodySecondary: string;
    bodySecondaryDark: string;
    bodyTernary: string;
    bodyContrastDark: string;
    selectedContrastBody: string;
    // Header
    header: string;
    headerLight: string;
    // Race Status
    OPEN: string;
    OPEN_LIGHT: string;
    CLOSED: string;
    CLOSED_LIGHT: string;
    RESULTED: string;
    RESULTED_LIGHT: string;
    CANCELLED: string;
    CANCELLED_LIGHT: string;
    //Borders
    borderPrimary: string;
    borderSecondary: string;
    borderContrastLight?: string;
    borderPrimaryThick: string;
    borderHighlight?: string;
    borderContrast: string;
    borderHover?: string;
    // Time
    postMtp: string;
    nearMtp: string;
    farMtp: string;
    //Shadows
    shadowDefault: string;
    // Severity
    active: string;
    activeAlpha?: string;
    activeText?: string;
    // Hovers
    btnHover: string;
    btnHoverDark: string;
    // Tables
    rowOdd: string;
    rowEven: string;
    [k: string]: any;
  }
}

export const ThemeData: { [theme: string]: DefaultTheme | null | undefined } = {
  lightBlue: {
    body: "#E7EBF4",
    bodyContent: "#dfe8f4",
    bodyContrast: "#262A53",
    bodyContrastDark: "#111434",
    selectedContrastBody: "linear-gradient(212deg,#f41233, #262a53,#262A54)",
    bodyContrastLight: "#7a80b3",
    bodySecondary: "#a3a9ca",
    bodySecondaryDark: "#2d314f",
    bodyTernary: "#454a7e",
    bgBetSlip: "#373b60",
    header: "#262A53",
    headerLight: "#6B70A2",
    text: "#101010",
    textContrast: "#ffffff",
    textSecondary: "#D5D5F5",
    textTernary: "#515477",
    textTernaryDark: "#262A53",
    textDull: "#898989",
    active: "#D03D4D",
    activeAlpha: "#D03D4D66",
    // active: "#936ddd",
    activeText: "#D03D4D",
    CLOSED: "#ff4f4f",
    CLOSED_LIGHT: "#ff5140",
    OPEN: "#9fff05",
    OPEN_LIGHT: "#ffe20f",
    RESULTED: "#23BC9F", // 80f1e1
    RESULTED_LIGHT: "#82ffba", // 80f1e1
    CANCELLED:
      "repeating-linear-gradient(45deg,#d8dfe7,#d8dfe7 10px,#b0b7c5 10px,#b0b7c5 20px)",
    CANCELLED_LIGHT:
      "repeating-linear-gradient(45deg,#d8dfe7,#d8dfe7 10px,#b0b7c5 10px,#b0b7c5 20px)",
    borderPrimary: "1px solid #bdbdbd",
    borderSecondary: "1px solid #101010",
    borderContrast: "1px solid #111434",
    borderContrastLight: "1px solid #555989",
    borderHover: "1px solid #D03D4D",
    borderPrimaryThick: "2px solid #bdbdbd",
    borderHighlight: "2px solid #D03D4D ",
    btnHover: "#afafaf",
    btnHoverDark: "#41415a",
    shadowDefault: "1px 1px 4px #2825345c",
    postMtp: "#D03D4D",
    farMtp: "#23BC9F",
    nearMtp: "#e0a00c",
    rowOdd: "#c9d0de",
    rowEven: "#d8dfe7",
    primary: "#262A53",
    primaryText: "#ffffff",
    warning: "#ffd333",
    warningText: "#121212",
    loaderBackdrop: "##2e567e6f",
    danger: "#f41233",
    dangerText: "#ffffff",
    successText: "#111111",
    success: "#12dd12",

  },
  darkBlue: {
    body: "#001F3F",
    bodyContent: "#012142fc",
    bodyContrast: "#2a3757",
    bodyContrastDark: "#111434",
    selectedContrastBody: "linear-gradient(212deg,#f41233, #262a53,#262A54)",
    bodyContrastLight: "#38486F",
    bodySecondary: "#38486f",
    bodySecondaryDark: "#2d314f",
    bodyTernary: "#35466e",
    bgBetSlip: "#373b60",
    header: "#293B5F",
    headerLight: "#6B70A2",
    text: "#ffffff",
    textContrast: "#ffffff",
    textSecondary: "#D5D5F5",
    textTernary: "#b9b2b2",
    textTernaryDark: "#ffffff",
    textDull: "#898989",
    active: "#D03D4D",
    activeAlpha: "#D03D4D66",
    // active: "#936ddd",
    activeText: "#D03D4D",
    CLOSED: "#ff4f4f",
    CLOSED_LIGHT: "#ff5140",
    OPEN: "#9fff05",
    OPEN_LIGHT: "#ffe20f",
    RESULTED: "#23BC9F", // 80f1e1
    RESULTED_LIGHT: "#82ffba", // 80f1e1
    CANCELLED:
      "repeating-linear-gradient(45deg,#d8dfe7,#d8dfe7 10px,#b0b7c5 10px,#b0b7c5 20px)",
    CANCELLED_LIGHT:
      "repeating-linear-gradient(45deg,#d8dfe7,#d8dfe7 10px,#b0b7c5 10px,#b0b7c5 20px)",
    borderPrimary: "1px solid #65657088",
    borderSecondary: "1px solid #656570",
    borderContrast: "1px solid #111434",
    borderContrastLight: "1px solid #555989",
    borderHover: "1px solid #D03D4D",
    borderPrimaryThick: "2px solid #656570",
    borderHighlight: "2px solid #D03D4D ",
    btnHover: "#afafaf",
    btnHoverDark: "#282D4F",
    shadowDefault: "1px 1px 4px #2825345c",
    postMtp: "#D03D4D",
    farMtp: "#23BC9F",
    nearMtp: "#e0a00c",
    rowOdd: "#131f3d",
    rowEven: "#1e2946",
    primary: "#262A53",
    primaryText: "#ffffff",
    warning: "#ffd333",
    warningText: "#121212",
    loaderBackdrop: "##2e567e6f",
    danger: "#f41233",
    dangerText: "#ffffff",
    successText: "#111111",
    success: "#12dd12",
  },
};
