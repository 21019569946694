import { doc, getDoc } from "@firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Auth, Firestore } from "../common/configs/firebase.config";
import { LS_KEYS, NODES } from "../common/constants/general.constant";
import { showToast } from "../common/helpers/debugging.helper";
import { _setStorage } from "../common/helpers/storage.helper";
import { UserDTO } from "../common/models/auth.model";
import { ROUTES } from "../common/routes/default.routes";
import LottieLoader from "../components/elements/loader/lottie.loader";
import useWindowSize from "../hook/useWindowSize";
import { AtomAuthUser } from "../store/auth/auth.store";

const IndexPage = () => {
  const [user, setUser] = useRecoilState(AtomAuthUser);
  const { width } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(
      Auth,
      (_user) => {
        if (_user) {
          const docRef = doc(Firestore, `${NODES.CLIENTS}/${_user.uid}`);
          getDoc(docRef)
            .then((res) => {
              if (res.exists()) {
                setUser(res.data() as UserDTO);
                navigate(width > 591 ? ROUTES.ALL_RACES : ROUTES.NEXT_TO_RACE);
              } else {
                navigate(ROUTES.CURRENCY);

                let _userData = {
                  ..._user,
                  username: _user.displayName,
                };
                _setStorage(LS_KEYS.auth, _userData);
              }
            })
            .catch((err) => {
              showToast(
                err?.message ?? "Something is wrong! Please try again."
              );
              navigate(ROUTES.LOGIN);
            });
          navigate(ROUTES.ALL_RACES);
        } else {
          navigate(ROUTES.LOGIN);
        }
      },
      (err) => {
        console.error(err);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return <LottieLoader />;
};

export default IndexPage;
