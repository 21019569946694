import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  AtomAllLegs,
  AtomSelectedRaceNo,
} from "../../../store/bets/multiBet.store";

import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";

import styles from "./MultiLegTab.module.scss";

const MultiLegTab = () => {
  const allLegs = useRecoilValue(AtomAllLegs);
  const t = useTranslation(LKeys.generals);

  const [selectedRaceNo, setSelectedRaceNO] =
    useRecoilState(AtomSelectedRaceNo);

  const AllTabBtn = () => {

    
    return (
      <SliderButton
        className={`${styles.raceSelectorButton}  ${
          selectedRaceNo === 0 ? "active" : ""
        }`}
        onClick={() => setSelectedRaceNO(0)}
      >
        {t("All Races")}
      </SliderButton>
    );
  };

  return (
    <RaceSelector className={styles.raceSelector}>
      {AllTabBtn()}
      {allLegs.map((leg, index) => {
        let _plusIndex = index + 1;
        return (
          <SliderButton
            onClick={() => setSelectedRaceNO(leg.raceNo)}
            className={`${styles.raceSelectorButton}  ${
              selectedRaceNo === leg.raceNo ? "active" : ""
            }`}
            key={index}
          >
            {t("Leg")} {_plusIndex} R-{leg.raceNo}
          </SliderButton>
        );
      })}
    </RaceSelector>
  );
};

export default MultiLegTab;

const RaceSelector = styled.div`
  background: ${(p) => p.theme.bodySecondary};
  color: ${(p) => p.theme.text};
  margin-top: 0.5rem;
  box-shadow: 0 -2px 3px #787a833c;
`;
const SliderButton = styled.button`
  color: ${(p) => p.theme.textContrast};
  margin-right: 0.25rem;
  &:hover,
  &.active {
    background: ${(p) => p.theme.bodyContrast};
    box-shadow: 2px 2px 3px #12312344;
    .btnChild {
      color: ${(p) => p.theme.textContrast};
    }
  }
`;
