export interface CurrencyDTO {
  label: string;
  currency: string;
  country: string;
}

export const CurrencyList = [
  {
    label: "Australia",
    currency: "AUD",
    country: "AU",
  },
  {
    label: "Brazilian",
    currency: "BRL",
    country: "BR",
  },
  {
    label: "Chinese",
    currency: "CNY",
    country: "CN",
  },
  {
    label: "Germany",
    currency: "EUR",
    country: "EU",
  },
  {
    label: "United Kingdom",
    currency: "GBP",
    country: "UK",
  },
  {
    label: "Hong Kong",
    currency: "HKD",
    country: "HK",
  },
  {
    label: "Indonesia",
    currency: "IDR",
    country: "DO",
  },
  {
    label: "Indian",
    currency: "INR",
    country: "IN",
  },
  {
    label: "Japanese",
    currency: "JPY",
    country: "JP",
  },
  {
    label: "South Korea",
    currency: "KRW",
    country: "KP",
  },
  {
    label: "Malaysia",
    currency: "MYR",
    country: "MY",
  },
  {
    label: "New Zealand",
    currency: "NZD",
    country: "NZ",
  },
  {
    label: "Philippines",
    currency: "PHP",
    country: "PH",
  },
  {
    label: "Turkish",
    currency: "TRY",
    country: "TR",
  },
  {
    label: "United State",
    currency: "USD",
    country: "US",
  },
  {
    label: "Vietnamese",
    currency: "VND",
    country: "VN",
  },
  {
    label: "South Africa",
    currency: "ZAR",
    country: "SA",
  },
];
