import { useRef, useEffect } from "react";

export function useHorizontalScroll() {
  const elRef = useRef<any>();
  useEffect(() => {
    const el = elRef.current;

    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        let _off = e.deltaY < 0 ? -600 : 600;

        el.scrollTo({
          left: el.scrollLeft + _off,
          behavior: "smooth",
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  });

  return elRef;
}
