import { useRef } from "react";
import { Calendar } from "primereact/calendar";
import styles from "./DatePicker.module.scss";
import { FaCalendarAlt } from "react-icons/fa";
import styled from "styled-components";

interface GoCalenderProps {
  value: Date[];
  onChange: any;
  isOpen?: any;
}

const DatePicker = (props: GoCalenderProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onChangeDate = (e: any) => {
    props.onChange(e.target.value);
  };

  return (
    <Wrapper ref={wrapperRef} className={styles.goCalenderContainer}>
      <div className={styles.calIcon}></div>
      <FaCalendarAlt color="white" />
      <MyCalender
        inputClassName={styles.inputStyle}
        value={props.value}
        className={styles.calender}
        // showIcon
        iconPos="left"
        selectionMode="range"
        dateFormat="dd-mm-yy"
        readOnlyInput
        onChange={onChangeDate}
      />
    </Wrapper>
  );
};

export default DatePicker;

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.bodyContrast};
`;

const MyCalender = styled(Calendar)`
  background: ${(p) => p.theme.bodyContrast} !important;
  color: ${(p) => p.theme.textContrast};
`;
