import { NavHeaderWrapper } from "../../../../styled-components/layoutComponents/globalLayout.styled";
import styles from "./navigationHeader.module.scss";
// import LOGO from "../../../../assets/logos/FullTextLogo.png";
import LOGOLight from "../../../../assets/logos/LogoLight.png";
import ProfileComponent from "./profile/profile.component";
import { NavigationMenu } from "../../../../common/data/menu.data";
import { Link, useLocation } from "react-router-dom";
import useTranslation from "../../../../store/locale/useTranslation";
import { LKeys } from "../../../../store/locale/locale.data";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import NavSideBar from "../../sidebar/navbar/navSidebar";
import NavCard from "../../navCard/nav.card";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { Sidebar } from "../../../../common/constants/general.constant";
import BetSlipComponent from "../../../pages/betSlipComponents/betSlipComponent";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AtomSidebar } from "../../../../store/global/global.store";
import DotNotification from "../../../ui/notifications/dot.notification";
import { AtomBetCards } from "../../../../store/bets/bets.store";
import WalletBalance from "../../walletBalance/walletBalance.component";
import { useState } from "react";
import {
  AtomSelectedTheme,
  AtomSelectedThemeData,
} from "../../../../store/theme/theme.store";
import { ThemeData } from "../../../../common/data/theme.data";
import ThemeSwitch from "../../../ui/switch/themeSwitch";
import LanChangerDropdown from "../../../elements/languageChangerDropdown/lanChangerDropdown.component";
import { ROUTES } from "../../../../common/routes/default.routes";

const NavigationHeader = () => {
  const t = useTranslation(LKeys.generals);
  const path = useLocation().pathname;
  const [show, setShow] = useRecoilState(AtomSidebar);
  const betCards = useRecoilValue(AtomBetCards);
  const setTheme = useSetRecoilState(AtomSelectedThemeData);
  const [selectTheme, setSelectTheme] = useRecoilState(AtomSelectedTheme);
  const [themToggle, setThemToggle] = useState(false);

  const onThemeChange = () => {
    let themeName = selectTheme === "lightBlue" ? "darkBlue" : "lightBlue";
    let _themeData = ThemeData[themeName];
    setTheme(_themeData);
    setSelectTheme(themeName);
    setThemToggle(!themToggle);
  };

  return (
    <NavHeaderWrapper>
      <header className={styles.header}>
        <div className={styles.firstCol}>
          <div className={styles.sideMenuWrp}>
            <GiHamburgerMenu
              size={23}
              onClick={() => setShow(Sidebar.navSideMenu)}
              cursor={"pointer"}
            />
            <NavSideBar
              show={show === Sidebar.navSideMenu}
              setShow={setShow}
              isLogo
            >
              <NavCard />
            </NavSideBar>
          </div>
          <div className={styles.logoWrapper}>
            <Link to={ROUTES.ALL_RACES}>
              <img src={LOGOLight} alt="AshvaBetsLogo" />
            </Link>
          </div>
          <div className={styles.navMenu}>
            {NavigationMenu.map((m) => {
              return (
                <NavLink
                  className={`${styles.navLink} ${
                    m.route === path && "active"
                  }`}
                  to={m.route}
                  key={m.route}
                >
                  {t(m.localeKey)}
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className={styles.secondCol}>
          <WalletBalance />

          <DotNotification
            number={Object.keys(betCards).length}
            onClick={() => setShow(Sidebar.betSideBar)}
            icon={BsCreditCard2FrontFill}
            className={styles.betSlipWrp}
          />
          <NavSideBar
            show={show === Sidebar.betSideBar}
            setShow={setShow}
            position="right"
          >
            <BetSlipComponent />
          </NavSideBar>
          <LanChangerDropdown />
          <ThemeSwitch value={themToggle} onChange={onThemeChange} />
          <ProfileComponent />
        </div>
      </header>
    </NavHeaderWrapper>
  );
};

export default NavigationHeader;

// * Styled components
const NavLink = styled(Link)`
  color: ${(p) => p.theme.textContrast};
  &.active {
    background: ${(p) => p.theme.active};
    color: ${(p) => p.theme.textContrast};
  }
`;

// const Wallet = styled.div`
//   border-radius: var(--radius-default);
//   border: ${(p) => p.theme.borderHighlight};
// `;

// const IconWrapper = styled.div`
//   background: ${(p) => p.theme.activeText};
// `;
