import {
  child,
  DataSnapshot,
  get,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
} from "firebase/database";
import { ReactNode, useRef, useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Auth, RTDatabase } from "../../common/configs/firebase.config";
import { dateToString } from "../../common/helpers/dateTime.helper";
import { LOG } from "../../common/helpers/debugging.helper";
import { Ref_AllRaces } from "../../common/services/nodes.service";
import { RacesService } from "../../common/services/races.service";
import {
  __TrackRacesDTO,
  __RaceDetailsDTO,
} from "../../common/types/dynamic.type";
import {
  AtomTrackRaces,
  AtomSelectedDate,
  AtomAllTrackList,
} from "./races.store";

interface Props {
  children?: ReactNode;
}

const RacesProvider = ({ children }: Props) => {
  const [trackRaces, setTrackRaces] = useRecoilState(AtomTrackRaces);
  const setTrackList = useSetRecoilState(AtomAllTrackList);
  const selectedDate = useRecoilValue(AtomSelectedDate);
  const refTrackRaces = useRef<any>(null); // database reference to save track races listener.
  const isDataLoaded = useRef<boolean>(false);
  const racesDataRef = useRef<__TrackRacesDTO>({});
  const isTracksFetched = useRef(false);

  const { makeTrackRacesObj, manageInitialRaces } = RacesService();
  // * fetch the track races as per the date.
  const getTrackRaces = useCallback((_selectedDate: Date) => {
    if (!_selectedDate) return;
    if (trackRaces !== undefined) setTrackRaces(undefined);

    const date = dateToString(_selectedDate);

    // const dbRef = Ref_AllRaces(date);
    const dbRef = ref(RTDatabase);

    get(child(dbRef, `${date}/raceDetails`))
      .then((res) => {
        LOG("getTrackRaces", "API");

        if (res.exists()) {
          let _trackToRaces = { ...res.val() };
          let _racesObj = makeTrackRacesObj(_trackToRaces);
          setTrackRaces(_racesObj._trackRaces);
          if (!isTracksFetched.current) {
            setTrackList(_racesObj._tracks);
            isTracksFetched.current = true;
          }
        } else {
          if (!isTracksFetched.current) {
            setTrackList([]);
            isTracksFetched.current = true;
          }
          setTrackRaces(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setTrackRaces(null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // * Start the race data listeners for selected date.
  const startRaceObserver = useCallback(
    (_selectedDate: Date) => {
      // console.log("RACE OBSERVER STARTS");

      if (!_selectedDate) return;
      const _date = dateToString(_selectedDate);
      if (refTrackRaces.current) off(refTrackRaces.current);
      refTrackRaces.current = Ref_AllRaces(_date);

      // * Update race
      const handleRaceUpdate = (snap: DataSnapshot) => {
        let _trackRaces = snap.val() as __RaceDetailsDTO; // all races of track
        //   console.log(_trackRaces);

        const pZoneCode: string | null = snap.key; // key of the update.
        if (!_trackRaces || !pZoneCode || !isDataLoaded.current) return;
        const province = pZoneCode.split("-")[0];
        const races = manageInitialRaces(_trackRaces);
        let _prev = { ...racesDataRef.current };
        let _tracks = { ..._prev[province] };
        if (!races) delete _tracks[pZoneCode];
        else _tracks[pZoneCode] = races;
        _prev = { ..._prev, [province]: _tracks };
        racesDataRef.current = _prev;
        setTrackRaces(() => _prev);
      };

      // * Delete race track // TODO
      const handleRemoveRace = (snap: DataSnapshot) => {
        let track = snap.key;
        if (track) {
          let province = track.split("-")[0];
          setTrackRaces((prev: any) => {
            if (prev[province]) {
              let _trackObj = { ...prev[province] };
              if (_trackObj) delete _trackObj[track ?? ""];
              prev[province] = _trackObj;
              return { ...prev };
            }
            return { ...prev };
          });
        }
      };

      onChildAdded(refTrackRaces.current, handleRaceUpdate);
      onChildChanged(refTrackRaces.current, handleRaceUpdate);
      onChildRemoved(refTrackRaces.current, handleRemoveRace);
    },
    [manageInitialRaces, setTrackRaces]
  );

  useEffect(() => {
    if (Auth.currentUser) {
      getTrackRaces(selectedDate);
      startRaceObserver(selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return <>{children}</>;
};
export default RacesProvider;
