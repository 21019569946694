import { API, API_URLS, getApiHeader } from "../../configs/api.config";
import { UserDTO } from "../../models/auth.model";

interface GetIntiInfoParams {
  user: UserDTO;
}

export const ClientAPIService = () => {
  // * Get aggregator pool rates
  const getPoolRates = async ({ user }: GetIntiInfoParams) => {
    try {
      const headers = getApiHeader(user);
      const res = await API.get(API_URLS.validate, { headers });
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  // * Get init data for aggregator.
  const getInitInfo = async ({ user }: GetIntiInfoParams) => {
    try {
      const headers = getApiHeader(user);
      const res = await API.get(API_URLS.init, { headers });
      return res.data;
    } catch (error) {
      throw error;
    }
  };
  return { getInitInfo, getPoolRates };
};
