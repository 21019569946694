import { ChangeEvent } from "react";
import styled from "styled-components";
import { ElementChangeEvent } from "../../../../../../common/types/general.type";
import styles from "./betInput.module.scss";

type Props = {
  value: string | number;
  onChange: (e: ElementChangeEvent) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
};

const BetInput = (props: Props) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value !== "" && isNaN(Number(value))) return;
    props.onChange({ data: value, name: "" });
  };
  return (
    <div className={styles.inputWrapper}>
      {props.label && <Label className={styles.label}>{props.label}</Label>}
      <input
        type="text"
        onChange={onInputChange}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
      />
    </div>
  );
};

export default BetInput;

const Label = styled.label`
  font-size: 0.65rem;
  color: ${(p) => p.theme.textContrast};
`;
