import { FaStar } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { CLOSED } from "../../../../common/constants/general.constant";
import { toWinPlaceBetKeyStr } from "../../../../common/helpers/value.helper";
import { RaceDetailsDTO } from "../../../../common/models/race.mode";
import { RaceTripDTO } from "../../../../common/models/raceTrip.model";
import {
  __FavoriteDTO,
  __SelectionOddsDTO,
} from "../../../../common/types/dynamic.type";
import { AtomBetCards } from "../../../../store/bets/bets.store";
import {
  AtomMultiBetSelection,
  AtomMultiLegFav,
} from "../../../../store/bets/multiBet.store";
import styles from "./MultiRaceTripSelector.module.scss";

type Props = {
  trip: RaceTripDTO;
  race: RaceDetailsDTO | null | undefined;
  odds: __SelectionOddsDTO;
  raceNo: number;
  onBetClick: () => void;
  fav: __FavoriteDTO;
};

const MultiRaceTripSelector = ({
  trip,
  race,
  odds,
  raceNo,
  onBetClick,
  fav,
}: Props) => {
  const MultiLegFav = useRecoilValue(AtomMultiLegFav);
  const betCards = useRecoilValue(AtomBetCards);
  const selectionObj = useRecoilValue(AtomMultiBetSelection);

  const betKey = toWinPlaceBetKeyStr(
    race?.raceKeyString ?? "",
    "WIN",
    trip.raceTripKey.selId
  );

  const getOddsValue = () => {
    if (!odds) return "-";
    if (odds && odds[trip.raceTripKey.selId])
      return odds[trip.raceTripKey.selId];
    return "-";
  };

  const isActive = selectionObj[raceNo]
    ? selectionObj[raceNo][trip.raceTripKey.selId]
    : false;

  return (
    <OddsButton
      onClick={(e) => {
        e.stopPropagation();
        onBetClick();
      }}
      className={`${styles.oddsBtn} ${isActive ? "active" : ""}`}
      disabled={
        race?.raceStatus === CLOSED ||
        trip.scratched ||
        (betCards[betKey] && betCards[betKey].processing)
      }
      key={"WIN"}
    >
      <>
        {MultiLegFav[trip.raceTripKey.raceNo] === trip.raceTripKey.selId && (
          <span className={styles.fav}>
            <FaStar size={9} />
          </span>
        )}
        {trip.scratched ? <SCR>SCR</SCR> : getOddsValue()}
      </>
    </OddsButton>
  );
};

export default MultiRaceTripSelector;

// * Styled Components
const SCR = styled.span`
  color: ${(p) => p.theme.textDull};
`;
const OddsButton = styled.button`
  background: ${(p) => p.theme.bodyTernary};
  color: ${(p) => p.theme.textContrast};
  border: 2px solid transparent;
  &:disabled {
    color: gray;
    cursor: auto;
  }
  &:not(:disabled):hover {
    border: ${(p) => p.theme.borderHighlight};
  }
  &.active {
    background: ${(p) => p.theme.active};
  }
`;
