import { SyncLoader, CircleLoader } from "react-spinners";
import styles from "./absLoader.module.scss";
interface LoaderProps {
  size?: number;
  color?: string;
  show?: boolean;
  className?: string;
  roundLoader?: boolean;
}

const AbsLoader = ({
  size,
  color,
  show,
  roundLoader,
  ...props
}: LoaderProps) => {
  if (!show) return null;
  return (
    <div className={styles.wrapper}>
      {roundLoader ? (
        <CircleLoader
          color={color ?? "#FFFFFF"}
          speedMultiplier={0.75}
          loading
          size={size ?? 20}
        />
      ) : (
        <div className={styles.loader}>
          <SyncLoader
            color={color ?? "#FFFFFF"}
            speedMultiplier={0.75}
            loading
            size={size ?? 15}
          />
        </div>
      )}
    </div>
  );
};

export default AbsLoader;
