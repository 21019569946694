// import styles from "./_raceFooters.module.scss";

import { useEffect, useRef, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { CLOSED, LS_KEYS } from "../../../../common/constants/general.constant";
import { getBetId } from "../../../../common/helpers/bet.helper";
import { _setStorage } from "../../../../common/helpers/storage.helper";
import { floatToInteger } from "../../../../common/helpers/value.helper";
import { BetRequestDTO } from "../../../../common/models/bet.model";
import { PoolTypeDTO } from "../../../../common/models/pool.model";
import { BetAPIService } from "../../../../common/services/api/betApi.service";

import { __PositionToRunners } from "../../../../common/types/dynamic.type";
import { ElementChangeEvent } from "../../../../common/types/general.type";
import useSelected from "../../../../hooks/useSelectedPool";
import {
  AtomAggregatorObject,
  AtomAuthUser,
} from "../../../../store/auth/auth.store";
import {
  AtomBetAmounts,
  AtomBetCards,
  AtomReRender,
} from "../../../../store/bets/bets.store";
import {
  AtomMultiBetCombo,
  AtomMultiBetSelection,
} from "../../../../store/bets/multiBet.store";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import { AtomPoolsData } from "../../../../store/races/selectedRace.store";
import BetAmtInput from "../../../elements/inputs/betAmount.input";
import ElevatedButton from "../../../ui/buttons/elevated.button";
import styles from "./_raceFooters.module.scss";

type Props = {};

const MultiFooter = (props: Props) => {
  const [selectionObj, setSelectionObject] = useRecoilState(
    AtomMultiBetSelection
  );
  // const [isViewSelections, setIsViewSelections] = useState(false);
  const [inputs, setInputs] = useState<{
    flexPerc?: string;
    stake?: string;
    stakePerCombo?: string;
  }>({});

  const resetSelections = useResetRecoilState(AtomMultiBetSelection);
  const { pool, race } = useSelected();
  const t = useTranslation(LKeys.generals);
  const allPools = useRecoilValue(AtomPoolsData);
  const timeOutRef = useRef<any>(null);
  const [combos, setCombos] = useRecoilState(AtomMultiBetCombo);
  const isDisabled =
    race?.raceStatus === CLOSED || race?.cancelled || combos.length === 0;
  const [roundedAmount, setRoundedAmount] = useState<number | null>(null);
  const [status, setStatus] = useState<any>({});
  const [betCards, setBetCards] = useRecoilState(AtomBetCards);
  const user = useRecoilValue(AtomAuthUser);
  const [error, setError] = useState("");
  const aggregatorObj = useRecoilValue(AtomAggregatorObject);
  const { placeBet } = BetAPIService();

  const [betAmount, setBetAmount] = useRecoilState(AtomBetAmounts);
  const setReRender = useSetRecoilState(AtomReRender);

  useEffect(() => {
    let len = Object.keys(status).length;
    if (len > 0) {
      timeOutRef.current = setTimeout(() => {
        setStatus({});
      }, 3000);
    }
  }, [status]);

  useEffect(() => {
    const _min = getMinAmount();
    if (inputs.stakePerCombo && Number(inputs.stakePerCombo) < _min) {
      setError("Minimum bet amount per combo is " + _min);
    } else {
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.stakePerCombo]);

  const getMinAmount = () => {
    const _pool = allPools[pool ?? ""];
    let _min = _pool?.minBetAmt ?? 0.01;
    if (aggregatorObj?.poolRates && _pool?.currency)
      _min = _min * aggregatorObj?.poolRates[_pool.currency] ?? 1;
    return _min;
  };

  const onClear = () => {
    resetSelections();
    setInputs({});
    setCombos([]);
    setSelectionObject({});
  };

  const quickBet = () => {
    if (!user) return;
    if (!inputs.stake || !inputs.flexPerc) {
      return setStatus({ error: "Input should not be empty" });
    }
    if (combos.length === 0) return;
    let _bet = {} as BetRequestDTO;
    _bet.poolKey = allPools[pool ?? ""].poolKey;
    _bet.betId = getBetId(pool ?? "", betCards);
    _bet.betKey = _bet.betId;
    _bet.positions = getPositions();
    _bet.selections = getSelections(_bet.positions) as any;
    _bet.minBetAmount = allPools[pool ?? ""]?.minBetAmt ?? 0.01;
    _bet.betAmount = Number(inputs.stake);
    _bet.customerId = user?.clientId ?? "";
    _bet.combinations = combos.length;
    return placeBet(_bet, user)
      .then((res) => {
        // console.log(res);
        setStatus({ success: res.betStatus });
        onClear();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSelections = (positions: __PositionToRunners) => {
    let _selections = [];
    for (const pos in positions) _selections.push(positions[pos]);
    let str = JSON.stringify(_selections).replace(/"/g, "");
    // Default amount will be changed at the time of place bet
    return { [str]: inputs.stakePerCombo };
  };

  const getPositions = () => {
    let _pos: __PositionToRunners = {};
    for (const race in selectionObj) {
      const _sels = selectionObj[race];
      _pos[race] = Object.keys(_sels);
    }
    return _pos;
  };

  //  const positionList = () => {
  //    let _posList = ["first", "second", "third", "fourth"];
  //    switch (pool) {
  //      case POOLS.DOUBLE:
  //        return _posList.slice(0, 3);

  //      default:
  //        return _posList.slice(0, 2);
  //    }
  //  };

  const addBet = () => {
    if (!user) return;

    if (combos.length === 0 || !race?.raceKey) return;

    let _betCards = { ...betCards };

    let _pool: PoolTypeDTO = allPools[pool ?? ""];

    let _bet = {} as BetRequestDTO;

    _bet.poolKey = allPools[pool ?? ""].poolKey;
    _bet.betId = getBetId(pool ?? "", betCards);
    _bet.betKey = _bet.betId;
    _bet.positions = getPositions();
    _bet.selections = getSelections(_bet.positions) as any;
    _bet.minBetAmount = allPools[pool ?? ""]?.minBetAmt ?? 0.01;

    if (aggregatorObj?.poolRates) {
      _bet.minBetAmount =
        _bet.minBetAmount * aggregatorObj.poolRates[_pool.currency] ?? 1;
    }

    let _beAmounts = { ...betAmount };
    _beAmounts[_bet.betId] = { ...inputs };
    setBetAmount(_beAmounts);

    _bet.combinations = combos.length;
    _bet.raceKey = race.raceKey;
    _betCards[_bet.betKey] = { bet: _bet, processing: false };

    _setStorage(LS_KEYS.bets, _betCards);
    setBetCards(_betCards);
    setReRender(new Date());
    setInputs({ flexPerc: "", stake: "", stakePerCombo: "" });
    onClear();
  };

  const onFlexChange = ({ data }: ElementChangeEvent) => {
    let val = Number(data === "" ? 0 : data);
    let _minBetAMt = allPools[pool ?? ""]?.minBetAmt ?? 0.01;
    let flexPerc = floatToInteger(Number(val)).toString();
    let stake = (Number(flexPerc) * combos.length * _minBetAMt).toFixed(2);
    let stakePerCombo = (Number(stake) / combos.length).toFixed(2);
    setStatus({});
    setInputs({
      stakePerCombo,
      stake,
      flexPerc,
    });
  };

  const onStackChange = ({ data }: ElementChangeEvent) => {
    setInputs({ stake: data });

    let _minBetAmt = allPools[pool ?? ""].minBetAmt ?? 0.01;
    let val = Number(data === "" ? 0 : data);
    let actualFlex = Number(val) / combos.length / _minBetAmt;
    let flexPerc = floatToInteger(actualFlex);
    let stake = val;
    let _roundedStake = Number(val);

    if (actualFlex !== flexPerc) {
      _roundedStake = flexPerc * _minBetAmt * combos.length;
      setRoundedAmount(_roundedStake);
    } else setRoundedAmount(null);

    let stakePerCombo = (_roundedStake / combos.length).toFixed(2);
    setStatus({});
    setInputs({
      stakePerCombo,
      flexPerc: flexPerc.toString(),
      stake: stake.toString(),
    });
  };
  const InputTemplates = () => {
    return (
      <div className={styles.leftButtons}>
        <div className={styles.multiBetInputWrapper}>
          <BetAmtInput
            className={`${styles.betInput} mr05`}
            label={t("Flex")}
            onChange={onFlexChange}
            suffix="%"
            value={inputs.flexPerc ?? ""}
            disabled={isDisabled}
            placeholder={"Flex"}
          />
          <BetAmtInput
            className={`${styles.betInput} mr05`}
            label={t("Cost")}
            suffix="AUD"
            value={inputs.stake ?? ""}
            onChange={onStackChange}
            disabled={isDisabled}
            placeholder={"Bet Amount"}
          />
        </div>
        <ElevatedButton
          className={styles.quickBetBtn}
          label={t("Quick Bet")}
          onClick={quickBet}
          severity="primary"
          size="sm"
          disabled={isDisabled}
        />
      </div>
    );
  };

  return (
    <footer className={styles.multiFooter}>
      <div>
        {InputTemplates()}

        <div className={styles.textShow}>
          {status && status.success ? (
            <div className={`${styles.statusMessage} ${styles.success}`}>
              {status.success}
            </div>
          ) : (
            <div className={`${styles.statusMessage} ${styles.error}`}>
              {status.error}
            </div>
          )}

          {roundedAmount ? (
            <div style={{ color: "#ffd281", paddingLeft: "7rem" }}>
              {" "}
              {t("roundingMsg")} {roundedAmount.toFixed(2)}{" "}
              {aggregatorObj?.currency ?? "$"}
            </div>
          ) : null}
          {error && <span className={styles.error}>{error}</span>}
        </div>
      </div>
      <div className={styles.rightButtons}>
        <div className={styles.multiFooterButtons}>
          <ElevatedButton
            className={styles.addBetButton}
            label={t("clear")}
            onClick={onClear}
            severity="warning"
            size="sm"
          />

          <ElevatedButton
            className={styles.addBetButton}
            label={t("addBet")}
            onClick={addBet}
            severity="primary"
            size="sm"
          />
        </div>
      </div>
    </footer>
  );
};

export default MultiFooter;
