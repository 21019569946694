import { Fragment, useState } from "react";
import { FaCaretDown, FaCaretUp, FaSort } from "react-icons/fa";
import styled from "styled-components";
import { sortRaceTrips } from "../../../../common/helpers/object.helper";
import { RaceTripDTO } from "../../../../common/models/raceTrip.model";
import { __SelectionOddsDTO } from "../../../../common/types/dynamic.type";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import styles from "./MultiRaceHeader.module.scss";

interface SortDTO {
  mode: 0 | 1 | -1;
  field: string;
}

type Props = {
  raceNo: number;
  trips: { [l: string]: RaceTripDTO[] };
  odds: __SelectionOddsDTO;
  setSortedTrips: (srt: RaceTripDTO[]) => void;
  index: number;
};

const MultiRaceHeader = ({
  raceNo,
  trips,
  odds,
  setSortedTrips,
  index,
}: Props) => {
  const [sort, setSort] = useState<SortDTO>({ mode: 0, field: "" });
  const t = useTranslation(LKeys.generals);

  // On click of sort
  const onOddsSort = (field: string) => {
    let _sort = { ...sort };
    let _mode: 0 | -1 | 1 = -1;

    if (_sort.field === field) {
      if (_sort.mode === 0) _mode = -1;
      else if (_sort.mode === -1) _mode = 1;
      else _mode = 0;
    }
    _sort = { field, mode: _mode };

    if (field === "runners") {
      sortRunners(_sort);
    } else {
      sortOdds(_sort);
    }
  };

  const sortRunners = (_sort: SortDTO) => {
    if (!trips) return;
    let tripsData = { ...trips };
    let _trips = [...tripsData[raceNo]];
    if (_sort.mode === 0) {
      _trips = sortRaceTrips(_trips, -1);
    } else {
      _trips = sortRaceTrips(_trips, _sort.mode);
    }
    tripsData[raceNo] = _trips;
    setSortedTrips(_trips);
    setSort(_sort);
  };

  const sortOdds = (_sort: SortDTO) => {
    if (!trips) return;
    let _tripsData = { ...trips };
    let _trips = [..._tripsData[raceNo]];
    if (_sort.mode === 0) {
      _trips = sortRaceTrips(_trips, -1);
    } else {
      _trips = _trips.sort((a, b) => {
        let _revMode = _sort.mode === -1 ? 1 : -1;
        if (!odds) return 0;
        let o1 = Number(odds[a.raceTripKey.selId] ?? 0);
        let o2 = Number(odds[b.raceTripKey.selId] ?? 0);
        return o1 < o2 ? _sort.mode : o1 > o2 ? _revMode : 0;
      });
    }
    _tripsData[raceNo] = _trips;
    setSortedTrips(_trips);
    setSort(_sort); /*  */
  };

  const sortButton = (label: string) => (
    <SortButton
      onClick={() => onOddsSort(label)}
      className={`${styles.poolHeaderBtn} ${
        sort.field === label && sort.mode !== 0 ? "active" : ""
      }`}
      key={label}
    >
      {t(label)}
      <span className={styles.sortIcon}>
        {sort.field === label && sort.mode === -1 ? (
          <FaCaretDown size={13} />
        ) : sort.field === label && sort.mode === 1 ? (
          <FaCaretUp size={13} />
        ) : (
          <FaSort size={12} />
        )}
      </span>
    </SortButton>
  );

  return (
    <TableHeader
      className={`${styles.tableHeader} ${
        index % 2 === 0 ? styles.bordered : ""
      }`}
    >
      <div>{sortButton("runners")}</div>
      <div className={styles.legTitle}> Race {raceNo}</div>
      <div className={styles.poolHeader}>
        <Fragment>{sortButton("WIN")}</Fragment>
      </div>
    </TableHeader>
  );
};

export default MultiRaceHeader;

// * Table Styled Components
const TableHeader = styled.div`
  button {
    color: ${(p) => p.theme.text};
  }
`;

const SortButton = styled.button`
  &.active {
    color: ${(p) => p.theme.active};
  }
`;
