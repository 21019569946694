import { format } from "date-fns";
import { API, API_URLS, getApiHeader } from "../../configs/api.config";
import { DATE_FORMATS } from "../../constants/dateTime.constant";
import {
  formatTransactionTime,
  numberToDate,
} from "../../helpers/dateTime.helper";
import { toRaceKey } from "../../helpers/value.helper";
import { UserDTO } from "../../models/auth.model";
import { BetRequestDTO, BetResponseDTO } from "../../models/bet.model";
import { WagersDTO } from "../../models/transaction.model";

export const BetAPIService = () => {
  // * Delete extra key values from bet request object.
  const _getApiBetObj = (bet: BetRequestDTO): BetResponseDTO => {
    let _bet = { ...bet } as any;
    delete _bet.incBetAmt;
    delete _bet.betKey;
    delete _bet.poolType;
    delete _bet.minBetAmount;
    delete _bet.singleBetAmt;
    delete _bet.silk;
    delete _bet.raceKey;
    delete _bet.winplaceOdds;
    delete _bet.selId;
    delete _bet.runnerName;
    delete _bet.isSubmitted;
    delete _bet.resStatus;
    delete _bet.betResponse;
    delete _bet.betReqId;
    delete _bet.positions;
    return _bet;
  };

  // * Manage open bets
  const manageOpenBets = (res: WagersDTO[]) => {
    let _betsData = [] as WagersDTO[];

    for (const bet of res) {
      if (bet.betStatus !== "CONFIRMED") continue;
      let raceKey =
        typeof bet.raceKey !== "object"
          ? toRaceKey(bet.raceKey ?? "")
          : bet.raceKey;
      bet.raceKey = raceKey;
      bet.localDate = numberToDate(raceKey.localDate.toString());
      bet.trackName = raceKey.trackName;
      bet.raceNo = raceKey.raceNo;
      bet.totalRefunds = Number(bet.totalRefunds?.toFixed(2));
      const winnings = Number(bet.totalWinnings);
      const success = Number(bet.totalInvested);
      bet.totalWinnings = Number(winnings.toFixed(2));
      bet.totalInvested = Number(success.toFixed(2));

      if (bet.betStatus === "CONFIRMED" || bet.betStatus === "WAITING") {
        bet.returnPnl = 0;
      } else {
        bet.returnPnl = Number((winnings - success).toFixed(2));
      }
      let timeStr = format(
        new Date(Number(bet.timestamp)),
        DATE_FORMATS.yyyyMMddHHmmss
      );

      bet.formattedTime = formatTransactionTime(
        timeStr,
        bet.timezone,
        DATE_FORMATS.ddMMyyyy_hhmmss_DASH
      );

      _betsData.push(bet);
    }
    return _betsData;
  };

  const placeBet = async (bet: BetRequestDTO, user: UserDTO) => {
  
    try {
      const headers = getApiHeader(user);
      const _bet = _getApiBetObj(bet);

      const res = await API.post(API_URLS.placeBet, _bet, { headers });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // * get Open Bets
  const getOpenBets = async (user: UserDTO) => {
    try {
      const headers = getApiHeader(user);
      const res = await API.get(API_URLS.openWagers, { headers });
      const _betsData = manageOpenBets(res.data);
      return _betsData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { placeBet, getOpenBets };
};
