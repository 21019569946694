import { PzoneCodeDTO, RaceKeyDTO } from "../models/race.mode";

// * To convert race key string to race key Object.
export const toRaceKey = (rk: string | RaceKeyDTO): RaceKeyDTO => {
  if (typeof rk === "string") {
    let tiltSplit = rk.split("~");
    let hyphenSplit = tiltSplit[1].split("-");
    return {
      trackName: getTrackName(tiltSplit[1]),
      eventType: hyphenSplit[2],
      localDate: Number(tiltSplit[0]),
      province: hyphenSplit[0],
      pzoneCode: replaceUnderscore(tiltSplit[1], true),
      raceNo: Number(tiltSplit[2]),
    };
  }
  const _code = rk.pzoneCode.split("-");
  return {
    ...rk,
    trackName: getTrackName(rk.pzoneCode),
    eventType: _code[2],
    province: _code[0],
  };
};

// * To convert raceKey object to race key string
export const toRaceKeyStr = (rk: RaceKeyDTO) => {
  if (!rk) return "";
  let _rno = rk.raceNo.toString();
  if (_rno.length === 1) {
    _rno = "0" + _rno;
  }
  return `${rk.localDate}~${rk.pzoneCode}~${_rno}`;
};

// * Extract track name from pzone code.
export const getTrackName = (pzoneCode: string, reverse?: boolean) => {
  if (reverse) return pzoneCode.split("-")[1].toLowerCase().replace(/ /g, "_");
  // return pzoneCode.split("-")[1].toLowerCase().replace(/_/g, " ");
  return pzoneCode.split("-")[1];
};

// * Replace underscore and space according to parameters
export const replaceUnderscore = (
  query?: string | string[],
  reverse?: boolean
) => {
  if (query) {
    if (reverse) return query.toString().replace(/ /g, "_");
    return query.toString().replace(/_/g, " ");
  }
  return "";
};

// * Returns unique bet key string for bet cards
export const toWinPlaceBetKeyStr = (
  raceKey: string,
  pool: string,
  selId: string
) => {
  return raceKey + "~" + pool + "~" + selId;
};

// TODO unknown working
// Remove the below method
export const floatToInteger = (val: number): number => {
  return val | 0;
};

// * Pzone code track name string to object
export const toPzoneCode = (trackCode: string): PzoneCodeDTO => {
  const _split = trackCode.split("-");
  return {
    eventType: _split[2],
    key: trackCode,
    province: _split[0],
    trackName: _split[1],
  };
};
