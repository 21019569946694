import { Fragment } from "react";
import ShimmerLoaders from "../../../ui/shimmerLoaders/shimmerLoader.ui";
import styles from "./upcRaceLoader.module.scss";

const UpcRaceLoader = () => {
  const style = {
    width: 275,
    height: 60,
  };

  return (
    <>
      {Array(5)
        .fill("")
        .map((i,index) => (
          <Fragment key={index}>
            <div className={styles.headerWrp}>
              {Array(4)
                .fill("")
                .map((i,index) => (
                  <ShimmerLoaders key={index} style={style} />
                ))}
            </div>
          </Fragment>
        ))}
    </>
  );
};

export default UpcRaceLoader;
