import { NavHeaderWrapper } from "../../../../styled-components/layoutComponents/globalLayout.styled";
import styles from "./policyHeader.module.scss";
import LOGOLight from "../../../../assets/logos/LogoLight.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../common/routes/default.routes";
import ElevatedButton from "../../../ui/buttons/elevated.button";

type Props = {};

const PolicyHeader = (props: Props) => {
  return (
    <NavHeaderWrapper className={styles.policyHeader}>
      <header className={styles.header}>
        <div className={styles.firstCol}>
          <div className={styles.logoWrapper}>
            <Link to={ROUTES.LOGIN}>
              <img src={LOGOLight} alt="AshvaBetsLogo" />
            </Link>
          </div>
        </div>
        <div>
          <Link to={ROUTES.LOGIN}>
            <ElevatedButton severity="warning">Go To Log in</ElevatedButton>
          </Link>
        </div>
      </header>
    </NavHeaderWrapper>
  );
};

export default PolicyHeader;
