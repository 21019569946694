import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import styles from "./card.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  bodyClass?: string;
  style?: CSSProperties;
  bodyStyle?: CSSProperties;
  headerTemplate?: any;
};

const Card = (props: Props) => {
  return (
    <CardWrapper style={props.style} className={`${props.className ?? ""}`}>
      {props.headerTemplate && (
        <header className="header">{props.headerTemplate()}</header>
      )}
      <div
        className={`${props.bodyClass ?? ""} ${styles.cardBody}`}
        style={props.bodyStyle}
      >
        {props.children}
      </div>
    </CardWrapper>
  );
};

export default Card;

// * Card wrapper styled  component
const CardWrapper = styled.div`
  background: ${(p) => p.theme.bodyContrast};
  color: ${(p) => p.theme.textContrast};
  border-radius: var(--radius-default);

  .header {
    overflow: hidden;
    background: ${(p) => p.theme.headerLight};
    border-top-left-radius: var(--radius-default);
    border-top-right-radius: var(--radius-default);
  }
`;
