import { POOLS } from "../constants/pools.constant";
import { __MultiSelectionDTO, __Selection } from "../types/dynamic.type";

// To find lengths of selection combos
export const getComboLength = (
  pool: string,
  selection: __Selection,
  isBoxed?: boolean
) => {
  switch (pool) {
    case POOLS.QUINELLA:
      return getQuinellaSwingerLength(selection, isBoxed);
    case POOLS.SWINGER:
      return getQuinellaSwingerLength(selection, isBoxed);
    case POOLS.EXACTA:
      return getExactaLength(selection, isBoxed);
    case POOLS.TRIFECTA:
      return getTrifectaLength(selection, isBoxed);
    case POOLS.SUPERFECTA:
      return getSuperFectaLength(selection, isBoxed);
    default:
      return 0;
  }
};

const getQuinellaSwingerLength = (
  selection: __Selection,
  isBoxed?: boolean
) => {
  let count = 0;
  if (isBoxed) {
    const selections = [...Object.keys(selection.boxed)];
    for (let i = 0; i < selections.length; i++) {
      if (i === selections.length - 1) break;
      for (let j = i; j < selections.length; j++) {
        if (j === selections.length - 1) break;
        count++;
      }
    }
    return count;
  }
  const first = [...Object.keys(selection.first)];
  const second = [...Object.keys(selection.second)];
  let comboStr: string[] = [];
  for (const f of first) {
    for (const s of second) {
      if (f === s) continue;
      let str = f + "*";
      str += s;
      let rv = s + "*" + f;
      if (comboStr.includes(rv)) continue;
      comboStr.push(str);
      count++;
    }
  }
  return count;
};

const getExactaLength = (selection: __Selection, isBoxed?: boolean) => {
  let count = 0;
  if (isBoxed) {
    const selections = [...Object.keys(selection.boxed)];

    for (const f of selections) {
      for (const s of selections) {
        if (f === s) continue;
        count++;
      }
    }
    return count;
  }
  const first = [...Object.keys(selection.first)];
  const second = [...Object.keys(selection.second)];

  for (const f of first) {
    for (const s of second) {
      if (f === s) continue;
      count++;
    }
  }
  return count;
};

// get length for trifecta selections
const getTrifectaLength = (selection: __Selection, isBoxed?: boolean) => {
  let count = 0;
  if (isBoxed) {
    const allSelections = [...Object.keys(selection.boxed)];

    for (const f of allSelections) {
      for (const s of allSelections) {
        for (const t of allSelections) {
          if (f === t || f === s || s === t) continue;
          count++;
        }
      }
    }

    return count;
  }
  const first = [...Object.keys(selection.first)];
  const second = [...Object.keys(selection.second)];
  const third = [...Object.keys(selection.third)];

  for (const f of first) {
    for (const s of second) {
      for (const t of third) {
        if (f === s || f === t || s === t) continue;
        count++;
      }
    }
  }

  return count;
};

// get length for superfecta selections
const getSuperFectaLength = (selection: __Selection, isBoxed?: boolean) => {
  let count = 0;
  if (isBoxed) {
    const selections = [...Object.keys(selection.boxed)];

    for (const f of selections) {
      for (const s of selections) {
        for (const t of selections) {
          for (const ft of selections) {
            if (
              f === s ||
              f === t ||
              f === ft ||
              s === t ||
              s === ft ||
              t === ft
            )
              continue;
            count++;
          }
        }
      }
    }

    return count++;
  }
  const first = [...Object.keys(selection.first)];
  const second = [...Object.keys(selection.second)];
  const third = [...Object.keys(selection.third)];
  const fourth = [...Object.keys(selection.fourth)];

  for (const f of first) {
    for (const s of second) {
      for (const t of third) {
        for (const ft of fourth) {
          if (f === s || f === t || f === ft || s === t || s === ft || t === ft)
            continue;
          count++;
        }
      }
    }
  }

  return count;
};

export const getMultiComboStr = (
  pool: string,
  selectionObj: __MultiSelectionDTO
) => {
  let _races: any[] = Object.keys(selectionObj);
  if (!_races.length) return [];
  const sels1: any = selectionObj[_races[0]] ?? {};
  const sels2: any = selectionObj[_races[1]] ?? {};
  const sels3: any = selectionObj[_races[2]] ?? {};
  const sels4: any = selectionObj[_races[3]] ?? {};

  switch (pool) {
    case POOLS.DOUBLE:
      return getDoubleComboStr(sels1, sels2);
    case POOLS.PICK_3:
      return getPick3ComboStr(sels1, sels2, sels3);
    case POOLS.PICK_4:
      return getPick4ComboStr(sels1, sels2, sels3, sels4);
    default:
      return [];
  }
};

const getDoubleComboStr = (sels1: any, sels2: any) => {
  let _comboStr: string[] = [];

  for (const f in sels1) {
    for (const s in sels2) {
      let str = f + "*" + s;
      _comboStr.push(str);
    }
  }
  return _comboStr;
};

const getPick3ComboStr = (sels1: any, sels2: any, sels3: any) => {
  let combostr: string[] = [];
  for (const f in sels1) {
    for (const s in sels2) {
      for (const t in sels3) {
        let str = f + "*" + s + "*" + t;
        combostr.push(str);
      }
    }
  }
  return combostr;
};

const getPick4ComboStr = (sels1: any, sels2: any, sels3: any, sels4: any) => {
  let combostr: string[] = [];
  for (const f in sels1) {
    for (const s in sels2) {
      for (const t in sels3) {
        for (const fo in sels4) {
          let str = f + "*" + s + "*" + t + "*" + fo;
          combostr.push(str);
        }
      }
    }
  }
  return combostr;
};
