import {
  RSCarouselBtnWrapper,
  RSHeaderWrapper,
} from "../../../../styled-components/layoutComponents/globalLayout.styled";
import RaceSnapCard from "./raceSnap.card";
import styles from "./raceSnapHeader.module.scss";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import { AtomRaceSnaps } from "../../../../store/races/races.store";
import useWindowSize from "../../../../hook/useWindowSize";
import RaceSnapLoader from "../raceSnapLoader/raceSnapLoader.loader";
import { useHorizontalScroll } from "../../../../hook/useHorizontalSCroll";

const RaceSnapHeader = () => {
  const races = useRecoilValue(AtomRaceSnaps);
  const scrollRef = useHorizontalScroll();
  // const scrollRef = useRef<HTMLDivElement>(null);
  const resize = useWindowSize();

  const onArrowClick = (isLeft?: boolean) => {
    let offSet = window.innerWidth - 60;

    let val = isLeft ? -offSet : +offSet;
    if (scrollRef.current) scrollRef.current.scrollLeft += val;
  };

  if (races === undefined) return <RaceSnapLoader />;
  // return <RaceSnapLoader />;

  return (
    <>
      {resize.width > 590 && (
        <RSHeaderWrapper>
          <header className={styles.headerWrapper}>
            {/* <RaceSnapFilter /> */}
            <div ref={scrollRef} className={styles.carouselWrapper}>
              <div className={styles.carouselChild}>
                {/* <RSCarouselBtnWrapper className={styles.actionBtnWrapperFront}>
              <button>
                <FaCaretLeft />
              </button>
              <button>
                <FaCaretRight />
              </button>
            </RSCarouselBtnWrapper> */}
                {races?.map((race, i) => {
                  return <RaceSnapCard race={race} key={i} />;
                })}
                {races?.length && (
                  <RSCarouselBtnWrapper className={styles.actionBtnWrapper}>
                    <button onClick={() => onArrowClick(true)}>
                      <FaCaretLeft />
                    </button>
                    <button onClick={() => onArrowClick()}>
                      <FaCaretRight />
                    </button>
                  </RSCarouselBtnWrapper>
                )}
              </div>
            </div>
          </header>
        </RSHeaderWrapper>
      )}
    </>
  );
};

export default RaceSnapHeader;
