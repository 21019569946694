import { off, onValue } from "firebase/database";
import { getDownloadURL, list } from "firebase/storage";
import { ReactNode, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { DATE_FORMATS } from "../../common/constants/dateTime.constant";
import { NON_DISPLAY } from "../../common/constants/general.constant";
import { formatZonedDateTime } from "../../common/helpers/dateTime.helper";
import { LOG } from "../../common/helpers/debugging.helper";
import { sortRaceTrips } from "../../common/helpers/object.helper";
import { getPoolOddsCols } from "../../common/helpers/pool.helper";
import {
  ParamsDTO,
  paramsToRaceKey,
} from "../../common/helpers/routing.helper";
import { toRaceKey, toRaceKeyStr } from "../../common/helpers/value.helper";
import { RaceDetailsDTO, RaceKeyDTO } from "../../common/models/race.mode";
import {
  Ref_LiveOdds,
  Ref_Pools,
  Ref_RaceDetails,
  Ref_RaceTips,
  Ref_RunnerDetails,
  Ref_SilkObj,
  Ref_TrackRaceNumbers,
} from "../../common/services/nodes.service";
import { PoolsService } from "../../common/services/pools.service";
import { RacesService } from "../../common/services/races.service";
import {
  __FavoriteDTO,
  __LiveOddsDTO,
  __SelIdToRaceTripDTO,
} from "../../common/types/dynamic.type";
import {
  AtomFavoriteSelections,
  AtomPoolColsForOdds,
} from "../pools/pools.store";
import { AtomHorseSelection } from "../selection/selection.store";
import { AtomRaceNumbers, AtomSelectedRace } from "./races.store";
import {
  AtomLiveOdds,
  AtomPoolNames,
  AtomPoolsData,
  AtomSelectedPoolName,
  AtomSelectedRaceTips,
  AtomSelectedRaceTrips,
  AtomSelectedSilks,
} from "./selectedRace.store";

interface SelectedRaceProviderProps {
  children?: ReactNode;
}

const SelectedRaceProvider = ({ children }: SelectedRaceProviderProps) => {
  const params = useParams() as ParamsDTO;
  const refRace = useRef<any>(null);
  const refRaceNumbers = useRef<any>(null);
  const refLiveOdds = useRef<any>(null);
  const refRaceTrips = useRef<any>(null);
  const refPoolsData = useRef<any>(null);
  const refRaceTips = useRef<any>(null);

  const setSelectedRace = useSetRecoilState(AtomSelectedRace);
  const setRaceNumbers = useSetRecoilState(AtomRaceNumbers);
  const setLiveOdds = useSetRecoilState(AtomLiveOdds);
  const setPoolOddsCols = useSetRecoilState(AtomPoolColsForOdds);
  const setRaceTrips = useSetRecoilState(AtomSelectedRaceTrips);
  const setSilkObj = useSetRecoilState(AtomSelectedSilks);
  const resetSelection = useResetRecoilState(AtomHorseSelection);
  const setPoolNames = useSetRecoilState(AtomPoolNames);
  const setSelectedPool = useSetRecoilState(AtomSelectedPoolName);
  const setPoolsData = useSetRecoilState(AtomPoolsData);
  const setRaceTips = useSetRecoilState(AtomSelectedRaceTips);
  const setFavorites = useSetRecoilState(AtomFavoriteSelections);
  // const setMultiLegs = useSetRecoilState(AtomMultiLegs);

  const { getPoolsObj } = PoolsService();
  const { manageInitialRaces } = RacesService();

  // * fetch race data from firebase
  const fetchSelectedRace = useCallback(
    (raceKey: RaceKeyDTO) => {
      if (refRace.current) off(refRace.current);
      LOG("fetchSelectedRace", "API");
      if (!raceKey) return;
      setSelectedRace(undefined);
      refRace.current = Ref_RaceDetails(raceKey);
      resetSelection();

      onValue(
        refRace.current,
        (res) => {
          if (res.exists()) {
            let _race: RaceDetailsDTO = res.val();
            // * prevent data display for NON DISPLAY Races.
            if (_race.raceStatus === NON_DISPLAY) {
              setSelectedRace(null);
              console.error("Race is not displayed!");
              return;
            }

            const _dateStr = formatZonedDateTime(
              _race.postTime,
              _race.timezoneId,
              DATE_FORMATS.ddMyyyy_HHmm_Space
            ).split(" ");
            _race.localPostTime = _dateStr[1];
            _race.localDate = _dateStr[0];
            _race.mtp = formatZonedDateTime(_race.postTime, _race.timezoneId);
            _race.raceKey = toRaceKey(_race.raceKey);
            _race.raceKeyString = toRaceKeyStr(_race.raceKey);
            setSelectedRace(_race);
          } else {
            setSelectedRace(null);
          }
        },
        (err) => {
          console.error(err);
          setSelectedRace(null);
        }
      );
    },
    [resetSelection, setSelectedRace]
  );

  // * Listener for selected track all races to show race numbers
  const fetchRaceNumbers = useCallback(
    (raceKey: RaceKeyDTO) => {
      if (refRaceNumbers.current) off(refRaceNumbers.current);
      refRaceNumbers.current = Ref_TrackRaceNumbers(raceKey);
      LOG("fetchRaceNumbers", "API");

      onValue(
        refRaceNumbers.current,
        (res) => {
          const _races = manageInitialRaces(res.val() ?? []);
          setRaceNumbers(Object.values(_races ?? {}));
        },
        (err) => {
          console.error(err);
          setRaceNumbers([]);
        }
      );
    },
    [manageInitialRaces, setRaceNumbers]
  );

  // * Listener for race trips for selected race.
  const fetchRaceTrips = useCallback(
    (raceKey: RaceKeyDTO) => {
      if (refRaceTrips.current) off(refRaceTrips.current);
      // setRaceTrips(undefined);
      LOG("fetchRaceTrips", "API");
      refRaceTrips.current = Ref_RunnerDetails(raceKey);
      return new Promise<__SelIdToRaceTripDTO>((resolve, reject) => {
        onValue(
          refRaceTrips.current,
          (res) => {
            const _sorted = sortRaceTrips(Object.values(res.val() ?? {}), -1);
            setRaceTrips(_sorted);
            const scrRunners = _sorted
              .filter((x) => x.scratched)
              .map((x) => x.raceTripKey.selId);

            fetchLiveOdds(raceKey, scrRunners);
          },
          (err) => {
            console.error(err);
            setRaceTrips(null);
          }
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRaceTrips]
  );

  // * Fetching live odds for selected race.
  const fetchLiveOdds = useCallback(
    (raceKey: RaceKeyDTO, scrRunners: string[]) => {
      if (refLiveOdds.current) off(refLiveOdds.current);
      LOG("fetchLiveOdds", "API");
      if (!raceKey) return;
      refLiveOdds.current = Ref_LiveOdds(raceKey);

      onValue(
        refLiveOdds.current,
        (res) => {
          const _oddsData = res.val() as __LiveOddsDTO;
          let fav: __FavoriteDTO = {};

          for (const pool in _oddsData) {
            const selIdToOdds = _oddsData[pool];
            let _min = Number.MAX_VALUE,
              _fav: string | null = null;

            for (const sel in selIdToOdds) {
              const odds = selIdToOdds[sel];
              if (scrRunners.includes(sel)) continue;
              if (odds && odds <= _min) {
                _fav = sel;
                _min = odds;
              }
            }
            fav[pool] = _fav;
          }
          setFavorites(fav);
          const _poolCols = getPoolOddsCols(_oddsData);
          setPoolOddsCols(_poolCols);
          setLiveOdds(_oddsData);
        },
        (err) => {
          console.error(err);
          setFavorites({});
          setLiveOdds({});
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLiveOdds, setPoolOddsCols]
  );

  // * Fetching and listening for pool updates
  const fetchPoolsData = useCallback(
    (raceKey: RaceKeyDTO) => {
      if (refPoolsData.current) off(refPoolsData.current);
      if (!raceKey) return;
      LOG("Fetching Pools Data", "API");
      refPoolsData.current = Ref_Pools(raceKey);
      let _isInit = true; // Check if

      onValue(
        refPoolsData.current,
        (res) => {
          const _poolNames = getPoolsObj({ poolObj: res.val() });
          setPoolNames(_poolNames ?? []);
          setPoolsData(res.val() ?? {});
          if (_isInit && _poolNames.length) {
            setSelectedPool(_poolNames[0]);
            _isInit = false;
          }
        },
        (err) => {
          console.error(err);
          setPoolNames([]);
        }
      );
    },
    [getPoolsObj, setPoolNames, setPoolsData, setSelectedPool]
  );

  // * fetch silk images from firebase storage for selected race.
  const fetchRunnerSilks = useCallback(
    (raceKey: RaceKeyDTO) => {
      setSilkObj({});
      if (!raceKey) return;
      const refStorage = Ref_SilkObj(raceKey);
      LOG("SILK API CALLED", "API");
      list(refStorage).then((url) => {
        url.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then(function (url) {
            let pathSplit = itemRef.fullPath.split("/");
            let silk: { [k: string]: string } = {};
            silk[pathSplit[pathSplit.length - 1]] = url;
            setSilkObj((prev) => {
              return { ...prev, ...silk };
            });
          });
        });
      });
    },
    [setSilkObj]
  );

  const fetchRaceTips = useCallback(
    (raceKey: RaceKeyDTO) => {
      if (refRaceTips.current) off(refRaceTips.current);

      LOG("Race Tips method call", "INFO");
      if (!raceKey) return;
      refRaceTips.current = Ref_RaceTips(raceKey);
      onValue(
        refRaceTips.current,
        (res) => {
          if (res.exists()) {
            setRaceTips(res.val());
          } else {
            setRaceTips(null);
          }
        },
        (err) => {
          setRaceTips(null);
        }
      );
    },
    [setRaceTips]
  );

  // * Detect race change from URL routing
  useEffect(() => {
    const raceKey = paramsToRaceKey(params);
    // LOG("SelectedRaceProvider Called", "INFO");

    if (raceKey) {
      fetchSelectedRace(raceKey);
      fetchRaceNumbers(raceKey);
      fetchPoolsData(raceKey);
      fetchRaceTrips(raceKey);
      fetchRunnerSilks(raceKey);
      fetchRaceTips(raceKey);
      // fetchMulti(raceKey);
    }
    return () => {
      //   LOG("SelectedRaceProvider Destroyed", "INFO");
      if (refRace.current) off(refRace.current);
      if (refRaceNumbers.current) off(refRaceNumbers.current);
      if (refLiveOdds.current) off(refLiveOdds.current);
      if (refRaceTrips.current) off(refRaceTrips.current);
      if (refPoolsData.current) off(refPoolsData.current);
      if (refRaceTips.current) off(refRaceTips.current);
    };
  }, [
    fetchPoolsData,
    fetchRaceNumbers,
    fetchRaceTips,
    fetchRaceTrips,
    fetchRunnerSilks,
    fetchSelectedRace,
    params,
  ]);

  return <>{children}</>;
};
export default SelectedRaceProvider;
