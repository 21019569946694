import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getMultiComboStr } from "../../../../common/helpers/combinations.helper";
import { RaceTripDTO } from "../../../../common/models/raceTrip.model";
import useSelected from "../../../../hooks/useSelectedPool";
import {
  AtomLegsRacesTrips,
  AtomMultiBetCombo,
  AtomMultiBetSelection,
  AtomMultiLegFav,
  AtomMultiWinLiveOdds,
  AtomSelectedRaceNo,
} from "../../../../store/bets/multiBet.store";

import MultiLegTab from "../MultiLegTab";
import MultiRaceHeader from "../MultiRaceHeader/MultiRaceHeader";
import MultiRaceTripsRow from "../MultiRaceTripsRow";
import styles from "./MultiRaceTripsTable.module.scss";

const MultiRaceTripsTable = () => {
  const [legTrips, setLegTrips] = useRecoilState(AtomLegsRacesTrips);
  const multiFav = useRecoilValue(AtomMultiLegFav);
  const multiRaceOdds = useRecoilValue(AtomMultiWinLiveOdds);
  const setCombos = useSetRecoilState(AtomMultiBetCombo);
  const [selectionObj, setSelectionObject] = useRecoilState(
    AtomMultiBetSelection
  );
  const selectedRaceNo = useRecoilValue(AtomSelectedRaceNo);
  const { race, pool } = useSelected();

  const handleBetClick = (trip: RaceTripDTO) => {
    let raceNo = trip.raceTripKey.raceNo;
    if (!race?.raceKey) return;
    let _selection = { ...selectionObj };
    let _selId = trip.raceTripKey.selId.toString();
    let _trip = { ..._selection[raceNo] };
    if (_trip && _trip[_selId]) {
      delete _trip[_selId];
    } else {
      _trip = { ..._trip, [_selId]: true };
    }
    _selection[raceNo] = _trip;
    let _combos = getMultiComboStr(pool ?? "", _selection);
    setCombos(_combos);
    setSelectionObject(_selection);
  };
  
  return (
    <>
      <MultiLegTab />
      <div
        className={styles.multiTableWrapper}
        style={{
          gridTemplateColumns: selectedRaceNo !== 0 ? "auto" : "auto auto",
        }}
      >
        {Object.keys(legTrips).map((leg, i) => {
          if (selectedRaceNo !== 0 && Number(leg) !== selectedRaceNo)
            return null;

          const trips: RaceTripDTO[] = legTrips[leg];

          return (
            <div
              className={`${styles.tableWrapper} ${
                i > 1 ? styles.topBordered : ""
              }`}
              key={i}
            >
              <MultiRaceHeader
                raceNo={Number(leg)}
                trips={legTrips}
                index={i}
                odds={multiRaceOdds[leg]}
                setSortedTrips={(trips) =>
                  setLegTrips({ ...legTrips, [leg]: trips })
                }
              />
              <div className={styles.wrapper}>
                <div className={i % 2 === 0 ? styles.bordered : ""}>
                  <React.Fragment key={i}>
                    {trips.map((trip, j) => {
                      return (
                        <MultiRaceTripsRow
                          trip={trip}
                          key={j}
                          race={race}
                          raceNo={Number(leg)}
                          odds={multiRaceOdds && multiRaceOdds[leg]}
                          onBetClick={() => handleBetClick(trip)}
                          fav={multiFav}
                        />
                      );
                    })}
                  </React.Fragment>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MultiRaceTripsTable;
