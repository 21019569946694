import { off, onValue } from "@firebase/database";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaTrophy } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  DigitToWords,
  NON_DISPLAY,
  RESULTED,
} from "../../../common/constants/general.constant";
import {
  getPoolName,
  sortWPPoolsObj,
} from "../../../common/helpers/pool.helper";
import { RaceDetailsDTO } from "../../../common/models/race.mode";
import { Ref_Pools } from "../../../common/services/nodes.service";
import { __PoolTypeDTO } from "../../../common/types/dynamic.type";
import useSelected from "../../../hooks/useSelectedPool";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import { AtomSelectedRaceTrips } from "../../../store/races/selectedRace.store";
import TripInfo from "../raceTrips/raceTripsRow/tripInfo/tripInfo.component";
import RaceTipsInline from "../selectedRace/raceTipComponent/raceTipsInline.component";
import ExoticsPayOff from "./exoticsPayoff/exoticsPayoff.component";
import styles from "./resultedRaceComponent.module.scss";

interface IResultDTO {
  selId: string;
  position: string | number;
}
const ResultedRaceComponent = () => {
  const { race } = useSelected();
  const [results, setResults] = useState<IResultDTO[]>([]);
  const refPool = useRef<any>(null);
  const [poolsData, setPoolsData] = useState<__PoolTypeDTO>({});
  const [wpPools, setWpPools] = useState<__PoolTypeDTO>({});
  const runners = useRecoilValue(AtomSelectedRaceTrips);
  const tGeneral = useTranslation(LKeys.generals);
  //   const tRunners = useTranslation(LKeys.runners);
  //   const tJockeys = useTranslation(LKeys.jockeys);
  //   const tTrainers = useTranslation(LKeys.trainers);

  // * Convert result string to selection and position object
  const extractResults = useCallback((race: RaceDetailsDTO) => {
    if (!race?.rr) return;
    let _results: IResultDTO[] = [];

    let i = 1;
    let rr = race.rr.slice(1, race.rr.length - 1).split(",");

    for (const char of rr) {
      let sel = char.trim();
      let obj = { selId: sel, position: i };
      if (sel[sel.length - 1] !== "]") i++;
      else obj.selId = sel.slice(0, -1).trim();
      _results.push(obj);
    }

    setResults(_results);
  }, []);

  const getPoolDetails = useCallback((race: RaceDetailsDTO) => {
    if (!race?.raceKey) return;
    if (refPool.current) off(refPool.current);

    refPool.current = Ref_Pools(race?.raceKey);
    onValue(
      refPool.current,
      (snap) => {
        let _pools: __PoolTypeDTO = {};
        let _wpPools: __PoolTypeDTO = {};
        if (snap.exists()) {
          _pools = sortWPPoolsObj(snap.val());

          for (const poolType in _pools) {
            const _pool = _pools[poolType];
            if (_pool.poolStatus === NON_DISPLAY) delete _pools[poolType];
            if (!_pool.payoff) delete _pools[poolType];
          }
          if (_pools["WIN"]) _wpPools["WIN"] = _pools["WIN"];
          if (_pools["PLACE"]) _wpPools["PLACE"] = _pools["PLACE"];
          if (_pools["SHOW"]) _wpPools["SHOW"] = _pools["SHOW"];
        }
        setPoolsData(_pools);
        setWpPools(_wpPools);
      },
      (err) => {
        setPoolsData({});
        setWpPools({});
        console.error(err);
      }
    );
  }, []);

  useEffect(() => {
    if (race && race?.raceStatus === RESULTED) {
      extractResults(race);
      getPoolDetails(race);
    }
  }, [extractResults, getPoolDetails, race]);

  // Table header template for pool names.
  const PoolHeader = (pool: string) => {
    if (poolsData[pool]?.poolStatus === NON_DISPLAY) return null;
    return (
      <Th key={pool}>
        {tGeneral(getPoolName(pool, race?.raceKey.province ?? ""))}
      </Th>
    );
  };

  const RunnerTemplate = ({ result }: { result: IResultDTO }) => {
    if (!runners) return null;
    const runner = runners.find((x) => x.raceTripKey.selId === result.selId);
    if (!runner) return null;
    return (
      <span className={styles.runnerInfoWrapper}>
        <TripInfo raceKey={race?.raceKey} trip={runner} />
      </span>
    );
  };

  if (race?.raceStatus !== RESULTED) return null;

  return (
    <div className={styles.resultsComponentWrapper}>
      <RaceTipsInline />
      <div>
        <table className={styles.resultsTable}>
          <thead>
            <tr>
              <Th>{tGeneral("runners")}</Th>
              {Object.keys(wpPools).map((pool) => {
                if (
                  poolsData[pool]?.poolStatus !== NON_DISPLAY &&
                  poolsData[pool]?.payoff
                )
                  return PoolHeader(pool);
                return null;
              })}
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => {
              return (
                <Tr key={index}>
                  <td>
                    <div className={styles.runner}>
                      <Trophy>
                        <FaTrophy
                          color="gold"
                          stroke="black"
                          strokeWidth="2"
                          size="1.25rem"
                          style={{
                            textShadow: "0 0 6px black",
                          }}
                        />
                        <span>
                          {DigitToWords[result.position] ??
                            result.position + "th"}
                        </span>
                      </Trophy>
                      <RunnerTemplate result={result} />
                    </div>
                  </td>
                  {Object.values(wpPools).map((_pool) => {
                    if (
                      _pool &&
                      _pool.poolStatus !== NON_DISPLAY &&
                      _pool.payoff
                    )
                      return (
                        <td key={_pool.poolKey.poolType}>
                          {_pool.payoff
                            ? _pool.payoff[result.selId]?.toFixed(2)
                            : "-"}
                        </td>
                      );
                    return null;
                  })}
                </Tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ExoticsPayOff race={race} poolsData={poolsData} />
    </div>
  );
};

export default ResultedRaceComponent;

// * Styled components
const Trophy = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  flex-direction: column;
  border-radius: var(--radius-default);
  background: ${(p) => p.theme.bodyContrastLight};
  color: gold;
`;

const Th = styled.th`
  font-size: 0.7rem;
  text-align: left;
  border: ${(p) => p.theme.borderPrimary};
  padding: 0.25rem;
  /* background: ${(p) => p.theme.bodySecondary}; */
  &:not(:first-child) {
    width: 5rem;
    text-align: center;
  }
`;

const Tr = styled.tr`
  td {
    padding: 0.25rem;
    border: ${(p) => p.theme.borderPrimary};
    &:not(:first-child) {
      text-align: center;
    }
  }
  &:nth-child(odd) {
    td {
      background: ${(p) => p.theme.rowOdd};
    }
  }
  &:nth-child(even) {
    td {
      background: ${(p) => p.theme.rowEven};
    }
  }
`;
