import { Link } from "react-router-dom";
import { ROUTES } from "../../common/routes/default.routes";

const DemoPage = () => {
  return (
    <div>
      {/* <LandscapeAd/> */}
      <h1>
        Demo Page
        <Link to={ROUTES.HELP}>Help Center</Link>
      </h1>
    </div>
  );
};

export default DemoPage;
