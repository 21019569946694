import { atom } from "recoil";
import { OddsType } from "../../common/constants/pools.constant";
import { WagersDTO } from "../../common/models/transaction.model";
import { LazyLoadParams } from "../../common/types/general.type";

// * State to store selected pool from pool names
export const AtomTransactionBetOption = atom<string>({
  key: "AtomTransactionSelectedPool",
  default: OddsType.FIXED,
});

// * State to store transaction selected dates.
export const AtomTransactionDates = atom<Date[]>({
  key: "atomTransactionDates",
  default: [new Date()],
});

// * State to store lazy load parameters for transaction.
export const AtomTransLazyParams = atom<LazyLoadParams>({
  key: "atomTransLazyParams",
  default: {
    first: 0,
    rows: 15,
    page: 1,
    totalRecords: 0,
  },
});

// * State to store transactions data
export const AtomWagerTransactions = atom<WagersDTO[] | undefined | null>({
  key: "AtomWagerTransactions",
  default: undefined,
});
