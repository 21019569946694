export const POOLS = {
  WIN: "WIN",
  SHOW: "SHOW",
  PLACE: "PLACE",
  FO_WIN: "FO_WIN",
  FO_PLACE: "FO_PLACE",
  WIN_PLACE: "WIN_PLACE",
  QUINELLA: "QUINELLA",
  SWINGER: "SWINGER",
  DOUBLE: "DOUBLE",
  PICK_3: "PICK_3",
  EXACTA: "EXACTA",
  PICK_4: "PICK_4",
  TRIFECTA: "TRIFECTA",
  SUPERFECTA: "SUPERFECTA",
};

export const SINGLE_POOLS = ["FO_WIN", "FO_PLACE", "WIN", "PLACE"];

export const POOL_SELECTION_NUM: any = {
  QUINELLA: 2,
  SWINGER: 2,
  EXACTA: 2,
  TRIFECTA: 3,
  SUPERFECTA: 4,
};




export enum OddsType {
  FIXED = "FIXED",
  TOTE = "TOTE",
}
export const OddsTypes = ["FIXED", "TOTE"];
