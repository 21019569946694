import styles from "./avatar.module.scss";
import avatar from "../../../assets/images/avatar.png";

type Props = {
  photoUrl?: string | null;
  className?: string;
};

const Avatar = ({ photoUrl, className }: Props) => {
  const getPhoto = () => {
    // if (!photoUrl) return null;
    // if (photoUrl === "") return null;
    // return photoUrl;
    // const url = Auth.currentUser?.photoURL ?? null;
    if(photoUrl === "") return null;
    const Img = photoUrl !== null ? photoUrl : null;
    return Img;
  };

  return (
    <span className={`${className} ${styles.avatar}`}>
      <img src={getPhoto() ?? avatar} alt="" referrerPolicy="no-referrer" />
    </span>
  );
};

export default Avatar;
