import styles from "./newBetSlipComponent.module.scss";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { AtomShowBetSlip } from "../../../store/bets/bets.store";
import Card from "../../ui/card/card.component";
import BetTab from "../betSlipComponents/betTab/betTab.component";
import BetSlipBody from "../betSlipComponents/betTabBody/betSlip.body";
import OpenBetsBody from "../betSlipComponents/betTabBody/openBets.body";
import { useEffect, useRef } from "react";
import { PreventOutSideClasses } from "../../../common/constants/general.constant";
const NewBetSlipComponent = () => {
  const [show, setShow] = useRecoilState(AtomShowBetSlip);
  const dropRef = useRef<HTMLInputElement>(null);

  // Callbacks
  useEffect(() => {
    // Hide the target dropdown.
    const onOutsideClick = (e: any) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        if (typeof e.target.className === "string") {
          if (
            e.target.className.includes(PreventOutSideClasses.betSlipToggle)
          ) {
            !show && setShow(true);
            return;
          }
          setShow(false);
        }
      }
    };

    window.addEventListener("click", onOutsideClick, true);
    return () => {
      window.removeEventListener("click", onOutsideClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShow]);

  return (
    <div
      ref={dropRef}
      className={`${styles.betSlipWrapper} ${show ? styles.show : ""}`}
    >
      <BetWrapper
        className={`${styles.betSlipCard} ${show ? styles.show : ""}`}
      >
        <div className={styles.stickyTab}>
          <BetTab />
        </div>
        <div className={`${styles.hiddenBody} ${show ? styles.show : ""}`}>
          <div className={styles.slipBody}>
            <BetSlipBody />
            <OpenBetsBody />
          </div>
        </div>
      </BetWrapper>
    </div>
  );
};

export default NewBetSlipComponent;

// * Styled components
const BetWrapper = styled(Card)`
  background: ${(p) => p.theme.bgBetSlip};
`;
