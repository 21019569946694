import { CSSProperties } from "react";
import { IconType } from "react-icons";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { AtomSelectedThemeData } from "../../../store/theme/theme.store";
import styles from "./_notifications.module.scss";

type Props = {
  className?: string;
  number: number;
  style?: CSSProperties;
  icon: IconType;
  onClick?: () => void;
  size?: number;
  isActive?: boolean;
};

const DotNotification = (props: Props) => {
  const theme = useRecoilValue(AtomSelectedThemeData);

  const style: CSSProperties = {
    background: props.isActive ? theme?.bodyContrast : theme?.active,
    color: theme?.textContrast,
  };

  return (
    <button
      onClick={props.onClick && props.onClick}
      className={`${props.className} ${styles.notificationWrapper}`}
    >
      <props.icon size={props.size ?? 35} />
      {props.number > 0 && (
        <DotWrapper style={style} className={`${styles.dotWrapper}`}>
          {props.number}
        </DotWrapper>
      )}
    </button>
  );
};

export default DotNotification;

const DotWrapper = styled.span`
  background: ${(p) => p.theme.bodyContrast};
  color: ${(p) => p.theme.activeText};
`;
