import { FaCheck } from "react-icons/fa";
import SelectionBox from "../../components/shared/selectionBox/selectionBox.component";

interface ISelectionBox {
  className?: string;
  value?: boolean;
  disabled?: boolean;
  first?: boolean;
  second?: boolean;
  third?: boolean;
  fourth?: boolean;
  isThree?: boolean;
  isFour?: boolean;
  onPosition?: (bool: boolean, ...args: any) => void;
}
// Checkbox for boxed on
export const Boxed = (props: ISelectionBox) => {
  return (
    <SelectionBox
      value={props.value}
      disabled={props.disabled}
      onChange={(e) => props.onPosition && props.onPosition(e, "boxed")}
    >
      {props.value ? <FaCheck /> : null}
    </SelectionBox>
  );
};

export const NormalBoxes = (props: ISelectionBox) => {
  return (
    <>
      <SelectionBox
        disabled={props.disabled}
        value={props.first}
        onChange={(e) => props.onPosition && props.onPosition(e, "first")}
      >
        1
      </SelectionBox>

      <SelectionBox
        disabled={props.disabled}
        value={props.second}
        onChange={(e) => props.onPosition && props.onPosition(e, "second")}
      >
        2
      </SelectionBox>

      {(props.isThree || props.isFour) && (
        <SelectionBox
          disabled={props.disabled}
          value={props.third}
          onChange={(e) => props.onPosition && props.onPosition(e, "third")}
        >
          3
        </SelectionBox>
      )}
      {props.isFour && (
        <SelectionBox
          disabled={props.disabled}
          value={props.fourth}
          onChange={(e) => props.onPosition && props.onPosition(e, "fourth")}
        >
          4
        </SelectionBox>
      )}
    </>
  );
};

// Checkboxes for Quinella
export const QuinellaBoxes = (props: ISelectionBox) => {
  return <NormalBoxes {...props} />;
};

// Checkboxes for Swinger
export const SwingerBoxes = (props: ISelectionBox) => {
  return <NormalBoxes {...props} />;
};

// Checkboxes for Exacta
export const ExactaBoxes = (props: ISelectionBox) => {
  return <NormalBoxes {...props} />;
};

// Checkboxes for Trifecta
export const TrifectaBoxes = (props: ISelectionBox) => {
  return <NormalBoxes {...props} isThree />;
};

// Checkboxes for Superfecta
export const SuperfectaBoxes = (props: ISelectionBox) => {
  return <NormalBoxes {...props} isFour />;
};
