import styles from "./_layouts.module.scss";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate } from "react-router";
import PolicyFooter from "../components/shared/footer/policy.footer";
import PzoneLogo from "../components/shared/pzoneLogo/pzoneLogo.component";
import { useEffect } from "react";
import { Auth } from "../common/configs/firebase.config";
import { ROUTES } from "../common/routes/default.routes";

type Props = {};

const AuthLayout = (props: Props) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;

  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged((user) => {
      if (user?.uid && path !== ROUTES.CURRENCY) {
        navigate(ROUTES.ALL_RACES);
      }
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.authWrp}>
        <div className={styles.leftWrp}></div>
        <RightWrapper>
          <PzoneLogo />
          <Outlet />
        </RightWrapper>
      </div>
      <PolicyFooter fixed />
    </>
  );
};

export default AuthLayout;

// * Styled components

const RightWrapper = styled.div`
  background: ${(p) => p.theme.body};
  color: ${(p) => p.theme.text};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  position: relative;
  @media screen and (max-width: 991px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
