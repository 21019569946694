import styles from "./tripSelectors.module.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AtomLiveOdds } from "../../../../../store/races/selectedRace.store";
import styled from "styled-components";
import {
  AtomBetCards,
  AtomReRender,
} from "../../../../../store/bets/bets.store";
import { toWinPlaceBetKeyStr } from "../../../../../common/helpers/value.helper";
import useSelected from "../../../../../hooks/useSelectedPool";
import useBetCard from "../../../../../hooks/useBetCard";
import { RaceTripDTO } from "../../../../../common/models/raceTrip.model";
import { _setStorage } from "../../../../../common/helpers/storage.helper";
import {
  CLOSED,
  LS_KEYS,
  PreventOutSideClasses,
} from "../../../../../common/constants/general.constant";
import {
  AtomFavoriteSelections,
  AtomPoolColsForOdds,
} from "../../../../../store/pools/pools.store";
import { FaStar } from "react-icons/fa";

type Props = {
  trip: RaceTripDTO;
};

const WinPlaceSelector = ({ trip }: Props) => {
  const { selId } = trip.raceTripKey;
  const { race, pool } = useSelected();
  const { generateWinPlaceBet } = useBetCard();
  const liveOdds = useRecoilValue(AtomLiveOdds);
  const [betCards, setBetCards] = useRecoilState(AtomBetCards);
  const poolCols = useRecoilValue(AtomPoolColsForOdds);
  const setReRender = useSetRecoilState(AtomReRender); // To re-render betSlip to update reference while other bets are in processing.
  const favorites = useRecoilValue(AtomFavoriteSelections);

  const onOddsClick = (p: string) => {
    // if (!isValidClie nt(client)) return; // TODO Validate client
    if (!race?.raceKeyString || !pool) return;
    const betKey = toWinPlaceBetKeyStr(race.raceKeyString, p, selId);
    let _betCards = { ...betCards };
    if (betKey in betCards && !betCards[betKey].processing) {
      delete _betCards[betKey];
      setBetCards(_betCards);
      _setStorage(LS_KEYS.bets, _betCards);
      setReRender(new Date());
      // stopPropagation();
      return;
    }
    const _bet = generateWinPlaceBet(p, trip);
    if (_bet) {
      _betCards = {
        [_bet.betKey]: { bet: _bet, processing: false },
        ...betCards,
      };
    }
    _setStorage(LS_KEYS.bets, _betCards);
    setBetCards(_betCards);
    setReRender(new Date());
  };

  // * Template for pool odds
  const getOddsValue = (p: string) => {
    if (!liveOdds) return "-";
    if (liveOdds && liveOdds[p] && liveOdds[p][selId])
      return liveOdds[p][selId];
    return "-";
  };

  return (
    <>
      {poolCols.map((p) => {
        if (!liveOdds || !liveOdds[p]) return <div key={p}></div>;
        const betKey = toWinPlaceBetKeyStr(race?.raceKeyString ?? "", p, selId);

        return (
          <OddsButton
            onClick={(e) => {
              e.stopPropagation();
              onOddsClick(p);
            }}
            className={`${styles.oddsBtn} ${
              betKey in betCards ? "active" : ""
            } ${PreventOutSideClasses.betSlipToggle}`}
            disabled={
              race?.raceStatus === CLOSED ||
              trip.scratched ||
              (betCards[betKey] && betCards[betKey].processing)
            }
            key={p}
          >
            {favorites[p ?? ""] === selId && (
              <span className={styles.fav}>
                <FaStar size={9} />
              </span>
            )}
            {trip.scratched ? <SCR>SCR</SCR> : getOddsValue(p)}
          </OddsButton>
        );
      })}
    </>
  );
};

export default WinPlaceSelector;

// * Styled Components
const SCR = styled.span`
  color: ${(p) => p.theme.textDull};
`;
const OddsButton = styled.button`
  background: ${(p) => p.theme.bodyTernary};
  color: ${(p) => p.theme.textContrast};
  border: 2px solid transparent;
  &:disabled {
    color: gray;
    cursor: auto;
  }
  &:not(:disabled):hover {
    border: ${(p) => p.theme.borderHighlight};
  }
  &.active {
    background: ${(p) => p.theme.active};
  }
`;
