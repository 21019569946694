/* eslint-disable no-useless-escape */
import { Dialog } from "primereact/dialog";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { WagersDTO } from "../../../../common/models/transaction.model";
import { ColumnsDTO } from "../../../../common/types/general.type";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import styles from "./transactionDialog.module.scss";

export interface TransactionProps {
  cols: ColumnsDTO[];
  data: WagersDTO;
  header: string;
  hideDialog: () => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const TransactionDialog: FC<TransactionProps> = ({
  data,
  header,
  hideDialog,
  setIsOpen,
  isOpen,
}) => {
  //   const [isOpen, setIsOpen] = useState(false);
  const [selections, setSelections] = useState<string[]>([]);
  const tTracks = useTranslation(LKeys.tracks);
  const tGenerals = useTranslation(LKeys.generals);
  const { province, eventType, raceNo, trackName } = data.raceKey;
  const onHide = () => {
    setIsOpen(false);
    setTimeout(() => {
      hideDialog();
    }, 300);
  };
  const Details = (label: string, value: any) => {
    return (
      <div className={styles.detailsWrapper}>
        <label>{label}</label>
        <Value>{value}</Value>
      </div>
    );
  };
  useEffect(() => {
    const combinations = data?.combinations ?? {};
    setSelections(combinations);
  }, [data]);

  return (
    <Dialog
      onHide={onHide}
      visible={isOpen}
      draggable={false}
      style={{ width: "40vw" }}
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      header={header}
      headerClassName={"dialogHeader"}
      contentClassName={"dialogContent"}
      dismissableMask
    >
      <div>
        <div className={styles.headerWrapper}>
          <h4 className={styles.raceInfo}>
            <span className={`flag-32 flag-${province.toLowerCase()}`}></span>
            <EventType className={styles.trackInfo}>
              {tTracks(trackName)} - R{raceNo}
              <span>{tGenerals(eventType)}</span>
            </EventType>
          </h4>
          <div className={styles.tidWrapper} title="Transaction ID">
            {tGenerals("t_id")}: {data.transactionId}
          </div>
        </div>
        <div className={styles.bodyWrapper}>
          <InfoTab className={styles.infoTab}>
            <div>
              {Details(tGenerals("time"), data.formattedTime)}
              {Details(
                tGenerals("pool_type"),
                // PoolMap[data.poolType] ?? data.poolType
                tGenerals(data.poolType)
              )}
              {Details(tGenerals("bet_status"), tGenerals(data.betStatus))}
            </div>
            <div
              className={styles.detailsWrapper}
              style={{ alignItems: "flex-end" }}
            >
              <label>{tGenerals("pnl")}</label>
              <span
                className={`${
                  data.pnl >= 0 ? styles.positive : styles.negative
                }`}
              >
                {data.pnl?.toFixed(2)}
              </span>
            </div>
          </InfoTab>

          <div className={styles.invDetails}>
            <div className={styles.detailsWrapper}>
              <label>{tGenerals("combinations")}</label>
              <span>
                {selections?.map((sel, index) => {
                  return (
                    <div className={styles.selsWrapper} key={index}>
                      <Value className={styles.index}>{index + 1}: </Value>
                      <Value className={styles.sels}>
                        {JSON.stringify(sel).replace(/[\[\]"']+/g, " ")}
                      </Value>
                    </div>
                  );
                })}
              </span>
            </div>
            {Details(tGenerals("investments"), data.totalInvested?.toFixed(2))}
            {/* {Details(translate("ticket"), ticket)} */}
            {Details(tGenerals("selection_amount"), data.selectionAmt)}
          </div>

          <div className={styles.winningsInfo}>
            <label>{tGenerals("total_winnings")}: </label>{" "}
            <span
              className={`${
                data.totalWinnings > 0 ? styles.positive : styles.negative
              }`}
            >
              {" "}
              {data.totalWinnings?.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TransactionDialog;

const EventType = styled.span`
  color: ${(p) => p.theme.textContrast};
`;

const Value = styled.span`
  color: ${(p) => p.theme.textSecondary};
`;

const InfoTab = styled.div`
  background-color: ${(p) => p.theme.bodyContrastDark};
`;
