import { POOLS } from "../constants/pools.constant";
import { __LiveOddsDTO, __PoolTypeDTO } from "../types/dynamic.type";

// * returns if pool is of type FIXED ODDS
export const isFOPool = (pool?: string | null): boolean | null => {
  if (!pool) return null;
  return pool.startsWith("FO_") ? true : false;
};

//  * Returns true if pool is of type exotics such as exacta, trifecta, etc.
export const isExotics = (pool?: string | null): boolean | null => {
  if (!pool) return null;
  // returns false if did not math below string
  return pool?.match(
    /^(WIN|PLACE|FO_WIN|FO_PLACE|DOUBLE|PICK_3|RUNNING_DOUBLE|PICK_4|WIN_PLACE|WIN \/ PLACE)$/
  )
    ? false
    : true;
};

export const isMulti = (pool: string) => {
  return pool.match(/^DOUBLE|RUNNING_DOUBLE|PICK_3|PICK_4/) ? true : false;
};

// * Get live odds column for table
export const getPoolOddsCols = (liveOdds: __LiveOddsDTO) => {
  let _cols: string[] = [];
  for (const pool in liveOdds) {
    let index = 0;
    switch (pool) {
      case POOLS.FO_PLACE:
        index = 1;
        break;
      case POOLS.WIN:
        index = 2;
        break;
      case POOLS.PLACE:
        index = 3;
        break;
    }
    _cols[index] = pool;
  }
  return _cols;
};

// * Sort pools for resulted component
export const sortWPPoolsObj = (poolData: __PoolTypeDTO) => {
  let sortedObj = Object.entries(poolData).sort((a, b) => {
    if (a[0] === "WIN") return -1;
    if (a[0] === "PLACE") return 1;
    return 1;
  });

  return Object.fromEntries(sortedObj);
};

// * Sorting all pools except win place show
export const sortExoticsObj = (poolsData: __PoolTypeDTO) => {
  let sortedList = [];

  for (const _poolType in poolsData) {
    let _pool = poolsData[_poolType];
    let _exoticData = {
      poolType: _poolType,
      winners: Object.keys(_pool.payoff ?? {}),
      payoffs: Object.values(_pool.payoff ?? {}),
    };
    switch (_poolType) {
      case POOLS.QUINELLA:
        sortedList[0] = _exoticData;
        break;
      case POOLS.SWINGER:
        sortedList[1] = _exoticData;
        break;
      case POOLS.EXACTA:
        sortedList[2] = _exoticData;
        break;
      case POOLS.TRIFECTA:
        sortedList[3] = _exoticData;
        break;
      case POOLS.SUPERFECTA:
        sortedList[4] = _exoticData;
        break;
      case POOLS.DOUBLE:
        sortedList[5] = _exoticData;
        break;
    }
  }
  return sortedList;
};

// *Pool name template for all UI
export const getPoolName = (poolType: string, province: string) => {
  if (CountryPoolsMap[poolType] && CountryPoolsMap[poolType][province])
    return CountryPoolsMap[poolType][province];
  return poolType;
};

// * Country wise pool map
export const CountryPoolsMap: any = {
  "WIN / PLACE": {
    FR: "S. GAGNANT / S. PLACE",
  },
  WIN: {
    FR: "S. GAGNANT",
  },
  PLACE: {
    FR: "S. PLACE",
  },
  QUINELLA: {
    FR: "COUPLE GAGNANT",
  },
  SWINGER: {
    FR: "COUPLE PLACE",
  },
  TRIO: {
    FR: "TRIO",
  },
  EXACTA: {
    HK: "FORECAST",
    MY: "FORECAST",
    JP: "FORECAST",
    TH: "FORECAST",
    SG: "FORECAST",
    IN: "FORECAST",
    FR: "COUPLE ORDRE",
  },
  EXACT_4: {
    AU: "FIRST_4",
    HK: "QUARTET",
    MY: "QUARTET",
    JP: "QUARTET",
    TH: "QUARTET",
    IN: "QUARTET",
    SG: "QUARTET",
    US: "SUPERFECTA",
  },
  TRIFECTA: {
    HK: "TIERCE",
    MY: "TIERCE",
    JP: "TIERCE",
    TH: "TIERCE",
    IN: "TIERCE",
    SG: "TIERCE",
    FR: "TRIO ORDRE",
  },
  TIERCE: {
    FR: "TIERCE'",
  },
  QUARTE: {
    FR: "QUARTE'",
  },
  SUPERFECTA: {
    AU: "FIRST_4",
    HK: "QUARTET",
    MY: "QUARTET",
    JP: "QUARTET",
    TH: "QUARTET",
    IN: "QUARTET",
    SG: "QUARTET",
  },
  PICK_4: {
    AU: "QUADDIE",
  },
  DOUBLE: {
    AU: "RUNNING DOUBLE",
  },
  PICK_3: {
    AU: "TREBLE",
    HK: "TREBLE",
    MY: "TREBLE",
    JP: "TREBLE",
    TH: "TREBLE",
    SG: "TREBLE",
    IN: "TREBLE",
  },
  PICK_6: {
    AU: "Super 6",
  },
  EXACT_5: {
    US: "PENTAFECTA",
  },
  PENTAFECTA: {
    FR: "EXACT_5",
  },
};
