import { DateTimeFormatter } from "@js-joda/core";

export const DATE_FORMATS = {
  yyyyMMddHHmmss: "yyyyMMddHHmmss",
  HHmm_colon: "HH:mm",
  yyyyMMdd: "yyyyMMdd",
  ddMyyyy_HHmm_Space: "dd-M-yyyy HH:mm",
  ddMMyyyy_hhmmss_DASH: "dd-MM-yyyy HH:mm:ss", // for joda format
};

export const JODA_FORMATTER = {
  yyyyMMddHHmmss: DateTimeFormatter.ofPattern(DATE_FORMATS.yyyyMMddHHmmss),
  HHmm_colon: DateTimeFormatter.ofPattern(DATE_FORMATS.HHmm_colon),
  yyyyMMdd: DateTimeFormatter.ofPattern(DATE_FORMATS.yyyyMMdd),
};
