import { Link } from "react-router-dom";
import styled from "styled-components";
import { RaceSnapDTO } from "../../../common/models/race.mode";
import { ROUTES } from "../../../common/routes/default.routes";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import styles from "./upcRaces.module.scss";
import RaceStatus from "../../shared/raceStatus/raceStatus.component";
import Tooltips from "../../ui/tooltip/tooltip.ui";

type Props = {
  race: RaceSnapDTO;
};

const UpcRaceCard = ({ race }: Props) => {
  const tTracks = useTranslation(LKeys.tracks);
  const tProvinces = useTranslation(LKeys.countries);
  const { eventType, raceNo, trackName, province, localDate } = race.raceKey;

  return (
    <RaceCard
      to={`${ROUTES.RACES}/${localDate}/${province}/${trackName}/${eventType}/${raceNo}`}
      className={styles.raceCardWrapper}
    >
      <div className={`${styles.raceCard} raceCard`}>
        <div className={styles.col}>
          <div className={`et48 ${eventType}-active`}></div>
        </div>
        <div className={styles.trackInfo}>
          <Tooltips
            info={tTracks(trackName)}
            trackName={tTracks(trackName)}
            maxWidth={"11rem"}
          />{" "}
          {/* <span className={styles.trackName}>{tTracks(trackName)}</span> */}
          <div className={styles.provinceWrapper}>
            <span className={`flag-16 flag-${province.toLowerCase()}`}></span>
            <span>{tProvinces(province)}</span>
          </div>
        </div>
        <div className={styles.col}>
          <span className={styles.raceNo}>R{raceNo}</span>
          <RaceStatus status={race.rs} mtp={race.mtp} isCanceled={race.cl} />
        </div>
      </div>
    </RaceCard>
  );
};

export default UpcRaceCard;

// * Styled components
const RaceCard = styled(Link)`
  background: ${(p) => p.theme.bodyContrastLight};
  color: ${(p) => p.theme.textContrast};
  border: 2px solid transparent;

  &:hover {
    border: ${(p) => p.theme.borderHighlight};
    background: ${(p) => p.theme.bodyContrast};
    /* .raceCard {
      transform: scale(1.05);
    } */
  }
`;
