import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import LandscapeAd from "../../components/Ads/landscape.ad";
import UpcRaceCards from "../../components/pages/upcRaces/upcRaceCards.component";
import { AtomUpcRaces } from "../../store/races/races.store";

const THRacingPage = () => {
  const upcRaces = useRecoilValue(AtomUpcRaces);

  return (
    <>
      <Helmet>
        <title>Thoroughbred Races - Ashva Racing</title>
      </Helmet>
      <div>
        <LandscapeAd />
        <UpcRaceCards races={upcRaces?.TH} title={"TH"} />
      </div>
    </>
  );
};

export default THRacingPage;
