import { ReactNode, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ThemeProvider } from "styled-components";
import ShimmerLoader from "../../components/shared/loaders/shimmerLoader.loader";
import { AtomSelectedTheme, AtomSelectedThemeData } from "./theme.store";
import { ThemeData } from "../../common/data/theme.data";

interface ThemeWrapperProps {
  children: ReactNode;
}

const ThemeWrapper = ({ children }: ThemeWrapperProps) => {
  const [theme, setTheme] = useRecoilState(AtomSelectedThemeData);
  const selectTheme = useRecoilValue(AtomSelectedTheme);

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(selectTheme ?? "lightBlue"));
    setTheme(ThemeData[selectTheme] ?? ThemeData["lightBlue"]);
  }, [setTheme, selectTheme]);

  if (!theme) return <ShimmerLoader />;
  return <ThemeProvider theme={theme} data-theme={selectTheme ?? "lightBlue"}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
