import {
  ZoneId,
  LocalDateTime,
  DateTimeFormatter,
  Duration,
  ZonedDateTime,
  LocalDate,
} from "@js-joda/core";
import { format } from "date-fns";
import { DATE_FORMATS, JODA_FORMATTER } from "../constants/dateTime.constant";

// * format date object to string.
export const dateToString = (d: Date, _format?: string) => {
  return format(d, _format ?? DATE_FORMATS.yyyyMMdd);
};

// * format date time according to time zone.
export const formatZonedDateTime = (
  pt: string,
  tz: string,
  _format: string = DATE_FORMATS.yyyyMMddHHmmss
) => {
  if (!pt || !tz) return "";
  let zoneId = ZoneId.of(tz);
  let pTime = LocalDateTime.parse(pt, JODA_FORMATTER.yyyyMMddHHmmss)
    .atZone(zoneId)
    .withZoneSameInstant(ZoneId.systemDefault())
    .toLocalDateTime()
    .format(DateTimeFormatter.ofPattern(_format));
  return pTime;
};

// * Method to handle timer for race mtp
export const getTimeObj = (mtp?: string) => {
  if (!mtp) return null;
  const localDate = ZonedDateTime.of(
    LocalDateTime.parse(mtp, JODA_FORMATTER.yyyyMMddHHmmss),
    ZoneId.SYSTEM
  );
  const duration = Duration.between(ZonedDateTime.now(), localDate);
  let hour = duration.toHours();
  let minute = duration.toMinutes() % 60;
  let second = duration.seconds() % 60;
  let timeObj = {} as any;
  if (hour > 0) {
    timeObj.isNormal = true;
    timeObj.timeStr = hour + "h";
  } else if (minute > 0) {
    timeObj.isNormal = true;
    timeObj.timeStr = minute + "m";
    if (minute < 3) {
      timeObj.isNearToJump = true;
      timeObj.isNormal = false;
      timeObj.timeStr = minute + "m:" + second + "s";
    }
  } else if (second > 0) {
    timeObj.isNearToJump = true;
    timeObj.isNormal = false;
    timeObj.timeStr = second + "s";
  } else {
    timeObj.isNegative = true;
    timeObj.isNormal = false;
    timeObj.isNearToJump = false;

    hour = Math.abs(hour);
    minute = Math.abs(minute);
    second = Math.abs(second);
    if (hour > 0) timeObj.timeStr = "-" + hour + "h";
    else if (minute > 0) timeObj.timeStr = "-" + minute + "m";
    else if (second > 0) timeObj.timeStr = "-" + second + "s";
    else timeObj.timeStr = second + "s";
  }
  return timeObj;
};

export const numberToDate = (date: string) => {
  if (date)
    return dateToString(
      new Date(LocalDate.parse(date, JODA_FORMATTER.yyyyMMdd).toString()),
      "dd-MM-yyyy"
    );
  return "-";
};

// * Temp Zoned Date Time Conversion.
export const formatTransactionTime = (
  pt: string,
  tz: string,
  format: string
) => {
  if (!pt || !tz) return "";
  let parsedDate = LocalDateTime.parse(pt, JODA_FORMATTER.yyyyMMddHHmmss);
  let formatted = parsedDate.format(DateTimeFormatter.ofPattern(format));
  return formatted;
};

export const getPieTime = (mtp?: string) => {
  if (!mtp) return null;
  const localDate = ZonedDateTime.of(
    LocalDateTime.parse(mtp, JODA_FORMATTER.yyyyMMddHHmmss),
    ZoneId.SYSTEM
  );

  const duration = Duration.between(ZonedDateTime.now(), localDate);
  let hour = duration.toHours();
  let minute = duration.toMinutes() % 60;
  let second = duration.seconds() % 60;
  let _second = duration.seconds();
  let timeObj = { _second } as any;

  if (hour > 0) {
    timeObj.isNormal = true;
    timeObj.timeStr = hour + "h";
  } else if (minute > 0) {
    timeObj.isNormal = true;
    timeObj.timeStr = minute + "m";
    if (minute < 1) {
      timeObj.isNearToJump = true;
      timeObj.isNormal = false;
      timeObj.timeStr = minute + "m:" + second + "s";
    }
  } else if (second > 0) {
    timeObj.isNearToJump = true;
    timeObj.isNormal = false;
    timeObj.timeStr = second + "s";
  } else {
    timeObj.isNegative = true;
    timeObj.isNormal = false;
    timeObj.isNearToJump = false;

    hour = Math.abs(hour);
    minute = Math.abs(minute);
    second = Math.abs(second);
    if (hour > 0) timeObj.timeStr = "-" + hour + "h";
    else if (minute > 0) timeObj.timeStr = "-" + minute + "m";
    else if (second > 0) timeObj.timeStr = "-" + second + "s";
    else timeObj.timeStr = second + "s";
  }

  return timeObj;
};

export const formatDateString = (date: string | number, format?: string) => {
  if (!date || date === "-") return "-";
  const _formattedDate = LocalDate.parse(
    date?.toString(),
    JODA_FORMATTER.yyyyMMdd
  ).format(
    DateTimeFormatter.ofPattern(format ?? DATE_FORMATS.ddMMyyyy_hhmmss_DASH)
  );
  return _formattedDate;
};
