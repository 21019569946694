import { CLOSED, NON_DISPLAY, RESULTED } from "../constants/general.constant";
import { RaceDetailsDTO, RaceSnapDTO } from "../models/race.mode";
import { RaceTripDTO } from "../models/raceTrip.model";

// * Validate race snap to be displayed
export const isValidRaceSnap = (_race: RaceSnapDTO) => {
  if (
    _race.cl ||
    !_race.pt ||
    !_race.t ||
    !_race.rk ||
    _race.rs === NON_DISPLAY ||
    _race.rs === RESULTED
  )
    return false;
  return true;
};

// * Returns null for invalids value comes from backend.
export const getValidValue = (data: any) => {
  let _data = data?.toString()?.toLowerCase() ?? "";
  switch (_data) {
    case "98765.56789":
      return null;
    case "unknown":
      return null;
    default:
      return _data.replace(/_/, " ");
  }
};

// * Returns true if runners is scratched or race is closed, cancelled or resulted
export const isDisableSelection = (
  trip: RaceTripDTO,
  race?: RaceDetailsDTO | null
) => {
  return trip?.scratched || race?.cancelled || race?.raceStatus === CLOSED;
};

// * Generate unique id for bets.
export const getUniqueID = (str: string) => {
  let firstPart = ((Math.random() * 46656) | 0).toString(36);
  let secondPart = ((Math.random() * 46656) | 0).toString(36);
  let thirdPart = ((Math.random() * 46656) | 0).toString(36);
  // let fourthPart = ((Math.random() * 46656) | 0).toString(36);
  return str + firstPart + secondPart + thirdPart;
};
