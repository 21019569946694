import { useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { CLOSED, LS_KEYS } from "../../../../common/constants/general.constant";
import { POOLS } from "../../../../common/constants/pools.constant";
import { getBetId } from "../../../../common/helpers/bet.helper";
import { getComboLength } from "../../../../common/helpers/combinations.helper";
import { _setStorage } from "../../../../common/helpers/storage.helper";
import { floatToInteger } from "../../../../common/helpers/value.helper";
import { BetRequestDTO } from "../../../../common/models/bet.model";
import { __PositionToRunners } from "../../../../common/types/dynamic.type";
import { ElementChangeEvent } from "../../../../common/types/general.type";
import useSelected from "../../../../hooks/useSelectedPool";
import { AtomAggregatorObject } from "../../../../store/auth/auth.store";
import {
  AtomBetAmounts,
  AtomBetCards,
  AtomReRender,
} from "../../../../store/bets/bets.store";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import { AtomPoolsData } from "../../../../store/races/selectedRace.store";
import {
  AtomHorseSelection,
  AtomRunnerPosition,
} from "../../../../store/selection/selection.store";
import BetAmtInput from "../../../elements/inputs/betAmount.input";
import ElevatedButton from "../../../ui/buttons/elevated.button";
// import BetInput from "../../betSlipComponents/betTabBody/betCards/betInput/bet.input";
import styles from "./_raceFooters.module.scss";

const ExoticsFooter = () => {
  const t = useTranslation(LKeys.generals);
  const { pool, isBoxed, race } = useSelected();
  const selections = useRecoilValue(AtomHorseSelection);
  const resetSelections = useResetRecoilState(AtomHorseSelection);
  const allPools = useRecoilValue(AtomPoolsData);
  const [, setRoundedAmount] = useState<number | null>(null);
  const [, setStatus] = useState<any>({});
  const [betCards, setBetCards] = useRecoilState(AtomBetCards);
  const [positions] = useRecoilState(AtomRunnerPosition);
  const aggregatorObj = useRecoilValue(AtomAggregatorObject);
  const [betAmount, setBetAmount] = useRecoilState(AtomBetAmounts);
  const reRender = useSetRecoilState(AtomReRender);

  const [inputs, setInputs] = useState<{
    flexPerc?: string;
    stake?: string;
    stakePerCombo?: string;
  }>({});
  const [, setDisabled] = useState(true);

  const getPositions = (posList: string[]) => {
    if (!pool) return {};
    let _positions = {} as __PositionToRunners;

    for (const pos of posList) {
      _positions[pos] = isBoxed ? positions[pool].boxed : positions[pool][pos];
    }
    return _positions;
  };

  const positionList = () => {
    let _posList = ["first", "second", "third", "fourth"];
    switch (pool) {
      case POOLS.TRIFECTA:
        return _posList.slice(0, 3);
      case POOLS.SUPERFECTA:
        return _posList;
      default:
        return _posList.slice(0, 2);
    }
  };

  const getSelections = (positions: __PositionToRunners) => {

    let _selections = [];
    for (const pos in positions) {
      const selection = positions[pos];

      _selections.push(selection);
    }
    let str = JSON.stringify(_selections).replace(/"/g, "");
    // TODO: remove static amount
    return { [str]: 1 };
  };

  // * Add exotics bet card to betSlip
  const addExoticsBet = () => {
    if (!race?.raceKey || !pool) return;
    let _comboLength = getComboLength(pool, selections[pool], isBoxed);

    if (_comboLength === 0) return;
    const _pool = allPools[pool];
    let _bet = {} as BetRequestDTO;
    let _betCards = { ...betCards };
    _bet.poolKey = _pool.poolKey;
    _bet.betId = getBetId(pool, betCards);
    _bet.betKey = _bet.betId;
    _bet.positions = getPositions(positionList());
    _bet.selections = getSelections(_bet.positions);
    _bet.minBetAmount = _pool?.minBetAmt ?? 0.01;
    if (aggregatorObj?.poolRates) {
      _bet.minBetAmount =
        _bet.minBetAmount * aggregatorObj.poolRates[_pool.currency] ?? 1;
    }

    let _betAmounts = { ...betAmount };

    _betAmounts[_bet.betId] = { ...inputs };

    setBetAmount(_betAmounts);
    _bet.combinations = _comboLength;
    // TODO: update static amount
    _bet.raceKey = race.raceKey;
    _betCards[_bet.betKey] = {
      bet: _bet,
      processing: false,
    };
    _setStorage(LS_KEYS.bets, _betCards);
    onClear();
    setBetCards(_betCards);
    reRender(new Date());

    // setReRender(new Date());
  };

  // * On Change of stack amount
  const handleStakeChange = ({ data }: ElementChangeEvent) => {
    if (!pool) return;
    let _comboLen = getComboLength(pool, selections[pool], isBoxed);
    const _pool = allPools[pool];
    let val = Number(data === "" ? 0 : data);
    let _minAmt = _pool?.minBetAmt ?? 0.01;
    // TODO : Init Object
    if (aggregatorObj?.poolRates) {
      _minAmt = _minAmt * aggregatorObj.poolRates[_pool.currency] ?? 1;
    }

    let actualFlex = val / _comboLen / _minAmt;
    let flexPercentage = floatToInteger(actualFlex);
    let stake = data;
    let _roundedStake = val;
    if (actualFlex !== flexPercentage) {
      _roundedStake = flexPercentage * _minAmt * _comboLen;
      setRoundedAmount(_roundedStake);
    } else setRoundedAmount(null);

    let stakePerCombo = (_roundedStake / _comboLen).toFixed(2);
    setStatus({});
    setInputs({
      stakePerCombo,
      flexPerc: flexPercentage.toString(),
      stake,
    });
    setDisabled(false);
  };

  // * On Change of flex percentage
  const handleFlexChange = ({ data }: ElementChangeEvent) => {
    if (!pool) return;
    let val = Number(data === "" ? 0 : data);
    let _comboLen = getComboLength(pool, selections[pool], isBoxed);
    let _minAmt = allPools[pool]?.minBetAmt ?? 0.01;
    let flexPercentage = floatToInteger(Number(val)).toString();
    let stake = (Number(flexPercentage) * _comboLen * _minAmt).toFixed(2);
    let stakePerCombo = (Number(stake) / _comboLen).toFixed(2);
    setStatus({});
    setInputs({ stakePerCombo, stake, flexPerc: flexPercentage });
    setDisabled(false);
  };

  // * On clear reset selections.
  const onClear = () => {
    resetSelections();
    setInputs({});
  };

  // * Template for bet inputs table.
  const InputTemplates = () => {
    if (!pool) return null;
    const _len = getComboLength(pool, selections[pool], isBoxed);
    // if (!_len) return <div></div>;
    return (
      <div className={styles.inputWrapper}>
        <BetAmtInput
          className="mr05"
          label={t("percentage")}
          suffix="%"
          value={inputs.flexPerc ?? ""}
          disabled={isDisabled || !_len}
          onChange={handleFlexChange}
        />

        <BetAmtInput
          className="mr05"
          label={t("amount")}
          suffix="AUD"
          value={inputs.stake ?? ""}
          disabled={isDisabled || !_len}
          onChange={handleStakeChange}
        />
      </div>
    );
  };

  const isDisabled = race?.raceStatus === CLOSED || race?.cancelled;
  return (
    <footer className={styles.footer}>
      {InputTemplates()}
      <div>
        <div></div>
        <div className={styles.footerButtons}>
          <ElevatedButton
            onClick={onClear}
            size="sm"
            severity="warning"
            label={t("clear")}
          />
          <ElevatedButton
            onClick={addExoticsBet}
            size="sm"
            severity="primary"
            label={t("addBet")}
          />
        </div>
      </div>
    </footer>
  );
};

export default ExoticsFooter;
