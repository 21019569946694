import styled from "styled-components";
import styles from "./betChips.module.scss";

interface IGoBetChipsProps {
  onChange: (amt: number) => void;
}
interface Chip {
  amount: number;
  isPreferred?: boolean;
}

const CHIPS: Chip[] = [
  { amount: 5, isPreferred: true },
  { amount: 10 },
  { amount: 50 },
  { amount: 100 },
  { amount: 500 },
  { amount: 2000 },
  { amount: 5000 },
];

const GoBetChips = (props: IGoBetChipsProps) => {
  //   const [modalToggle, setModalToggle] = useState(false);

  const ButtonLabel = (amount: number) => {
    if (amount >= 1000) return amount.toString()[0] + "K";
    if (amount >= 1000000) return amount.toString()[0] + "M";
    return amount;
  };

  const onChipChange = (chip: Chip) => {
    props.onChange(chip.amount);
  };

  return (
    <ChipWrapper className={styles.chipContainer}>
      {CHIPS.map((chip) => {
        return (
          <ChipButton
            key={chip.amount}
            className={`${styles.chip}`}
            onClick={() => onChipChange(chip)}
          >
            {ButtonLabel(chip.amount)}
          </ChipButton>
        );
      })}
    </ChipWrapper>
  );
};

export default GoBetChips;

// * Styled components
const ChipWrapper = styled.div`
  border-bottom: ${(p) => p.theme.borderContrast};
`;
const ChipButton = styled.button`
  background-color: ${(p) => p.theme.bodyContrast};
  width: 100%;
  border: none;
  padding: 0.15rem;
  color: ${(p) => p.theme.textSecondary};
  &:hover {
    background: ${(p) => p.theme.bodyContrastDark};
  }
  &:not(:last-child) {
    border-right: ${(p) => p.theme.borderContrast};
  }
`;
