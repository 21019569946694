import styled from "styled-components";
import styles from "./_loaders.module.scss";
import { HashLoader } from "react-spinners";
type Props = {
  isVisible?: boolean;
  loadingText?: string;
};

const InlineLoader = (props: Props) => {
  if (props.isVisible === false) return null;
  return (
    <Loader className={styles.inlineLoaderWrapper}>
      <HashLoader color="#ffca44" size={32} />
      {props.loadingText && <Message>{props.loadingText}</Message>}
    </Loader>
  );
};

export default InlineLoader;

const Message = styled.span`
  display: inline-block;
  padding-left: 0.5rem;
  font-weight: 600;
  text-shadow: ${(p) => p.theme.shadowDefault};
`;

const Loader = styled.div`
  /* background: ${(p) => p.theme.loaderBackdrop};#12345644 */
  background: #2e567e6f;
  color: ${(p) => p.theme.warning};
`;
