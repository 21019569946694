import { ReactNode } from "react";
import styles from "./title.module.scss";
import styled from "styled-components";

type Props = {
  children?: ReactNode;
  noUnderLine?: boolean;
  type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "default";
  endTemplate?: any;
};

const Title = (props: Props) => {
  return (
    <TitleParentWrapper>
      <TitleWrapper
        className={`${!props.noUnderLine && "underline"} ${
          styles.childWrapper
        }`}
      >
        {props.children}
      </TitleWrapper>
      {props.endTemplate && props.endTemplate()}
    </TitleParentWrapper>
  );
};

export default Title;

// * Styled components
const TitleWrapper = styled.div`
  position: relative;

  font-weight: 600;
  display: inline-flex;
  align-items: center;

  &.underline {
    &::after {
      border-radius: 2rem;
      content: "";
      position: absolute;
      bottom: -2px;
      height: 3px;
      background: ${(p) => p.theme.headerLight};
      left: 0;
      right: 0;
    }
  }
`;

const TitleParentWrapper = styled.div`
  border-bottom: ${(p) => p.theme.borderPrimary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
