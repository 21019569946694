import { useRecoilValue } from "recoil";
import { CANCELLED, OPEN } from "../../../../common/constants/general.constant";
import { RaceDetailsDTO } from "../../../../common/models/race.mode";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import { AtomSelectedThemeData } from "../../../../store/theme/theme.store";
import SelectRaceTimer from "../../../shared/raceStatus/selectedRace.timer";
import styles from "./selectedRaceStatus.module.scss";

type Props = {
  race: RaceDetailsDTO;
};

const SelectedRaceStatus = ({ race }: Props) => {
  const t = useTranslation(LKeys.generals);
  const theme = useRecoilValue(AtomSelectedThemeData);

  const raceStatus = race.cancelled ? CANCELLED : race.raceStatus;
  // if (raceStatus === OPEN) return;
  return (
    <>
      {raceStatus === OPEN ? (
        <SelectRaceTimer mtp={race.mtp} />
      ) : (
        <div
          className={styles.statusWrapper}
          style={{
            background: theme ? theme[raceStatus] : "",
            color: "black",
          }}
        >
          {t(raceStatus)}
        </div>
      )}
    </>
  );
};

export default SelectedRaceStatus;
