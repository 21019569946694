import { RaceTripDTO } from "../models/raceTrip.model";

// * Sorting race trips.
export const sortRaceTrips = (raceTrips: RaceTripDTO[], mode: 0 | 1 | -1) => {
  return [...raceTrips].sort((a, b) => {
    let _revMode = mode === -1 ? 1 : -1;
    let selA: any = a.raceTripKey.selId;
    let selB = b.raceTripKey.selId;
    const a1 = Number(selA);
    const b1 = Number(selB);
    if (a1 < b1) return mode;
    else if (a1 > b1) return _revMode;
    return selA < selB ? mode : selA > selB ? _revMode : 0;
  });
};

// * check if object has data 
export const isEmptyObj = (obj: any) => {
  return Object.keys(obj ?? {}).length === 0;
};
