import styles from "./betSlip.module.scss";
import { RiPagesLine } from "react-icons/ri";
import { AtomBetTab, AtomOpenBets } from "../../../../store/bets/bets.store";
import { useRecoilValue } from "recoil";
import { isExotics, isMulti } from "../../../../common/helpers/pool.helper";
import OpenExoticsBetCard from "./betCards/openExotics.betCard";
import OpenWinPlaceBetCard from "./betCards/openWinPlace.betCard";
import useTranslation from "../../../../store/locale/useTranslation";
import { LKeys } from "../../../../store/locale/locale.data";
import OpenMultiBetCard from "./betCards/openMultiBet.betcard";
type Props = {};

const OpenBetsBody = (props: Props) => {
  const betTab = useRecoilValue(AtomBetTab);
  const openBets = useRecoilValue(AtomOpenBets);
  const tErrors = useTranslation(LKeys.errors);

  if (betTab === "betSlip") return null;
  if (openBets.length === 0)
    return (
      <div className={styles.noBetSlipWrp}>
        <RiPagesLine size={25} />
        <div className={styles.text1}>{tErrors("emptyOpenBets")}</div>
        {/* <div className={styles.text2}>Checkout today's racing & bet now!</div> */}
      </div>
    );
  return (
    <div className={"p05"}>
      {openBets.map((wager, index) => {
        const { poolType } = wager;
        if (isExotics(poolType)) {
          return <OpenExoticsBetCard key={index} wager={wager} />;
        } else if (isMulti(poolType)) {
          return <OpenMultiBetCard wager={wager} key={index} />;
        }
        return <OpenWinPlaceBetCard key={index} wager={wager} />;
      })}
    </div>
  );
};

export default OpenBetsBody;
