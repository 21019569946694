import { RaceKeyDTO } from "../models/race.mode";
import { ROUTES } from "../routes/default.routes";
import { replaceUnderscore } from "./value.helper";

// * URL Params DTO
export interface ParamsDTO {
  dt: string;
  pn: string;
  tn: string;
  rt: string;
  rn: string;
  [k: string]: any;
}

// * Convert URL params to race key.
export const paramsToRaceKey = (p: ParamsDTO): RaceKeyDTO | null => {
  const { dt, pn, rn, rt, tn } = p; // extract parameters
  // Check if any one parameter is missing from URL. If not found return null
  if (!dt || !pn || !rn || !rt || !tn) {
    console.error("URL Not appropriate for fetching race!");
    return null;
  }

  const _trackName = replaceUnderscore(tn?.toUpperCase(), true);
  const pzoneCode = `${p.pn}-${_trackName}-${rt}`;

  return {
    eventType: rt,
    localDate: Number(dt),
    province: pn,
    raceNo: Number(rn),
    pzoneCode,
    trackName: _trackName,
  };
};

// * Convert URL to race key string
export const paramsToRaceKeyStr = (p: ParamsDTO): string => {
  const trackName = replaceUnderscore(p.tn?.toUpperCase(), true);
  const pzoneCode = `${p.pn}-${trackName}-${p.rt}`;
  const raceNo = p?.rn?.toString().length === 1 ? `0${p.rn}` : p.rn;
  return `${p.dt}~${pzoneCode}~${raceNo}`;
};

export const getRacePath = (rk?: RaceKeyDTO) => {
  if(!rk) return "";
  const { eventType, localDate, province, trackName, raceNo } = rk;
  return `${ROUTES.RACES}/${localDate}/${province}/${trackName}/${eventType}/${raceNo}`;
};
