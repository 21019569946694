import { CSSProperties, ReactNode, MouseEvent } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { Severity, Size } from "../../../common/types/general.type";
import { AtomSelectedThemeData } from "../../../store/theme/theme.store";
import styles from "./_button.module.scss";

type Props = {
  label?: string;
  children?: ReactNode;
  severity?: Severity;
  size?: Size;
  className?: string;
  style?: CSSProperties;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

const ElevatedButton = ({ children, ...props }: Props) => {
  const theme = useRecoilValue(AtomSelectedThemeData);

  return (
    <Elevation
      style={{
        ...props.style,
        background: theme && theme[props.severity ?? "primary"],
        color: `${theme && theme[`${props.severity}Text` ?? "primaryText"]}`,
      }}
      onClick={props.onClick && props.onClick}
      className={`${props.className ?? ""} ${styles.btn} ${
        styles.elevatedBtn
      } ${styles[props.severity ?? "primary"]} 
            ${styles[props.size ?? "default"]}
          `}
      disabled={props.disabled}
    >
      {children ?? props.label}
    </Elevation>
  );
};

export default ElevatedButton;

const Elevation = styled.button`
  box-shadow: ${(p) => p.theme.shadowDefault};
`;
