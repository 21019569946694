import { Fragment } from "react";
import ShimmerLoaders from "../../../ui/shimmerLoaders/shimmerLoader.ui";
import styles from "./trackRacesLoader.module.scss";

const TrackRacesLoader = () => {
  return (
    <div className={styles.loaderWrapper} style={{ overflow: "hidden" }}>
      {Array(10)
        .fill("")
        .map((i, index) => (
          <Fragment key={index}>
            <div className={styles.headerWrp}>
              <ShimmerLoaders className="mediumLight" style={{ height: 10 }} />
              <ShimmerLoaders className="mediumLight" style={{ height: 10 }} />
            </div>
            <div className={styles.header}>
              {Array(10)
                .fill("")
                .map((i, _index) => (
                  <ShimmerLoaders className="mediumLight" key={_index} />
                ))}
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export default TrackRacesLoader;
