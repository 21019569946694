export const ROUTES = {
  INDEX: "/",
  LOGIN: "/auth/login",
  CURRENCY: "/currency-select",
  ALL_RACES: "/all-races",
  UPCOMING_RACES: "/upcoming-races",
  TH_RACING: "/racing/TH",
  HS_RACING: "/racing/HS",
  DO_RACING: "/racing/DO",
  TRANSACTIONS: "/transactions",
  RACES: "/races",
  HELP: "/help",
  DEMO: "/demo",
  BET_SLIP: "/betSlip",
  NEXT_TO_RACE: "/nextToRace",
  POLICY_TERMS_AND_USE: "/policy/terms-of-use",
  POLICY_PRIVACY_POLICY: "/policy/privacy-policy",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
};
