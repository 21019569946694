import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { CLOSED } from "../../../../../common/constants/general.constant";
import {
  POOLS,
  POOL_SELECTION_NUM,
} from "../../../../../common/constants/pools.constant";
import {
  Boxed,
  NormalBoxes,
} from "../../../../../common/helpers/selection.helper";
import useSelected from "../../../../../hooks/useSelectedPool";
import { LKeys } from "../../../../../store/locale/locale.data";
import useTranslation from "../../../../../store/locale/useTranslation";
import { AtomSelectedRace } from "../../../../../store/races/races.store";
import { AtomSelectedRaceTrips } from "../../../../../store/races/selectedRace.store";
import {
  AtomHorseSelection,
  AtomRunnerPosition,
} from "../../../../../store/selection/selection.store";
import styles from "./tripHeader.module.scss";

const ExoticsHeader = () => {
  const { pool, isBoxed } = useSelected();
  const race = useRecoilValue(AtomSelectedRace);
  const trips = useRecoilValue(AtomSelectedRaceTrips);
  const [selection, setSelection] = useRecoilState(AtomHorseSelection);
  const [positions, setPositions] = useRecoilState(AtomRunnerPosition);

  const t = useTranslation(LKeys.generals);
  const [allChecked, setAllChecked] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    boxed: false,
  } as any); // State to store value if all runner position is checked.

  // Run on every selections to update positions
  useEffect(() => {
    let rLen = Object.values(trips ?? {}).filter((x) => !x.scratched).length;

    if (rLen && pool && pool !== POOLS.WIN_PLACE) {
      let _checked = { ...allChecked } as any;
      let _positions = { ...positions };

      for (const pos in selection[pool]) {
        if (!selection[pool]) continue;
        let q = Object.keys(selection[pool][pos] ?? {});
        if (rLen === q.length) _checked[pos] = true;
        else _checked[pos] = false;
        _positions = {
          ..._positions,
          [pool]: { ..._positions[pool], [pos]: q },
        };
      }

      setPositions(_positions);
      setAllChecked(_checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, pool]);

  // Set all boxes toggle for selections
  const setAllBoxes = (check: boolean, pos: string) => {
    // if (!isValidClient(client)) return;
    if (!pool) return;
    let _selection = {
      ...selection,
      [pool]: { ...selection[pool], [pos]: {} },
    };

    if (check) {
      for (const trip of trips ?? []) {
        const { selId } = trip.raceTripKey;

        if (!trip.scratched) {
          _selection[pool] = {
            ..._selection[pool],
            [pos]: { ..._selection[pool][pos], [selId]: true },
            boxed: { ..._selection[pool]["boxed"], [selId]: true },
          };
        }
      }
    }
    setAllChecked({ ...allChecked, [pos]: check });
    setSelection(_selection);
  };

  const DynamicBox = ({
    Box,
    isThree,
    isFour,
  }: {
    Box: any;
    isThree?: boolean;
    isFour?: boolean;
  }) => {
    return (
      <Box
        className={styles.boxWrapper}
        disabled={race?.raceStatus === CLOSED}
        value={allChecked.boxed}
        onPosition={setAllBoxes}
        first={allChecked.first}
        second={allChecked.second}
        third={allChecked.third}
        fourth={allChecked.fourth}
        isThree={isThree ? true : false}
        isFour={isFour ? true : false}
      />
    );
  };

  const HeaderTemplate = () => {
    if (!pool) return null;
    // return <DynamicBox Box={Boxed} />;
    if (isBoxed) return <DynamicBox Box={Boxed} />;
    switch (pool) {
      case POOLS.TRIFECTA:
        return <DynamicBox Box={NormalBoxes} isThree />;
      case POOLS.SUPERFECTA:
        return <DynamicBox Box={NormalBoxes} isFour />;
      default:
        return <DynamicBox Box={NormalBoxes} />;
    }
  };

  return (
    <TableHeader className={styles.tableHeader}>
      <div>
        <RunnerBtn className={styles.headerBtn}>{t("runners")}</RunnerBtn>
      </div>
      <div
        style={{
          gridTemplateColumns: `repeat(${
            isBoxed ? 1 : POOL_SELECTION_NUM[pool ?? 2]
          }, 40px)`,
        }}
        className={styles.exPoolHeader}
      >
        {HeaderTemplate()}
      </div>
    </TableHeader>
  );
};

export default ExoticsHeader;

// * Table Styled Components
const TableHeader = styled.div`
  button {
    color: ${(p) => p.theme.textContrast};
  }
`;

const RunnerBtn = styled.button`
  color: ${(p) => p.theme.text} !important;
`;
