import { atom } from "recoil";
import { RaceDetailsDTO, RaceSnapDTO } from "../../common/models/race.mode";
import {
  __PzoneCodeAndRaces,
  __TrackRacesDTO,
} from "../../common/types/dynamic.type";
import { UpcRacesDTO } from "../../common/types/raceUI.type";

// * Date to fetch all races.
export const AtomSelectedDate = atom<Date>({
  key: "atomSelectedDate",
  default: new Date(),
});

// * state to store all races according to selected date.
export const AtomTrackRaces = atom<__TrackRacesDTO | null | undefined>({
  key: "atomAllRaces",
  default: undefined,
});

// * State to store today's all track list
export const AtomAllTrackList = atom<__PzoneCodeAndRaces[]>({
  key: "atomAllTrackList",
  default: [],
});

// * State to store upcoming races for the day.
export const AtomRaceSnaps = atom<RaceSnapDTO[] | undefined | null>({
  key: "atomRaceSnaps",
  default: undefined,
});

// * State to store filtered race snaps
export const AtomUpcRaces = atom<UpcRacesDTO | undefined>({
  key: "atomFilteredRaceSnaps",
  default: undefined,
});

// * state to store filter values for upcoming races.
export const AtomUpcFilter = atom({
  key: "atomUpcFilter",
  default: {
    provinces: [] as string[],
    eventTypes: [] as string[],
  },
});

// * State to store selected races to display
export const AtomSelectedRace = atom<RaceDetailsDTO | null | undefined>({
  key: "atomSelectedRace",
  default: undefined,
});

// * State to store race numbers data for selected race track
export const AtomRaceNumbers = atom<RaceDetailsDTO[]>({
  key: "atomRaceNumbers",
  default: [],
});
