import { atom } from "recoil";
import {
  __PoolToPositions,
  __PoolToSelection,
} from "../../common/types/dynamic.type";

// * State to store is Boxed is selected
export const AtomIsBoxed = atom<boolean>({
  key: "atomIsBoxed",
  default: false,
});

// * State to store horse selection for betting in race trips table
export const AtomHorseSelection = atom<__PoolToSelection>({
  key: "atomHorseSelection",
  default: {
    QUINELLA: {
      first: {},
      second: {},
      boxed: {},
    },
    SWINGER: {
      first: {},
      second: {},
      boxed: {},
    },
    EXACTA: {
      first: {},
      second: {},
      boxed: {},
    },
    TRIFECTA: {
      first: {},
      second: {},
      third: {},
      boxed: {},
    },
    SUPERFECTA: {
      first: {},
      second: {},
      third: {},
      fourth: {},
      boxed: {},
    },
  },
});

// * State to store selected runner position for pool horse selection
export const AtomRunnerPosition = atom<__PoolToPositions>({
  key: "atomRunnerPosition",
  default: {},
});
