import { LocaleDTO } from "./locale.type";
export const LKeys = {
  generals: "generals",
  tracks: "tracks",
  countries: "countries",
  runners: "runners",
  jockeys: "jockeys",
  trainers: "trainers",
  errors: "errors",
};

export const DATA: any = {
  en: {
    errors: {
      noRaces: "Races not available at the moment!",
      noRaceData: "Race data not available!",
      emptyBetSlip: "Bet Slip Is Empty!",
      emptyOpenBets: "There are no open bets!",
      cancelledRace: "The Race is Cancelled!",
    },
    generals: {
      allRaces: "All Races",
      races: "Races",
      race: "Race",
      runners: "Runners",
      transactions: "Transactions",
      home: "Home",
      openBets: "Open Bets",
      betSlip: "Bet Slip",
      raceType: "Race Type",
      date: "Date",
      time: "Time",
      distance: "Distance",
      trackCondition: "Track Condition",
      weather: "Weather",
      filter: "Filter",
      thRacing: "Thoroughbred Racing",
      hsRacing: "Harness Racing",
      doRacing: "Greyhound Racing",
      helpCenter: "Help Center",
      demoVideos: "Demo Videos",
      upcomingRaces: "Upcoming Races",
      percentage: "Percentage",
      amount: "Amount",
      addBet: "Add Bet",
      placeAllBets: "Place All Bets",
      bet: "Bet",
      clear_all: "Clear All",
      betTypes: "Bet Types",
      winners: "Winners",
      payOut: "Pay Out",
      pool_type: "Pool Type",
      bet_status: "Bet Status",
      combinations: "Combinations",
      investments: "Investments",
      selection_amount: "Selection Amount",
      total_winnings: "Total Winnings",
      t_id: "Token",
      tracks: "Tracks",
      race_number: "Race Number",
      invested: "Invested",
      pnl: "PNL",
      poolType: "Pool Type",
      costPerCombo: "Cost/Combo",
      processing: "Processing",
      TH: "Thoroughbred",
      HS: "Harness",
      DO: "Greyhound",
      RESULTED: "RESULTED",
      OPEN: "OPEN",
      CLOSED: "CLOSED",
      CANCELLED: "CANCELLED",
      odds: "Odds",
      pool: "Pool",
      betAmount: "Bet Amount",
      WIN_PLACE: "WIN/PLACE",
      WIN: "WIN",
      PLACE: "PLACE",
      SHOW: "SHOW",
      QUINELLA: "QUINELLA",
      EXACTA: "EXACTA",
      TRIFECTA: "TRIFECTA",
      SUPERFECTA: "SUPERFECTA",
      FO_WIN: "Win Fixed",
      FO_PLACE: "Place Fixed",
      pools_payout: "Pools Payout",
      no_data_available: "No Data Available.",
      first: "1st",
      second: "2nd",
      third: "3rd",
      fourth: "4th",
      negotiateMessage: "Do you want to negotiate the odds with",
      accept: "Accept",
      reject: "Reject",
      today: "Today",
      yesterday: "Yesterday",
      expertTips: "Expert Tips",
      winProbability: "Win Probability",
      tip: "Tip",
    },
    tracks: {
      ABU_DHABI: "Abu Dhabi",
      JEBEL_ALI: "Jebel Ali",
      MEYDAN: "Meydan",
      SHARJAH: "Sharjah",
      ADAMINABY: "Adaminaby",
      ADELAIDE_RIVER: "Adelaide River",
      ALBANY: "Albany",
      ALBION_PARK: "Albion Park",
      ALBURY: "Albury",
      ALEXANDRA: "Alexandra",
      ALICE_SPRINGS: "Alice Springs",
      ALPHA: "Alpha",
      ANGLE_PARK: "Angle Park",
      ARAMAC: "Aramac",
      ARARAT: "Ararat",
      ARDLETHAN: "Ardlethan",
      ARMIDALE: "Armidale",
      ASCOT: "Ascot",
      ATHERTON: "Atherton",
      AUGATHELLA: "Augathella",
      AVOCA: "Avoca",
      BAIRNSDALE: "Bairnsdale",
      BALAKLAVA: "Balaklava",
      BALLARAT: "Ballarat",
      BALLART_SYNTHETIC: "Ballart Synthetic",
      BALLINA: "Ballina",
      BALNARRING: "Balnarring",
      BALRANALD: "Balranald",
      BANKSTOWN: "Bankstown",
      BARCALDINE: "Barcaldine",
      BARRABA: "Barraba",
      BATHURST: "Bathurst",
      BEAUDESERT: "Beaudesert",
      BEAUMONT: "Beaumont",
      BEDOURIE: "Bedourie",
      BELL: "Bell",
      BELMONT_PARK: "Belmont Park",
      BENALLA: "Benalla",
      BENDIGO: "Bendigo",
      BERRIGAN: "Berrigan",
      BETOOTA: "Betoota",
      BINGARA: "Bingara",
      BINNAWAY: "Binnaway",
      BIRCHIP: "Birchip",
      BIRDSVILLE: "Birdsville",
      BLACKALL: "Blackall",
      BLAYNEY: "Blayney",
      BLUFF: "Bluff",
      BOMBALA: "Bombala",
      BONG_BONG: "Bong Bong",
      BOORT: "Boort",
      BORDERTOWN: "Bordertown",
      BOULIA: "Boulia",
      BOURKE: "Bourke",
      BOWEN: "Bowen",
      BOWRAVILLE: "Bowraville",
      BRAIDWOOD: "Braidwood",
      BREWARRINA: "Brewarrina",
      BRIDGETOWN: "Bridgetown",
      BROKEN_HILL: "Broken Hill",
      BROOME: "Broome",
      BUCHAN: "Buchan",
      BULLI: "Bulli",
      BUNBURY: "Bunbury",
      BUNDABERG: "Bundaberg",
      BUNDARRA: "Bundarra",
      BURNIE: "Burnie",
      BURRA: "Burra",
      BURRUMBEET: "Burrumbeet",
      BUSSELTON: "Busselton",
      CAIRNS: "Cairns",
      CALLIOPE: "Calliope",
      CAMOOWEAL: "Camooweal",
      CAMPERDOWN: "Camperdown",
      CANBERRA: "Canberra",
      CANNINGTON: "Cannington",
      CANTERBURY: "Canterbury",
      CAPALABA: "Capalaba",
      CARINDA: "Carinda",
      CARNARVON: "Carnarvon",
      CARRATHOOL: "Carrathool",
      CARRICK_PARK: "Carrick Park",
      CASINO: "Casino",
      CASTERTON: "Casterton",
      CAULFIELD: "Caulfield",
      CEDUNA: "Ceduna",
      CESSNOCK: "Cessnock",
      CHARLEVILLE: "Charleville",
      CHARLTON: "Charlton",
      CHARTERS_TOWERS: "Charters Towers",
      CHINCHILLA: "Chinchilla",
      CLARE: "Clare",
      CLERMONT: "Clermont",
      CLIFTON: "Clifton",
      CLONCURRY: "Cloncurry",
      COBAR: "Cobar",
      COBRAM: "Cobram",
      COFFS_HARBOUR: "Coffs Harbour",
      COLAC: "Colac",
      COLERAINE: "Coleraine",
      COLLARENEBRI: "Collarenebri",
      COLLIE: "Collie",
      COOKTOWN: "Cooktown",
      COOLAMON: "Coolamon",
      COOMA_MONARO_RACE_CLUB: "Cooma Monaro Race Club",
      COONABARABRAN: "Coonabarabran",
      COONAMBLE: "Coonamble",
      COOTAMUNDRA: "Cootamundra",
      COROWA: "Corowa",
      COWRA: "Cowra",
      CRANBOURNE: "Cranbourne",
      CUNNAMULLA: "Cunnamulla",
      DALBY: "Dalby",
      DAPTO: "Dapto",
      DARWIN: "Darwin",
      DEAGON: "Deagon",
      DEDERANG: "Dederang",
      DEEPWATER: "Deepwater",
      DENILIQUIN: "Deniliquin",
      DERBY: "Derby",
      DEVONPORT: "Devonport",
      DEVONPORT_SYNTHETIC: "Devonport Synthetic",
      DINGO: "Dingo",
      DONALD: "Donald",
      DONGARA: "Dongara",
      DOOMBEN: "Doomben",
      DROUIN: "Drouin",
      DUBBO: "Dubbo",
      DUNKELD: "Dunkeld",
      EAGLE_FARM: "Eagle Farm",
      ECHUCA: "Echuca",
      EDENHOPE: "Edenhope",
      EINASLEIGH: "Einasleigh",
      ELMORE: "Elmore",
      EMERALD: "Emerald",
      ESK: "Esk",
      ESPERANCE: "Esperance",
      EUGOWRA: "Eugowra",
      EWAN: "Ewan",
      FANNIE_BAY: "Fannie Bay",
      FLEMINGTON: "Flemington",
      FLINDERS: "Flinders",
      FORBES: "Forbes",
      GATTON: "Gatton",
      GAWLER: "Gawler",
      GAYNDAH: "Gayndah",
      GEELONG: "Geelong",
      GERALDTON: "Geraldton",
      GEURIE: "Geurie",
      GILGANDRA: "Gilgandra",
      GLADSTONE: "Gladstone",
      GLEN_INNES: "Glen Innes",
      GLOBE_DERBY_PARK: "Globe Derby Park",
      GLOUCESTER_PARK: "Gloucester Park",
      GOLD_COAST: "Gold Coast",
      GOONDIWINDI: "Goondiwindi",
      GORDONVALE: "Gordonvale",
      GOSFORD: "Gosford",
      GOULBURN: "Goulburn",
      GRAFTON: "Grafton",
      GREAT_WESTERN: "Great Western",
      GREGORY_DOWNS: "Gregory Downs",
      GRENFELL: "Grenfell",
      GRIFFITH: "Griffith",
      GULARGAMBONE: "Gulargambone",
      GULGONG: "Gulgong",
      GUNBOWER: "Gunbower",
      GUNDAGAI: "Gundagai",
      GUNNEDAH: "Gunnedah",
      GYMPIE: "Gympie",
      HALIDON: "Halidon",
      HAMILTON: "Hamilton",
      HANGING_ROCK: "Hanging Rock",
      HARDEN: "Harden",
      HAWKER: "Hawker",
      HAWKESBURY: "Hawkesbury",
      HAY: "Hay",
      HEALESVILLE: "Healesville",
      HILLSTON: "Hillston",
      HINNOMUNJIE: "Hinnomunjie",
      HOBART: "Hobart",
      HOLBROOK: "Holbrook",
      HOME_HILL: "Home Hill",
      HORSHAM: "Horsham",
      HUGHENDEN: "Hughenden",
      ILFRACOMBE: "Ilfracombe",
      INGHAM: "Ingham",
      INJUNE: "Injune",
      INNISFAIL: "Innisfail",
      INVERELL: "Inverell",
      IPSWICH: "Ipswich",
      ISISFORD: "Isisford",
      JAMESTOWN: "Jamestown",
      JANDOWAE: "Jandowae",
      JERILDERIE: "Jerilderie",
      JULIA_CREEK: "Julia Creek",
      JUNCTION: "Junction",
      JUNDAH: "Jundah",
      JUNEE: "Junee",
      KALGOORLIE: "Kalgoorlie",
      KANGAROO_ISLAND: "Kangaroo Island",
      KAPUNDA: "Kapunda",
      KATHERINE: "Katherine",
      KELLERBERRIN: "Kellerberrin",
      KEMBLA_GRANGE: "Kembla Grange",
      KEMPSEY: "Kempsey",
      KERANG: "Kerang",
      KILCOY: "Kilcoy",
      KILMORE: "Kilmore",
      KINGSCOTE: "Kingscote",
      KING_ISLAND: "King Island",
      KOJONUP: "Kojonup",
      KUMBIA: "Kumbia",
      KUNUNURRA: "Kununurra",
      KYNETON: "Kyneton",
      LANDOR: "Landor",
      LAUNCESTON: "Launceston",
      LAVERTON: "Laverton",
      LEETON: "Leeton",
      LEINSTER: "Leinster",
      LEONORA: "Leonora",
      LISMORE: "Lismore",
      LITHGOW: "Lithgow",
      LOCK: "Lock",
      LOCKHART: "Lockhart",
      LONGFORD: "Longford",
      LONGREACH: "Longreach",
      LOUTH: "Louth",
      MACKAY: "Mackay",
      MAITLAND: "Maitland",
      MALLAWA: "Mallawa",
      MANANGATANG: "Manangatang",
      MANDURAH: "Mandurah",
      MANSFIELD: "Mansfield",
      MARBLE_BAR: "Marble Bar",
      MARBURG: "Marburg",
      MAREEBA: "Mareeba",
      MARYBOROUGH: "Maryborough",
      MAXWELTON: "Maxwelton",
      MCKINLAY: "Mckinlay",
      MEEKATHARRA: "Meekatharra",
      MELBOURNE: "Melbourne",
      MELTON: "Melton",
      MENANGLE: "Menangle",
      MENDOORAN: "Mendooran",
      MERRIWA: "Merriwa",
      MERTON: "Merton",
      MIDDLEMOUNT: "Middlemount",
      MILDURA: "Mildura",
      MILES: "Miles",
      MINGENEW: "Mingenew",
      MITCHELL: "Mitchell",
      MOE: "Moe",
      MONTO: "Monto",
      MOONEE_VALLEY: "Moonee Valley",
      MOORA: "Moora",
      MORANBAH: "Moranbah",
      MOREE: "Moree",
      MORNINGTON: "Mornington",
      MORPHETTVILLE: "Morphettville",
      MORTLAKE: "Mortlake",
      MORUYA: "Moruya",
      MORVEN: "Morven",
      MOULAMEIN: "Moulamein",
      MOUNT_GAMBIER: "Mount Gambier",
      MT_BARKER: "Mt Barker",
      MT_GAMBIER: "Mt Gambier",
      MT_GARNET: "Mt Garnet",
      MT_ISA: "Mt Isa",
      MT_MAGNET: "Mt Magnet",
      MT_NEWMAN: "Mt Newman",
      MT_PERRY: "Mt Perry",
      MUDGEE: "Mudgee",
      MUNGERY: "Mungery",
      MURRAY_BRIDGE: "Murray Bridge",
      MURTOA: "Murtoa",
      MURWILLUMBAH: "Murwillumbah",
      MUSWELLBROOK: "Muswellbrook",
      MUTTABURRA: "Muttaburra",
      NANANGO: "Nanango",
      NARACOORTE: "Naracoorte",
      NARRABRI: "Narrabri",
      NARRANDERA: "Narrandera",
      NARROGIN: "Narrogin",
      NARROMINE: "Narromine",
      NEWCASTLE: "Newcastle",
      NHILL: "Nhill",
      NOORAMA: "Noorama",
      NORSEMAN: "Norseman",
      NORTHAM: "Northam",
      NOWRA: "Nowra",
      NYNGAN: "Nyngan",
      OAKBANK: "Oakbank",
      OAK_PARK: "Oak Park",
      ORANGE: "Orange",
      OUYEN: "Ouyen",
      PAKENHAM: "Pakenham",
      PARKES: "Parkes",
      PEAK_HILL: "Peak Hill",
      PENOLA: "Penola",
      PENONG: "Penong",
      PENRITH: "Penrith",
      PENSHURST: "Penshurst",
      PENTLAND: "Pentland",
      PINGRUP: "Pingrup",
      PINJARRA: "Pinjarra",
      POONCARIE: "Pooncarie",
      PORT_AUGUSTA: "Port Augusta",
      PORT_HEDLAND: "Port Hedland",
      PORT_LINCOLN: "Port Lincoln",
      PORT_MACQUARIE: "Port Macquarie",
      PORT_PIRIE: "Port Pirie",
      POTTS_PARK: "Potts Park",
      PRAIRIE: "Prairie",
      QUEANBEYAN: "Queanbeyan",
      QUILPIE: "Quilpie",
      QUIRINDI: "Quirindi",
      QUORN: "Quorn",
      RANDWICK: "Randwick",
      REDCLIFFE: "Redcliffe",
      RICHMOND: "Richmond",
      ROCKHAMPTON: "Rockhampton",
      ROEBOURNE: "Roebourne",
      ROMA: "Roma",
      ROSEHILL: "Rosehill",
      ROXBY_DOWNS: "Roxby Downs",
      SALE: "Sale",
      SANDOWN: "Sandown",
      SANDOWN_HILLSIDE: "Sandown Hillside",
      SANDOWN_LAKESIDE: "Sandown Lakeside",
      SANDOWN_PARK: "Sandown Park",
      SAPPHIRE_COAST: "Sapphire Coast",
      SCONE: "Scone",
      SCOTTSDALE: "Scottsdale",
      SEYMOUR: "Seymour",
      SHEPPARTON: "Shepparton",
      SOUTH_AUSTRALIA: "South Australia",
      SPRINGSURE: "Springsure",
      STAMFORD: "Stamford",
      STANTHORPE: "Stanthorpe",
      STAWELL: "Stawell",
      STONY_CREEK: "Stony Creek",
      STRATHALBYN: "Strathalbyn",
      STREAKY_BAY: "Streaky Bay",
      ST_ARNAUD: "St Arnaud",
      ST_GEORGE: "St George",
      ST_MARYS: "St Marys",
      SUNSHINE_COAST: "Sunshine Coast",
      SURAT: "Surat",
      SWAN_HILL: "Swan Hill",
      SWIFT_S_CREEK: "Swift S Creek",
      TABULAM: "Tabulam",
      TALWOOD: "Talwood",
      TAMBO: "Tambo",
      TAMWORTH: "Tamworth",
      TARA: "Tara",
      TAREE: "Taree",
      TAROOM: "Taroom",
      TATURA: "Tatura",
      TEMORA: "Temora",
      TENNANT_CREEK: "Tennant Creek",
      TERANG: "Terang",
      TEXAS: "Texas",
      THANGOOL: "Thangool",
      THE_GARDENS: "The Gardens",
      THE_MEADOWS: "The Meadows",
      TOCUMWAL: "Tocumwal",
      TOMINGLEY: "Tomingley",
      TOODYAY: "Toodyay",
      TOOWOOMBA: "Toowoomba",
      TOTTENHAM: "Tottenham",
      TOWNSVILLE: "Townsville",
      TOWONG: "Towong",
      TRANGIE: "Trangie",
      TRARALGON: "Traralgon",
      TULLIBIGEAL: "Tullibigeal",
      TUMBARUMBA: "Tumbarumba",
      TUMUT: "Tumut",
      TUNCURRY: "Tuncurry",
      TWIN_HILLS: "Twin Hills",
      VICTOR_HARBOR: "Victor Harbor",
      WAGGA: "Wagga",
      WAGGA_RIVERSIDE: "Wagga Riverside",
      WAGIN: "Wagin",
      WALCHA: "Walcha",
      WALGETT: "Walgett",
      WALLABADAH: "Wallabadah",
      WANDOAN: "Wandoan",
      WANGARATTA: "Wangaratta",
      WARIALDA: "Warialda",
      WARRA: "Warra",
      WARRACKNABEAL: "Warracknabeal",
      WARRAGUL: "Warragul",
      WARREN: "Warren",
      WARRNAMBOOL: "Warrnambool",
      WARRNAMBOOL_EXTRA: "Warrnambool Extra",
      WARWICK: "Warwick",
      WARWICK_FARM: "Warwick Farm",
      WAUCHOPE: "Wauchope",
      WAYVILLE: "Wayville",
      WEAN: "Wean",
      WEDDERBURN: "Wedderburn",
      WELLINGTON: "Wellington",
      WENTWORTH: "Wentworth",
      WENTWORTH_PARK: "Wentworth Park",
      WERRIBEE: "Werribee",
      WEST_WYALONG: "West Wyalong",
      WHYALLA: "Whyalla",
      WILLIAMS: "Williams",
      WINNELLIE_PARK: "Winnellie Park",
      WINTON: "Winton",
      WODONGA: "Wodonga",
      WONDAI: "Wondai",
      WOOLAMAI: "Woolamai",
      WYCHEPROOF: "Wycheproof",
      WYNDHAM: "Wyndham",
      WYONG: "Wyong",
      YALGOO: "Yalgoo",
      YARRA_VALLEY: "Yarra Valley",
      YASS: "Yass",
      YEA: "Yea",
      YEPPOON: "Yeppoon",
      YORK: "York",
      YOUNG: "Young",
      RICHMOND_STRAIGHT: "Richmond Straight",
      MURRAY_BRIDGE_STRAIGHT: "Murray Bridge Straight",
      BADEN_BADEN: "Baden Baden",
      BAD_DOBERAN: "Bad Doberan",
      BAD_HARZBURG: "Bad Harzburg",
      BREMEN: "Bremen",
      COLOGNE: "Cologne",
      DINSLAKEN: "Dinslaken",
      DORTMUND: "Dortmund",
      DRESDE: "Dresde",
      DRESDEN: "Dresden",
      DUSSELDORF: "Dusseldorf",
      GELSENKIRCHEN: "Gelsenkirchen",
      HAMBOURG: "Hambourg",
      HAMBOURG_HORN: "Hambourg Horn",
      HAMBURG: "Hamburg",
      HANNOVER: "Hannover",
      HANOVRE: "Hanovre",
      HASSLOCH: "Hassloch",
      HOPPEGARTEN: "Hoppegarten",
      KARLSHORST: "Karlshorst",
      KOLN: "Koln",
      KREFELD: "Krefeld",
      KRIEAU: "Krieau",
      LEIPZIG: "Leipzig",
      MAGDEBURG: "Magdeburg",
      MANNHEIM: "Mannheim",
      MARIENDORF: "Mariendorf",
      MONCHENGLADBACH: "Monchengladbach",
      MULHEIM: "Mulheim",
      MUNCHEN: "Munchen",
      MUNICH_DAGLFING: "Munich Daglfing",
      MUNICH_RIEM: "Munich Riem",
      NEUSS: "Neuss",
      SAARBRUCKEN: "Saarbrucken",
      STRAUBING: "Straubing",
      AGEN_LA_GARENNE: "Agen La Garenne",
      AGON_COUTAINVILLE: "Agon Coutainville",
      AIX_LES_BAINS: "Aix Les Bains",
      AJACCIO: "Ajaccio",
      ALENCON: "Alencon",
      AMIENS: "Amiens",
      ANGERS: "Angers",
      ANGOULEME: "Angouleme",
      ARGENTAN: "Argentan",
      ARRAS: "Arras",
      AUCH: "Auch",
      AUTEUIL: "Auteuil",
      AVIGNON: "Avignon",
      BEAUMONT_DE_LOMAGNE: "Beaumont De Lomagne",
      BERNAY: "Bernay",
      BIARRITZ: "Biarritz",
      BOLLENE: "Bollene",
      BORELY: "Borely",
      BREHAL: "Brehal",
      CABOURG: "Cabourg",
      CAEN: "Caen",
      CAGNES_MIDI: "Cagnes Midi",
      CAGNES_SUR_MER: "Cagnes Sur Mer",
      CARENTAN: "Carentan",
      CARPENTRAS: "Carpentras",
      CASTERA_VERDUZAN: "Castera Verduzan",
      CASTILLONNES: "Castillonnes",
      CAVAILLON: "Cavaillon",
      CHAMP_DE_MARS: "Champ De Mars",
      CHANTILLY: "Chantilly",
      CHANTILLY_MIDI: "Chantilly Midi",
      CHARTRES: "Chartres",
      CHATEAUBRIANT: "Chateaubriant",
      CHATEAUROUX: "Chateauroux",
      CHATELAILLON: "Chatelaillon",
      CHATILLON: "Chatillon",
      CHERBOURG: "Cherbourg",
      CHOLET: "Cholet",
      CLAIREFONTAINE: "Clairefontaine",
      COMPIEGNE: "Compiegne",
      CORDEMAIS: "Cordemais",
      CRAON: "Craon",
      DAX: "Dax",
      DEAUVILLE: "Deauville",
      DEAUVILLE_MIDI: "Deauville Midi",
      DIEPPE: "Dieppe",
      DIVONNE_LES_BAINS: "Divonne Les Bains",
      DURTAL: "Durtal",
      EAUZE: "Eauze",
      ECOMMOY: "Ecommoy",
      ENGHIEN: "Enghien",
      EVREUX: "Evreux",
      FEURS: "Feurs",
      FONTAINEBLEAU: "Fontainebleau",
      FOUGERES: "Fougeres",
      GRAIGNES: "Graignes",
      GRANVILLE: "Granville",
      GRENADE_SUR_GARONNE: "Grenade Sur Garonne",
      GUADELOUPE: "Guadeloupe",
      HYERES: "Hyeres",
      ISLE_SUR_SORGUE: "Isle Sur Sorgue",
      JULLIANGES: "Jullianges",
      LANDIVISIAU: "Landivisiau",
      LANGON_LIBOURNE: "Langon Libourne",
      LAON: "Laon",
      LAVAL: "Laval",
      LA_CAPELLE: "La Capelle",
      LA_CEPIERE: "La Cepiere",
      LA_CLAYETTE: "La Clayette",
      LA_GACILLY: "La Gacilly",
      LA_ROCHE_POSAY: "La Roche Posay",
      LA_TESTE: "La Teste",
      LES_SABLES_D_OLONNE: "Les Sables D Olonne",
      LE_BOUSCAT: "Le Bouscat",
      LE_CROISE_LAROCHE: "Le Croise Laroche",
      LE_LION_DANGERS: "Le Lion Dangers",
      LE_MANS: "Le Mans",
      LE_MONT_SAINT_MICHEL: "Le Mont Saint Michel",
      LE_TOUQUET: "Le Touquet",
      LIGNIERES: "Lignieres",
      LISIEUX: "Lisieux",
      LYON_LA_SOIE: "Lyon La Soie",
      LYON_PARILLY: "Lyon Parilly",
      MACHECOUL: "Machecoul",
      MACON_CLUNY: "Macon Cluny",
      MAISONS_LAFFITTE: "Maisons Laffitte",
      MARTINIQUE: "Martinique",
      MAUQUENCHY: "Mauquenchy",
      MAURE_DE_BRETAGNE: "Maure De Bretagne",
      MESLAY_DU_MAINE: "Meslay Du Maine",
      MONTAUBAN: "Montauban",
      MONTIER_EN_DER: "Montier En Der",
      MONTLUCON: "Montlucon",
      MONT_DE_MARSAN: "Mont De Marsan",
      MORLAIX: "Morlaix",
      MOULINS: "Moulins",
      NANCY_BRABOIS: "Nancy Brabois",
      NANTES: "Nantes",
      NIMES: "Nimes",
      NIORT: "Niort",
      NORT_SUR_ERDRE: "Nort Sur Erdre",
      ORAISON: "Oraison",
      PARAY_LE_MONIAL: "Paray Le Monial",
      PARISLONGCHAMP: "ParisLongchamp",
      PAU: "Pau",
      POMPADOUR: "Pompadour",
      PONTCHATEAU: "Pontchateau",
      PONT_DE_VIVAUX: "Pont De Vivaux",
      PORNICHET: "Pornichet",
      PRUNELLI: "Prunelli",
      RAMBOUILLET: "Rambouillet",
      REIMS: "Reims",
      ROYAN: "Royan",
      SABLE_SUR_SARTHE: "Sable Sur Sarthe",
      SAINT_BRIEUC: "Saint Brieuc",
      SAINT_CLOUD: "Saint Cloud",
      SAINT_GALMIER: "Saint Galmier",
      SAINT_MALO: "Saint Malo",
      SALON_DE_PROVENCE: "Salon De Provence",
      SEGRE: "Segre",
      SENONNES_POUANCE: "Senonnes Pouance",
      STRASBOURG: "Strasbourg",
      ST_JEAN_DE_MONTS: "St Jean De Monts",
      TARBES: "Tarbes",
      TOURS: "Tours",
      VANNES: "Vannes",
      VICHY: "Vichy",
      VICHY_SOIR: "Vichy Soir",
      VILLENEUVE_SUR_LOT: "Villeneuve Sur Lot",
      VILLEREAL: "Villereal",
      VINCENNES: "Vincennes",
      VIRE: "Vire",
      VITRE: "Vitre",
      VITTEL: "Vittel",
      WISSEMBOURG: "Wissembourg",
      ZONZA: "Zonza",
      AINTREE: "Aintree",
      AYR: "Ayr",
      BANGOR_ON_DEE: "Bangor On Dee",
      BATH: "Bath",
      BELLE_VUE: "Belle Vue",
      BEVERLEY: "Beverley",
      BRIGHTON: "Brighton",
      BRIGHTON_HOVE: "Brighton Hove",
      CARLISLE: "Carlisle",
      CARTMEL: "Cartmel",
      CARTMELL: "Cartmell",
      CATTERICK_BRIDGE: "Catterick Bridge",
      CENTRAL_PARK: "Central Park",
      CHELMSFORD_CITY: "Chelmsford City",
      CHELTENHAM: "Cheltenham",
      CHEPSTOW: "Chepstow",
      CHESTER: "Chester",
      COVENTRY: "Coventry",
      CRAYFORD: "Crayford",
      DONCASTER: "Doncaster",
      EPSOM_DOWNS: "Epsom Downs",
      EXETER: "Exeter",
      FAKENHAM: "Fakenham",
      FFOS_LAS: "Ffos Las",
      FONTWELL: "Fontwell",
      GOODWOOD: "Goodwood",
      GOWRAN_PARK: "Gowran Park",
      GREAT_YARMOUTH: "Great Yarmouth",
      HALL_GREEN: "Hall Green",
      HAMILTON_PARK: "Hamilton Park",
      HARLOW: "Harlow",
      HARLOW_AM: "Harlow am",
      HAYDOCK_PARK: "Haydock Park",
      HENLOW: "Henlow",
      HENLOW_AM: "Henlow am",
      HEREFORD: "Hereford",
      HEXHAM: "Hexham",
      HOVE: "Hove",
      HOVE_AM: "Hove am",
      HUNTINGDON: "Huntingdon",
      KELSO: "Kelso",
      KEMPTON_PARK: "Kempton Park",
      KINSLEY: "Kinsley",
      LEICESTER: "Leicester",
      LINGFIELD_PARK: "Lingfield Park",
      LUDLOW: "Ludlow",
      MARKET_RASEN: "Market Rasen",
      MILDENHALL: "Mildenhall",
      MILTON_KEYNES: "Milton Keynes",
      MONMORE: "Monmore",
      MONMORE_GREEN: "Monmore Green",
      MUSSELBURGH: "Musselburgh",
      NEWBURY: "Newbury",
      NEWMARKET: "Newmarket",
      NEWTON_ABBOT: "Newton Abbot",
      NOTTINGHAM: "Nottingham",
      PELAW_GRANGE: "Pelaw Grange",
      PERRY_BARR: "Perry Barr",
      PERTH: "Perth",
      PETERBOROUGH: "Peterborough",
      PLUMPTON: "Plumpton",
      PONTEFRACT: "Pontefract",
      POOLE: "Poole",
      REDCAR: "Redcar",
      RIPON: "Ripon",
      ROMFORD: "Romford",
      ROMFORD_AM: "Romford am",
      RYE_HOUSE: "Rye House",
      SALISBURY: "Salisbury",
      SEDGEFIELD: "Sedgefield",
      SHAWFIELD: "Shawfield",
      SHEFFIELD: "Sheffield",
      SITTINGBOURNE: "Sittingbourne",
      SITTINGBOURNE_AM: "Sittingbourne Am",
      SOUTHWELL: "Southwell",
      STRATFORD_ON_AVON: "Stratford On Avon",
      SUNDERLAND: "Sunderland",
      SWAFFHAM: "Swaffham",
      SWINDON: "Swindon",
      TAUNTON: "Taunton",
      THIRSK: "Thirsk",
      TOWCESTER: "Towcester",
      UTTOXETER: "Uttoxeter",
      WETHERBY: "Wetherby",
      WIMBLEDON: "Wimbledon",
      WINCANTON: "Wincanton",
      WINDSOR: "Windsor",
      WISBECH: "Wisbech",
      WOLVERHAMPTON: "Wolverhampton",
      WORCESTER: "Worcester",
      YARMOUTH: "Yarmouth",
      HAPPY_VALLEY: "Happy Valley",
      SHA_TIN: "Sha Tin",
      BALLINROBE: "Ballinrobe",
      BELLEWSTOWN: "Bellewstown",
      CLONMEL: "Clonmel",
      CORK: "Cork",
      CURRAGH: "Curragh",
      DOWNPATRICK: "Downpatrick",
      DOWN_ROYAL: "Down Royal",
      DUNDALK: "Dundalk",
      FAIRYHOUSE: "Fairyhouse",
      GALWAY: "Galway",
      KILBEGGAN: "Kilbeggan",
      KILLARNEY: "Killarney",
      LAYTOWN: "Laytown",
      LEOPARDSTOWN: "Leopardstown",
      LIMERICK: "Limerick",
      LISTOWEL: "Listowel",
      NAAS: "Naas",
      NAVAN: "Navan",
      PUNCHESTOWN: "Punchestown",
      ROSCOMMON: "Roscommon",
      SLIGO: "Sligo",
      THURLES: "Thurles",
      TIPPERARY: "Tipperary",
      TRAMORE: "Tramore",
      WEXFORD: "Wexford",
      KAWASAKI: "Kawasaki",
      ADDINGTON: "Addington",
      ADDINGTON_EXTRA: "Addington Extra",
      ALEXANDRA_PARK: "Alexandra Park",
      ARAWA_PARK: "Arawa Park",
      ASCOT_PARK: "Ascot Park",
      ASCOT_PARK_EXTRA: "Ascot Park Extra",
      ASHBURTON: "Ashburton",
      ASHWOOD_PARK: "Ashwood Park",
      AVONDALE: "Avondale",
      AWAPUNI: "Awapuni",
      BLENHEIM: "Blenheim",
      CAMBRIDGE: "Cambridge",
      CROMWELL: "Cromwell",
      DARGAVILLE: "Dargaville",
      ELLERSLIE: "Ellerslie",
      FORBURY_PARK: "Forbury Park",
      FORBURY_PARK_EXTRA: "Forbury Park Extra",
      GERALDINE: "Geraldine",
      GORE: "Gore",
      GREYMOUTH: "Greymouth",
      HASTINGS: "Hastings",
      HATRICK: "Hatrick",
      HATRICK_EXTRA: "Hatrick Extra",
      HAWERA: "Hawera",
      HOKITIKA: "Hokitika",
      KAIKOURA: "Kaikoura",
      KUMARA: "Kumara",
      KUROW: "Kurow",
      MAKARAKA: "Makaraka",
      MANAWATU: "Manawatu",
      MANAWATU_EXTRA: "Manawatu Extra",
      MANUKAU: "Manukau",
      MANUKAU_EXTRA: "Manukau Extra",
      MATAMATA: "Matamata",
      MOTUKARARA: "Motukarara",
      MT_HARDING: "Mt Harding",
      NEW_PLYMOUTH: "New Plymouth",
      NEW_ZEALAND: "New Zealand",
      OAMARU: "Oamaru",
      OMAKAU: "Omakau",
      OMOTO: "Omoto",
      OTAKI: "Otaki",
      OTAKI_MAORI: "Otaki Maori",
      PHAR_LAP: "Phar Lap",
      PUKEKOHE: "Pukekohe",
      PUKEKURA: "Pukekura",
      RANGIORA: "Rangiora",
      REEFTON: "Reefton",
      RICCARTON_PARK: "Riccarton Park",
      RICHMOND_PARK: "Richmond Park",
      RIVERTON: "Riverton",
      ROTORUA: "Rotorua",
      ROXBURGH: "Roxburgh",
      RUAKAKA: "Ruakaka",
      STRATFORD: "Stratford",
      TAUHERENIKAU: "Tauherenikau",
      TAUPO: "Taupo",
      TAURANGA: "Tauranga",
      TE_AROHA: "Te Aroha",
      TE_AWAMUTU: "Te Awamutu",
      TE_KAPUA: "Te Kapua",
      TE_KAPUA_PARK: "Te Kapua Park",
      TE_KUPENGA: "Te Kupenga",
      TE_RAPA: "Te Rapa",
      TE_TEKO: "Te Teko",
      THAMES: "Thames",
      TRENTHAM: "Trentham",
      WAIKOUAITI: "Waikouaiti",
      WAIMATE: "Waimate",
      WAIPUKURAU: "Waipukurau",
      WAIROA: "Wairoa",
      WANGANUI: "Wanganui",
      WATERLEA: "Waterlea",
      WATERLEA_PARK: "Waterlea Park",
      WAVERLEY: "Waverley",
      WESTPORT: "Westport",
      WINGATUI: "Wingatui",
      WOODVILLE: "Woodville",
      CAMBRIDGE_EXTRA: "Cambridge Extra",
      SINGAPORE: "Singapore",
      AFTON: "Afton",
      AIKEN: "Aiken",
      AJAX_DOWNS: "Ajax Downs",
      ALBERTA_DOWNS: "Alberta Downs",
      ALBION: "Albion",
      ALBUQUERQUE: "Albuquerque",
      ALLEGAN: "Allegan",
      ALTAMONT: "Altamont",
      ANNA: "Anna",
      ANTHONY_DOWNS: "Anthony Downs",
      APACHE_COUNTY: "Apache County",
      AQUEDUCT: "Aqueduct",
      ARAPAHOE_PARK: "Arapahoe Park",
      ARIZONA_DOWNS: "Arizona Downs",
      ARLINGTON: "Arlington",
      ASHLAND: "Ashland",
      ASSINIBOIA_DOWNS: "Assiniboia Downs",
      ATHENS: "Athens",
      ATLANTA: "Atlanta",
      ATLANTIC_CITY: "Atlantic City",
      ATOKAD_DOWNS: "Atokad Downs",
      ATTICA: "Attica",
      AYERS_CLIFF: "Ayers Cliff",
      BALMORAL_PARK: "Balmoral Park",
      BANGOR_HOLLYWOOD_SLOTS: "Bangor Hollywood Slots",
      BARTON: "Barton",
      BATAVIA_DOWNS: "Batavia Downs",
      BAY_MEADOWS: "Bay Meadows",
      BEDFORD: "Bedford",
      BELLEFONTAINE: "Bellefontaine",
      BELTERRA_PARK: "Belterra Park",
      BELVIDERE: "Belvidere",
      BEULAH_PARK: "Beulah Park",
      BIG_RAPIDS: "Big Rapids",
      BIRMINGHAM_MATINEE: "Birmingham Matinee",
      BIRMINGHAM_NIGHT: "Birmingham Night",
      BLACK_FOOT: "Black Foot",
      BLOOMFIELD: "Bloomfield",
      BLOOMSBURG: "Bloomsburg",
      BLUE_GRASS_DOWNS: "Blue Grass Downs",
      BLUE_RIBBON_DOWNS: "Blue Ribbon Downs",
      BOONVILLE: "Boonville",
      BOWLING_GREEN: "Bowling Green",
      BROCKTON_FAIR: "Brockton Fair",
      BROOKHILL_FARM: "Brookhill Farm",
      BROWN_COUNTY: "Brown County",
      BUCYRUS: "Bucyrus",
      BUFFALO_RACEWAY: "Buffalo Raceway",
      BUTLER: "Butler",
      CALDWELL: "Caldwell",
      CAL_EXPO: "Cal Expo",
      CALIENTE_EVENING: "Caliente Evening",
      CAMARERO: "Camarero",
      CAMDEN: "Camden",
      CANFIELD: "Canfield",
      CANNON_FALLS: "Cannon Falls",
      CANTERBURY_PARK: "Canterbury Park",
      CANTON: "Canton",
      CARLINVILLE: "Carlinville",
      CARMI: "Carmi",
      CARROLLTON: "Carrollton",
      CASSIA_COUNTY: "Cassia County",
      CELINA: "Celina",
      CENTRAL_FAIR: "Central Fair",
      CENTREVILLE: "Centreville",
      CENTURY_DOWNS: "Century Downs",
      CENTURY_MILE: "Century Mile",
      CENTURY_MILE_2: "Century Mile 2",
      CHARLESTON: "Charleston",
      CHARLES_TOWN: "Charles Town",
      CHARLOTTE: "Charlotte",
      CHARLOTTETOWN: "Charlottetown",
      CHARLOTTETOWN_MATINEE: "Charlottetown Matinee",
      CHILLICOTHE: "Chillicothe",
      CHIPPEWA_DOWNS: "Chippewa Downs",
      CHURCHILL_DOWNS: "Churchill Downs",
      CIRCLEVILLE: "Circleville",
      CLEARFIELD: "Clearfield",
      CLEMMONS: "Clemmons",
      CLINTON_RACEWAY: "Clinton Raceway",
      COBLESKILL: "Cobleskill",
      COCHISE_COUNTY: "Cochise County",
      COLONIAL_DOWNS: "Colonial Downs",
      COLUMBUS: "Columbus",
      CONNERSVILLE: "Connersville",
      CONVERSE: "Converse",
      CORYDON: "Corydon",
      COSHOCTON: "Coshocton",
      CROOKED_RIVER: "Crooked River",
      CROSWELL: "Croswell",
      CROTON: "Croton",
      CUMBERLAND: "Cumberland",
      DAYTON: "Dayton",
      DAYTONA_BEACH_EVENING: "Daytona Beach Evening",
      DAYTONA_BEACH_MATINEE: "Daytona Beach Matinee",
      DAYTON_RACEWAY: "Dayton Raceway",
      DECATUR: "Decatur",
      DELAWARE_COUNTY_FAIR: "Delaware County Fair",
      DELAWARE_COUNTY_FAIR_MATINEE: "Delaware County Fair Matinee",
      DELAWARE_PARK: "Delaware Park",
      DELEON_SPRINGS: "Deleon Springs",
      DELTA_DOWNS: "Delta Downs",
      DEL_MAR: "Del Mar",
      DERBY_LANE: "Derby Lane",
      DESERT_PARK: "Desert Park",
      DETROIT_RACE_COURSE: "Detroit Race Course",
      DIXIE_DOWNS: "Dixie Downs",
      DOVER: "Dover",
      DOVER_DOWNS: "Dover Downs",
      DRESDEN_RACEWAY: "Dresden Raceway",
      DUNKIRK: "Dunkirk",
      DU_QUOIN: "Du Quoin",
      DU_QUOIN_EVENING: "Du Quoin Evening",
      DU_QUOIN_STATE_FAIR: "Du Quoin State Fair",
      EATON: "Eaton",
      ELDON: "Eldon",
      ELKHORN: "Elkhorn",
      ELKO_COUNTY_FAIR: "Elko County Fair",
      ELLIS_PARK: "Ellis Park",
      ELMIRA: "Elmira",
      ELY: "Ely",
      EMERALD_DOWNS: "Emerald Downs",
      EMMETT: "Emmett",
      ENERGY_DOWNS: "Energy Downs",
      EUREKA: "Eureka",
      EVANGELINE_DOWNS: "Evangeline Downs",
      EXHIBITION_PARK: "Exhibition Park",
      FAIRFAX: "Fairfax",
      FAIRFIELD: "Fairfield",
      FAIRMOUNT_PARK: "Fairmount Park",
      FAIRPLEX_PARK: "Fairplex Park",
      FAIR_GROUNDS: "Fair Grounds",
      FAIR_HILL: "Fair Hill",
      FAIR_MEADOWS_TULSA: "Fair Meadows Tulsa",
      FAIR_PLAY_PARK: "Fair Play Park",
      FARMER_CITY: "Farmer City",
      FARMINGTON: "Farmington",
      FAR_HILLS: "Far Hills",
      FAYETTEVILLE: "Fayetteville",
      FERNDALE: "Ferndale",
      FINDLAY: "Findlay",
      FINGER_LAKES: "Finger Lakes",
      FLAGSTAFF: "Flagstaff",
      FLAMBORO_DOWNS: "Flamboro Downs",
      FONNER_PARK: "Fonner Park",
      FORT_ERIE: "Fort Erie",
      FORT_PIERRE: "Fort Pierre",
      FOWLERVILLE: "Fowlerville",
      FOXFIELD: "Foxfield",
      FRANKFORT: "Frankfort",
      FRASER_DOWNS: "Fraser Downs",
      FREDERICK: "Frederick",
      FREDERICTON_RACEWAY: "Fredericton Raceway",
      FREEHOLD_RACEWAY: "Freehold Raceway",
      FRESNO: "Fresno",
      FRYEBURG: "Fryeburg",
      GAITWAY_FARMS: "Gaitway Farms",
      GARDEN_STATE: "Garden State",
      GENESEE_VALLEY: "Genesee Valley",
      GEORGIAN_DOWNS: "Georgian Downs",
      GILA_COUNTY_FAIR: "Gila County Fair",
      GILLESPIE_COUNTY: "Gillespie County",
      GLENBORO: "Glenboro",
      GLYNDON: "Glyndon",
      GOLDEN_GATE_FIELDS: "Golden Gate Fields",
      GOSHEN: "Goshen",
      GOUVERNEUR: "Gouverneur",
      GRAHAM_COUNTY: "Graham County",
      GRANDE_PRAIRIE: "Grande Prairie",
      GRAND_NATIONAL: "Grand National",
      GRAND_PRAIRIE: "Grand Prairie",
      GRAND_RIVER_RACEWAY: "Grand River Raceway",
      GRANTS_PASS: "Grants Pass",
      GRATZ: "Gratz",
      GREAT_BARRINGTON_FAIR: "Great Barrington Fair",
      GREAT_FALLS: "Great Falls",
      GREAT_LAKES_DOWNS: "Great Lakes Downs",
      GREAT_MEADOW: "Great Meadow",
      GREENELEE_COUNTY: "Greenelee County",
      GREENUP: "Greenup",
      GREENVILLE: "Greenville",
      GULFSTREAM_PARK: "Gulfstream Park",
      GULFSTREAM_PARK_WEST: "Gulfstream Park West",
      HANOVER_RACEWAY: "Hanover Raceway",
      HARBOR_PARK: "Harbor Park",
      HARNEY_COUNTY: "Harney County",
      HARRAHS_CHESTER: "Harrahs Chester",
      HARRAHS_PHILADELPHIA: "Harrahs Philadelphia",
      HARRINGTON_RACEWAY: "Harrington Raceway",
      HARRISON: "Harrison",
      HART: "Hart",
      HAWTHORNE: "Hawthorne",
      HAWTHORNE_RACECOURSE: "Hawthorne Racecourse",
      HAZEL_PARK: "Hazel Park",
      HAZEL_PARK_HARNESS: "Hazel Park Harness",
      HELENA_DOWNS: "Helena Downs",
      HEMLOCK: "Hemlock",
      HENRY: "Henry",
      HIALEAH_PARK: "Hialeah Park",
      HIAWATHA_HORSE_PARK: "Hiawatha Horse Park",
      HICKSVILLE: "Hicksville",
      HILLIARD: "Hilliard",
      HIPPODROME_3R: "Hippodrome 3r",
      HIPPODROME_DE_QUEBEC: "Hippodrome De Quebec",
      HISTORIC_TRACK_GOSHEN: "Historic Track Goshen",
      HOLLAND: "Holland",
      HOLLYWOOD_PARK: "Hollywood Park",
      HONESDALE: "Honesdale",
      HOOSIER_PARK: "Hoosier Park",
      HORSEMENS_PARK: "Horsemens Park",
      HUGHESVILLE: "Hughesville",
      HUMBOLDT: "Humboldt",
      HUMBOLDT_COUNTY: "Humboldt County",
      INDIANA: "Indiana",
      INDIANA_DOWNS: "Indiana Downs",
      INDIANA_STATE_FAIR: "Indiana State Fair",
      INVERNESS_RACEWAY: "Inverness Raceway",
      IOWA_EVENING: "Iowa Evening",
      IOWA_MATINEE: "Iowa Matinee",
      IRONWOOD: "Ironwood",
      JACKSONVILLE_MATINEE: "Jacksonville Matinee",
      JACKSONVILLE_NIGHT: "Jacksonville Night",
      JEFFERSON: "Jefferson",
      JEROME_COUNTY: "Jerome County",
      KAHOKIA: "Kahokia",
      KALISPELL: "Kalispell",
      KAMLOOPS: "Kamloops",
      KAWARTHA_DOWNS: "Kawartha Downs",
      KEENELAND: "Keeneland",
      KENDALLVILLE: "Kendallville",
      KENTLAND: "Kentland",
      KENTON: "Kenton",
      KENTUCKY_DOWNS: "Kentucky Downs",
      KINROSS: "Kinross",
      KIN_PARK: "Kin Park",
      KNOXVILLE: "Knoxville",
      LACENTER: "Lacenter",
      LANCASTER: "Lancaster",
      LAUREL_BROWN: "Laurel Brown",
      LAUREL_PARK: "Laurel Park",
      LEAMINGTON_RACEWAY: "Leamington Raceway",
      LEBANON: "Lebanon",
      LEBANON_RACEWAY: "Lebanon Raceway",
      LES_BOIS: "Les Bois",
      LETHBRIDGE: "Lethbridge",
      LEWISBURG: "Lewisburg",
      LEWISTOWN: "Lewistown",
      LEXINGTON: "Lexington",
      LIMA: "Lima",
      LINCOLN: "Lincoln",
      LISBON: "Lisbon",
      LITTLE_EVERGLADES: "Little Everglades",
      LONE_STAR_PARK: "Lone Star Park",
      LOS_ALAMITOS: "Los Alamitos",
      LOS_ALAMITOS_QH: "Los Alamitos Qh",
      LOUISIANA_DOWNS: "Louisiana Downs",
      LOWVILLE: "Lowville",
      MAHONING_VALLEY: "Mahoning Valley",
      MALONE: "Malone",
      MALVERN: "Malvern",
      MANOR_DOWNS: "Manor Downs",
      MARIAS_FAIR: "Marias Fair",
      MARIETTA: "Marietta",
      MARION: "Marion",
      MARQUIS_DOWNS: "Marquis Downs",
      MARSHALL: "Marshall",
      MARTINSVILLE: "Martinsville",
      MARYSVILLE: "Marysville",
      MAYFIELD: "Mayfield",
      MAYWOOD_PARK: "Maywood Park",
      MCCONNELSVILLE: "Mcconnelsville",
      MEADOWLANDS: "Meadowlands",
      MEADVILLE: "Meadville",
      MELVILLE_DISTRICT_AGRIPAR: "Melville District Agripar",
      MIAMI: "Miami",
      MIAMI_VALLEY: "Miami Valley",
      MIDDLEBURG: "Middleburg",
      MILES_CITY: "Miles City",
      MILLARVILLE: "Millarville",
      MINERAL_POINT: "Mineral Point",
      MINIDOKA_COUNTY: "Minidoka County",
      MOHAVE_COUNTY: "Mohave County",
      MOHAWK_RACETRACK: "Mohawk Racetrack",
      MONKTON: "Monkton",
      MONMOUTH_PARK: "Monmouth Park",
      MONTICELLO_RACEWAY: "Monticello Raceway",
      MONTPELIER: "Montpelier",
      MORRIS: "Morris",
      MORRISON: "Morrison",
      MORVEN_PARK: "Morven Park",
      MOUNTAINEER_PARK: "Mountaineer Park",
      MOUNT_GILEAD: "Mount Gilead",
      MOUNT_STERLING: "Mount Sterling",
      MOUNT_VERNON: "Mount Vernon",
      MT_PLEASANT_MEADOWS: "Mt Pleasant Meadows",
      NAPOLEON: "Napoleon",
      NEWTON: "Newton",
      NORTHAMPTON: "Northampton",
      NORTHERN_MAINE_FAIR: "Northern Maine Fair",
      NORTHFIELD_PARK: "Northfield Park",
      NORTHLANDS_PARK: "Northlands Park",
      NORTHVILLE_DOWNS: "Northville Downs",
      NORTH_DAKOTA_HORSE_PARK: "North Dakota Horse Park",
      NORTH_SIDE_DOWNS: "North Side Downs",
      NORWICH: "Norwich",
      NOUVELLE: "Nouvelle",
      OAKLAWN_PARK: "Oaklawn Park",
      OAK_GROVE_RACING_GAMING: "Oak Grove Racing Gaming",
      OAK_HARBOR: "Oak Harbor",
      OAK_RIDGE: "Oak Ridge",
      OAK_TREE_AT_HOLLYWOOD: "Oak Tree At Hollywood",
      OAK_TREE_AT_PLEASANTON: "Oak Tree At Pleasanton",
      OAK_TREE_AT_SANTA_ANITA: "Oak Tree At Santa Anita",
      OCALA_TRAINING_CENTER: "Ocala Training Center",
      OCEAN_DOWNS: "Ocean Downs",
      OLD_WASHINGTON: "Old Washington",
      ONEIDA_COUNTY: "Oneida County",
      OREGON_LIVESTOCK: "Oregon Livestock",
      OSKALOOSA: "Oskaloosa",
      OTTAWA: "Ottawa",
      OWEGO: "Owego",
      OXFORD: "Oxford",
      PABOS: "Pabos",
      PADUCAH: "Paducah",
      PAINESVILLE: "Painesville",
      PALMYRA: "Palmyra",
      PALM_BEACH_EVENING: "Palm Beach Evening",
      PALM_BEACH_MATINEE: "Palm Beach Matinee",
      PANA: "Pana",
      PARIS: "Paris",
      PAULDING: "Paulding",
      PBKC_SUMMER_MATINEE: "Pbkc Summer Matinee",
      PENNSYLVANIA_HUNT_CUP: "Pennsylvania Hunt Cup",
      PENN_NATIONAL: "Penn National",
      PERCY_WARNER: "Percy Warner",
      PETERSBURG: "Petersburg",
      PHILADELPHIA: "Philadelphia",
      PHILADELPHIA_PARK: "Philadelphia Park",
      PICOV_DOWNS: "Picov Downs",
      PIKETON: "Piketon",
      PIMLICO: "Pimlico",
      PINE_MTN_CALLOWAY_GARDEN: "Pine Mtn Calloway Garden",
      PINNACLE_RACE_COURSE: "Pinnacle Race Course",
      PLAINRIDGE_RACECOURSE: "Plainridge Racecourse",
      PLATTSBURG: "Plattsburg",
      PLAYERS_BLUEGRASS_DOWNS: "Players Bluegrass Downs",
      PLAYFAIR: "Playfair",
      PLEASANTON: "Pleasanton",
      POCATELLO_DOWNS: "Pocatello Downs",
      POCONO_DOWNS: "Pocono Downs",
      POMPANO_PARK: "Pompano Park",
      PORTLAND: "Portland",
      PORTLAND_MEADOWS: "Portland Meadows",
      PRAIRIE_MEADOWS: "Prairie Meadows",
      PRESCOTT_DOWNS: "Prescott Downs",
      PRESQUE_ISLE_DOWNS: "Presque Isle Downs",
      PRINEVILLE_TURF_CLUB: "Prineville Turf Club",
      QUEENSBURY_DOWNS: "Queensbury Downs",
      RACEWAY_PARK: "Raceway Park",
      REMINGTON_PARK: "Remington Park",
      RETAMA_PARK: "Retama Park",
      RICE_LAKE: "Rice Lake",
      RICHLAND_CENTER: "Richland Center",
      RICHWOOD: "Richwood",
      RIDEAU_CARLETON: "Rideau Carleton",
      RILLITO: "Rillito",
      RIVER_AT_BEULAH: "River At Beulah",
      RIVER_DOWNS: "River Downs",
      ROCKINGHAM_PARK: "Rockingham Park",
      ROCKSPRINGS: "Rocksprings",
      ROSECROFT_RACEWAY: "Rosecroft Raceway",
      ROSSBURN_PARK: "Rossburn Park",
      ROUND_UP_PARK: "Round Up Park",
      RUIDOSO_DOWNS: "Ruidoso Downs",
      RUNNING_ACES: "Running Aces",
      RUSHVILLE: "Rushville",
      SACRAMENTO: "Sacramento",
      SAINT_ESPRIT: "Saint Esprit",
      SAINT_PETER: "Saint Peter",
      SALEM: "Salem",
      SAM_HOUSTON: "Sam Houston",
      SANDWICH: "Sandwich",
      SANDY_CREEK: "Sandy Creek",
      SANDY_DOWNS: "Sandy Downs",
      SANFORD_ORLANDO_EVENING: "Sanford Orlando Evening",
      SANFORD_ORLANDO_MATINEE: "Sanford Orlando Matinee",
      SANTA_ANITA: "Santa Anita",
      SANTA_CRUZ_COUNTY: "Santa Cruz County",
      SANTA_FE: "Santa Fe",
      SANTA_ROSA: "Santa Rosa",
      SARATOGA: "Saratoga",
      SARATOGA_HARNESS: "Saratoga Harness",
      SCARBOROUGH_DOWNS: "Scarborough Downs",
      SCIOTO_DOWNS: "Scioto Downs",
      SHAWAN_DOWNS: "Shawan Downs",
      SHELBYVILLE: "Shelbyville",
      SHENANDOAH_DOWNS: "Shenandoah Downs",
      SIDNEY: "Sidney",
      SILVER_SPRINGS_RANCH: "Silver Springs Ranch",
      SIOUX_CENTER: "Sioux Center",
      SKOWHEGAN: "Skowhegan",
      SOLANO: "Solano",
      SOUTHERN_OAKS_TRAINING_CENTER: "Southern Oaks Training Center",
      SOUTHLAND_MATINEE: "Southland Matinee",
      SOUTHLAND_NIGHT: "Southland Night",
      SOUTHLAND_TWILIGHT: "Southland Twilight",
      SPORTSMANS_PARK: "Sportsmans Park",
      SPORTS_CREEK_RACEWAY: "Sports Creek Raceway",
      SPRINGFIELD_ILLINOIS: "Springfield Illinois",
      STAMPEDE_PARK: "Stampede Park",
      STOCKTON: "Stockton",
      STONEBORO: "Stoneboro",
      STONEYBROOK_AT_FIVE_POINTS: "Stoneybrook At Five Points",
      ST_AIME_DES_LACS: "St Aime Des Lacs",
      ST_HUGUES: "St Hugues",
      ST_JOHNS_MATINEE: "St Johns Matinee",
      ST_JOHNS_NIGHT: "St Johns Night",
      ST_JOHNS_RACING: "St Johns Racing",
      ST_JOHN_S: "St John S",
      ST_JOSEPH_DE_LEPAGE: "St Joseph De Lepage",
      SUDBURY_DOWNS: "Sudbury Downs",
      SUFFOLK_DOWNS: "Suffolk Downs",
      SUMMERSIDE_RACEWAY: "Summerside Raceway",
      SUNLAND_PARK: "Sunland Park",
      SUNRAY_PARK: "Sunray Park",
      SUN_DOWNS: "Sun Downs",
      SWEETWATER_DOWNS: "Sweetwater Downs",
      TAMPA_BAY_DOWNS: "Tampa Bay Downs",
      TAMPA_DL_MATINEE: "Tampa DL Matinee",
      TAMPA_DL_NIGHT: "Tampa DL Night",
      TESTING: "TESTING",
      THE_RED_MILE: "The Red Mile",
      THE_TRACK_ON_2: "The Track On 2",
      THISTLEDOWN: "Thistledown",
      THUNDER_RIDGE: "Thunder Ridge",
      TIFFIN: "Tiffin",
      TILLAMOOK_COUNTY: "Tillamook County",
      TIMONIUM: "Timonium",
      TIOGA_DOWNS: "Tioga Downs",
      TOPSHAM: "Topsham",
      TROY: "Troy",
      TRUMANSBURG: "Trumansburg",
      TRURO_RACEWAY: "Truro Raceway",
      TRYON: "Tryon",
      TUNBRIDGE: "Tunbridge",
      TURFWAY_PARK: "Turfway Park",
      TURF_PARADISE: "Turf Paradise",
      UNION: "Union",
      UNIONVILLE: "Unionville",
      UPPER_SANDUSKY: "Upper Sandusky",
      URBANA: "Urbana",
      VAN_WERT: "Van Wert",
      VERNON_DOWNS: "Vernon Downs",
      VIROQUA: "Viroqua",
      WAITSBURG_RACE: "Waitsburg Race",
      WALLA_WALLA: "Walla Walla",
      WAPAKONETA: "Wapakoneta",
      WASHINGTON: "Washington",
      WASHINGTON_COURT_HOUSE: "Washington Court House",
      WATTSBURG: "Wattsburg",
      WAUSEON: "Wauseon",
      WAYNESBURG: "Waynesburg",
      WEBER_DOWNS: "Weber Downs",
      WEBSTER_CITY: "Webster City",
      WELLSTOWN: "Wellstown",
      WESTERN_FAIR_RACEWAY: "Western Fair Raceway",
      WESTERN_MT_FAIR: "Western Mt Fair",
      WHAT_CHEER: "What Cheer",
      WHEATON: "Wheaton",
      WHEELING_MATINEE: "Wheeling Matinee",
      WHEELING_NIGHT: "Wheeling Night",
      WHITE_PINE: "White Pine",
      WILLOWDALE: "Willowdale",
      WILL_ROGERS_DOWNS: "Will Rogers Downs",
      WILMINGTON: "Wilmington",
      WINDSOR_RACEWAY: "Windsor Raceway",
      WINTERTHUR: "Winterthur",
      WOODBINE: "Woodbine",
      WOODBINE_2: "Woodbine 2",
      WOODLANDS: "Woodlands",
      WOODSFIELD: "Woodsfield",
      WOODSTOCK: "Woodstock",
      WOODSTOCK_RACEWAY: "Woodstock Raceway",
      WOOSTER: "Wooster",
      WYOMING_DOWNS: "Wyoming Downs",
      XENIA: "Xenia",
      YAKIMA_MEADOWS: "Yakima Meadows",
      YAVAPAI_DOWNS: "Yavapai Downs",
      YELLOWSTONE_DOWNS: "Yellowstone Downs",
      YONKERS_RACEWAY: "Yonkers Raceway",
      YORKTON: "Yorkton",
      YORKTON_EX_ASSOC: "Yorkton Ex Assoc",
      ZANESVILLE: "Zanesville",
      ZIA_PARK: "Zia Park",
      FLAGLER_MATINEE: "Flagler Matinee",
      FLAGLER_NIGHT: "Flagler Night",
      FLAGLER_TWILIGHT: "Flagler Twilight",
      MARDI_GRAS_EVENING: "Mardi Gras Evening",
      MARDI_GRAS_MATINEE: "Mardi Gras Matinee",
      SARASOTA_MATINEE: "Sarasota Matinee",
      TUCSON: "Tucson",
      TRI_STATE_MATINEE: "Tri State Matinee",
      DURBANVILLE: "Durbanville",
      FAIRVIEW: "Fairview",
      FLAMINGO_PARK: "Flamingo Park",
      GREYVILLE: "Greyville",
      KENILWORTH: "Kenilworth",
      SCOTTSVILLE: "Scottsville",
      TURFFONTEIN: "Turffontein",
      VAAL: "Vaal",
    },
    countries: {
      BD: "Bangladesh",
      BE: "Belgium",
      BF: "Burkina Faso",
      BG: "Bulgaria",
      BA: "Bosnia and Herzegovina",
      BB: "Barbados",
      WF: "Wallis and Futuna",
      BL: "Saint Barthelemy",
      BM: "Bermuda",
      BN: "Brunei",
      BO: "Bolivia",
      BH: "Bahrain",
      BI: "Burundi",
      BJ: "Benin",
      BT: "Bhutan",
      JM: "Jamaica",
      BV: "Bouvet Island",
      BW: "Botswana",
      WS: "Samoa",
      BQ: "Bonaire, Saint Eustatius and Saba",
      BR: "Brazil",
      BS: "Bahamas",
      JE: "Jersey",
      BY: "Belarus",
      BZ: "Belize",
      RU: "Russia",
      RW: "Rwanda",
      RS: "Serbia",
      TL: "East Timor",
      RE: "Reunion",
      TM: "Turkmenistan",
      TJ: "Tajikistan",
      RO: "Romania",
      TK: "Tokelau",
      GW: "Guinea-Bissau",
      GU: "Guam",
      GT: "Guatemala",
      GS: "South Georgia and the South Sandwich Islands",
      GR: "Greece",
      GQ: "Equatorial Guinea",
      GP: "Guadeloupe",
      JP: "Japan",
      GY: "Guyana",
      GG: "Guernsey",
      GF: "French Guiana",
      GE: "Georgia",
      GD: "Grenada",
      GB: "United Kingdom",
      GA: "Gabon",
      SV: "El Salvador",
      GN: "Guinea",
      GM: "Gambia",
      GL: "Greenland",
      GI: "Gibraltar",
      GH: "Ghana",
      OM: "Oman",
      TN: "Tunisia",
      JO: "Jordan",
      HR: "Croatia",
      HT: "Haiti",
      HU: "Hungary",
      HK: "Hong Kong",
      HN: "Honduras",
      HM: "Heard Island and McDonald Islands",
      VE: "Venezuela",
      PR: "Puerto Rico",
      PS: "Palestinian Territory",
      PW: "Palau",
      PT: "Portugal",
      SJ: "Svalbard and Jan Mayen",
      PY: "Paraguay",
      IQ: "Iraq",
      PA: "Panama",
      PF: "French Polynesia",
      PG: "Papua New Guinea",
      PE: "Peru",
      PK: "Pakistan",
      PH: "Philippines",
      PN: "Pitcairn",
      PL: "Poland",
      PM: "Saint Pierre and Miquelon",
      ZM: "Zambia",
      EH: "Western Sahara",
      EE: "Estonia",
      EG: "Egypt",
      ZA: "South Africa",
      EC: "Ecuador",
      IT: "Italy",
      VN: "Vietnam",
      SB: "Solomon Islands",
      ET: "Ethiopia",
      SO: "Somalia",
      ZW: "Zimbabwe",
      SA: "Saudi Arabia",
      ES: "Spain",
      ER: "Eritrea",
      ME: "Montenegro",
      MD: "Moldova",
      MG: "Madagascar",
      MF: "Saint Martin",
      MA: "Morocco",
      MC: "Monaco",
      UZ: "Uzbekistan",
      MM: "Myanmar",
      ML: "Mali",
      MO: "Macao",
      MN: "Mongolia",
      MH: "Marshall Islands",
      MK: "Macedonia",
      MU: "Mauritius",
      MT: "Malta",
      MW: "Malawi",
      MV: "Maldives",
      MQ: "Martinique",
      MP: "Northern Mariana Islands",
      MS: "Montserrat",
      MR: "Mauritania",
      IM: "Isle of Man",
      UG: "Uganda",
      TZ: "Tanzania",
      MY: "Malaysia",
      MX: "Mexico",
      IL: "Israel",
      FR: "France",
      IO: "British Indian Ocean Territory",
      SH: "Saint Helena",
      FI: "Finland",
      FJ: "Fiji",
      FK: "Falkland Islands",
      FM: "Micronesia",
      FO: "Faroe Islands",
      NI: "Nicaragua",
      NL: "Netherlands",
      NO: "Norway",
      NA: "Namibia",
      VU: "Vanuatu",
      NC: "New Caledonia",
      NE: "Niger",
      NF: "Norfolk Island",
      NG: "Nigeria",
      NZ: "New Zealand",
      NP: "Nepal",
      NR: "Nauru",
      NU: "Niue",
      CK: "Cook Islands",
      XK: "Kosovo",
      CI: "Ivory Coast",
      CH: "Switzerland",
      CO: "Colombia",
      CN: "China",
      CM: "Cameroon",
      CL: "Chile",
      CC: "Cocos Islands",
      CA: "Canada",
      CG: "Republic of the Congo",
      CF: "Central African Republic",
      CD: "Democratic Republic of the Congo",
      CZ: "Czech Republic",
      CY: "Cyprus",
      CX: "Christmas Island",
      CR: "Costa Rica",
      CW: "Curacao",
      CV: "Cape Verde",
      CU: "Cuba",
      SZ: "Swaziland",
      SY: "Syria",
      SX: "Sint Maarten",
      KG: "Kyrgyzstan",
      KE: "Kenya",
      SS: "South Sudan",
      SR: "Suriname",
      KI: "Kiribati",
      KH: "Cambodia",
      KN: "Saint Kitts and Nevis",
      KM: "Comoros",
      ST: "Sao Tome and Principe",
      SK: "Slovakia",
      KR: "South Korea",
      SI: "Slovenia",
      KP: "North Korea",
      KW: "Kuwait",
      SN: "Senegal",
      SM: "San Marino",
      SL: "Sierra Leone",
      SC: "Seychelles",
      KZ: "Kazakhstan",
      KY: "Cayman Islands",
      SG: "Singapore",
      SE: "Sweden",
      SD: "Sudan",
      DO: "Dominican Republic",
      DM: "Dominica",
      DJ: "Djibouti",
      DK: "Denmark",
      VG: "British Virgin Islands",
      DE: "Germany",
      YE: "Yemen",
      DZ: "Algeria",
      US: "United States",
      UY: "Uruguay",
      YT: "Mayotte",
      UM: "United States Minor Outlying Islands",
      LB: "Lebanon",
      LC: "Saint Lucia",
      LA: "Laos",
      TV: "Tuvalu",
      TW: "Taiwan",
      TT: "Trinidad and Tobago",
      TR: "Turkey",
      LK: "Sri Lanka",
      LI: "Liechtenstein",
      LV: "Latvia",
      TO: "Tonga",
      LT: "Lithuania",
      LU: "Luxembourg",
      LR: "Liberia",
      LS: "Lesotho",
      TH: "Thailand",
      TF: "French Southern Territories",
      TG: "Togo",
      TD: "Chad",
      TC: "Turks and Caicos Islands",
      LY: "Libya",
      VA: "Vatican",
      VC: "Saint Vincent and the Grenadines",
      AE: "United Arab Emirates",
      AD: "Andorra",
      AG: "Antigua and Barbuda",
      AF: "Afghanistan",
      AI: "Anguilla",
      VI: "U.S. Virgin Islands",
      IS: "Iceland",
      IR: "Iran",
      AM: "Armenia",
      AL: "Albania",
      AO: "Angola",
      AQ: "Antarctica",
      AS: "American Samoa",
      AR: "Argentina",
      AU: "Australia",
      AT: "Austria",
      AW: "Aruba",
      IN: "India",
      AX: "Aland Islands",
      AZ: "Azerbaijan",
      IE: "Ireland",
      ID: "Indonesia",
      UA: "Ukraine",
      QA: "Qatar",
      MZ: "Mozambique",
    },
  },
  fr: {
    errors: {
      noRaces: "Courses non disponibles pour le moment!",
      emptyBetSlip: "Le bulletin de pari est vide!",
      emptyOpenBets: "Il n'y a pas de paris ouverts!",
      noRaceData: "Données de course non disponibles!",
      cancelledRace: "La course est annulée!",
    },
    generals: {
      allRaces: "Toutes les courses",
      races: "Les courses",
      race: "Course",
      runners: "Coureurs",
      transactions: "Transactions",
      home: "Domicile",
      openBets: "Paris ouverts",
      betSlip: "Pari slip",
      raceType: "Type de course",
      date: "Date",
      time: "Temps",
      distance: "Distance",
      trackCondition: "Condition de piste",
      weather: "Temps",
      filter: "Filtre",
      thRacing: "Racing pur-sang",
      hsRacing: "Racing de harnais",
      doRacing: "Les courses de lévriers",
      helpCenter: "Centre d'aide",
      demoVideos: "Vidéos de démonstration",
      upcomingRaces: "Courses à venir",
      percentage: "Pourcentage",
      amount: "Quantité",
      addBet: "Ajouter un pari",
      placeAllBets: "Placer tous les paris",
      bet: "Pari",
      clear_all: "Tout effacer",
      betTypes: "Types de paris",
      winners: "Gagnants",
      payOut: "Payer",
      pool_type: "Type de piscine",
      bet_status: "Statut de pari",
      combinations: "Combinaisons",
      investments: "Investissements",
      selection_amount: "Montant de sélection",
      total_winnings: "Le total des gains",
      t_id: "T_id",
      tracks: "Des pistes",
      race_number: "Numéro de course",
      invested: "Investi",
      pnl: "Pnl",
      poolType: "Type de piscine",
      costPerCombo: "Coût / combo",
      processing: "Traitement",
      TH: "De race",
      HS: "Harnais",
      DO: "Levrette",
      RESULTED: "ABOUTI",
      OPEN: "OUVRIR",
      CLOSED: "FERMÉ",
      CANCELLED: "ANNULÉ",
      odds: "Chances",
      pool: "Piscine",
      betAmount: "Montant de pari",
      WIN_PLACE: "Gagner/Endroit",
      WIN: "Gagner",
      PLACE: "Endroit",
      SHOW: "Afficher",
      QUINELLA: "Quinella",
      SWINGER: "Swinger",
      EXACTA: "Exacta",
      TRIFECTA: "Trifecta",
      SUPERFECTA: "Superfecta",
      FO_WIN: "Gagner fixe",
      FO_PLACE: "Lieu fixe",
      pools_payout: "Paiement des piscines",
      no_data_available: "Pas de données disponibles.",
      first: "1er",
      second: "2e",
      third: "3e",
      fourth: "4e",
      boxed: "Boxed",
      negotiateMessage: "Voulez-vous négocier les cotes avec",
      accept: "Accepter",
      reject: "Rejeter",
      today: "Aujourd'hui",
      yesterday: "Hier",
      expertTips: "Conseils d'experts",
      winProbability: "Probabilité de victoire",
      tip: "Tips",
    },
    tracks: {
      ABU_DHABI: "Abu Dhabi",
      JEBEL_ALI: "Jebel Ali",
      MEYDAN: "Meydan",
      SHARJAH: "Sharjah",
      ADAMINABY: "Adaminaby",
      ADELAIDE_RIVER: "River Adélaïde",
      ALBANY: "Albany",
      ALBION_PARK: "Albion Park",
      ALBURY: "Albury",
      ALEXANDRA: "Alexandra",
      ALICE_SPRINGS: "Alice Springs",
      ALPHA: "Alpha",
      ANGLE_PARK: "Angle Park",
      ARAMAC: "Aramac",
      ARARAT: "Ararat",
      ARDLETHAN: "Ardlethan",
      ARMIDALE: "Armidale",
      ASCOT: "ASCOT",
      ATHERTON: "Atherton",
      AUGATHELLA: "Augathella",
      AVOCA: "Avoca",
      BAIRNSDALE: "Bairnsdale",
      BALAKLAVA: "Balaklava",
      BALLARAT: "Ballarat",
      BALLART_SYNTHETIC: "Balart synthétique",
      BALLINA: "Ballina",
      BALNARRING: "Balnarring",
      BALRANALD: "Balranald",
      BANKSTOWN: "Bankstown",
      BARCALDINE: "Barcaldine",
      BARRABA: "Barraba",
      BATHURST: "Bathurst",
      BEAUDESERT: "Beaudesert",
      BEAUMONT: "Beaumont",
      BEDOURIE: "Bedourie",
      BELL: "Cloche",
      BELMONT_PARK: "Belmont Park",
      BENALLA: "Benalla",
      BENDIGO: "Bendigo",
      BERRIGAN: "Berrigan",
      BETOOTA: "Betoota",
      BINGARA: "Bingara",
      BINNAWAY: "Binnaway",
      BIRCHIP: "Bouleau",
      BIRDSVILLE: "Birdsville",
      BLACKALL: "Blackall",
      BLAYNEY: "Blayney",
      BLUFF: "Bluffer",
      BOMBALA: "Bombala",
      BONG_BONG: "Bong bóng",
      BOORT: "BOORT",
      BORDERTOWN: "Ville frontière",
      BOULIA: "Boulia",
      BOURKE: "Bourke",
      BOWEN: "Bowen",
      BOWRAVILLE: "Bowraville",
      BRAIDWOOD: "Braidwood",
      BREWARRINA: "Brewarrina",
      BRIDGETOWN: "Bridgetown",
      BROKEN_HILL: "Broken Hill",
      BROOME: "Broome",
      BUCHAN: "Buchan",
      BULLI: "Bulli",
      BUNBURY: "Bunbury",
      BUNDABERG: "Bundaberg",
      BUNDARRA: "Bundarra",
      BURNIE: "Burnie",
      BURRA: "Burra",
      BURRUMBEET: "Burrumbeet",
      BUSSELTON: "Busselton",
      CAIRNS: "Cairns",
      CALLIOPE: "Calliope",
      CAMOOWEAL: "Camooweal",
      CAMPERDOWN: "Camperdown",
      CANBERRA: "Canberra",
      CANNINGTON: "Cannington",
      CANTERBURY: "Canterbury",
      CAPALABA: "Capalaba",
      CARINDA: "Carinda",
      CARNARVON: "Carnarvon",
      CARRATHOOL: "Carrathool",
      CARRICK_PARK: "Carrick Park",
      CASINO: "Casino",
      CASTERTON: "Casterton",
      CAULFIELD: "Caulfield",
      CEDUNA: "Ceduna",
      CESSNOCK: "Cessnock",
      CHARLEVILLE: "Charleville",
      CHARLTON: "Charlton",
      CHARTERS_TOWERS: "Towers charters",
      CHINCHILLA: "Chinchilla",
      CLARE: "Clare",
      CLERMONT: "Clermont",
      CLIFTON: "Clifton",
      CLONCURRY: "Clconcurry",
      COBAR: "Cobar",
      COBRAM: "Cobram",
      COFFS_HARBOUR: "Coffs Harbour",
      COLAC: "Colac",
      COLERAINE: "Coleraine",
      COLLARENEBRI: "Collarenebri",
      COLLIE: "Colley",
      COOKTOWN: "Cooktown",
      COOLAMON: "Coolamon",
      COOMA_MONARO_RACE_CLUB: "Cooma Monaro Race Club",
      COONABARABRAN: "Coonabarabran",
      COONAMBLE: "Coonamble",
      COOTAMUNDRA: "Cootamundra",
      COROWA: "Corowa",
      COWRA: "Cowra",
      CRANBOURNE: "Cranbourne",
      CUNNAMULLA: "Cunnamulla",
      DALBY: "Dalby",
      DAPTO: "Dapto",
      DARWIN: "Darwin",
      DEAGON: "Deon",
      DEDERANG: "Dederang",
      DEEPWATER: "Eaux profondes",
      DENILIQUIN: "Deniliquin",
      DERBY: "Derby",
      DEVONPORT: "Devonport",
      DEVONPORT_SYNTHETIC: "Devonport Synthetic",
      DINGO: "Dingo",
      DONALD: "Donald",
      DONGARA: "Dongara",
      DOOMBEN: "Doomben",
      DROUIN: "Drouin",
      DUBBO: "Dubbo",
      DUNKELD: "Dunkeld",
      EAGLE_FARM: "Eagle Farm",
      ECHUCA: "Echuca",
      EDENHOPE: "Edenhope",
      EINASLEIGH: "Einasleigh",
      ELMORE: "Elmore",
      EMERALD: "Émeraude",
      ESK: "Esk",
      ESPERANCE: "Espérance",
      EUGOWRA: "Eugowra",
      EWAN: "Ewan",
      FANNIE_BAY: "Fannie Bay",
      FLEMINGTON: "Flemington",
      FLINDERS: "Flinders",
      FORBES: "Forbes",
      GATTON: "Gatton",
      GAWLER: "Gawler",
      GAYNDAH: "Gayndah",
      GEELONG: "Geelong",
      GERALDTON: "Geraldton",
      GEURIE: "Geurie",
      GILGANDRA: "Gilgandra",
      GLADSTONE: "Gladstone",
      GLEN_INNES: "Glen Innes",
      GLOBE_DERBY_PARK: "Park Globe Derby",
      GLOUCESTER_PARK: "Gloucester Park",
      GOLD_COAST: "Côte d'or",
      GOONDIWINDI: "Goondiwindi",
      GORDONVALE: "Gordonvale",
      GOSFORD: "Gosford",
      GOULBURN: "Goulburn",
      GRAFTON: "Grafton",
      GREAT_WESTERN: "Grand Western",
      GREGORY_DOWNS: "Gregory Downs",
      GRENFELL: "Grenfell",
      GRIFFITH: "Griffith",
      GULARGAMBONE: "Gulargambone",
      GULGONG: "Gulgong",
      GUNBOWER: "Gunbower",
      GUNDAGAI: "Gundagai",
      GUNNEDAH: "Gunnedah",
      GYMPIE: "Gympie",
      HALIDON: "Halidon",
      HAMILTON: "Hamilton",
      HANGING_ROCK: "Rocher suspendu",
      HARDEN: "Durcir",
      HAWKER: "Colporteur",
      HAWKESBURY: "Hawkesbury",
      HAY: "Foins",
      HEALESVILLE: "Healesville",
      HILLSTON: "Hillston",
      HINNOMUNJIE: "Hinnomunjie",
      HOBART: "Hobart",
      HOLBROOK: "Holbrook",
      HOME_HILL: "Home Hill",
      HORSHAM: "Horsham",
      HUGHENDEN: "Hughedenden",
      ILFRACOMBE: "Ilfracombe",
      INGHAM: "Ingham",
      INJUNE: "En juin",
      INNISFAIL: "Innisfail",
      INVERELL: "Inverell",
      IPSWICH: "Ipswich",
      ISISFORD: "Isisford",
      JAMESTOWN: "Jamestown",
      JANDOWAE: "Jandowae",
      JERILDERIE: "Jerilderie",
      JULIA_CREEK: "Julia Creek",
      JUNCTION: "Jonction",
      JUNDAH: "Jundah",
      JUNEE: "JUNEE",
      KALGOORLIE: "Kalgoorlie",
      KANGAROO_ISLAND: "Île Kangaroo",
      KAPUNDA: "Kapunda",
      KATHERINE: "Katherine",
      KELLERBERRIN: "Kellerberrin",
      KEMBLA_GRANGE: "Kembla Grange",
      KEMPSEY: "Kempsey",
      KERANG: "Kerang",
      KILCOY: "Kilcoy",
      KILMORE: "Kilmore",
      KINGSCOTE: "Kingscote",
      KING_ISLAND: "Île royale",
      KOJONUP: "Kojonup",
      KUMBIA: "Kumbia",
      KUNUNURRA: "Kununurra",
      KYNETON: "Kyneton",
      LANDOR: "Landor",
      LAUNCESTON: "Launceston",
      LAVERTON: "Laverton",
      LEETON: "Leeton",
      LEINSTER: "Leinster",
      LEONORA: "Leonora",
      LISMORE: "Limore",
      LITHGOW: "Lithgow",
      LOCK: "Serrure",
      LOCKHART: "Lockhart",
      LONGFORD: "Longford",
      LONGREACH: "Longreach",
      LOUTH: "Louth",
      MACKAY: "Mackay",
      MAITLAND: "Maitland",
      MALLAWA: "Mallawa",
      MANANGATANG: "Manangatang",
      MANDURAH: "Mandurah",
      MANSFIELD: "Mansfield",
      MARBLE_BAR: "Bar en marbre",
      MARBURG: "Marburg",
      MAREEBA: "Mareeba",
      MARYBOROUGH: "Maryborough",
      MAXWELTON: "Maxwelton",
      MCKINLAY: "McKinlay",
      MEEKATHARRA: "Meekatharra",
      MELBOURNE: "Melbourne",
      MELTON: "Melton",
      MENANGLE: "Menangle",
      MENDOORAN: "Mendooran",
      MERRIWA: "Merriwa",
      MERTON: "Merton",
      MIDDLEMOUNT: "Middlemount",
      MILDURA: "Mildura",
      MILES: "Kilomètres",
      MINGENEW: "Mingenew",
      MITCHELL: "Mitchell",
      MOE: "Moe",
      MONTO: "Monto",
      MOONEE_VALLEY: "Vallée de Moonee",
      MOORA: "Moora",
      MORANBAH: "Moranbah",
      MOREE: "Plus",
      MORNINGTON: "Mornington",
      MORPHETTVILLE: "Morphettville",
      MORTLAKE: "Mortlake",
      MORUYA: "Moruya",
      MORVEN: "Morven",
      MOULAMEIN: "Moulamein",
      MOUNT_GAMBIER: "Mount Gambier",
      MT_BARKER: "Mt Barker",
      MT_GAMBIER: "Mt Gambier",
      MT_GARNET: "MT Garnet",
      MT_ISA: "Mt Isa",
      MT_MAGNET: "Aimant MT",
      MT_NEWMAN: "Mt Newman",
      MT_PERRY: "Mt Perry",
      MUDGEE: "Mudgee",
      MUNGERY: "Mungery",
      MURRAY_BRIDGE: "Murray Bridge",
      MURTOA: "Murtoa",
      MURWILLUMBAH: "Murwilmbah",
      MUSWELLBROOK: "Muswellbrook",
      MUTTABURRA: "Muttaburra",
      NANANGO: "Nanango",
      NARACOORTE: "Naracoorte",
      NARRABRI: "Narrabri",
      NARRANDERA: "Narrandes",
      NARROGIN: "Narrogin",
      NARROMINE: "Narromine",
      NEWCASTLE: "Newcastle",
      NHILL: "Nhill",
      NOORAMA: "Noorama",
      NORSEMAN: "Norseman",
      NORTHAM: "Northam",
      NOWRA: "Nowra",
      NYNGAN: "Nyngan",
      OAKBANK: "Oakbank",
      OAK_PARK: "Oak Park",
      ORANGE: "Orange",
      OUYEN: "Ouyen",
      PAKENHAM: "Pakenham",
      PARKES: "Parkes",
      PEAK_HILL: "Peak Hill",
      PENOLA: "Penola",
      PENONG: "Penong",
      PENRITH: "Penrith",
      PENSHURST: "Penshurst",
      PENTLAND: "Pentland",
      PINGRUP: "Pingrup",
      PINJARRA: "Pinjarra",
      POONCARIE: "Pooncarie",
      PORT_AUGUSTA: "Port Augusta",
      PORT_HEDLAND: "Port Hedland",
      PORT_LINCOLN: "Port Lincoln",
      PORT_MACQUARIE: "Port Macquarie",
      PORT_PIRIE: "Port Pirie",
      POTTS_PARK: "Potts Park",
      PRAIRIE: "Prairie",
      QUEANBEYAN: "Queanbeyan",
      QUILPIE: "Quilpie",
      QUIRINDI: "Quirindi",
      QUORN: "Quorn",
      RANDWICK: "Randwick",
      REDCLIFFE: "Redcliffe",
      RICHMOND: "Richmond",
      ROCKHAMPTON: "Rockhampton",
      ROEBOURNE: "Roebourne",
      ROMA: "Roma",
      ROSEHILL: "Rosehill",
      ROXBY_DOWNS: "Roxby Downs",
      SALE: "Vente",
      SANDOWN: "Sandown",
      SANDOWN_HILLSIDE: "Sandown Hillside",
      SANDOWN_LAKESIDE: "Sandown Lakeside",
      SANDOWN_PARK: "Sandown Park",
      SAPPHIRE_COAST: "Côte de Sapphire",
      SCONE: "Scone",
      SCOTTSDALE: "Scottsdale",
      SEYMOUR: "Seymour",
      SHEPPARTON: "Shepparton",
      SOUTH_AUSTRALIA: "Le sud de l'Australie",
      SPRINGSURE: "Springsure",
      STAMFORD: "Stamford",
      STANTHORPE: "Stanthorpe",
      STAWELL: "Stawell",
      STONY_CREEK: "Stony Creek",
      STRATHALBYN: "Strathalbyn",
      STREAKY_BAY: "Baie striée",
      ST_ARNAUD: "St Arnaud",
      ST_GEORGE: "Saint George",
      ST_MARYS: "St Marys",
      SUNSHINE_COAST: "Côte ensoleillée",
      SURAT: "Surat",
      SWAN_HILL: "Swan Hill",
      SWIFT_S_CREEK: "Swift S Creek",
      TABULAM: "Tabulam",
      TALWOOD: "Talwood",
      TAMBO: "Tambo",
      TAMWORTH: "Tamworth",
      TARA: "Tara",
      TAREE: "Taree",
      TAROOM: "Taroom",
      TATURA: "Tatura",
      TEMORA: "Temora",
      TENNANT_CREEK: "Tennant Creek",
      TERANG: "Terang",
      TEXAS: "Texas",
      THANGOOL: "Thangool",
      THE_GARDENS: "Les jardins",
      THE_MEADOWS: "Les prairies",
      TOCUMWAL: "TOCUMWAL",
      TOMINGLEY: "Tomingley",
      TOODYAY: "Toodyay",
      TOOWOOMBA: "Toowoomba",
      TOTTENHAM: "Tottenham",
      TOWNSVILLE: "Townsville",
      TOWONG: "Towong",
      TRANGIE: "Trangie",
      TRARALGON: "Traralgon",
      TULLIBIGEAL: "Tullibigeal",
      TUMBARUMBA: "Tumbarumba",
      TUMUT: "Tumut",
      TUNCURRY: "Tuncurry",
      TWIN_HILLS: "Collines jumelles",
      VICTOR_HARBOR: "Victor Harbor",
      WAGGA: "Wagga",
      WAGGA_RIVERSIDE: "Wagga Riverside",
      WAGIN: "Wagin",
      WALCHA: "Walcha",
      WALGETT: "Walgett",
      WALLABADAH: "WALLABADAH",
      WANDOAN: "Wandoan",
      WANGARATTA: "Wangaratta",
      WARIALDA: "Warialda",
      WARRA: "Warra",
      WARRACKNABEAL: "Warracknabeal",
      WARRAGUL: "Warragul",
      WARREN: "Garenne",
      WARRNAMBOOL: "Warrnambool",
      WARRNAMBOOL_EXTRA: "Warrnambool extra",
      WARWICK: "Warwick",
      WARWICK_FARM: "Warwick Farm",
      WAUCHOPE: "Wauchope",
      WAYVILLE: "Wayville",
      WEAN: "Sevrer",
      WEDDERBURN: "Wedderburn",
      WELLINGTON: "Wellington",
      WENTWORTH: "Wentworth",
      WENTWORTH_PARK: "Wentworth Park",
      WERRIBEE: "Werribee",
      WEST_WYALONG: "West Wyalong",
      WHYALLA: "Whyalla",
      WILLIAMS: "Williams",
      WINNELLIE_PARK: "Park Winnellie",
      WINTON: "Winton",
      WODONGA: "Wodonga",
      WONDAI: "Wondai",
      WOOLAMAI: "Woolamai",
      WYCHEPROOF: "Wycheproof",
      WYNDHAM: "Wyndham",
      WYONG: "Wyong",
      YALGOO: "Yalgoo",
      YARRA_VALLEY: "Yarra Valley",
      YASS: "Yass",
      YEA: "Ouais",
      YEPPOON: "Yeppoon",
      YORK: "York",
      YOUNG: "Jeune",
      RICHMOND_STRAIGHT: "Richmond Straight",
      MURRAY_BRIDGE_STRAIGHT: "Pont Murray droit",
      BADEN_BADEN: "Baden Baden",
      BAD_DOBERAN: "Bad Doberan",
      BAD_HARZBURG: "Bad Harzburg",
      BREMEN: "Brême",
      COLOGNE: "Eau de Cologne",
      DINSLAKEN: "Dinslaken",
      DORTMUND: "Dortmund",
      DRESDE: "Dresde",
      DRESDEN: "Dresde",
      DUSSELDORF: "Dusseldorf",
      GELSENKIRCHEN: "Gelsenkirchen",
      HAMBOURG: "Hambourg",
      HAMBOURG_HORN: "Corne hambourg",
      HAMBURG: "Hambourg",
      HANNOVER: "Hanover",
      HANOVRE: "Hanovre",
      HASSLOCH: "Hassloch",
      HOPPEGARTEN: "Hoppegarten",
      KARLSHORST: "Karlshorst",
      KOLN: "Koln",
      KREFELD: "Krefeld",
      KRIEAU: "Krieau",
      LEIPZIG: "Leipzig",
      MAGDEBURG: "Magdeburg",
      MANNHEIM: "Mannheim",
      MARIENDORF: "Mariendorf",
      MONCHENGLADBACH: "Monchengladbach",
      MULHEIM: "Mulheim",
      MUNCHEN: "Munchen",
      MUNICH_DAGLFING: "Munich daglfing",
      MUNICH_RIEM: "Munich Riem",
      NEUSS: "Neuss",
      SAARBRUCKEN: "Saarbrucken",
      STRAUBING: "Straubing",
      AGEN_LA_GARENNE: "Agen la Garenne",
      AGON_COUTAINVILLE: "Agon Coutainville",
      AIX_LES_BAINS: "Aix les bains",
      AJACCIO: "Ajaccio",
      ALENCON: "Alencon",
      AMIENS: "Amiens",
      ANGERS: "Angers",
      ANGOULEME: "Angouleme",
      ARGENTAN: "Argentan",
      ARRAS: "Arras",
      AUCH: "Auch",
      AUTEUIL: "Auteuil",
      AVIGNON: "Avignon",
      BEAUMONT_DE_LOMAGNE: "Beaumont de Lomagne",
      BERNAY: "Bernay",
      BIARRITZ: "Biarritz",
      BOLLENE: "Bollene",
      BORELY: "Borely",
      BREHAL: "Brehal",
      CABOURG: "Cabourg",
      CAEN: "Caen",
      CAGNES_MIDI: "Cagnes Midi",
      CAGNES_SUR_MER: "Cagnes sur mer",
      CARENTAN: "Carentan",
      CARPENTRAS: "Carpentras",
      CASTERA_VERDUZAN: "Castera Verduzan",
      CASTILLONNES: "Castillonnes",
      CAVAILLON: "Cavaillon",
      CHAMP_DE_MARS: "Champ de Mars",
      CHANTILLY: "Chantilly",
      CHANTILLY_MIDI: "Chantilly Midi",
      CHARTRES: "Chartres",
      CHATEAUBRIANT: "Chateaubriant",
      CHATEAUROUX: "Châteauroux",
      CHATELAILLON: "Chatelaillon",
      CHATILLON: "Chatillon",
      CHERBOURG: "Cherbourg",
      CHOLET: "Cholet",
      CLAIREFONTAINE: "Clairefontaine",
      COMPIEGNE: "Compiegne",
      CORDEMAIS: "Cordais",
      CRAON: "Craon",
      DAX: "Dax",
      DEAUVILLE: "Deauville",
      DEAUVILLE_MIDI: "Deauville Midi",
      DIEPPE: "Dieppe",
      DIVONNE_LES_BAINS: "Divonne Les Bains",
      DURTAL: "Durtal",
      EAUZE: "Eauze",
      ECOMMOY: "Ecommoyée",
      ENGHIEN: "Enghien",
      EVREUX: "Evreux",
      FEURS: "Feurs",
      FONTAINEBLEAU: "Fontainebleau",
      FOUGERES: "Fougeres",
      GRAIGNES: "Graignes",
      GRANVILLE: "Granville",
      GRENADE_SUR_GARONNE: "Grenade sur garonne",
      GUADELOUPE: "Guadeloupe",
      HYERES: "Hyeres",
      ISLE_SUR_SORGUE: "Isle Sur Sorgue",
      JULLIANGES: "Merlienges",
      LANDIVISIAU: "Landivisiau",
      LANGON_LIBOURNE: "Langon Libourne",
      LAON: "Laon",
      LAVAL: "Laval",
      LA_CAPELLE: "La Capelle",
      LA_CEPIERE: "La cepière",
      LA_CLAYETTE: "La Clayette",
      LA_GACILLY: "La Gacilly",
      LA_ROCHE_POSAY: "La Roche Posay",
      LA_TESTE: "La Teste,",
      LES_SABLES_D_OLONNE: "Les Sables D Olonne",
      LE_BOUSCAT: "Le bouscat",
      LE_CROISE_LAROCHE: "Le Croise LaRoche",
      LE_LION_DANGERS: "Dangers du lion",
      LE_MANS: "Le Mans",
      LE_MONT_SAINT_MICHEL: "Le Mont Saint Michel",
      LE_TOUQUET: "Le touquet",
      LIGNIERES: "Ligniseres",
      LISIEUX: "Lisieux",
      LYON_LA_SOIE: "Lyon La Soie",
      LYON_PARILLY: "Lyon Parilly",
      MACHECOUL: "Machecoul",
      MACON_CLUNY: "Macon Cluny",
      MAISONS_LAFFITTE: "Maisons laffitte",
      MARTINIQUE: "Martinique",
      MAUQUENCHY: "Mauquechy",
      MAURE_DE_BRETAGNE: "Maure de Bretagne",
      MESLAY_DU_MAINE: "Meslay du Maine",
      MONTAUBAN: "Montauban",
      MONTIER_EN_DER: "Montier en der",
      MONTLUCON: "Montlucon",
      MONT_DE_MARSAN: "Mont de Marsan",
      MORLAIX: "Morlaix",
      MOULINS: "Moulins",
      NANCY_BRABOIS: "Nancy Brabois",
      NAN: "Nantes",
      NIMES: "Nimes",
      NIORT: "Niort",
      NORT_SUR_ERDRE: "Nort sur erdre",
      ORAISON: "Oraison",
      PARAY_LE_MONIAL: "Paray le Monial",
      PARISLONGCHAMP: "Parislongchamp",
      PAU: "Pau",
      POMPADOUR: "Banane",
      PONTCHATEAU: "Pontchateau",
      PONT_DE_VIVAUX: "Pont de Vivaux",
      PORNICHET: "Pornichette",
      PRUNELLI: "Prunelli",
      RAMBOUILLET: "Rambouillet",
      REIMS: "Reims",
      ROYAN: "Royan",
      SABLE_SUR_SARTHE: "Sable sur Sarthe",
      SAINT_BRIEUC: "Saint Brieuc",
      SAINT_CLOUD: "Saint Cloud",
      SAINT_GALMIER: "Saint Galmier",
      SAINT_MALO: "Saint Malo",
      SALON_DE_PROVENCE: "Salon de Provence",
      SEGRE: "Segre",
      SENONNES_POUANCE: "Senonnes Pouance",
      STRASBOURG: "Strasbourg",
      ST_JEAN_DE_MONTS: "St Jean de Monts",
      TARBES: "Tarbes",
      TOURS: "Visites",
      VANNES: "Vannes",
      VICHY: "Vichy",
      VICHY_SOIR: "Vichy Soir",
      VILLENEUVE_SUR_LOT: "Villeneuve sur lot",
      VILLEREAL: "Villereal",
      VINCENNES: "Vincennes",
      VIRE: "Vire",
      VITRE: "Vitre",
      VITTEL: "Vittel",
      WISSEMBOURG: "Wissembourg",
      ZONZA: "Zonza",
      AINTREE: "Aintree",
      AYR: "Ayr",
      BANGOR_ON_DEE: "Bangor sur Dee",
      BATH: "Une baignoire",
      BELLE_VUE: "Belle Vue",
      BEVERLEY: "Beverley",
      BRIGHTON: "Brighton",
      BRIGHTON_HOVE: "Brighton Hove",
      CARLISLE: "Carlisle",
      CARTMEL: "Cartmel",
      CARTMELL: "Cartmell",
      CATTERICK_BRIDGE: "Catterick Bridge",
      CENTRAL_PARK: "Parc central",
      CHELMSFORD_CITY: "Chelmsford City",
      CHELTENHAM: "Cheltenham",
      CHEPSTOW: "Chepstow",
      CHESTER: "Chester",
      COVENTRY: "Coventry",
      CRAYFORD: "Crayford",
      DONCASTER: "Doncaster",
      EPSOM_DOWNS: "Epsom Downs",
      EXETER: "Exeter",
      FAKENHAM: "Fakenham",
      FFOS_LAS: "Ffos las",
      FONTWELL: "Fontwell",
      GOODWOOD: "Bon bois",
      GOWRAN_PARK: "Gowran Park",
      GREAT_YARMOUTH: "Grand Yarmouth",
      HALL_GREEN: "Hall Green",
      HAMILTON_PARK: "Hamilton Park",
      HARLOW: "Harlow",
      HARLOW_AM: "Harlow am",
      HAYDOCK_PARK: "Haydock Park",
      HENLOW: "Henlow",
      HENLOW_AM: "Henlow am",
      HEREFORD: "Hereford",
      HEXHAM: "Hexham",
      HOVE: "Hove",
      HOVE_AM: "Hove am",
      HUNTINGDON: "Huntingdon",
      KELSO: "Kelso",
      KEMPTON_PARK: "Kempton Park",
      KINSLEY: "Kinsley",
      LEICESTER: "Leicester",
      LINGFIELD_PARK: "Lingfield Park",
      LUDLOW: "Ludlow",
      MARKET_RASEN: "Market rasen",
      MILDENHALL: "Mildenhall",
      MILTON_KEYNES: "Milton Keynes",
      MONMORE: "Monmore",
      MONMORE_GREEN: "Monmore Green",
      MUSSELBURGH: "Musselburgh",
      NEWBURY: "Newbury",
      NEWMARKET: "Nouveau marché",
      NEWTON_ABBOT: "Newton Abbot",
      NOTTINGHAM: "Nottingham",
      PELAW_GRANGE: "Pelaw Grange",
      PERRY_BARR: "Perry Barr",
      PERTH: "Perth",
      PETERBOROUGH: "Peterborough",
      PLUMPTON: "Plumpton",
      PONTEFRACT: "Pontefract",
      POOLE: "Poole",
      REDCAR: "Voiture rouge",
      RIPON: "Ripon",
      ROMFORD: "Romford",
      ROMFORD_AM: "Romford Am",
      RYE_HOUSE: "Maison de seigle",
      SALISBURY: "Salisbury",
      SEDGEFIELD: "Sedgefield",
      SHAWFIELD: "Shawfield",
      SHEFFIELD: "Sheffield",
      SITTINGBOURNE: "Sittingbourne",
      SITTINGBOURNE_AM: "Sittingbourne am",
      SOUTHWELL: "Southwell",
      STRATFORD_ON_AVON: "Stratford sur Avon",
      SUNDERLAND: "Sunderland",
      SWAFFHAM: "Swaffham",
      SWINDON: "Swindon",
      TAUNTON: "Taunton",
      THIRSK: "Thirsk",
      TOWCESTER: "Towcester",
      UTTOXETER: "UttOxeter",
      WETHERBY: "Wetherby",
      WIMBLEDON: "Wimbledon",
      WINCANTON: "Wincanton",
      WINDSOR: "Windsor",
      WISBECH: "Wisbech",
      WOLVERHAMPTON: "Wolverhampton",
      WORCESTER: "Worcester",
      YARMOUTH: "Yarmouth",
      HAPPY_VALLEY: "Happy Valley",
      SHA_TIN: "Sha Tin",
      BALLINROBE: "Ballinrobe",
      BELLEWSTOWN: "Bellewstown",
      CLONMEL: "Clonmel",
      CORK: "Liège",
      CURRAGH: "Curragh",
      DOWNPATRICK: "Downpatrick",
      DOWN_ROYAL: "Down royal",
      DUNDALK: "Dundalk",
      FAIRYHOUSE: "Fairyhouse",
      GALWAY: "Galway",
      KILBEGGAN: "Kilbeggan",
      KILLARNEY: "Killarney",
      LAYTOWN: "Laytown",
      LEOPARDSTOWN: "Leopardstown",
      LIMERICK: "Limerick",
      LISTOWEL: "Listowel",
      NAAS: "Naas",
      NAVAN: "Navan",
      PUNCHESTOWN: "Punchestown",
      ROSCOMMON: "Roscommon",
      SLIGO: "Sligo",
      THURLES: "Thurles",
      TIPPERARY: "Tipperary",
      TRAMORE: "Tramore",
      WEXFORD: "Wexford",
      KAWASAKI: "Kawasaki",
      ADDINGTON: "Addington",
      ADDINGTON_EXTRA: "Addington Extra",
      ALEXANDRA_PARK: "Alexandra Park",
      ARAWA_PARK: "Park Arawa",
      ASCOT_PARK: "Ascot Park",
      ASCOT_PARK_EXTRA: "Ascot Park Extra",
      ASHBURTON: "Ashburton",
      ASHWOOD_PARK: "Ashwood Park",
      AVONDALE: "Avondale",
      AWAPUNI: "Awapuni",
      BLENHEIM: "Blenheim",
      CAMBRIDGE: "Cambridge",
      CROMWELL: "Cromwell",
      DARGAVILLE: "Dargaville",
      ELLERSLIE: "Ellerslie",
      FORBURY_PARK: "Forbury Park",
      FORBURY_PARK_EXTRA: "Forbury Park Extra",
      GERALDINE: "Geraldine",
      GORE: "Sang",
      GREYMOUTH: "Greymouth",
      HASTINGS: "Hastings",
      HATRICK: "Hatrick",
      HATRICK_EXTRA: "Hatrick extra",
      HAWERA: "Hawera",
      HOKITIKA: "Hokitika",
      KAIKOURA: "Kaikoura",
      KUMARA: "Kumara",
      KUROW: "Kurow",
      MAKARAKA: "Makaraka",
      MANAWATU: "Manawatu",
      MANAWATU_EXTRA: "Manawatu Extra",
      MANUKAU: "Manukau",
      MANUKAU_EXTRA: "Manukau Extra",
      MATAMATA: "Mata mata",
      MOTUKARARA: "Motukarara",
      MT_HARDING: "Mt Harding",
      NEW_PLYMOUTH: "New Plymouth",
      NEW_ZEALAND: "Nouvelle-Zélande",
      OAMARU: "Oamaru",
      OMAKAU: "Omakau",
      OMOTO: "Omoto",
      OTAKI: "Otaki",
      OTAKI_MAORI: "Otaki Maori",
      PHAR_LAP: "Phar Lap",
      PUKEKOHE: "Pukekohe",
      PUKEKURA: "Pukekura",
      RANGIORA: "Rangiora",
      REEFTON: "Reefton",
      RICCARTON_PARK: "Riccarton Park",
      RICHMOND_PARK: "Richmond Park",
      RIVERTON: "Riverton",
      ROTORUA: "Rotorua",
      ROXBURGH: "Roxburgh",
      RUAKAKA: "Ruakaka",
      STRATFORD: "Stratford",
      TAUHERENIKAU: "Tauherenikau",
      TAUPO: "Taupo",
      TAURANGA: "Tauranga",
      TE_AROHA: "Te aroha",
      TE_AWAMUTU: "Te Awamutu",
      TE_KAPUA: "Te kapua",
      TE_KAPUA_PARK: "TE KAPUA PARK",
      TE_KUPENGA: "Te Kupenga",
      TE_RAPA: "Te rapa",
      TE_TEKO: "Te Teko",
      THAMES: "Tamise",
      TRENTHAM: "Trentham",
      WAIKOUAITI: "Waikouaiti",
      WAIMATE: "Waimate",
      WAIPUKURAU: "Waipukurau",
      WAIROA: "Wairoa",
      WANGANUI: "Wanganui",
      WATERLEA: "Waterlea",
      WATERLEA_PARK: "Waterlea Park",
      WAVERLEY: "Waverley",
      WESTPORT: "Westport",
      WINGATUI: "Wingatui",
      WOODVILLE: "Woodville",
      CAMBRIDGE_EXTRA: "Cambridge Extra",
      SINGAPORE: "Singapour",
      AFTON: "Afton",
      AIKEN: "Aiken",
      AJAX_DOWNS: "Ajax downs",
      ALBERTA_DOWNS: "Alberta Downs",
      ALBION: "Albion",
      ALBUQUERQUE: "Albuquerque",
      ALLEGAN: "Allegan",
      ALTAMONT: "Altamont",
      ANNA: "Anna",
      ANTHONY_DOWNS: "Anthony Downs",
      APACHE_COUNTY: "Comté d'Apache",
      AQUEDUCT: "Aqueduc",
      ARAPAHOE_PARK: "Parc Arapahoe",
      ARIZONA_DOWNS: "Arizona Downs",
      ARLINGTON: "Arlington",
      ASHLAND: "Ashland",
      ASSINIBOIA_DOWNS: "Assiniboia Downs",
      ATHENS: "Athènes",
      ATLANTA: "Atlanta",
      ATLANTIC_CITY: "Atlantic City",
      ATOKAD_DOWNS: "Atokad downs",
      ATTICA: "Attique",
      AYERS_CLIFF: "Ayers Cliff",
      BALMORAL_PARK: "Balmoral Park",
      BANGOR_HOLLYWOOD_SLOTS: "Slots de Bangor Hollywood",
      BARTON: "Barton",
      BATAVIA_DOWNS: "Batavia Downs",
      BAY_MEADOWS: "Bay Meadows",
      BEDFORD: "Bedford",
      BELLEFONTAINE: "Bellefontaine",
      BELTERRA_PARK: "Belterra Park",
      BELVIDERE: "Belvidere",
      BEULAH_PARK: "Park Beulah",
      BIG_RAPIDS: "Grands rapides",
      BIRMINGHAM_MATINEE: "Birmingham Matinee",
      BIRMINGHAM_NIGHT: "Birmingham Night",
      BLACK_FOOT: "Pied noir",
      BLOOMFIELD: "Bloomfield",
      BLOOMSBURG: "Bloomsburg",
      BLUE_GRASS_DOWNS: "Grass bleus, Downs",
      BLUE_RIBBON_DOWNS: "Downs de ruban bleu",
      BOONVILLE: "Boonville",
      BOWLING_GREEN: "Terrain de boules",
      BROCKTON_FAIR: "Brockton Fair",
      BROOKHILL_FARM: "Brookhill Farm",
      BROWN_COUNTY: "Brown County",
      BUCYRUS: "Bucyrus",
      BUFFALO_RACEWAY: "Buffalo Raceway",
      BUTLER: "Majordome",
      CALDWELL: "Caldwell",
      CAL_EXPO: "Cal Expo",
      CALIENTE_EVENING: "Caliente Evening",
      CAMARERO: "Camarero",
      CAMDEN: "Camden",
      CANFIELD: "Canfield",
      CANNON_FALLS: "Cannon Falls",
      CANTERBURY_PARK: "Canterbury Park",
      CANTON: "Canton",
      CARLINVILLE: "Carlinville",
      CARMI: "Carmi",
      CARROLLTON: "Carrollton",
      CASSIA_COUNTY: "Comté de Cassia",
      CELINA: "Celina",
      CENTRAL_FAIR: "Central Fair",
      CENTREVILLE: "Centerville",
      CENTURY_DOWNS: "Century Downs",
      CENTURY_MILE: "Century Mile",
      CENTURY_MILE_2: "Century Mile 2",
      CHARLESTON: "Charleston",
      CHARLES_TOWN: "Charles Town",
      CHARLOTTE: "Charlotte",
      CHARLOTTETOWN: "Charlottetown",
      CHARLOTTETOWN_MATINEE: "Charlottetown Matinee",
      CHILLICOTHE: "Chillicothe",
      CHIPPEWA_DOWNS: "Chippewa Downs",
      CHURCHILL_DOWNS: "Churchill Downs",
      CIRCLEVILLE: "Circleville",
      CLEARFIELD: "Champ libre",
      CLEMMONS: "Clemmons",
      CLINTON_RACEWAY: "Clinton Raceway",
      COBLESKILL: "Cobleskill",
      COCHISE_COUNTY: "Comté de Cochise",
      COLONIAL_DOWNS: "Colonial Downs",
      COLUMBUS: "Colomb",
      CONNERSVILLE: "Connersville",
      CONVERSE: "Converser",
      CORYDON: "Corydon",
      COSHOCTON: "Coshocton",
      CROOKED_RIVER: "Rivière tordue",
      CROSWELL: "Croswell",
      CROTON: "Croton",
      CUMBERLAND: "Cumberland",
      DAYTON: "Dayton",
      DAYTONA_BEACH_EVENING: "Soirée de la plage de Daytona",
      DAYTONA_BEACH_MATINEE: "Daytona Beach Matinee",
      DAYTON_RACEWAY: "Dayton Raceway",
      DECATUR: "Decatur",
      DELAWARE_COUNTY_FAIR: "Foire du comté de Delaware",
      DELAWARE_COUNTY_FAIR_MATINEE: "Matinée de la foire du comté de Delaware",
      DELAWARE_PARK: "Delaware Park",
      DELEON_SPRINGS: "Deleon Springs",
      DELTA_DOWNS: "Delta Downs",
      DEL_MAR: "Del Mar",
      DERBY_LANE: "Derby Lane",
      DESERT_PARK: "Desert Park",
      DETROIT_RACE_COURSE: "Cours de course de Detroit",
      DIXIE_DOWNS: "Dixie Downs",
      DOVER: "Douvres",
      DOVER_DOWNS: "Dover Downs",
      DRESDEN_RACEWAY: "Dresden Raceway",
      DUNKIRK: "Dunkerque",
      DU_QUOIN: "Du quoin",
      DU_QUOIN_EVENING: "Du Quoin Evening",
      DU_QUOIN_STATE_FAIR: "Du Quoin State Fair",
      EATON: "Eaton",
      ELDON: "Eldon",
      ELKHORN: "Elkhorn",
      ELKO_COUNTY_FAIR: "Fair du comté d'Elko",
      ELLIS_PARK: "Ellis Park",
      ELMIRA: "Elmira",
      ELY: "Ely",
      EMERALD_DOWNS: "Emerald Downs",
      EMMETT: "Emmett",
      ENERGY_DOWNS: "Energy Downs",
      EUREKA: "Eureka",
      EVANGELINE_DOWNS: "Evangeline Downs",
      EXHIBITION_PARK: "Parc d'exposition",
      FAIRFAX: "Fairfax",
      FAIRFIELD: "Fairfield",
      FAIRMOUNT_PARK: "Fairmount Park",
      FAIRPLEX_PARK: "Fairplex Park",
      FAIR_GROUNDS: "Terrains équitables",
      FAIR_HILL: "Fair Hill",
      FAIR_MEADOWS_TULSA: "Fair Meadows Tulsa",
      FAIR_PLAY_PARK: "Park de fair-play",
      FARMER_CITY: "Farmer City",
      FARMINGTON: "Farmington",
      FAR_HILLS: "Far Hills",
      FAYETTEVILLE: "Fayetteville",
      FERNDALE: "Ferndale",
      FINDLAY: "Findlay",
      FINGER_LAKES: "Finger Lakes",
      FLAGSTAFF: "Flagstaff",
      FLAMBORO_DOWNS: "Flamboro Downs",
      FONNER_PARK: "Fonner Park",
      FORT_ERIE: "Fort Érié",
      FORT_PIERRE: "Fort Pierre",
      FOWLERVILLE: "FOWLERVILLE",
      FOXFIELD: "Foxfield",
      FRANKFORT: "Frankfort",
      FRASER_DOWNS: "Fraser Downs",
      FREDERICK: "Frederick",
      FREDERICTON_RACEWAY: "Fredericton Raceway",
      FREEHOLD_RACEWAY: "Freehold Raceway",
      FRESNO: "Fresno",
      FRYEBURG: "Fryeburg",
      GAITWAY_FARMS: "Gaitway Farms",
      GARDEN_STATE: "Garden State",
      GENESEE_VALLEY: "Genesee Valley",
      GEORGIAN_DOWNS: "Downs géorgiens",
      GILA_COUNTY_FAIR: "Fair du comté de Gila",
      GILLESPIE_COUNTY: "Comté de Gillespie",
      GLENBORO: "Glenboro",
      GLYNDON: "Glyndon",
      GOLDEN_GATE_FIELDS: "Golden Gate Fields",
      GOSHEN: "Goshen",
      GOUVERNEUR: "Gouverneur",
      GRAHAM_COUNTY: "Comté de Graham",
      GRANDE_PRAIRIE: "Grande Prairie",
      GRAND_NATIONAL: "Grand National",
      GRAND_PRAIRIE: "Grand Prairie",
      GRAND_RIVER_RACEWAY: "Grand River Raceway",
      GRANTS_PASS: "Subventions passer",
      GRATZ: "Gratz",
      GREAT_BARRINGTON_FAIR: "Grande foire de Barrington",
      GREAT_FALLS: "Bonnes chutes",
      GREAT_LAKES_DOWNS: "Grands lacs",
      GREAT_MEADOW: "Super prairie",
      GREENELEE_COUNTY: "COMTÉ DE GREENELEE",
      GREENUP: "Greenup",
      GREENVILLE: "Greenville",
      GULFSTREAM_PARK: "Gulfstream Park",
      GULFSTREAM_PARK_WEST: "Gulfstream Park West",
      HANOVER_RACEWAY: "Hanover Raceway",
      HARBOR_PARK: "Parc Harbor",
      HARNEY_COUNTY: "Comté de Harney",
      HARRAHS_CHESTER: "Harrahs Chester",
      HARRAHS_PHILADELPHIA: "Harrahs Philadelphie",
      HARRINGTON_RACEWAY: "Harrington Raceway",
      HARRISON: "Harrison",
      HART: "Cerf",
      HAWTHORNE: "Hawthorne",
      HAWTHORNE_RACECOURSE: "Hawthorne Racecourse",
      HAZEL_PARK: "Hazel Park",
      HAZEL_PARK_HARNESS: "Harnais Hazel Park",
      HELENA_DOWNS: "Helena Downs",
      HEMLOCK: "Ciguë",
      HENRY: "Henri",
      HIALEAH_PARK: "Hialeah Park",
      HIAWATHA_HORSE_PARK: "Hiawatha Horse Park",
      HICKSVILLE: "Hicksville",
      HILLIARD: "Hilliard",
      HIPPODROME_3R: "Hippodrome 3R",
      HIPPODROME_DE_QUEBEC: "Hippodrome de Québec",
      HISTORIC_TRACK_GOSHEN: "Piste historique Goshen",
      HOLLAND: "Hollande",
      HOLLYWOOD_PARK: "Hollywood Park",
      HONESDALE: "Honesdale",
      HOOSIER_PARK: "Hoosier Park",
      HORSEMENS_PARK: "Park à cheval",
      HUGHESVILLE: "Hughesville",
      HUMBOLDT: "Humboldt",
      HUMBOLDT_COUNTY: "Comté de Humboldt",
      INDIANA: "Indiana",
      INDIANA_DOWNS: "Indiana Downs",
      INDIANA_STATE_FAIR: "Foire d'État de l'Indiana",
      INVERNESS_RACEWAY: "Inverness Raceway",
      IOWA_EVENING: "Soirée de l'Iowa",
      IOWA_MATINEE: "Iowa Matinee",
      IRONWOOD: "Ironwood",
      JACKSONVILLE_MATINEE: "Jacksonville Matinee",
      JACKSONVILLE_NIGHT: "Jacksonville Night",
      JEFFERSON: "Jefferson",
      JEROME_COUNTY: "Comté de Jerome",
      KAHOKIA: "Kahokia",
      KALISPELL: "Kalispell",
      KAMLOOPS: "Kamloops",
      KAWARTHA_DOWNS: "Kawartha downs",
      KEENELAND: "Keeneland",
      KENDALLVILLE: "Kendallville",
      KENTLAND: "Kentland",
      KENTON: "Kenton",
      KENTUCKY_DOWNS: "Kentucky Downs",
      KINROSS: "Kinross",
      KIN_PARK: "Kin Park",
      KNOXVILLE: "Knoxville",
      LACENTER: "Lacenter",
      LANCASTER: "Lancaster",
      LAUREL_BROWN: "Laurel Brown",
      LAUREL_PARK: "Laurel Park",
      LEAMINGTON_RACEWAY: "Leamington Raceway",
      LEBANON: "Liban",
      LEBANON_RACEWAY: "Liban Raceway",
      LES_BOIS: "Les Bois",
      LETHBRIDGE: "Lethbridge",
      LEWISBURG: "Lewisburg",
      LEWISTOWN: "Lewistown",
      LEXINGTON: "Lexington",
      LIMA: "Lima",
      LINCOLN: "Lincoln",
      LISBON: "Lisbonne",
      LITTLE_EVERGLADES: "Petit Everglades",
      LONE_STAR_PARK: "Lone Star Park",
      LOS_ALAMITOS: "Los Alamitos",
      LOS_ALAMITOS_QH: "Los Alamitos QH",
      LOUISIANA_DOWNS: "Louisiane Downs",
      LOWVILLE: "Lowville",
      MAHONING_VALLEY: "Mahoning Valley",
      MALONE: "Malone",
      MALVERN: "Malvern",
      MANOR_DOWNS: "Manoir downs",
      MARIAS_FAIR: "Marias Fair",
      MARIETTA: "Marietta",
      MARION: "Marion",
      MARQUIS_DOWNS: "Marquis downs",
      MARSHALL: "Marshall",
      MARTINSVILLE: "Martinsville",
      MARYSVILLE: "Marysville",
      MAYFIELD: "Mayfield",
      MAYWOOD_PARK: "Maywood Park",
      MCCONNELSVILLE: "McConnelsville",
      MEADOWLANDS: "Meadowlands",
      MEADVILLE: "Meadville",
      MELVILLE_DISTRICT_AGRIPAR: "District de Melville Agripar",
      MIAMI: "Miami",
      MIAMI_VALLEY: "Miami Valley",
      MIDDLEBURG: "Middleburg",
      MILES_CITY: "Miles City",
      MILLARVILLE: "Millarville",
      MINERAL_POINT: "Point minéral",
      MINIDOKA_COUNTY: "Comté de Minidoka",
      MOHAVE_COUNTY: "Comté de Mohave",
      MOHAWK_RACETRACK: "Chauffe de Mohawk",
      MONKTON: "Monkton",
      MONMOUTH_PARK: "Park Monmouth",
      MONTICELLO_RACEWAY: "Monticello Raceway",
      MONTPELIER: "Montpellier",
      MORRIS: "Morris",
      MORRISON: "Morrison",
      MORVEN_PARK: "Morven Park",
      MOUNTAINEER_PARK: "Mountaineer Park",
      MOUNT_GILEAD: "Mount Gilead",
      MOUNT_STERLING: "Mount Sterling",
      MOUNT_VERNON: "Mount Vernon",
      MT_PLEASANT_MEADOWS: "Mt Pleasant Meadows",
      NAPOLEON: "Napoléon",
      NEWTON: "Newton",
      NORTHAMPTON: "Northampton",
      NORTHERN_MAINE_FAIR: "Northern Maine Fair",
      NORTHFIELD_PARK: "Northfield Park",
      NORTHLANDS_PARK: "Northlands Park",
      NORTHVILLE_DOWNS: "Northville Downs",
      NORTH_DAKOTA_HORSE_PARK: "Parc à cheval du Dakota du Nord",
      NORTH_SIDE_DOWNS: "Downs du côté nord",
      NORWICH: "Norwich",
      NOUVELLE: "Novelle",
      OAKLAWN_PARK: "Oaklawn Park",
      OAK_GROVE_RACING_GAMING: "Oak Grove Racing Gaming",
      OAK_HARBOR: "Oak Harbor",
      OAK_RIDGE: "Oak Ridge",
      OAK_TREE_AT_HOLLYWOOD: "Oak Tree à Hollywood",
      OAK_TREE_AT_PLEASANTON: "Oak Tree à Pleasanton",
      OAK_TREE_AT_SANTA_ANITA: "Oak Tree à Santa Anita",
      OCALA_TRAINING_CENTER: "Centre de formation Ocala",
      OCEAN_DOWNS: "Océan Downs",
      OLD_WASHINGTON: "Old Washington",
      ONEIDA_COUNTY: "Comté d'Oneida",
      OREGON_LIVESTOCK: "Bétail de l'Oregon",
      OSKALOOSA: "Oskaloosa",
      OTTAWA: "Ottawa",
      OWEGO: "Owego",
      OXFORD: "Oxford",
      PABOS: "Pabos",
      PADUCAH: "Paducah",
      PAINESVILLE: "Painesville",
      PALMYRA: "Palmyre",
      PALM_BEACH_EVENING: "Palm Beach Evening",
      PALM_BEACH_MATINEE: "Palm Beach Matinee",
      PANA: "Pana",
      PARIS: "Paris",
      PAULDING: "Paulding",
      PBKC_SUMMER_MATINEE: "Matinée d'été PBKC",
      PENNSYLVANIA_HUNT_CUP: "Pennsylvania Hunt Cup",
      PENN_NATIONAL: "Penn National",
      PERCY_WARNER: "Percy Warner",
      PETERSBURG: "Pétersbourg",
      PHILADELPHIA: "Crême Philadelphia",
      PHILADELPHIA_PARK: "Philadelphia Park",
      PICOV_DOWNS: "Picov Downs",
      PIKETON: "Piketon",
      PIMLICO: "Pimlico",
      PINE_MTN_CALLOWAY_GARDEN: "Pine Mtn Calloway Garden",
      PINNACLE_RACE_COURSE: "Pinnacle Race Course",
      PLAINRIDGE_RACECOURSE: "Plainridge Racecourse",
      PLATTSBURG: "Plattsburg",
      PLAYERS_BLUEGRASS_DOWNS: "Joueurs Bluegrass Downs",
      PLAYFAIR: "Jouer équitablement",
      PLEASANTON: "Pleasanton",
      POCATELLO_DOWNS: "Pocatello Downs",
      POCONO_DOWNS: "Pocono Downs",
      POMPANO_PARK: "Pompano Park",
      PORTLAND: "Portland",
      PORTLAND_MEADOWS: "Portland Meadows",
      PRAIRIE_MEADOWS: "Prairie meadows",
      PRESCOTT_DOWNS: "Prescott Downs",
      PRESQUE_ISLE_DOWNS: "Presque Isle Downs",
      PRINEVILLE_TURF_CLUB: "Prineville Turf Club",
      QUEENSBURY_DOWNS: "Queensbury Downs",
      RACEWAY_PARK: "Raceway Park",
      REMINGTON_PARK: "Remington Park",
      RETAMA_PARK: "Retama Park",
      RICE_LAKE: "Lac de riz",
      RICHLAND_CENTER: "Richland Center",
      RICHWOOD: "Richwood",
      RIDEAU_CARLETON: "Rideau Carleton",
      RILLITO: "Rillito",
      RIVER_AT_BEULAH: "Rivière à Beulah",
      RIVER_DOWNS: "River Downs",
      ROCKINGHAM_PARK: "Rockingham Park",
      ROCKSPRINGS: "Rocksprings",
      ROSECROFT_RACEWAY: "Rosecroft Raceway",
      ROSSBURN_PARK: "Rossburn Park",
      ROUND_UP_PARK: "Parc à côté",
      RUIDOSO_DOWNS: "Ruidoso downs",
      RUNNING_ACES: "As en cours d'exécution",
      RUSHVILLE: "Rushville",
      SACRAMENTO: "Sacramento",
      SAINT_ESPRIT: "Saint-Esprit",
      SAINT_PETER: "Saint Pierre",
      SALEM: "Salem",
      SAM_HOUSTON: "Sam Houston",
      SANDWICH: "Sandwich",
      SANDY_CREEK: "Sandy Creek",
      SANDY_DOWNS: "Sandy Downs",
      SANFORD_ORLANDO_EVENING: "Soirée de Sanford Orlando",
      SANFORD_ORLANDO_MATINEE: "Sanford Orlando Matinee",
      SANTA_ANITA: "Santa Anita",
      SANTA_CRUZ_COUNTY: "Comté de Santa Cruz",
      SANTA_FE: "Santa Fe",
      SANTA_ROSA: "Santa Rosa",
      SARATOGA: "Saratoga",
      SARATOGA_HARNESS: "Harnais de Saratoga",
      SCARBOROUGH_DOWNS: "Scarborough Downs",
      SCIOTO_DOWNS: "Scioto Downs",
      SHAWAN_DOWNS: "Shawan Downs",
      SHELBYVILLE: "Shelbyville",
      SHENANDOAH_DOWNS: "Shenandoah Downs",
      SIDNEY: "Sidney",
      SILVER_SPRINGS_RANCH: "Silver Springs Ranch",
      SIOUX_CENTER: "Centre Sioux",
      SKOWHEGAN: "Skowhegan",
      SOLANO: "Solano",
      SOUTHERN_OAKS_TRAINING_CENTER: "Centre de formation Southern Oaks",
      SOUTHLAND_MATINEE: "Southland Matinee",
      SOUTHLAND_NIGHT: "Southland Night",
      SOUTHLAND_TWILIGHT: "Southland Twilight",
      SPORTSMANS_PARK: "Sportsmans Park",
      SPORTS_CREEK_RACEWAY: "Sports Creek Raceway",
      SPRINGFIELD_ILLINOIS: "Springfield Illinois",
      STAMPEDE_PARK: "Parc Stampede",
      STOCKTON: "Stockton",
      STONEBORO: "Stoneboro",
      STONEYBROOK_AT_FIVE_POINTS: "Stoneybrook à cinq points",
      ST_AIME_DES_LACS: "St aime des lacs",
      ST_HUGUES: "St Hugues",
      ST_JOHNS_MATINEE: "St Johns Matinee",
      ST_JOHNS_NIGHT: "St Johns Night",
      ST_JOHNS_RACING: "St Johns Racing",
      ST_JOHN_S: "St. John's",
      ST_JOSEPH_DE_LEPAGE: "St Joseph de Lepage",
      SUDBURY_DOWNS: "Sudbury Downs",
      SUFFOLK_DOWNS: "Suffolk Downs",
      SUMMERSIDE_RACEWAY: "Summerside Raceway",
      SUNLAND_PARK: "Sunland Park",
      SUNRAY_PARK: "Sunray Park",
      SUN_DOWNS: "Soleil Downs",
      SWEETWATER_DOWNS: "Sweetwater Downs",
      TAMPA_BAY_DOWNS: "Tampa Bay Downs",
      TAMPA_DL_MATINEE: "Tampa DL Matinee",
      TAMPA_DL_NIGHT: "Tampa DL Night",
      ING: "ESSAI",
      THE_RED_MILE: "Le mile rouge",
      THE_TRACK_ON_2: "La piste sur 2",
      THISTLEDOWN: "Thistledown",
      THUNDER_RIDGE: "Thunder Ridge",
      TIFFIN: "Repas de midi",
      TILLAMOOK_COUNTY: "Comté de Tillamook",
      TIMONIUM: "Timonium",
      TIOGA_DOWNS: "Tioga Downs",
      TOPSHAM: "Topsham",
      TROY: "Troy",
      TRUMANSBURG: "Trumansburg",
      TRURO_RACEWAY: "Truro Raceway",
      TRYON: "Essayer",
      TUNBRIDGE: "Tunbridge",
      TURFWAY_PARK: "Parc de gazon",
      TURF_PARADISE: "Turf Paradise",
      UNION: "Syndicat",
      UNIONVILLE: "Unionville",
      UPPER_SANDUSKY: "Upper Sandusky",
      URBANA: "Urbana",
      VAN_WERT: "Van Wert",
      VERNON_DOWNS: "Vernon Downs",
      VIROQUA: "Viroqua",
      WAITSBURG_RACE: "Race Waitsburg",
      WALLA_WALLA: "Walla walla",
      WAPAKONETA: "Wapakoneta",
      WASHINGTON: "Washington",
      WASHINGTON_COURT_HOUSE: "House de Washington",
      WATTSBURG: "Wattsburg",
      WAUSEON: "Wauseon",
      WAYNESBURG: "Waynesburg",
      WEBER_DOWNS: "Weber Downs",
      WEBSTER_CITY: "Webster City",
      WELLSTOWN: "Bien",
      WESTERN_FAIR_RACEWAY: "Western Fair Raceway",
      WESTERN_MT_FAIR: "Western Mt Fair",
      WHAT_CHEER: "Quelle joie",
      WHEATON: "Wheaton",
      WHEELING_MATINEE: "Matinée de roues",
      WHEELING_NIGHT: "Nuit de roue",
      WHITE_PINE: "Pin blanc",
      WILLOWDALE: "Willowdale",
      WILL_ROGERS_DOWNS: "Will Rogers Downs",
      WILMINGTON: "Wilmington",
      WINDSOR_RACEWAY: "Windsor Raceway",
      WINTERTHUR: "Winterthur",
      WOODBINE: "Chèvrefeuille",
      WOODBINE_2: "Woodbine 2",
      WOODLANDS: "Woodlands",
      WOODSFIELD: "Woodsfield",
      WOODSTOCK: "Woodstock",
      WOODSTOCK_RACEWAY: "Woodstock Raceway",
      WOOSTER: "Wooster",
      WYOMING_DOWNS: "Wyoming Downs",
      XENIA: "Xenia",
      YAKIMA_MEADOWS: "Yakima Meadows",
      YAVAPAI_DOWNS: "Yavapai Downs",
      YELLOWSTONE_DOWNS: "Yellowstone Downs",
      YONKERS_RACEWAY: "Yonkers Raceway",
      YORKTON: "Yorkton",
      YORKTON_EX_ASSOC: "Yorkton ex associé",
      ZANESVILLE: "Zanesville",
      ZIA_PARK: "Zia Park",
      FLAGLER_MATINEE: "Flagler Matinee",
      FLAGLER_NIGHT: "Nuit du Flagler",
      FLAGLER_TWILIGHT: "Flagler Twilight",
      MARDI_GRAS_EVENING: "Soirée du Mardi Gras",
      MARDI_GRAS_MATINEE: "Mardi Gras Matinee",
      SARASOTA_MATINEE: "Sarasota Matinee",
      TUCSON: "Tucson",
      TRI_STATE_MATINEE: "Tri State Matinee",
      DURBANVILLE: "Durbanville",
      FAIRVIEW: "Fairview",
      FLAMINGO_PARK: "Flamingo Park",
      GREYVILLE: "Greyville",
      KENILWORTH: "Kenilworth",
      SCOTTSVILLE: "Scottsville",
      TURFFONTEIN: "Turffontein",
      VAAL: "Vaal",
    },
    countries: {
      BD: "Bangladesh",
      BE: "Belgique",
      BF: "Burkina Faso",
      BG: "Bulgarie",
      BA: "Bosnie Herzégovine",
      BB: "Barbade",
      WF: "Wallis et Futuna",
      BL: "Saint Barthelemy",
      BM: "Bermudes",
      BN: "Brunei",
      BO: "Bolivie",
      BH: "Bahreïn",
      BI: "Burundi",
      BJ: "Bénin",
      BT: "Bhoutan",
      JM: "Jamaïque",
      BV: "Île Bouvet",
      BW: "Botswana",
      WS: "Samoa",
      BQ: "Bonaire, Saint Eustatius et Saba",
      BR: "Brésil",
      BS: "Bahamas",
      JE: "Jersey",
      BY: "Bélarus",
      BZ: "Belize",
      RU: "Russie",
      RW: "Rwanda",
      RS: "Serbie",
      TL: "Timor oriental",
      RE: "Réunion",
      TM: "Turkménistan",
      TJ: "Tadjikistan",
      RO: "Roumanie",
      TK: "Tokelau",
      GW: "Guinée-Bissau",
      GU: "Guam",
      GT: "Guatemala",
      GS: "Géorgie du Sud et îles Sandwich du Sud",
      GR: "Grèce",
      GQ: "Guinée Équatoriale",
      GP: "Guadeloupe",
      JP: "Japon",
      GY: "Guyane",
      GG: "Guernesey",
      GF: "Guyane Française",
      GE: "Géorgie",
      GD: "Grenade",
      GB: "Royaume-Uni",
      GA: "Gabon",
      SV: "Le Salvador",
      GN: "Guinée",
      GM: "Gambie",
      GL: "Groenland",
      GI: "Gibraltar",
      GH: "Ghana",
      OM: "Oman",
      TN: "Tunisie",
      JO: "Jordan",
      HR: "Croatie",
      HT: "Haïti",
      HU: "Hongrie",
      HK: "Hong Kong",
      HN: "Honduras",
      HM: "Entendus îles et îles McDonald",
      VE: "Venezuela",
      PR: "Porto Rico",
      PS: "Territoire Palestinien",
      PW: "Palau",
      PT: "Le Portugal",
      SJ: "Svalbard et Jan Mayen",
      PY: "Paraguay",
      IQ: "Irak",
      PA: "Panama",
      PF: "Polynésie française",
      PG: "Papouasie Nouvelle Guinée",
      PE: "Pérou",
      PK: "Pakistan",
      PH: "Philippines",
      PN: "Pitcairn",
      PL: "Pologne",
      PM: "Saint-Pierre-et-Miquelon",
      ZM: "Zambie",
      EH: "Sahara occidental",
      EE: "Estonie",
      EG: "Egypte",
      ZA: "Afrique du Sud",
      EC: "Équateur",
      IT: "Italie",
      VN: "Vietnam",
      SB: "Les îles Salomon",
      ET: "Ethiopie",
      SO: "Somalie",
      ZW: "Zimbabwe",
      SA: "Arabie Saoudite",
      ES: "Espagne",
      ER: "Érythrée",
      ME: "Monténégro",
      MD: "Moldavie",
      MG: "Madagascar",
      MF: "Saint Martin",
      MA: "Maroc",
      MC: "Monaco",
      UZ: "Ouzbékistan",
      MM: "Myanmar",
      ML: "Mali",
      MO: "Macao",
      MN: "Mongolie",
      MH: "Iles Marshall",
      MK: "Macédoine",
      MU: "Maurice",
      MT: "Malte",
      MW: "Malawi",
      MV: "Maldives",
      MQ: "Martinique",
      MP: "Îles Mariannes du Nord",
      MS: "Montserrat",
      MR: "Mauritanie",
      IM: "Île de Man",
      UG: "Ouganda",
      TZ: "Tanzanie",
      MY: "Malaisie",
      MX: "Mexique",
      IL: "Israël",
      FR: "France",
      IO: "Territoire britannique de l'océan Indien",
      SH: "Saint Helena",
      FI: "Finlande",
      FJ: "Fidji",
      FK: "Les îles Falkland",
      FM: "Micronésie",
      FO: "Îles Féroé",
      NI: "Nicaragua",
      NL: "Pays-Bas",
      NO: "Norvège",
      NA: "Namibie",
      VU: "Vanuatu",
      NC: "Nouvelle Calédonie",
      NE: "Niger",
      NF: "L'ile de Norfolk",
      NG: "Nigeria",
      NZ: "Nouvelle-Zélande",
      NP: "Népal",
      NR: "Nauru",
      NU: "Niue",
      CK: "Les Îles Cook",
      XK: "Kosovo",
      CI: "Côte d'Ivoire",
      CH: "Suisse",
      CO: "Colombie",
      CN: "Chine",
      CM: "Cameroun",
      CL: "Chili",
      CC: "Îles Cocos",
      CA: "Canada",
      CG: "République du Congo",
      CF: "République centrafricaine",
      CD: "République Démocratique du Congo",
      CZ: "République tchèque",
      CY: "Chypre",
      CX: "L'île de noël",
      CR: "Costa Rica",
      CW: "Curacao",
      CV: "Cap-Vert",
      CU: "Cuba",
      SZ: "Swaziland",
      SY: "Syrie",
      SX: "Sint Maarten",
      KG: "Kirghizistan",
      KE: "Kenya",
      SS: "Soudan du sud",
      SR: "Suriname",
      KI: "Kiribati",
      KH: "Cambodge",
      KN: "Saint-Christophe-et-Niévès",
      KM: "Comoros",
      ST: "Sao tome et principe",
      SK: "Slovaquie",
      KR: "Corée du Sud",
      SI: "Slovénie",
      KP: "Corée du Nord",
      KW: "Koweit",
      SN: "Sénégal",
      SM: "Saint Marin",
      SL: "Sierra Leone",
      SC: "Les Seychelles",
      KZ: "Kazakhstan",
      KY: "Îles Caïmans",
      SG: "Singapour",
      SE: "Suède",
      SD: "Soudan",
      DO: "République dominicaine",
      DM: "Dominique",
      DJ: "Djibouti",
      DK: "Danemark",
      VG: "Îles Vierges britanniques",
      DE: "Allemagne",
      YE: "Yémen",
      DZ: "Algérie",
      US: "États-Unis",
      UY: "Uruguay",
      YT: "Mayotte",
      UM: "Îles mineures éloignées des États-Unis",
      LB: "Liban",
      LC: "Sainte-Lucie",
      LA: "Laos",
      TV: "Tuvalu",
      TW: "Taïwan",
      TT: "Trinité-et-Tobago",
      TR: "Turquie",
      LK: "Sri Lanka",
      LI: "Liechtenstein",
      LV: "Lettonie",
      TO: "Tonga",
      LT: "Lituanie",
      LU: "Luxembourg",
      LR: "Libéria",
      LS: "Lesotho",
      TH: "Thaïlande",
      TF: "Territoires du Sud français",
      TG: "Aller",
      TD: "Tchad",
      TC: "Îles Turques-et-Caïques",
      LY: "Libye",
      VA: "Vatican",
      VC: "Saint-Vincent-et-les-Grenadines",
      AE: "Emirats Arabes Unis",
      AD: "Andorre",
      AG: "Antigua-et-Barbuda",
      AF: "Afghanistan",
      AI: "Anguilla",
      VI: "Îles Vierges américaines",
      IS: "Islande",
      IR: "L'Iran",
      AM: "Arménie",
      AL: "Albanie",
      AO: "Angola",
      AQ: "Antarctique",
      AS: "Samoa américaine",
      AR: "Argentine",
      AU: "Australie",
      AT: "L'Autriche",
      AW: "Aruba",
      IN: "Inde",
      AX: "Iles Aland",
      AZ: "Azerbaïdjan",
      IE: "Irlande",
      ID: "Indonésie",
      UA: "Ukraine",
      QA: "Qatar",
      MZ: "Mozambique",
    },
  },
  hi: {
    errors: {
      noRaces: "इस समय रेस उपलब्ध नहीं हैं!",
      emptyBetSlip: "बेट स्लिप खाली है!",
      emptyOpenBets: "कोई खुला दांव नहीं है!",
      noRaceData: "रेस डेटा उपलब्ध नहीं है!",
      cancelledRace: "दौड़ रद्द कर दी गई है!",
    },
    generals: {
      allRaces: "सभी दौड़",
      races: "दौड़",
      race: "रेस",
      runners: "धावक",
      transactions: "लेनदेन",
      home: "घर",
      openBets: "खुले दांव",
      betSlip: "शर्त पर्ची",
      raceType: "दौड़ प्रकार",
      date: "तारीख",
      time: "समय",
      distance: "दूरी",
      trackCondition: "ट्रैक की स्थिति",
      weather: "मौसम",
      filter: "फ़िल्टर",
      thRacing: "पूरी तरह से रेसिंग",
      hsRacing: "हार्नेस रेसिंग",
      doRacing: "ग्रेहाउंड रेसिंग",
      helpCenter: "सहायता केंद्र",
      demoVideos: "डेमो वीडियो",
      upcomingRaces: "आगामी दौड़",
      percentage: "प्रतिशत",
      amount: "मात्रा",
      addBet: "दांव जोड़ें",
      placeAllBets: "सभी दांव लगाओ",
      bet: "दांव",
      clear_all: "सभी साफ करें",
      betTypes: "दांव प्रकार",
      winners: "विजेता",
      payOut: "भुगतान करना",
      pool_type: "पूल प्रकार",
      bet_status: "शर्त स्थिति",
      combinations: "संयोजन",
      investments: "निवेश",
      selection_amount: "चयन राशि",
      total_winnings: "सम्पूर्ण जीता",
      t_id: "लेनदेन क्रम",
      tracks: "ट्रैक",
      race_number: "दौड़ संख्या",
      invested: "निवेश किया",
      pnl: "पीएनएल",
      poolType: "पूल प्रकार",
      costPerCombo: "लागत/कॉम्बो",
      processing: "प्रसंस्करण",
      TH: "पूरी तरह से",
      HS: "हार्नेस",
      DO: "ग्रेहाउंड",
      RESULTED: "परिणाम दिया",
      OPEN: "चालु",
      CLOSED: "ख़तम",
      CANCELLED: "रद्द",
      odds: "कठिनाइयाँ",
      pool: "पोखर",
      betAmount: "शर्त राशि",
      WIN_PLACE: "विन / प्लेस",
      WIN: "जीत",
      PLACE: "स्थान",
      SHOW: "प्रदर्शन",
      QUINELLA: "क्विनेला",
      EXACTA: "एक्साक्टा",
      TRIFECTA: "त्रिफेक्टा",
      SUPERFECTA: "सुपरफेक्टा",
      FO_WIN: "जीत तय",
      FO_PLACE: "तय करें",
      pools_payout: "पूल भुगतान",
      no_data_available: "कोई डेटा उपलब्ध नहीं है।",
      first: "1",
      second: "2",
      third: "3",
      fourth: "4",
      negotiateMessage: "क्या आप के साथ बातचीत करना चाहते हैं",
      accept: "स्वीकार करना",
      reject: "अस्वीकार",
      today: "आज",
      yesterday: "कल",
      expertTips: "विशेषज्ञ युक्तियाँ",
      winProbability: "जीत की संभावना",
      tip: "सलाह",
    },
    tracks: {
      ABU_DHABI: "अबु धाबी",
      JEBEL_ALI: "जेबल अली",
      MEYDAN: "मेदन",
      SHARJAH: "शरजाह",
      ADAMINABY: "एडमिनाबी",
      ADELAIDE_RIVER: "एडिलेड नदी",
      ALBANY: "अल्बानी",
      ALBION_PARK: "एल्बियन पार्क",
      ALBURY: "एल्बरी",
      ALEXANDRA: "एलेक्जेंड्रा",
      ALICE_SPRINGS: "एलिस स्प्रिंग्स",
      ALPHA: "अल्फा",
      ANGLE_PARK: "एंगल पार्क",
      ARAMAC: "अरामैक",
      ARARAT: "अररत",
      ARDLETHAN: "अर्दलेथन",
      ARMIDALE: "आर्मिडेल",
      ASCOT: "Ascot",
      ATHERTON: "एथरटन",
      AUGATHELLA: "ऑगाथेला",
      AVOCA: "एवोका",
      BAIRNSDALE: "बैरनडेल",
      BALAKLAVA: "बालाक्लावा",
      BALLARAT: "बल्लारत",
      BALLART_SYNTHETIC: "बैलार्ट सिंथेटिक",
      BALLINA: "बैलिना",
      BALNARRING: "बाल्नरिंग",
      BALRANALD: "बलरनाल्ड",
      BANKSTOWN: "बैंकस्टाउन",
      BARCALDINE: "बार्कलडाइन",
      BARRABA: "बरबा",
      BATHURST: "बाथर्स्ट",
      BEAUDESERT: "Beaudesert",
      BEAUMONT: "ब्यूमोंट",
      BEDOURIE: "बेडौरी",
      BELL: "बेल",
      BELMONT_PARK: "बेलमोंट पार्क",
      BENALLA: "बेनाला",
      BENDIGO: "बेंडिगो",
      BERRIGAN: "बेरिगन",
      BETOOTA: "Betoota",
      BINGARA: "बिंगारा",
      BINNAWAY: "Binnaway",
      BIRCHIP: "बिर्चिप",
      BIRDSVILLE: "बर्ड्सविले",
      BLACKALL: "Blackall",
      BLAYNEY: "ब्लेनी",
      BLUFF: "झांसा",
      BOMBALA: "बम",
      BONG_BONG: "बोंग बोंग",
      BOORT: "कूड़े",
      BORDERTOWN: "सरहदी कस्बा",
      BOULIA: "बोउलिया",
      BOURKE: "बोर्के",
      BOWEN: "बोवेन",
      BOWRAVILLE: "बोवरविले",
      BRAIDWOOD: "ब्रैडवुड",
      BREWARRINA: "Brewarrina",
      BRIDGETOWN: "ब्रिजटाउन",
      BROKEN_HILL: "टूटी हुई पहाड़ी",
      BROOME: "ब्रूम",
      BUCHAN: "बुकान",
      BULLI: "बुल्ली",
      BUNBURY: "बनबरी",
      BUNDABERG: "बुंडाबर्ग",
      BUNDARRA: "बुंदरा",
      BURNIE: "बर्नी",
      BURRA: "बुर्रा",
      BURRUMBEET: "बुरुम्बेट",
      BUSSELTON: "बुसेलटन",
      CAIRNS: "केर्न्स",
      CALLIOPE: "Calliope",
      CAMOOWEAL: "Camooweal",
      CAMPERDOWN: "कैम्परडाउन",
      CANBERRA: "कैनबरा",
      CANNINGTON: "कैनिंगटन",
      CANTERBURY: "कैंटरबरी",
      CAPALABA: "कैपलाबा",
      CARINDA: "कारिंडा",
      CARNARVON: "कार्नरवॉन",
      CARRATHOOL: "कारथूल",
      CARRICK_PARK: "कैरिक पार्क",
      CASINO: "कैसीनो",
      CASTERTON: "Casterton",
      CAULFIELD: "Caulfield",
      CEDUNA: "सेडुना",
      CESSNOCK: "Cessnock",
      CHARLEVILLE: "चार्लविले",
      CHARLTON: "चार्लटन",
      CHARTERS_TOWERS: "चार्टर्स टावर्स",
      CHINCHILLA: "चिनचिला",
      CLARE: "क्लेयर",
      CLERMONT: "क्लरमोंट",
      CLIFTON: "क्लिफ्टन",
      CLONCURRY: "Cloncurry",
      COBAR: "कोबार",
      COBRAM: "कोब्राम",
      COFFS_HARBOUR: "कॉफ्स हार्बर",
      COLAC: "कोलैक",
      COLERAINE: "Coleraine",
      COLLARENEBRI: "Collarenebri",
      COLLIE: "कोली",
      COOKTOWN: "कुकटाउन",
      COOLAMON: "कूलमोन",
      COOMA_MONARO_RACE_CLUB: "कूमा मोनारो रेस क्लब",
      COONABARABRAN: "Coonabarabran",
      COONAMBLE: "Coonamble",
      COOTAMUNDRA: "Cootamundra",
      COROWA: "कोरोवा",
      COWRA: "कोरा",
      CRANBOURNE: "क्रैनबोर्न",
      CUNNAMULLA: "कुन्नामुल्ला",
      DALBY: "डल्बी",
      DAPTO: "डैप्टो",
      DARWIN: "डार्विन",
      DEAGON: "बधिर",
      DEDERANG: "डेडरंग",
      DEEPWATER: "गहरा पानी",
      DENILIQUIN: "डेनिलिकिन",
      DERBY: "डर्बी",
      DEVONPORT: "Devonport",
      DEVONPORT_SYNTHETIC: "डेवोनपोर्ट सिंथेटिक",
      DINGO: "डिंगो",
      DONALD: "डोनाल्ड",
      DONGARA: "दोंगरा",
      DOOMBEN: "डोम्बेन",
      DROUIN: "Drouin",
      DUBBO: "डब्बो",
      DUNKELD: "डंकल्ड",
      EAGLE_FARM: "ईगल फार्म",
      ECHUCA: "इचुका",
      EDENHOPE: "एडेनहोप",
      EINASLEIGH: "Einasleigh",
      ELMORE: "एलमोर",
      EMERALD: "पन्ना",
      ESK: "एस्क",
      ESPERANCE: "Gesperance",
      EUGOWRA: "यूजोवरा",
      EWAN: "इवान",
      FANNIE_BAY: "फैनी बे",
      FLEMINGTON: "फ्लेमिंगटन",
      FLINDERS: "Flinders",
      FORBES: "फोर्ब्स",
      GATTON: "गैटन",
      GAWLER: "गावलर",
      GAYNDAH: "GAYNDAH",
      GEELONG: "Gielong",
      GERALDTON: "गेराल्डटन",
      GEURIE: "Geurie",
      GILGANDRA: "गिलगांड्रा",
      GLADSTONE: "ग्लेडस्टोन",
      GLEN_INNES: "ग्लेन इन्स",
      GLOBE_DERBY_PARK: "ग्लोब डर्बी पार्क",
      GLOUCESTER_PARK: "ग्लूसेस्टर पार्क",
      GOLD_COAST: "घाना",
      GOONDIWINDI: "GOONDIWINDI",
      GORDONVALE: "गॉर्डनवेल",
      GOSFORD: "गोस्फोर्ड",
      GOULBURN: "गॉलबर्न",
      GRAFTON: "ग्राफ्टन",
      GREAT_WESTERN: "महान पश्चिमी",
      GREGORY_DOWNS: "ग्रेगरी डाउन्स",
      GRENFELL: "ग्रेनफेल",
      GRIFFITH: "ग्रिफ़िथ",
      GULARGAMBONE: "Gulargambone",
      GULGONG: "गुलगोंग",
      GUNBOWER: "गनबॉवर",
      GUNDAGAI: "गुंडगई",
      GUNNEDAH: "गुनदाह",
      GYMPIE: "जिम्पी",
      HALIDON: "हैलिडॉन",
      HAMILTON: "हैमिल्टन",
      HANGING_ROCK: "हैंगिंग रॉक",
      HARDEN: "कठोर",
      HAWKER: "हॉकर",
      HAWKESBURY: "हॉक्सबरी",
      HAY: "हे",
      HEALESVILLE: "हील्सविले",
      HILLSTON: "हिलस्टन",
      HINNOMUNJIE: "Hinnomunjie",
      HOBART: "होबार्ट",
      HOLBROOK: "होलब्रुक",
      HOME_HILL: "होम हिल",
      HORSHAM: "होशम",
      HUGHENDEN: "ह्यूगेंडेन",
      ILFRACOMBE: "Ilfracombe",
      INGHAM: "इंगम",
      INJUNE: "जून में",
      INNISFAIL: "सांस",
      INVERELL: "Inverell",
      IPSWICH: "इप्सविच",
      ISISFORD: "Isisford",
      JAMESTOWN: "जेम्सटाउन",
      JANDOWAE: "जंडोवा",
      JERILDERIE: "जेरिल्डी",
      JULIA_CREEK: "जूलिया क्रीक",
      JUNCTION: "संगम",
      JUNDAH: "जुंडा",
      JUNEE: "Junee",
      KALGOORLIE: "कलगोरली",
      KANGAROO_ISLAND: "कंगारू द्वीप",
      KAPUNDA: "कपुंडा",
      KATHERINE: "कैथरीन",
      KELLERBERRIN: "केलरबरीन",
      KEMBLA_GRANGE: "केम्बला ग्रेंज",
      KEMPSEY: "केम्पसी",
      KERANG: "केरंग",
      KILCOY: "किलकॉय",
      KILMORE: "किलमोर",
      KINGSCOTE: "किंग्सकोट",
      KING_ISLAND: "किंग आइलैंड",
      KOJONUP: "कोजोनुप",
      KUMBIA: "कुंबिया",
      KUNUNURRA: "कुनुनुर्रा",
      KYNETON: "Kyneton",
      LANDOR: "लैंडर",
      LAUNCESTON: "लाउंसेस्टन",
      LAVERTON: "लवर्टन",
      LEETON: "लेटॉन",
      LEINSTER: "लेइनस्टर",
      LEONORA: "लियोनोरा",
      LISMORE: "लिस्मोर",
      LITHGOW: "लिथगो",
      LOCK: "ताला",
      LOCKHART: "लॉकहार्ट",
      LONGFORD: "लॉन्गफोर्ड",
      LONGREACH: "लंबी पहँच",
      LOUTH: "लूथ",
      MACKAY: "मैके",
      MAITLAND: "मैटलैंड",
      MALLAWA: "मलवा",
      MANANGATANG: "मनंगतांग",
      MANDURAH: "मंडुराह",
      MANSFIELD: "मैन्सफील्ड",
      MARBLE_BAR: "संगमरमर बार",
      MARBURG: "मारबर्ग",
      MAREEBA: "Mareeba",
      MARYBOROUGH: "मैरीबोरो",
      MAXWELTON: "मैक्सवेल्टन",
      MCKINLAY: "मैकिनले",
      MEEKATHARRA: "Meekatharra",
      MELBOURNE: "मेलबर्न",
      MELTON: "मेल्टन",
      MENANGLE: "Menangle",
      MENDOORAN: "मेंडोरन",
      MERRIWA: "मेरिवा",
      MERTON: "मर्टन",
      MIDDLEMOUNT: "Middlemount",
      MILDURA: "मिल्डुरा",
      MILES: "मील",
      MINGENEW: "Mingenew",
      MITCHELL: "मिशेल",
      MOE: "मो",
      MONTO: "मोंटो",
      MOONEE_VALLEY: "मोनी घाटी",
      MOORA: "मूर",
      MORANBAH: "मोरनबाह",
      MOREE: "मोर",
      MORNINGTON: "मॉर्निंगटन",
      MORPHETTVILLE: "मॉर्फेटविले",
      MORTLAKE: "मॉर्टलेक",
      MORUYA: "मोरुया",
      MORVEN: "मोरवेन",
      MOULAMEIN: "मौलमीन",
      MOUNT_GAMBIER: "माउंट गैंबियर",
      MT_BARKER: "माउंट बार्कर",
      MT_GAMBIER: "माउंट गैंबियर",
      MT_GARNET: "एमटी गार्नेट",
      MT_ISA: "माउंट ईसा",
      MT_MAGNET: "एमटी चुंबक",
      MT_NEWMAN: "एमटी न्यूमैन",
      MT_PERRY: "माउंट पेरी",
      MUDGEE: "मुडगे",
      MUNGERY: "मुंगरी",
      MURRAY_BRIDGE: "मरे ब्रिज",
      MURTOA: "मुर्टो",
      MURWILLUMBAH: "मुरविलुम्बा",
      MUSWELLBROOK: "Muswellbrook",
      MUTTABURRA: "मुत्तबुर्रा",
      NANANGO: "नानांगो",
      NARACOORTE: "नरकोर्टे",
      NARRABRI: "नरबरी",
      NARRANDERA: "नरदंडेरा",
      NARROGIN: "नरोगिन",
      NARROMINE: "नरमिन",
      NEWCASTLE: "न्यूकैसल",
      NHILL: "Nhill",
      NOORAMA: "नूरमा",
      NORSEMAN: "Norseman",
      NORTHAM: "नोर्थम",
      NOWRA: "नोवा",
      NYNGAN: "Nyngan",
      OAKBANK: "ओकबैंक",
      OAK_PARK: "ओक पार्क",
      ORANGE: "नारंगी",
      OUYEN: "ओयेन",
      PAKENHAM: "पाकनहम",
      PARKES: "पार्क",
      PEAK_HILL: "पीक हिल",
      PENOLA: "पेनोला",
      PENONG: "पेनोंग",
      PENRITH: "पेन्रिथ",
      PENSHURST: "पेनशर्स्ट",
      PENTLAND: "पेंटलैंड",
      PINGRUP: "पिंग्रप",
      PINJARRA: "पिंजारा",
      POONCARIE: "Pooncarie",
      PORT_AUGUSTA: "पोर्ट ऑगस्टा",
      PORT_HEDLAND: "पोर्ट हेडलैंड",
      PORT_LINCOLN: "पोर्ट लिंकन",
      PORT_MACQUARIE: "पोर्ट मैक्वेरी",
      PORT_PIRIE: "पोर्ट पिरी",
      POTTS_PARK: "पॉट्स पार्क",
      PRAIRIE: "प्रैरी",
      QUEANBEYAN: "Queanbeyan",
      QUILPIE: "Quilpie",
      QUIRINDI: "क्विरिंडी",
      QUORN: "Quorn",
      RANDWICK: "रैंडविक",
      REDCLIFFE: "Redcliffe",
      RICHMOND: "रिचमंड",
      ROCKHAMPTON: "रॉकहैम्प्टन",
      ROEBOURNE: "Roebourne",
      ROMA: "रोमा",
      ROSEHILL: "गुलाब",
      ROXBY_DOWNS: "रॉक्सबी डाउन्स",
      SALE: "बिक्री",
      SANDOWN: "सैंडाउन",
      SANDOWN_HILLSIDE: "सैंडाउन हिलसाइड",
      SANDOWN_LAKESIDE: "सैंडाउन लेकसाइड",
      SANDOWN_PARK: "सैंडाउन पार्क",
      SAPPHIRE_COAST: "नीलम तट",
      SCONE: "स्कोन",
      SCOTTSDALE: "स्कॉट्सडेल",
      SEYMOUR: "सीमोर",
      SHEPPARTON: "शेपार्टन",
      SOUTH_AUSTRALIA: "दक्षिण ऑस्ट्रेलिया",
      SPRINGSURE: "स्प्रिंग्स",
      STAMFORD: "स्टैमफोर्ड",
      STANTHORPE: "Stanthorpe",
      STAWELL: "स्टावेल",
      STONY_CREEK: "स्टोनी क्रीक",
      STRATHALBYN: "स्ट्रैथलबिन",
      STREAKY_BAY: "स्ट्रीकी बे",
      ST_ARNAUD: "सेंट अरनौद",
      ST_GEORGE: "सेट जॉर्ज",
      ST_MARYS: "सेंट मैरी",
      SUNSHINE_COAST: "सनशाइन समुद्री तट",
      SURAT: "सूरत",
      SWAN_HILL: "स्वान हिल",
      SWIFT_S_CREEK: "स्विफ्ट एस क्रीक",
      TABULAM: "तबुलम",
      TALWOOD: "टैलवुड",
      TAMBO: "टैम्बो",
      TAMWORTH: "टैमवर्थ",
      TARA: "तारा",
      TAREE: "तारी",
      TAROOM: "तारूम",
      TATURA: "तातुरा",
      TEMORA: "टेम्पोरा",
      TENNANT_CREEK: "टेनेन्ट क्रीक",
      TERANG: "तेरांग",
      TEXAS: "टेक्सास",
      THANGOOL: "थांगूल",
      THE_GARDENS: "बगीचे",
      THE_MEADOWS: "घास के मैदान",
      TOCUMWAL: "TOMUMWAL",
      TOMINGLEY: "टोमिंगली",
      TOODYAY: "Toodyay",
      TOOWOOMBA: "Toowoomba",
      TOTTENHAM: "टोटेनहम",
      TOWNSVILLE: "टाउनस्विले",
      TOWONG: "टोंग",
      TRANGIE: "Trangie",
      TRARALGON: "ट्रालगॉन",
      TULLIBIGEAL: "Tullibigeal",
      TUMBARUMBA: "तुंबरुम्बा",
      TUMUT: "ट्यूमर",
      TUNCURRY: "तंक्रि",
      TWIN_HILLS: "ट्विन हिल्स",
      VICTOR_HARBOR: "विक्टर हार्बर",
      WAGGA: "वाग्गा",
      WAGGA_RIVERSIDE: "वाग्गा रिवरसाइड",
      WAGIN: "वैगिन",
      WALCHA: "वाल्चा",
      WALGETT: "Walgett",
      WALLABADAH: "वलाबदाह",
      WANDOAN: "वांडोन",
      WANGARATTA: "वांगराट्टा",
      WARIALDA: "वारियाल्डा",
      WARRA: "वार",
      WARRACKNABEAL: "वॉरैकनबेल",
      WARRAGUL: "वारगुल",
      WARREN: "वॉरेन",
      WARRNAMBOOL: "वॉरनम्बूल",
      WARRNAMBOOL_EXTRA: "वॉरनबोल अतिरिक्त",
      WARWICK: "वारविक",
      WARWICK_FARM: "वारविक फार्म",
      WAUCHOPE: "वाउचोप",
      WAYVILLE: "वेविले",
      WEAN: "वीन",
      WEDDERBURN: "वेडरबर्न",
      WELLINGTON: "वेलिंगटन",
      WENTWORTH: "वेंटवर्थ",
      WENTWORTH_PARK: "वेंटवर्थ पार्क",
      WERRIBEE: "Werribee",
      WEST_WYALONG: "वेस्ट वायलॉन्ग",
      WHYALLA: "Whyalla",
      WILLIAMS: "विलियम्स",
      WINNELLIE_PARK: "विनेली पार्क",
      WINTON: "विंटन",
      WODONGA: "वोडोंगा",
      WONDAI: "वोंदाई",
      WOOLAMAI: "वूलमई",
      WYCHEPROOF: "Wycheprof",
      WYNDHAM: "Wyndham",
      WYONG: "वायोंग",
      YALGOO: "यल्गू",
      YARRA_VALLEY: "यारा घाटी",
      YASS: "यास",
      YEA: "हाँ",
      YEPPOON: "Yeppoon",
      YORK: "यॉर्क",
      YOUNG: "युवा",
      RICHMOND_STRAIGHT: "रिचमंड स्ट्रेट",
      MURRAY_BRIDGE_STRAIGHT: "मरे ब्रिज सीधे",
      BADEN_BADEN: "बैडेन बैडेन",
      BAD_DOBERAN: "बुरा डोबरन",
      BAD_HARZBURG: "बुरा हर्ज़बर्ग",
      BREMEN: "ब्रेमेन",
      COLOGNE: "कोलोन",
      DINSLAKEN: "Dinslaken",
      DORTMUND: "डॉर्टमुंड",
      DRESDE: "Dresde",
      DRESDEN: "ड्रेसडेन",
      DUSSELDORF: "Dusseldorf",
      GELSENKIRCHEN: "गेल्सनकिर्चेन",
      HAMBOURG: "हैम्बरग",
      HAMBOURG_HORN: "हैम्बरग हॉर्न",
      HAMBURG: "हैम्बर्ग",
      HANNOVER: "हनोवर",
      HANOVRE: "हनोव्रे",
      HASSLOCH: "हसलोच",
      HOPPEGARTEN: "होपगार्टन",
      KARLSHORST: "कार्लशोरस्ट",
      KOLN: "कोलन",
      KREFELD: "क्रेफेल्ड",
      KRIEAU: "Krieau",
      LEIPZIG: "लीपज़िग",
      MAGDEBURG: "मैगडेबर्ग",
      MANNHEIM: "मैनहेम",
      MARIENDORF: "Mariendorf",
      MONCHENGLADBACH: "मोनचेंग्लादबैक",
      MULHEIM: "मुलहेम",
      MUNCHEN: "मुंचेन",
      MUNICH_DAGLFING: "म्यूनिख डग्लफिंग",
      MUNICH_RIEM: "म्यूनिख रीम",
      NEUSS: "नेस",
      SAARBRUCKEN: "सार्ब्रूकेन",
      STRAUBING: "Strabing",
      AGEN_LA_GARENNE: "एगेन ला गेरेन",
      AGON_COUTAINVILLE: "एगोन कॉटनविले",
      AIX_LES_BAINS: "ऐक्स लेस बैंस",
      AJACCIO: "Ajaccio",
      ALENCON: "एलेनकॉन",
      AMIENS: "अमीन्स",
      ANGERS: "एंगर्स",
      ANGOULEME: "अंगुलीम",
      ARGENTAN: "अर्जेंटीना",
      ARRAS: "Arras",
      AUCH: "Auch",
      AUTEUIL: "Auteuil",
      AVIGNON: "Avignon",
      BEAUMONT_DE_LOMAGNE: "ब्यूमोंट डी लोमगन",
      BERNAY: "बर्न",
      BIARRITZ: "Biarritz",
      BOLLENE: "बोलेन",
      BORELY: "बोरली",
      BREHAL: "ब्रेहल",
      CABOURG: "कैबॉर्ग",
      CAEN: "केन",
      CAGNES_MIDI: "कैगनेस मिडी",
      CAGNES_SUR_MER: "Cagnes sur mer",
      CARENTAN: "कारेंटन",
      CARPENTRAS: "कारपेंट्रास",
      CASTERA_VERDUZAN: "Castera Verduzan",
      CASTILLONNES: "कैस्टिलोन्स",
      CAVAILLON: "कैविलोन",
      CHAMP_DE_MARS: "चैंपियन डे मार्स",
      CHANTILLY: "चैंटिली",
      CHANTILLY_MIDI: "Chantilly Midi",
      CHARTRES: "चार्ट्रेस",
      CHATEAUBRIANT: "Chateaubriant",
      CHATEAUROUX: "Chateauroux",
      CHATELAILLON: "Chatelaillon",
      CHATILLON: "चैटिलोन",
      CHERBOURG: "चेरबर्ग",
      CHOLET: "कोला",
      CLAIREFONTAINE: "Clairefontaine",
      COMPIEGNE: "Compiegne",
      CORDEMAIS: "कॉर्डेमाइस",
      CRAON: "क्राओन",
      DAX: "डैक्स",
      DEAUVILLE: "ड्यूविले",
      DEAUVILLE_MIDI: "ड्यूविले मिडी",
      DIEPPE: "Dieppe",
      DIVONNE_LES_BAINS: "डिवोन लेस बैंस",
      DURTAL: "डर्टल",
      EAUZE: "Eauze",
      ECOMMOY: "ECOMMOY",
      ENGHIEN: "Enghien",
      EVREUX: "Evreux",
      FEURS: "Feurs",
      FONTAINEBLEAU: "Fontainebleau",
      FOUGERES: "Fougeres",
      GRAIGNES: "Graignes",
      GRANVILLE: "ग्रानविले",
      GRENADE_SUR_GARONNE: "ग्रेनेड सुर गारोन",
      GUADELOUPE: "ग्वाडेलूप",
      HYERES: "हाइरेस",
      ISLE_SUR_SORGUE: "आइल सॉरग्यू",
      JULLIANGES: "Jullianges",
      LANDIVISIAU: "Landivisiau",
      LANGON_LIBOURNE: "लैंगोन लिबोर्न",
      LAON: "लोन",
      LAVAL: "लावल",
      LA_CAPELLE: "ला कैपेल",
      LA_CEPIERE: "ला सेपियर",
      LA_CLAYETTE: "ला क्लेयेट",
      LA_GACILLY: "ला गैसीली",
      LA_ROCHE_POSAY: "ला रोश पॉय",
      LA_TESTE: "ला टेस्टे",
      LES_SABLES_D_OLONNE: "लेस सैबल्स डी ओलोन",
      LE_BOUSCAT: "ले बूसकैट",
      LE_CROISE_LAROCHE: "ले क्रोज़ लारोचे",
      LE_LION_DANGERS: "ले शेर के खतरे",
      LE_MANS: "ले मैंस",
      LE_MONT_SAINT_MICHEL: "ले मॉन्ट सेंट मिशेल",
      LE_TOUQUET: "ले टाउकेट",
      LIGNIERES: "लिग्नियर",
      LISIEUX: "लिसेक्स",
      LYON_LA_SOIE: "लियोन ला सोइ",
      LYON_PARILLY: "लियोन पार्ली",
      MACHECOUL: "माचिसोल",
      MACON_CLUNY: "मैकॉन क्लुनी",
      MAISONS_LAFFITTE: "मैसन्स लफिटे",
      MARTINIQUE: "मार्टिनिक",
      MAUQUENCHY: "माउक्वेंची",
      MAURE_DE_BRETAGNE: "मौर डे ब्रेटेन",
      MESLAY_DU_MAINE: "मेसले डू मेन",
      MONTAUBAN: "मोंटुबन",
      MONTIER_EN_DER: "मोंटियर एन डेर",
      MONTLUCON: "मोंट्लुकॉन",
      MONT_DE_MARSAN: "मोंट डी मारसन",
      MORLAIX: "मोरलिक्स",
      MOULINS: "मौलिंस",
      NANCY_BRABOIS: "नैन्सी ब्रेबोइस",
      NANTES: "नैन्टेस",
      NIMES: "Nimes",
      NIORT: "Niort",
      NORT_SUR_ERDRE: "Nort sur erdre",
      ORAISON: "Oraison",
      PARAY_LE_MONIAL: "Paray le Monial",
      PARISLONGCHAMP: "Parislongchamp",
      PAU: "पाऊ",
      POMPADOUR: "पोम्पडौर",
      PONTCHATEAU: "पोंटचेट्यू",
      PONT_DE_VIVAUX: "पोंट डे विवाक्स",
      PORNICHET: "पोर्निच",
      PRUNELLI: "Prunelli",
      RAMBOUILLET: "Rambouillet",
      REIMS: "रिम्स",
      ROYAN: "रोयण",
      SABLE_SUR_SARTHE: "सेबल सर सरथे",
      SAINT_BRIEUC: "संत ब्रीक",
      SAINT_CLOUD: "सेंट क्लाउड",
      SAINT_GALMIER: "सेंट गैल्मियर",
      SAINT_MALO: "सेंट मालो",
      SALON_DE_PROVENCE: "सैलून डी प्रोवेंस",
      SEGRE: "सेग्रे",
      SENONNES_POUANCE: "सेनोनेस प्यून्स",
      STRASBOURG: "स्ट्रासबर्ग",
      ST_JEAN_DE_MONTS: "सेंट जीन डी मोंट",
      TARBES: "टार्ब्स",
      TOURS: "पर्यटन",
      VANNES: "Vanses",
      VICHY: "विची",
      VICHY_SOIR: "विची सोइर",
      VILLENEUVE_SUR_LOT: "विलेनुवे सुर लॉट",
      VILLEREAL: "खलनायक",
      VINCENNES: "विन्सेन्स",
      VIRE: "Vire",
      VITRE: "विट्रे",
      VITTEL: "विटेल",
      WISSEMBOURG: "Wissemburg",
      ZONZA: "ज़ोंज़ा",
      AINTREE: "Aintree",
      AYR: "Ayr",
      BANGOR_ON_DEE: "बैंगोर ऑन डी",
      BATH: "नहाना",
      BELLE_VUE: "बेले वू",
      BEVERLEY: "बेवर्ली",
      BRIGHTON: "ब्राइटन",
      BRIGHTON_HOVE: "ब्राइटन होव",
      CARLISLE: "कार्लिसल",
      CARTMEL: "कार्टमेल",
      CARTMELL: "कार्टेल",
      CATTERICK_BRIDGE: "कैटरिक ब्रिज",
      CENTRAL_PARK: "केंद्रीय उद्यान",
      CHELMSFORD_CITY: "चेम्सफोर्ड सिटी",
      CHELTENHAM: "चेल्टेनहम",
      CHEPSTOW: "Chepstow",
      CHESTER: "चेस्टर",
      COVENTRY: "कोवेंट्री",
      CRAYFORD: "क्रेफोर्ड",
      DONCASTER: "डॉनकास्टर",
      EPSOM_DOWNS: "एप्सोम डाउन्स",
      EXETER: "एक्सेटर",
      FAKENHAM: "फेकनहैम",
      FFOS_LAS: "एफएफओएस लास",
      FONTWELL: "फोंटवेल",
      GOODWOOD: "अच्छा फर्निचर",
      GOWRAN_PARK: "गौवन पार्क",
      GREAT_YARMOUTH: "महान यारमाउथ",
      HALL_GREEN: "हॉल ग्रीन",
      HAMILTON_PARK: "हैमिल्टन पार्क",
      HARLOW: "हार्लो",
      HARLOW_AM: "हार्लो एम",
      HAYDOCK_PARK: "हेडॉक पार्क",
      HENLOW: "हेनलो",
      HENLOW_AM: "HENLOW AM",
      HEREFORD: "हियरफोर्ड",
      HEXHAM: "हेक्सम",
      HOVE: "होव",
      HOVE_AM: "होव एम",
      HUNTINGDON: "हंटिंगडन",
      KELSO: "केलो",
      KEMPTON_PARK: "केम्पटन पार्क",
      KINSLEY: "किंसले",
      LEICESTER: "लीसेस्टर",
      LINGFIELD_PARK: "लिंगफील्ड पार्क",
      LUDLOW: "लुडलो",
      MARKET_RASEN: "बाजार रस",
      MILDENHALL: "मिल्डेनहॉल",
      MILTON_KEYNES: "मिल्टन कीन्स",
      MONMORE: "मोनमोर",
      MONMORE_GREEN: "मोनमोर ग्रीन",
      MUSSELBURGH: "मुसेलबर्ग",
      NEWBURY: "न्यूबरी",
      NEWMARKET: "नया बाज़ार",
      NEWTON_ABBOT: "न्यूटन एबॉट",
      NOTTINGHAM: "नॉटिंघम",
      PELAW_GRANGE: "पेलॉ ग्रेंज",
      PERRY_BARR: "पेरी बैर",
      PERTH: "पर्थ",
      PETERBOROUGH: "पीटरबरो",
      PLUMPTON: "प्लम्पटन",
      PONTEFRACT: "Pontefract",
      POOLE: "पूले",
      REDCAR: "लाल रंग की कार",
      RIPON: "रिपन",
      ROMFORD: "रोमफोर्ड",
      ROMFORD_AM: "रोमफोर्ड एम",
      RYE_HOUSE: "राई हाउस",
      SALISBURY: "सैलिसबरी",
      SEDGEFIELD: "सेजफील्ड",
      SHAWFIELD: "शॉफील्ड",
      SHEFFIELD: "शेफ़ील्ड",
      SITTINGBOURNE: "सिटिंगबॉर्न",
      SITTINGBOURNE_AM: "सिटिंगबॉर्न एम",
      SOUTHWELL: "साउथवेल",
      STRATFORD_ON_AVON: "एवन पर स्ट्रैटफ़ोर्ड",
      SUNDERLAND: "सुंदरलैंड",
      SWAFFHAM: "स्वैफम",
      SWINDON: "स्विंडन",
      TAUNTON: "टुनटन",
      THIRSK: "थिरस्क",
      TOWCESTER: "TOWCESTER",
      UTTOXETER: "उत्तरी",
      WETHERBY: "Wetherby",
      WIMBLEDON: "विंबलडन",
      WINCANTON: "विनकंटन",
      WINDSOR: "विंडसर",
      WISBECH: "विस्बेच",
      WOLVERHAMPTON: "वॉल्वरहैम्प्टन",
      WORCESTER: "वॉर्सेस्टर",
      YARMOUTH: "यारमाउथ",
      HAPPY_VALLEY: "हैप्पी वैली",
      SHA_TIN: "शा टिन",
      BALLINROBE: "बैलिनरोब",
      BELLEWSTOWN: "बेलेवस्टाउन",
      CLONMEL: "क्लोनमेल",
      CORK: "कॉर्क",
      CURRAGH: "कुराग",
      DOWNPATRICK: "डाउनपैट्रिक",
      DOWN_ROYAL: "नीचे शाही",
      DUNDALK: "डंडालक",
      FAIRYHOUSE: "फेयरीहाउस",
      GALWAY: "गॉलवे",
      KILBEGGAN: "किल्बेगगन",
      KILLARNEY: "किलार्नी",
      LAYTOWN: "लेटाउन",
      LEOPARDSTOWN: "तेंदुए",
      LIMERICK: "लिमरिक",
      LISTOWEL: "Listowel",
      NAAS: "नास",
      NAVAN: "नवान",
      PUNCHESTOWN: "पंचस्टाउन",
      ROSCOMMON: "Rossommon",
      SLIGO: "स्लिगो",
      THURLES: "Thurles",
      TIPPERARY: "Tipperary",
      TRAMORE: "ट्रामोर",
      WEXFORD: "वेक्सफ़ोर्ड",
      KAWASAKI: "कावासाकी",
      ADDINGTON: "Addington",
      ADDINGTON_EXTRA: "Addington अतिरिक्त",
      ALEXANDRA_PARK: "एलेक्जेंड्रा पार्क",
      ARAWA_PARK: "अरवा पार्क",
      ASCOT_PARK: "एस्कॉट पार्क",
      ASCOT_PARK_EXTRA: "एस्कॉट पार्क अतिरिक्त",
      ASHBURTON: "एशबर्टन",
      ASHWOOD_PARK: "एशवुड पार्क",
      AVONDALE: "एवोंडेल",
      AWAPUNI: "अवापुनी",
      BLENHEIM: "ब्लेनहेम",
      CAMBRIDGE: "कैम्ब्रिज",
      CROMWELL: "क्रॉमवेल",
      DARGAVILLE: "डारगाविले",
      ELLERSLIE: "एलर्सली",
      FORBURY_PARK: "फोर्बरी पार्क",
      FORBURY_PARK_EXTRA: "Forbury Park Extra",
      GERALDINE: "गेराल्डिन",
      GORE: "गोर",
      GREYMOUTH: "ग्रीमाउथ",
      HASTINGS: "हेस्टिंग्स",
      HATRICK: "हैट्रिक",
      HATRICK_EXTRA: "हैट्रिक अतिरिक्त",
      HAWERA: "हवेरा",
      HOKITIKA: "होकिटिका",
      KAIKOURA: "काइकौरा",
      KUMARA: "कुमारा",
      KUROW: "कुरो",
      MAKARAKA: "मकारक",
      MANAWATU: "मनवातु",
      MANAWATU_EXTRA: "मानवातु अतिरिक्त",
      MANUKAU: "मनुकाऊ",
      MANUKAU_EXTRA: "मनुकाऊ अतिरिक्त",
      MATAMATA: "मातमाता",
      MOTUKARARA: "मोटुकरारा",
      MT_HARDING: "माउंट हार्डिंग",
      NEW_PLYMOUTH: "नया प्लायमाउथ",
      NEW_ZEALAND: "न्यूज़ीलैंड",
      OAMARU: "ओमारू",
      OMAKAU: "ओमाकाऊ",
      OMOTO: "ओमोटो",
      OTAKI: "ओटकी",
      OTAKI_MAORI: "ओटकी माओरी",
      PHAR_LAP: "फार लैप",
      PUKEKOHE: "पुकेकोहे",
      PUKEKURA: "पुकेकुरा",
      RANGIORA: "रंगिओरा",
      REEFTON: "रीफटन",
      RICCARTON_PARK: "रिकार्टन पार्क",
      RICHMOND_PARK: "रिचमंड पार्क",
      RIVERTON: "रिवर्टन",
      ROTORUA: "रोटोरुआ",
      ROXBURGH: "रॉक्सबर्ग",
      RUAKAKA: "रुआका",
      STRATFORD: "स्ट्रैटफ़ोर्ड",
      TAUHERENIKAU: "ताऊहेरेंकाऊ",
      TAUPO: "ताओपो",
      TAURANGA: "तौरंगा",
      TE_AROHA: "ते अरहा",
      TE_AWAMUTU: "ते अवमुतु",
      TE_KAPUA: "ते कपुआ",
      TE_KAPUA_PARK: "ते कपुआ पार्क",
      TE_KUPENGA: "ते कुपेंगा",
      TE_RAPA: "ते रापा",
      TE_TEKO: "ते टेको",
      THAMES: "थेम्स",
      TRENTHAM: "ट्रेंथम",
      WAIKOUAITI: "वाइकौती",
      WAIMATE: "Waimate",
      WAIPUKURAU: "वेपुकुराऊ",
      WAIROA: "वैरोआ",
      WANGANUI: "वांगानुई",
      WATERLEA: "वाटरली",
      WATERLEA_PARK: "वाटरली पार्क",
      WAVERLEY: "वेवरली",
      WESTPORT: "वेस्टपोर्ट",
      WINGATUI: "विंगातुई",
      WOODVILLE: "वुडविले",
      CAMBRIDGE_EXTRA: "कैम्ब्रिज अतिरिक्त",
      SINGAPORE: "सिंगापुर",
      AFTON: "Afton",
      AIKEN: "Aiken",
      AJAX_DOWNS: "अजाक्स डाउन्स",
      ALBERTA_DOWNS: "अल्बर्टा डाउन्स",
      ALBION: "अल्बियन",
      ALBUQUERQUE: "अल्बुकर्क",
      ALLEGAN: "एलेगन",
      ALTAMONT: "Altamont",
      ANNA: "अन्ना",
      ANTHONY_DOWNS: "एंथोनी डाउन्स",
      APACHE_COUNTY: "अपाचे काउंटी",
      AQUEDUCT: "एक्वाडक्ट",
      ARAPAHOE_PARK: "अरापाहो पार्क",
      ARIZONA_DOWNS: "एरिज़ोना डाउन्स",
      ARLINGTON: "अर्लिंग्टन",
      ASHLAND: "एशलैंड",
      ASSINIBOIA_DOWNS: "असिनिबोया डाउन्स",
      ATHENS: "एथेंस",
      ATLANTA: "अटलांटा",
      ATLANTIC_CITY: "अटलांटिक शहर",
      ATOKAD_DOWNS: "अटोकड डाउन्स",
      ATTICA: "अटिका",
      AYERS_CLIFF: "आयर्स क्लिफ",
      BALMORAL_PARK: "बाल्मोरल पार्क",
      BANGOR_HOLLYWOOD_SLOTS: "बांगोर हॉलीवुड स्लॉट",
      BARTON: "बार्टन",
      BATAVIA_DOWNS: "बटाविया डाउन्स",
      BAY_MEADOWS: "बे मीडोज",
      BEDFORD: "बेडफोर्ड",
      BELLEFONTAINE: "बेलेफोंटेन",
      BELTERRA_PARK: "बेल्ट्रा पार्क",
      BELVIDERE: "बेल्विडेरे",
      BEULAH_PARK: "बेउला पार्क",
      BIG_RAPIDS: "बिग रैपिड्स",
      BIRMINGHAM_MATINEE: "बर्मिंघम मैटिनी",
      BIRMINGHAM_NIGHT: "बर्मिंघम रात",
      BLACK_FOOT: "काला पैर",
      BLOOMFIELD: "ब्लूमफील्ड",
      BLOOMSBURG: "ब्लूम्सबर्ग",
      BLUE_GRASS_DOWNS: "नीली घास नीचे",
      BLUE_RIBBON_DOWNS: "नीली रिबन डाउन",
      BOONVILLE: "बूनविले",
      BOWLING_GREEN: "गेंदबाजी का हरा मैदान",
      BROCKTON_FAIR: "ब्रॉकटन मेला",
      BROOKHILL_FARM: "ब्रुकहिल फार्म",
      BROWN_COUNTY: "ब्राउन काउंटी",
      BUCYRUS: "Bucyrus",
      BUFFALO_RACEWAY: "बफ़ेलो रेसवे",
      BUTLER: "बटलर",
      CALDWELL: "कैलडवेल",
      CAL_EXPO: "कैल एक्सपो",
      CALIENTE_EVENING: "कैलीन्टे शाम",
      CAMARERO: "केमेरो",
      CAMDEN: "कैमडेन",
      CANFIELD: "कैनफील्ड",
      CANNON_FALLS: "तोप गिरता है",
      CANTERBURY_PARK: "कैंटरबरी पार्क",
      CANTON: "कैंटन",
      CARLINVILLE: "कार्लिनविले",
      CARMI: "कार्मी",
      CARROLLTON: "कैरोलोन",
      CASSIA_COUNTY: "कैसिया काउंटी",
      CELINA: "सेलिना",
      CENTRAL_FAIR: "केंद्रीय मेला",
      CENTREVILLE: "सेंटर विले",
      CENTURY_DOWNS: "सेंचुरी डाउन्स",
      CENTURY_MILE: "सेंचुरी मील",
      CENTURY_MILE_2: "सेंचुरी मील 2",
      CHARLESTON: "चार्ल्सटन",
      CHARLES_TOWN: "चार्ल्स टाउन",
      CHARLOTTE: "शार्लोट",
      CHARLOTTETOWN: "शार्लोटटाउन",
      CHARLOTTETOWN_MATINEE: "शार्लोटटाउन मैटिनी",
      CHILLICOTHE: "चिल्लिकोटे",
      CHIPPEWA_DOWNS: "चिप्पेवा डाउन्स",
      CHURCHILL_DOWNS: "चर्चिल डाउन्स",
      CIRCLEVILLE: "सर्किलविले",
      CLEARFIELD: "साफ क्षेत्र",
      CLEMMONS: "क्लेमन्स",
      CLINTON_RACEWAY: "क्लिंटन रेसवे",
      COBLESKILL: "Cobleskill",
      COCHISE_COUNTY: "कोचिस काउंटी",
      COLONIAL_DOWNS: "औपनिवेशिक डाउन्स",
      COLUMBUS: "कोलंबस",
      CONNERSVILLE: "कोनर्सविले",
      CONVERSE: "समझाना",
      CORYDON: "Corydon",
      COSHOCTON: "Coshocton",
      CROOKED_RIVER: "कुटिल नदी",
      CROSWELL: "क्रॉसवेल",
      CROTON: "क्रोटन",
      CUMBERLAND: "कंबरलैंड",
      DAYTON: "डेटन",
      DAYTONA_BEACH_EVENING: "डेटोना बीच शाम",
      DAYTONA_BEACH_MATINEE: "डेटोना बीच मैटिनी",
      DAYTON_RACEWAY: "डेटन रेसवे",
      DECATUR: "Decatur",
      DELAWARE_COUNTY_FAIR: "डेलावेयर काउंटी मेला",
      DELAWARE_COUNTY_FAIR_MATINEE: "डेलावेयर काउंटी फेयर मैटिनी",
      DELAWARE_PARK: "डेलावेयर पार्क",
      DELEON_SPRINGS: "डेलेन स्प्रिंग्स",
      DELTA_DOWNS: "डेल्टा डाउन्स",
      DEL_MAR: "डेल मार",
      DERBY_LANE: "डर्बी लेन",
      DESERT_PARK: "डेजर्ट पार्क",
      DETROIT_RACE_COURSE: "डेट्रायट रेस कोर्स",
      DIXIE_DOWNS: "डिक्सी डाउन्स",
      DOVER: "डोवर",
      DOVER_DOWNS: "डोवर डाउन्स",
      DRESDEN_RACEWAY: "ड्रेसडेन रेसवे",
      DUNKIRK: "डनकर्क",
      DU_QUOIN: "डू क्वॉइन",
      DU_QUOIN_EVENING: "डु क्वॉइन शाम",
      DU_QUOIN_STATE_FAIR: "डू क्वॉइन स्टेट फेयर",
      EATON: "ईटन",
      ELDON: "एल्डन",
      ELKHORN: "एल्खोर्न",
      ELKO_COUNTY_FAIR: "एल्को काउंटी मेला",
      ELLIS_PARK: "एलिस पार्क",
      ELMIRA: "एल्मिरा",
      ELY: "Ely",
      EMERALD_DOWNS: "पन्ना डाउन्स",
      EMMETT: "एम्मेट",
      ENERGY_DOWNS: "ऊर्जा गिरावट",
      EUREKA: "यूरेका",
      EVANGELINE_DOWNS: "इवेंजलाइन डाउन",
      EXHIBITION_PARK: "प्रदर्शनी पार्क",
      FAIRFAX: "फेयरफैक्स",
      FAIRFIELD: "फेयरफील्ड",
      FAIRMOUNT_PARK: "फेयरमाउंट पार्क",
      FAIRPLEX_PARK: "फेयरप्लेक्स पार्क",
      FAIR_GROUNDS: "निष्पक्ष आधार",
      FAIR_HILL: "फेयर हिल",
      FAIR_MEADOWS_TULSA: "फेयर मीडोज तुलसा",
      FAIR_PLAY_PARK: "फेयर प्ले पार्क",
      FARMER_CITY: "किसान शहर",
      FARMINGTON: "फार्मिंगटन",
      FAR_HILLS: "दूर पहाड़ियों",
      FAYETTEVILLE: "फेयेटविले",
      FERNDALE: "फर्नडेल",
      FINDLAY: "फाइंडले",
      FINGER_LAKES: "उंगली की झीलें",
      FLAGSTAFF: "फ्लैगस्टाफ",
      FLAMBORO_DOWNS: "फ्लेमबोरो डाउन्स",
      FONNER_PARK: "फॉनर पार्क",
      FORT_ERIE: "फोर्ट एरी",
      FORT_PIERRE: "फोर्ट पियरे",
      FOWLERVILLE: "Fowlerville",
      FOXFIELD: "फॉक्सफील्ड",
      FRANKFORT: "फ्रैंकफर्ट",
      FRASER_DOWNS: "फ्रेजर डाउन्स",
      FREDERICK: "फ्रेडरिक",
      FREDERICTON_RACEWAY: "फ्रेडेरिक्टन रेसवे",
      FREEHOLD_RACEWAY: "फ्रीहोल्ड रेसवे",
      FRESNO: "फ्रेस्नो",
      FRYEBURG: "फ्राइबर्ग",
      GAITWAY_FARMS: "गैटवे फार्म्स",
      GARDEN_STATE: "उद्यान राज्य",
      GENESEE_VALLEY: "जेनेसी घाटी",
      GEORGIAN_DOWNS: "जॉर्जियाई डाउन्स",
      GILA_COUNTY_FAIR: "गिला काउंटी मेला",
      GILLESPIE_COUNTY: "गिलेस्पी काउंटी",
      GLENBORO: "ग्लेनबोरो",
      GLYNDON: "Glyndon",
      GOLDEN_GATE_FIELDS: "गोल्डन गेट फील्ड्स",
      GOSHEN: "गोशेन",
      GOUVERNEUR: "Gouverneur",
      GRAHAM_COUNTY: "ग्राहम काउंटी",
      GRANDE_PRAIRIE: "ग्रांड प्रैरी",
      GRAND_NATIONAL: "ग्रैंड नैशनल",
      GRAND_PRAIRIE: "घास का विशाल मैदान",
      GRAND_RIVER_RACEWAY: "ग्रैंड रिवर रेसवे",
      GRANTS_PASS: "अनुदान पास",
      GRATZ: "ग्रैज",
      GREAT_BARRINGTON_FAIR: "ग्रेट बैरिंगटन मेला",
      GREAT_FALLS: "ग्रेट फॉल्स",
      GREAT_LAKES_DOWNS: "महान झीलें नीचे",
      GREAT_MEADOW: "महान घास का मैदान",
      GREENELEE_COUNTY: "ग्रीनली काउंटी",
      GREENUP: "ग्रीनअप",
      GREENVILLE: "ग्रीनविले",
      GULFSTREAM_PARK: "गल्फस्ट्रीम पार्क",
      GULFSTREAM_PARK_WEST: "गल्फस्ट्रीम पार्क वेस्ट",
      HANOVER_RACEWAY: "हनोवर रेसवे",
      HARBOR_PARK: "हार्बर पार्क",
      HARNEY_COUNTY: "हार्नी काउंटी",
      HARRAHS_CHESTER: "हैराह चेस्टर",
      HARRAHS_PHILADELPHIA: "हैराहस फिलाडेल्फिया",
      HARRINGTON_RACEWAY: "हैरिंगटन रेसवे",
      HARRISON: "हैरिसन",
      HART: "हार्ट",
      HAWTHORNE: "नागफनी",
      HAWTHORNE_RACECOURSE: "नागफनी रेसकोर्स",
      HAZEL_PARK: "हेज़ल पार्क",
      HAZEL_PARK_HARNESS: "हेज़ल पार्क हार्नेस",
      HELENA_DOWNS: "हेलेना डाउन्स",
      HEMLOCK: "हेमलॉक",
      HENRY: "हेनरी",
      HIALEAH_PARK: "Hialeah पार्क",
      HIAWATHA_HORSE_PARK: "हियावथा हॉर्स पार्क",
      HICKSVILLE: "हिक्सविले",
      HILLIARD: "हिलियार्ड",
      HIPPODROME_3R: "हिप्पोड्रोम 3 आर",
      HIPPODROME_DE_QUEBEC: "हिप्पोड्रोम डी क्यूबेक",
      HISTORIC_TRACK_GOSHEN: "ऐतिहासिक ट्रैक गोशेन",
      HOLLAND: "हॉलैंड",
      HOLLYWOOD_PARK: "हॉलीवुड पार्क",
      HONESDALE: "Honesdale",
      HOOSIER_PARK: "होसियर पार्क",
      HORSEMENS_PARK: "हॉर्समैन पार्क",
      HUGHESVILLE: "ह्यूजेसविले",
      HUMBOLDT: "हम्बोल्ट",
      HUMBOLDT_COUNTY: "हम्बोल्ट काउंटी",
      INDIANA: "इंडियाना",
      INDIANA_DOWNS: "इंडियाना डाउन्स",
      INDIANA_STATE_FAIR: "इंडियाना स्टेट फेयर",
      INVERNESS_RACEWAY: "इनवर्नेस रेसवे",
      IOWA_EVENING: "आयोवा शाम",
      IOWA_MATINEE: "आयोवा मैटिनी",
      IRONWOOD: "आयरनवुड",
      JACKSONVILLE_MATINEE: "जैक्सनविले मैटिनी",
      JACKSONVILLE_NIGHT: "जैक्सनविले रात",
      JEFFERSON: "जेफरसन",
      JEROME_COUNTY: "जेरोम काउंटी",
      KAHOKIA: "काहोकिया",
      KALISPELL: "Kalispell",
      KAMLOOPS: "कमलूप्स",
      KAWARTHA_DOWNS: "कवर्था डाउन्स",
      KEENELAND: "कीनलैंड",
      KENDALLVILLE: "केंडलविले",
      KENTLAND: "केंटलैंड",
      KENTON: "केंटन",
      KENTUCKY_DOWNS: "केंटकी डाउन्स",
      KINROSS: "किन्नरस",
      KIN_PARK: "किन पार्क",
      KNOXVILLE: "नॉक्सविले",
      LACENTER: "लैकेंटर",
      LANCASTER: "लैंकेस्टर",
      LAUREL_BROWN: "लॉरेल ब्राउन",
      LAUREL_PARK: "लॉरेल पार्क",
      LEAMINGTON_RACEWAY: "लेमिंगटन रेसवे",
      LEBANON: "लेबनान",
      LEBANON_RACEWAY: "लेबनान रेसवे",
      LES_BOIS: "लेस बोइस",
      LETHBRIDGE: "लेथब्रिज",
      LEWISBURG: "लुईसबर्ग",
      LEWISTOWN: "लेविस्टाउन",
      LEXINGTON: "लेक्सिंगटन",
      LIMA: "लीमा",
      LINCOLN: "लिंकन",
      LISBON: "लिस्बन",
      LITTLE_EVERGLADES: "लिटिल एवरग्लेड्स",
      LONE_STAR_PARK: "लोन स्टार पार्क",
      LOS_ALAMITOS: "लॉस अलमिटोस",
      LOS_ALAMITOS_QH: "लॉस अलमिटोस क्यूएच",
      LOUISIANA_DOWNS: "लुइसियाना डाउन्स",
      LOWVILLE: "लोविले",
      MAHONING_VALLEY: "महोनिंग घाटी",
      MALONE: "मालोन",
      MALVERN: "मालवर्न",
      MANOR_DOWNS: "मनोर डाउन",
      MARIAS_FAIR: "मारियास मेला",
      MARIETTA: "Marietta",
      MARION: "मैरियन",
      MARQUIS_DOWNS: "Marquis downs",
      MARSHALL: "मार्शल",
      MARTINSVILLE: "मार्टिंसविले",
      MARYSVILLE: "मैरीसविले",
      MAYFIELD: "मेफील्ड",
      MAYWOOD_PARK: "मेवुड पार्क",
      MCCONNELSVILLE: "मैककोनेल्सविले",
      MEADOWLANDS: "मीडोवलैंड्स",
      MEADVILLE: "मीडविले",
      MELVILLE_DISTRICT_AGRIPAR: "मेलविले डिस्ट्रिक्ट एग्रीपर",
      MIAMI: "मियामी",
      MIAMI_VALLEY: "मियामी घाटी",
      MIDDLEBURG: "मिडलबर्ग",
      MILES_CITY: "माइल्स सिटी",
      MILLARVILLE: "मिलरविले",
      MINERAL_POINT: "खनिज बिंदु",
      MINIDOKA_COUNTY: "मिनिडोका काउंटी",
      MOHAVE_COUNTY: "मोहवे काउंटी",
      MOHAWK_RACETRACK: "मोहक रेसट्रैक",
      MONKTON: "मॉन्कटन",
      MONMOUTH_PARK: "मोनमाउथ पार्क",
      MONTICELLO_RACEWAY: "मोंटिसेलो रेसवे",
      MONTPELIER: "मोंटपेलियर",
      MORRIS: "मॉरिस",
      MORRISON: "मॉरिसन",
      MORVEN_PARK: "मोरवेन पार्क",
      MOUNTAINEER_PARK: "पर्वतारोही पार्क",
      MOUNT_GILEAD: "माउंट गिलियड",
      MOUNT_STERLING: "माउंट स्टर्लिंग",
      MOUNT_VERNON: "माउंट वर्नोन",
      MT_PLEASANT_MEADOWS: "एमटी सुखद घास के मैदान",
      NAPOLEON: "नेपोलियन",
      NEWTON: "न्यूटन",
      NORTHAMPTON: "नॉर्थम्प्टन",
      NORTHERN_MAINE_FAIR: "उत्तरी मेन फेयर",
      NORTHFIELD_PARK: "नॉर्थफील्ड पार्क",
      NORTHLANDS_PARK: "नॉर्थलैंड्स पार्क",
      NORTHVILLE_DOWNS: "नॉर्थविले डाउन्स",
      NORTH_DAKOTA_HORSE_PARK: "नॉर्थ डकोटा हॉर्स पार्क",
      NORTH_SIDE_DOWNS: "उत्तर की ओर नीचे",
      NORWICH: "नॉर्विच",
      NOUVELLE: "नूवेल",
      OAKLAWN_PARK: "ओकलेन पार्क",
      OAK_GROVE_RACING_GAMING: "ओक ग्रोव रेसिंग गेमिंग",
      OAK_HARBOR: "ओक हार्बर",
      OAK_RIDGE: "ओक रिज",
      OAK_TREE_AT_HOLLYWOOD: "हॉलीवुड में ओक ट्री",
      OAK_TREE_AT_PLEASANTON: "प्लीसेंटॉन में ओक ट्री",
      OAK_TREE_AT_SANTA_ANITA: "सांता अनीता में ओक का पेड़",
      OCALA_TRAINING_CENTER: "ओकला प्रशिक्षण केंद्र",
      OCEAN_DOWNS: "महासागर नीचे",
      OLD_WASHINGTON: "ओल्ड वाशिंगटन",
      ONEIDA_COUNTY: "Oneida काउंटी",
      OREGON_LIVESTOCK: "ओरेगन पशुधन",
      OSKALOOSA: "ओस्कलोसा",
      OTTAWA: "ओटावा",
      OWEGO: "ओवेगो",
      OXFORD: "ऑक्सफोर्ड",
      PABOS: "पाबोस",
      PADUCAH: "पडुका",
      PAINESVILLE: "पेन्सविले",
      PALMYRA: "पल्मायरा",
      PALM_BEACH_EVENING: "पाम बीच शाम",
      PALM_BEACH_MATINEE: "पाम बीच मैटिनी",
      PANA: "पाना",
      PARIS: "पेरिस",
      PAULDING: "पॉलिंग",
      PBKC_SUMMER_MATINEE: "PBKC समर मैटिनी",
      PENNSYLVANIA_HUNT_CUP: "पेंसिल्वेनिया हंट कप",
      PENN_NATIONAL: "पेन नेशनल",
      PERCY_WARNER: "पर्सी वार्नर",
      PETERSBURG: "पीटर्सबर्ग",
      PHILADELPHIA: "फिलाडेल्फिया",
      PHILADELPHIA_PARK: "फिलाडेल्फिया पार्क",
      PICOV_DOWNS: "पिकोव डाउन्स",
      PIKETON: "पिकेटन",
      PIMLICO: "पिम्लिको",
      PINE_MTN_CALLOWAY_GARDEN: "पाइन mtn Calloway गार्डन",
      PINNACLE_RACE_COURSE: "शिखर दौड़ पाठ्यक्रम",
      PLAINRIDGE_RACECOURSE: "प्लेनरिज रेसकोर्स",
      PLATTSBURG: "प्लैट्सबर्ग",
      PLAYERS_BLUEGRASS_DOWNS: "खिलाड़ी ब्लूग्रास डाउन्स",
      PLAYFAIR: "निष्पक्ष खेलें",
      PLEASANTON: "प्लीसेंटन",
      POCATELLO_DOWNS: "Pocatello downs",
      POCONO_DOWNS: "Pocono downs",
      POMPANO_PARK: "पोम्पानो पार्क",
      PORTLAND: "पोर्टलैंड",
      PORTLAND_MEADOWS: "पोर्टलैंड मीडोज",
      PRAIRIE_MEADOWS: "प्रेयरी मीडोज",
      PRESCOTT_DOWNS: "प्रेस्कॉट डाउन्स",
      PRESQUE_ISLE_DOWNS: "प्रेस्क आइल डाउन्स",
      PRINEVILLE_TURF_CLUB: "प्रिनविले टर्फ क्लब",
      QUEENSBURY_DOWNS: "क्वींसबरी डाउन्स",
      RACEWAY_PARK: "रेसवे पार्क",
      REMINGTON_PARK: "रेमिंगटन पार्क",
      RETAMA_PARK: "रेटामा पार्क",
      RICE_LAKE: "राइस लेक",
      RICHLAND_CENTER: "रिचलैंड सेंटर",
      RICHWOOD: "रिचवुड",
      RIDEAU_CARLETON: "रिड्यू कार्लटन",
      RILLITO: "रिलिटो",
      RIVER_AT_BEULAH: "बेउला में नदी",
      RIVER_DOWNS: "रिवर डाउन्स",
      ROCKINGHAM_PARK: "रॉकिंगम पार्क",
      ROCKSPRINGS: "Rocksprings",
      ROSECROFT_RACEWAY: "Rosecroft रेसवे",
      ROSSBURN_PARK: "रॉसबर्न पार्क",
      ROUND_UP_PARK: "राउंड अप पार्क",
      RUIDOSO_DOWNS: "रुडोसो डाउन्स",
      RUNNING_ACES: "इक्के चल रहा है",
      RUSHVILLE: "रशविले",
      SACRAMENTO: "सैक्रामेंटो",
      SAINT_ESPRIT: "संत एस्प्रिट",
      SAINT_PETER: "सेंट पीटर",
      SALEM: "सलेम",
      SAM_HOUSTON: "सैम ह्यूस्टन",
      SANDWICH: "सैंडविच",
      SANDY_CREEK: "सैंडी क्रीक",
      SANDY_DOWNS: "सैंडी डाउन्स",
      SANFORD_ORLANDO_EVENING: "सैनफोर्ड ऑरलैंडो शाम",
      SANFORD_ORLANDO_MATINEE: "सैनफोर्ड ऑरलैंडो मैटिनी",
      SANTA_ANITA: "सांता अनीता",
      SANTA_CRUZ_COUNTY: "सांता क्रूज़ काउंटी",
      SANTA_FE: "सांता फे",
      SANTA_ROSA: "सांता रोजा",
      SARATOGA: "साराटोगा",
      SARATOGA_HARNESS: "साराटोगा हार्नेस",
      SCARBOROUGH_DOWNS: "स्कारबोरो डाउन्स",
      SCIOTO_DOWNS: "Scioto डाउन्स",
      SHAWAN_DOWNS: "शवन डाउन्स",
      SHELBYVILLE: "शेल्बीविले",
      SHENANDOAH_DOWNS: "Shenandoah डाउन्स",
      SIDNEY: "सिडनी",
      SILVER_SPRINGS_RANCH: "सिल्वर स्प्रिंग्स खेत",
      SIOUX_CENTER: "सिओक्स सेंटर",
      SKOWHEGAN: "स्कोवेगन",
      SOLANO: "सोलानो",
      SOUTHERN_OAKS_TRAINING_CENTER: "दक्षिणी ओक्स प्रशिक्षण केंद्र",
      SOUTHLAND_MATINEE: "साउथलैंड मैटिनी",
      SOUTHLAND_NIGHT: "साउथलैंड नाइट",
      SOUTHLAND_TWILIGHT: "साउथलैंड गोधूलि",
      SPORTSMANS_PARK: "स्पोर्ट्समैन पार्क",
      SPORTS_CREEK_RACEWAY: "स्पोर्ट्स क्रीक रेसवे",
      SPRINGFIELD_ILLINOIS: "स्प्रिंगफील्ड इलिनोइस",
      STAMPEDE_PARK: "स्टैम्पेड पार्क",
      STOCKTON: "स्टॉकटन",
      STONEBORO: "स्टोनबोरो",
      STONEYBROOK_AT_FIVE_POINTS: "पांच अंकों पर स्टोनीब्रुक",
      ST_AIME_DES_LACS: "सेंट एइम डेस लाख",
      ST_HUGUES: "सेंट हग्यूज़",
      ST_JOHNS_MATINEE: "सेंट जॉन्स मैटिनी",
      ST_JOHNS_NIGHT: "सेंट जॉन्स रात",
      ST_JOHNS_RACING: "सेंट जॉन्स रेसिंग",
      ST_JOHN_S: "संट जॉन्स",
      ST_JOSEPH_DE_LEPAGE: "सेंट जोसेफ डी लेपेज",
      SUDBURY_DOWNS: "सुदबरी डाउन्स",
      SUFFOLK_DOWNS: "सफ़ोक डाउन्स",
      SUMMERSIDE_RACEWAY: "समरसाइड रेसवे",
      SUNLAND_PARK: "सनलैंड पार्क",
      SUNRAY_PARK: "सनरे पार्क",
      SUN_DOWNS: "सूरज की गिरावट",
      SWEETWATER_DOWNS: "मीठे पानी की गिरावट",
      TAMPA_BAY_DOWNS: "टाम्पा बे डाउन्स",
      TAMPA_DL_MATINEE: "टाम्पा डीएल मैटिनी",
      TAMPA_DL_NIGHT: "ताम्पा डीएल रात",
      TESTING: "परिक्षण",
      THE_RED_MILE: "लाल मील",
      THE_TRACK_ON_2: "2 पर ट्रैक",
      THISTLEDOWN: "Thistledown",
      THUNDER_RIDGE: "थंडर रिज",
      TIFFIN: "टिफ़िन",
      TILLAMOOK_COUNTY: "टिलमूक काउंटी",
      TIMONIUM: "टिमोनियम",
      TIOGA_DOWNS: "Tioga downs",
      TOPSHAM: "Topsham",
      TROY: "ट्रॉय",
      TRUMANSBURG: "ट्रूमंसबर्ग",
      TRURO_RACEWAY: "ट्रू रेसवे",
      TRYON: "पर कोशिश",
      TUNBRIDGE: "Tunbridge",
      TURFWAY_PARK: "टर्फवे पार्क",
      TURF_PARADISE: "टर्फ स्वर्ग",
      UNION: "संघ",
      UNIONVILLE: "यूनियनविले",
      UPPER_SANDUSKY: "ऊपरी सैंडुस्की",
      URBANA: "उरबाना",
      VAN_WERT: "वैन वर्ट",
      VERNON_DOWNS: "वर्नोन डाउन्स",
      VIROQUA: "वीरोक्वा",
      WAITSBURG_RACE: "वेट्सबर्ग रेस",
      WALLA_WALLA: "वाल्ला वाल्ला",
      WAPAKONETA: "वैपकोनेट",
      WASHINGTON: "वाशिंगटन",
      WASHINGTON_COURT_HOUSE: "वाशिंगटन कोर्ट हाउस",
      WATTSBURG: "वाट्सबर्ग",
      WAUSEON: "Wauseon",
      WAYNESBURG: "वेनसबर्ग",
      WEBER_DOWNS: "वेबर डाउन्स",
      WEBSTER_CITY: "वेबस्टर सिटी",
      WELLSTOWN: "वेलस्टाउन",
      WESTERN_FAIR_RACEWAY: "वेस्टर्न फेयर रेसवे",
      WESTERN_MT_FAIR: "पश्चिमी माउंट मेला",
      WHAT_CHEER: "क्या जयकार",
      WHEATON: "व्हीटन",
      WHEELING_MATINEE: "व्हीलिंग मैटिनी",
      WHEELING_NIGHT: "रात",
      WHITE_PINE: "सफेद देवदार",
      WILLOWDALE: "Willowdale",
      WILL_ROGERS_DOWNS: "क्या रोजर्स डाउन्स",
      WILMINGTON: "विलमिंगटन",
      WINDSOR_RACEWAY: "विंडसर रेसवे",
      WINTERTHUR: "विंटरथुर",
      WOODBINE: "वुडबाइन",
      WOODBINE_2: "वुडबाइन 2",
      WOODLANDS: "वुडलैंड्स",
      WOODSFIELD: "वुड्सफील्ड",
      WOODSTOCK: "वुडस्टॉक",
      WOODSTOCK_RACEWAY: "वुडस्टॉक रेसवे",
      WOOSTER: "वूस्टर",
      WYOMING_DOWNS: "वाइमिंग डाउन्स",
      XENIA: "ज़ेनिया",
      YAKIMA_MEADOWS: "याकिमा मीडोज",
      YAVAPAI_DOWNS: "यावपाई डाउन्स",
      YELLOWSTONE_DOWNS: "येलोस्टोन डाउन्स",
      YONKERS_RACEWAY: "योंकर्स रेसवे",
      YORKTON: "यॉर्कटन",
      YORKTON_EX_ASSOC: "यॉर्कटन पूर्व Assoc",
      ZANESVILLE: "ज़ेन्सविले",
      ZIA_PARK: "ज़िया पार्क",
      FLAGLER_MATINEE: "फ्लैगलर मैटिनी",
      FLAGLER_NIGHT: "झंडे की रात",
      FLAGLER_TWILIGHT: "फ्लैगलर गोधूलि",
      MARDI_GRAS_EVENING: "मार्डी ग्रास शाम",
      MARDI_GRAS_MATINEE: "मार्डी ग्रास मैटिनी",
      SARASOTA_MATINEE: "सरसोता मैटिनी",
      TUCSON: "टक्सन",
      TRI_STATE_MATINEE: "त्रि राज्य मैटिनी",
      DURBANVILLE: "डरबनविले",
      FAIRVIEW: "फेयरव्यू",
      FLAMINGO_PARK: "फ्लेमिंगो पार्क",
      GREYVILLE: "ग्रेविले",
      KENILWORTH: "केनिलवर्थ",
      SCOTTSVILLE: "स्कॉट्सविले",
      TURFFONTEIN: "Turffontein",
      VAAL: "वल",
    },
    countries: {
      BD: "बांग्लादेश",
      BE: "बेल्जियम",
      BF: "बुर्किना फासो",
      BG: "बुल्गारिया",
      BA: "बोस्निया और हर्जेगोविना",
      BB: "बारबाडोस",
      WF: "वाली और फ़्युटुना",
      BL: "सेंट बार्थेलेमी",
      BM: "बरमूडा",
      BN: "ब्रुनेई",
      BO: "बोलीविया",
      BH: "बहरीन",
      BI: "बुरुंडी",
      BJ: "बेनिन",
      BT: "भूटान",
      JM: "जमैका",
      BV: "बाउवेट द्वीप",
      BW: "बोत्सवाना",
      WS: "समोआ",
      BQ: "बोनायर, सेंट यूस्टैटियस और सबा",
      BR: "ब्राजील",
      BS: "बहामास",
      JE: "जर्सी",
      BY: "बेलारूस",
      BZ: "बेलीज",
      RU: "रूस",
      RW: "रवांडा",
      RS: "सर्बिया",
      TL: "पूर्वी तिमोर",
      RE: "पुनर्मिलन",
      TM: "तुर्कमेनिस्तान",
      TJ: "ताजिकिस्तान",
      RO: "रोमानिया",
      TK: "Tokelau",
      GW: "गिनी-बिसाऊ",
      GU: "गुआम",
      GT: "ग्वाटेमाला",
      GS: "दक्षिण जॉर्जिया और दक्षिण सैंडविच द्वीप",
      GR: "यूनान",
      GQ: "भूमध्यवर्ती गिनी",
      GP: "ग्वाडेलूप",
      JP: "जापान",
      GY: "गुयाना",
      GG: "गर्नसे",
      GF: "फ्रेंच गयाना",
      GE: "जॉर्जिया",
      GD: "ग्रेनाडा",
      GB: "यूनाइटेड किंगडम",
      GA: "गैबॉन",
      SV: "अल साल्वाडोर",
      GN: "गिनी",
      GM: "गाम्बिया",
      GL: "ग्रीनलैंड",
      GI: "जिब्राल्टर",
      GH: "घाना",
      OM: "ओमान",
      TN: "ट्यूनीशिया",
      JO: "जॉर्डन",
      HR: "क्रोएशिया",
      HT: "हैती",
      HU: "हंगरी",
      HK: "हॉगकॉग",
      HN: "होंडुरास",
      HM: "हर्ड आइलैंड और मैकडॉनल्ड द्वीप",
      VE: "वेनेजुएला",
      PR: "प्यूर्टो रिको",
      PS: "फ़िलिस्तीन राष्ट्र",
      PW: "पलाऊ",
      PT: "पुर्तगाल",
      SJ: "स्वालबार्ड और जान मयेन",
      PY: "पराग्वे",
      IQ: "इराक",
      PA: "पनामा",
      PF: "फ्रेंच पॉलीनेशिया",
      PG: "पापुआ न्यू गिनी",
      PE: "पेरू",
      PK: "पाकिस्तान",
      PH: "फिलीपींस",
      PN: "पिटकेर्न",
      PL: "पोलैंड",
      PM: "सेंट पियरे और मिकेलोन",
      ZM: "ज़ाम्बिया",
      EH: "पश्चिमी सहारा",
      EE: "एस्टोनिया",
      EG: "मिस्र",
      ZA: "दक्षिण अफ्रीका",
      EC: "इक्वाडोर",
      IT: "इटली",
      VN: "वियतनाम",
      SB: "सोलोमन इस्लैंडस",
      ET: "इथियोपिया",
      SO: "सोमालिया",
      ZW: "ज़िम्बाब्वे",
      SA: "सऊदी अरब",
      ES: "स्पेन",
      ER: "इरिट्रिया",
      ME: "मोंटेनेग्रो",
      MD: "मोल्दोवा",
      MG: "मेडागास्कर",
      MF: "संत मार्टिन",
      MA: "मोरक्को",
      MC: "मोनाको",
      UZ: "उज्बेकिस्तान",
      MM: "म्यांमार",
      ML: "माली",
      MO: "मकाओ",
      MN: "मंगोलिया",
      MH: "मार्शल द्वीप समूह",
      MK: "मैसेडोनिया",
      MU: "मॉरीशस",
      MT: "माल्टा",
      MW: "मलावी",
      MV: "मालदीव",
      MQ: "मार्टिनिक",
      MP: "उत्तरी मरीयाना द्वीप समूह",
      MS: "मोंटसेराट",
      MR: "मॉरिटानिया",
      IM: "मैन द्वीप",
      UG: "युगांडा",
      TZ: "तंजानिया",
      MY: "मलेशिया",
      MX: "मेक्सिको",
      IL: "इजराइल",
      FR: "फ्रांस",
      IO: "ब्रिटेन और भारतीय समुद्री क्षेत्र",
      SH: "सेंट हेलेना",
      FI: "फिनलैंड",
      FJ: "फ़िजी",
      FK: "फ़ॉकलैंड आइलैंड",
      FM: "माइक्रोनेशिया",
      FO: "फ़ैरो द्वीप",
      NI: "निकारागुआ",
      NL: "नीदरलैंड",
      NO: "नॉर्वे",
      NA: "नामीबिया",
      VU: "वानुअतु",
      NC: "नया केलडोनिया",
      NE: "नाइजर",
      NF: "नॉरफ़ॉक द्वीप",
      NG: "नाइजीरिया",
      NZ: "न्यूज़ीलैंड",
      NP: "नेपाल",
      NR: "नौरू",
      NU: "नीयू",
      CK: "कुक द्वीपसमूह",
      XK: "कोसोवो",
      CI: "हाथीदांत का किनारा",
      CH: "स्विट्जरलैंड",
      CO: "कोलंबिया",
      CN: "चीन",
      CM: "कैमरून",
      CL: "चिली",
      CC: "कोकोस द्वीप",
      CA: "कनाडा",
      CG: "कांगो गणराज्य",
      CF: "मध्य अफ़्रीकी गणतंत्र",
      CD: "कांगो लोकतांत्रिक गणराज्य",
      CZ: "चेक रिपब्लिक",
      CY: "साइप्रस",
      CX: "क्रिसमस द्वीप",
      CR: "कोस्टा रिका",
      CW: "कुराकाओ",
      CV: "केप वर्दे",
      CU: "क्यूबा",
      SZ: "स्वाज़ीलैंड",
      SY: "सीरिया",
      SX: "सिंट मार्टेन",
      KG: "किर्गिस्तान",
      KE: "केन्या",
      SS: "दक्षिण सूडान",
      SR: "Suriname",
      KI: "किर्बती",
      KH: "कंबोडिया",
      KN: "संत किट्ट्स और नेविस",
      KM: "कोमोरोस",
      ST: "साओ टोमे और प्रिंसिपे",
      SK: "स्लोवाकिया",
      KR: "दक्षिण कोरिया",
      SI: "स्लोवेनिया",
      KP: "उत्तर कोरिया",
      KW: "कुवैत",
      SN: "सेनेगल",
      SM: "सैन मारिनो",
      SL: "सियरा लिओन",
      SC: "सेशेल्स",
      KZ: "कजाकिस्तान",
      KY: "केमैन टापू",
      SG: "सिंगापुर",
      SE: "स्वीडन",
      SD: "सूडान",
      DO: "डोमिनिकन गणराज्य",
      DM: "डोमिनिका",
      DJ: "जिबूती",
      DK: "डेनमार्क",
      VG: "ब्रिटिश वर्जिन आईलैन्ड्स",
      DE: "जर्मनी",
      YE: "यमन",
      DZ: "अल्जीरिया",
      US: "संयुक्त राज्य अमेरिका",
      UY: "उरुग्वे",
      YT: "Maotte",
      UM: "संयुक्त राज्य अमेरिका के छोटे दूरस्थ द्वीपसमूह",
      LB: "लेबनान",
      LC: "सेंट लूसिया",
      LA: "लाओस",
      TV: "तुवालु",
      TW: "ताइवान",
      TT: "त्रिनिदाद और टोबैगो",
      TR: "टर्की",
      LK: "श्रीलंका",
      LI: "लिकटेंस्टीन",
      LV: "लातविया",
      TO: "टोंगा",
      LT: "लिथुआनिया",
      LU: "लक्जमबर्ग",
      LR: "लाइबेरिया",
      LS: "लेसोथो",
      TH: "थाईलैंड",
      TF: "दक्षिणी फ्राँसिसी क्षेत्र",
      TG: "जाना",
      TD: "चाड",
      TC: "तुर्क और कैकोस द्वीप समूह",
      LY: "लीबिया",
      VA: "वेटिकन",
      VC: "संत विंसेंट अँड थे ग्रेनडीनेस",
      AE: "संयुक्त अरब अमीरात",
      AD: "एंडोरा",
      AG: "अंतिगुया और बार्बूडा",
      AF: "अफगानिस्तान",
      AI: "एंगुइला",
      VI: "यूएस वर्जिन द्वीप",
      IS: "आइसलैंड",
      IR: "ईरान",
      AM: "आर्मेनिया",
      AL: "अल्बानिया",
      AO: "अंगोला",
      AQ: "अंटार्कटिका",
      AS: "अमेरिकन समोआ",
      AR: "अर्जेंटीना",
      AU: "ऑस्ट्रेलिया",
      AT: "ऑस्ट्रिया",
      AW: "अरूबा",
      IN: "भारत",
      AX: "एलैंड द्वीप समूह",
      AZ: "अज़रबैजान",
      IE: "आयरलैंड",
      ID: "इंडोनेशिया",
      UA: "यूक्रेन",
      QA: "कतर",
      MZ: "मोजाम्बिक",
    },
  },
};

export const supportedLocale: LocaleDTO[] = [
  { province: "US", language: "English", code: "en" },
  { province: "FR", language: "French", code: "fr" },
  { province: "IN", language: "Hindi", code: "hi" },
];
