import ShimmerLoaders from "../../../ui/shimmerLoaders/shimmerLoader.ui";
import styles from "./raceSnapLoader.module.scss";

const RaceSnapLoader = () => {
  const style = {
    width: 370,
    height: 60,
  };

  return (
    <div className={styles.headerWrp}>
      {Array(7)
        .fill("")
        .map((i, index) => (
          <ShimmerLoaders key={index} style={style} />
        ))}
    </div>
  );
};

export default RaceSnapLoader;
