import { useRecoilValue } from "recoil";
import { isExotics } from "../../../../../common/helpers/pool.helper";
import { RaceTripDTO } from "../../../../../common/models/raceTrip.model";
import { AtomPoolColsForOdds } from "../../../../../store/pools/pools.store";
import { AtomSelectedPoolName } from "../../../../../store/races/selectedRace.store";
import ExoticsSelector from "./exotics.selector";
import styles from "./tripSelectors.module.scss";
import WinPlaceSelector from "./winplace.selector";

type Props = {
  trip: RaceTripDTO;
};

const TripSelector = ({ trip }: Props) => {
  const selectedPool = useRecoilValue(AtomSelectedPoolName);
  const _isExotics = isExotics(selectedPool);
  const poolCols = useRecoilValue(AtomPoolColsForOdds);

  // const { selId } = trip.raceTripKey;
  return (
    <>
      {_isExotics === false ? (
        <div
          style={{
            gridTemplateColumns: `repeat(${poolCols.length}, var(--width-selection))`,
          }}
          className={styles.winPlaceSelection}
        >
          <WinPlaceSelector trip={trip} />
        </div>
      ) : _isExotics === true ? (
        <ExoticsSelector trip={trip} />
      ) : (
        "Select Pool"
      )}
    </>
  );
};

export default TripSelector;
