import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { Severity } from "../../../../common/types/general.type";
import { AtomGlobalNotification } from "../../../../store/global/global.store";
import SnackBar from "../../../ui/snackBar/snackbar.ui";

type Props = {};

const NotificationHeader = (props: Props) => {
  const notification = useRecoilValue(AtomGlobalNotification);

  if (notification?.hidden || !notification?.message) return null;
  return (
    <SnackBar
      severity={notification ? (notification.severity as Severity) : "primary"}
      isRelative
      isShow
    >
      <Wrapper
        dangerouslySetInnerHTML={{
          __html: notification?.message ?? "",
        }}
      ></Wrapper>
    </SnackBar>
  );
};

export default NotificationHeader;

const Wrapper = styled.div`
  font-size: 0.75rem;
  @media screen and (max-width: 480px) {
    font-size: 0.65rem;
  }
`;
