import { RaceTripDTO } from "../../../common/models/raceTrip.model";
import RaceTripsRow from "./raceTripsRow/raceTripsRow.component";
import WinPlaceHeader from "./raceTripsRow/tripHeader/winPlace.header";
import useSelected from "../../../hooks/useSelectedPool";
import ExoticsHeader from "./raceTripsRow/tripHeader/exotics.header";


type Props = {
  raceTrips: RaceTripDTO[];
};

const RaceTripsTable = (props: Props) => {
  const { isExotics, race } = useSelected();
  return (
    <div>
      {isExotics ? <ExoticsHeader /> : <WinPlaceHeader />}
      <div >
        {props.raceTrips.map((trip, i) => {
          return <RaceTripsRow race={race} key={i} trip={trip} />;
        })}
      </div>
    </div>
  );
};

export default RaceTripsTable;
