import banner1 from "../../assets/images/banners/svgs/presenting_ashva_platform.svg";
import banner2 from "../../assets/images/banners/ashva_odds_n_probs_api.png";
import banner3 from "../../assets/images/banners/ashva_race_n_runner_statistics.png";
import banner4 from "../../assets/images/banners/svgs/Banner Finals_04.svg";
import banner5 from "../../assets/images/banners/svgs/Banner Finals_05.svg";
import mobileBanner1 from "../../assets/images/banners/svgs/Mobile_Banner Finals_01.svg";
import mobileBanner2 from "../../assets/images/banners/Mobile_Banner-Finals_02.png";
import mobileBanner3 from "../../assets/images/banners/Mobile_Banner-Finals_03.png";
import mobileBanner4 from "../../assets/images/banners/svgs/Mobile_Banner Finals_04.svg";
import mobileBanner5 from "../../assets/images/banners/svgs/Mobile_Banner Finals_05.svg";

export interface ImgMenu {
  desktopSrc: string;
  mobileSrc: string;
}

export const Images: ImgMenu[] = [
  {
    desktopSrc: banner1,
    mobileSrc: mobileBanner1,
  },
  {
    desktopSrc: banner2,
    mobileSrc: mobileBanner2,
  },
  {
    desktopSrc: banner3,
    mobileSrc: mobileBanner3,
  },
  {
    desktopSrc: banner4,
    mobileSrc: mobileBanner4,
  },
  {
    desktopSrc: banner5,
    mobileSrc: mobileBanner5,
  },
];
