import { Fragment, useEffect, useRef, useState } from "react";
import { ElementChangeEvent } from "../../../common/types/general.type";
import { IoMdArrowDropdown } from "react-icons/io";
import styles from "./countryDropdown.module.scss";
// import styled from "styled-components";
import { CountryDTO } from "../../../common/data/countryCode.data";

interface DropDownProps {
  options: any[];
  selectedValue: any;
  name: string;
  placeholder?: string;
  className?: string;
  label?: string;
  optionKey?: string;
  valueObjKey?: string;
  onChange?: (e: ElementChangeEvent, valueObjKey?: string) => void;
  disabled?: boolean;
  returnValue?: "string" | "object" | "number";
  icon?: any;
}

const CountryDropdown = ({
  name,
  className,
  placeholder,
  label,
  options,
  selectedValue,
  ...props
}: DropDownProps) => {
  const dropRef = useRef<HTMLInputElement>(null);
  const [shown, setShown] = useState(false);

  const handleOptionClick = (option: any) => {
    setShown(false);

    props.onChange && props.onChange({ data: option, name }, props.valueObjKey);
  };

  // Callbacks
  useEffect(() => {
    // Hide the target dropdown.
    const onOutsideClick = (e: any) =>
      dropRef.current && !dropRef.current.contains(e.target) && setShown(false);

    window.addEventListener("click", onOutsideClick);
    return () => {
      window.removeEventListener("click", onOutsideClick);
    };
  }, []);

  return (
    <div
      className={`${styles.dropdownWrapper} ${
        props.disabled && styles.disabled
      }`}
      ref={dropRef}
    >
      <label htmlFor={name} className={styles.label}>
        {" "}
        {label}
      </label>
      <input
        readOnly
        onClick={() => props.disabled !== true && setShown(!shown)}
        type="text"
        className={styles.input}
        placeholder={placeholder}
        name={name}
        value={selectedValue}
        disabled={props.disabled && props.disabled}
      />

      <div className={styles.buttonIcon}>{<IoMdArrowDropdown />}</div>
      <div className={`${styles.optionWrapper} ${shown ? styles.shown : ""}`}>
        {options.length > 0 ? (
          options.map((opt, index) => {
            return (
              <Fragment key={index}>
                {opt.map((_cur: CountryDTO, i: number) => {
                  return (
                    <Fragment key={i}>
                      <div
                        className={styles.dropdownButton}
                        onClick={() => handleOptionClick(_cur)}
                      >
                        <span
                        // className={`flag-32 flag-${_cur.country.toLowerCase()}`}
                        ></span>
                        <pre className={styles.curName}>{_cur.code} </pre>[
                        <pre>{_cur.dial_code}</pre>]
                      </div>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })
        ) : (
          <h4 style={{ padding: "1.5rem" }}>No Options Available</h4>
        )}
      </div>
    </div>
  );
};

export default CountryDropdown;

// const OptionWrapper = styled.div`
//   background: ${(p) => p.theme.body};
//   color: ${(p) => p.theme.textContrast};
//   :hover {
//     background: ${(p) => p.theme.bodyContrast};
//   }
// `;

// const Options = styled.div`
//   :hover {
//     background: ${(p) => p.theme.bodyContrast};
//   }
// `;
