import { RaceTripDTO } from "../../../../common/models/raceTrip.model";
import styles from "./raceTripsRow.module.scss";
import TripSelector from "./tripSelectors/tripSelectors.component";
import styled from "styled-components";
import TripInfo from "./tripInfo/tripInfo.component";
import { RaceDetailsDTO } from "../../../../common/models/race.mode";
import TripDetails from "./tripInfo/tripDetailsTab/TripDetails.component";
import { useState } from "react";

type Props = {
  trip: RaceTripDTO;
  race: RaceDetailsDTO | null | undefined;
};

const RaceTripsRow = ({ trip, race }: Props) => {
  const [showDetail, setShowDetail] = useState(false);

  if (!trip?.raceTripKey) return null;

  const handleDetailShow = () => {
    setShowDetail(!showDetail);
  };


  return (
    <>
      <TableRow className={styles.rowGrid}>
        <div className={styles.rowDiv} onClick={handleDetailShow}>
          <TripInfo raceKey={race?.raceKey} trip={trip} />
          <TripSelector trip={trip} />
        </div>  
        <div className={styles.detailsDiv}>
          <TripDetails trip={trip} race={race} showDetail={showDetail} />
        </div>
      </TableRow>
    </>
  );
};

export default RaceTripsRow;

// * Styled components
const TableRow = styled.div`
  &:nth-child(odd) {
    background: ${(p) => p.theme.rowOdd};
  }
  &:nth-child(even) {
    background: ${(p) => p.theme.rowEven};
  }
`;
