import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { AtomSelectedRace } from "../../../store/races/races.store";
import NoRaceData from "../../shared/errors/noRaceData.error";
import ShimmerLoader from "../../shared/loaders/shimmerLoader.loader";
import RaceTripsComponent from "../raceTrips/raceTrips.component";
import ResultedRaceComponent from "../resultedRace/resultedRace.component";
import RaceFooters from "./RaceFooters/raceFooters.footer";
import RaceMetaData from "./raceMetaData/raceMetaData.component";
import RaceNumbers from "./raceNumbers/raceNumbers.component";
import styles from "./selectedRace.module.scss";
import SelectedRaceStatus from "./selectedRaceStatus/selectedRaceStatus.component";
import TrackSelector from "./TrackSelector/trackSelector.component";

const SelectedRaceComponent = () => {
  const race = useRecoilValue(AtomSelectedRace);
  if (race === undefined) return <ShimmerLoader />;
  if (race === null) return <NoRaceData />;
  return (
    <div className={styles.selectedRaceWrapper}>
      <Header className={styles.selectedRaceHeader}>
        <TrackSelector />
        <div>
          <SelectedRaceStatus race={race} />
        </div>
      </Header>
      <RaceMetaData race={race} />
      <RaceNumbers />
      <ResultedRaceComponent />
      <RaceTripsComponent />
      <RaceFooters />
    </div>
  );
};

export default SelectedRaceComponent;

// * Styled components
const Header = styled.header`
  background: ${(p) => p.theme.bodyContrast};
`;
