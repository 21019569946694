import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { LKeys } from "../../../../store/locale/locale.data";
import useTranslation from "../../../../store/locale/useTranslation";
import {
  AtomPoolNames,
  AtomSelectedPoolName,
} from "../../../../store/races/selectedRace.store";
import styles from "./poolsTab.module.scss";

interface Props {
  onPoolChange: (p: string) => void;
}

const PoolsTab = ({onPoolChange}: Props) => {
  const poolNames = useRecoilValue(AtomPoolNames);
  const [selectedPool, setSelectedPool] = useRecoilState(AtomSelectedPoolName);
  const t = useTranslation(LKeys.generals);
  //   const resetSelection = useResetRecoilState(AtomHorseSelection);

  // * On change of pool reset the horse selection for previous pools
  const onPoolTabChange = (pool: string) => {
    // resetSelection();
    onPoolChange(pool);
    setSelectedPool(pool);
  };

  return (
    <TabWrapper className={styles.tabWrapper}>
      {poolNames.map((pool) => {
        return (
          <PoolButton
            className={selectedPool === pool ? "active" : ""}
            key={pool}
            onClick={() => onPoolTabChange(pool)}
          >
            <BtnWrp className={styles.btnWrp}>
              <div>{t(pool)}</div>
              {/* <Icon>
                <AiOutlineInfoCircle size={15} />
              </Icon> */}
            </BtnWrp>
          </PoolButton>
        );
      })}
    </TabWrapper>
  );
};

export default PoolsTab;

// * Styled Components
const TabWrapper = styled.div`
  border-bottom: 3px solid ${(p) => p.theme.bodyContrast};
`;
const PoolButton = styled.button`
  background: transparent;
  padding: 0.5rem 1rem;
  border: none;
  color: ${(p) => p.theme.text};
  &:hover {
    background: ${(p) => p.theme.bodyContrastLight};
    color: ${(p) => p.theme.textContrast};
  }
  &.active {
    position: relative;
    color: ${(p) => p.theme.textContrast};
    background: ${(p) => p.theme.bodyContrast};
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(50%, 0);
      width: 50%;
      height: 4px;
      background: ${(p) => p.theme.active};
    }
    /* &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: ${(p) => p.theme.bodyContrast};
    } */
  }
  @media screen and (max-width: 480px) {
    font-size: 0.8rem !important;
  }
  @media screen and (max-width: 325px) {
    font-size: 0.6rem !important;
  }
`;

const BtnWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const Icon = styled.div`
//   padding-left: 10px;
//   display: flex;
// `;
