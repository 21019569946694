import React, { CSSProperties, useState, useEffect } from "react";
import styles from "./AnimatedBadge.module.scss";
import styled from "styled-components";

interface AnimatedBadgeProps {
  data: string | number;
  shade?: "dark" | "light";
  size?: "sm" | "lg";
  active?: boolean;
  style?: CSSProperties;
}

export const AnimatedBadge = ({
  data,
  shade,
  size,
  style,
  ...props
}: AnimatedBadgeProps) => {
  const [num, setNum] = useState<string | number>(0);
  const [isAnimate, setIsAnimate] = useState(false);

  useEffect(() => {
    let timeout: any = null;
    if (data !== num && !timeout) {
      setIsAnimate(true);
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => {
        setNum(data);
        setIsAnimate(false);
      }, 300);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <TextWrapper
      style={style}
      className={`goBadge round ${styles.animWrapper} ${styles[size ?? "sm"]} ${
        props.active && styles.active
      }`}
    >
      <span>{num}</span>
      <span
        className={`${styles.overlay} ${styles[shade ?? "light"]} ${
          isAnimate && styles.hide
        }`}
      >
        {num}
      </span>
    </TextWrapper>
  );
};

export default AnimatedBadge;

const TextWrapper = styled.span`
  /* color: ${(p) => p.theme.text}; */
`;
