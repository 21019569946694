import styles from "../../styles/pageStyles/help/helpCenter.module.scss";
import th_racing from "../../assets/images/helpCenter/th_racing.jpg";
import hs_racing from "../../assets/images/helpCenter/hs_racing.jpg";
import do_racing from "../../assets/images/helpCenter/do_racing.jpg";
import hh_racing from "../../assets/images/helpCenter/hh_racing.jpg";
import mh_racing from "../../assets/images/helpCenter/mh_racing.jpg";
import qh_racing from "../../assets/images/helpCenter/qh_racing.jpg";
import sh_racing from "../../assets/images/helpCenter/sh_racing.jpg";
import ssh_racing from "../../assets/images/helpCenter/ssh_racing_1.jpg";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const HelpCenterPage = () => {
  return (
    <>
      <Helmet>
        <title>Help Center - Ashva Racing</title>
      </Helmet>
      <div className={styles.helpWrapper}>
        <MainTitle className={styles.title}>Help Center</MainTitle>

        <div className={styles.infoWrapper}>
          <div>
            <Title>Quarter Horse Racing</Title>
            <ul>
              <li>
                Quarter horse is an American breed of horse that excels at
                sprinting short distances.
              </li>
              <li>
                Quarter horses might rears up more than thoroughbred horse while
                loading into gates due to less experienced horses.
              </li>
              <li>
                Apart from that there is no difference between thoroughbred and
                quarter horse racing.
              </li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={qh_racing} alt="qh_racing" />
          </div>
        </div>

        <div className={styles.infoWrapper}>
          <div>
            <Title>Hurdle Horse Racing</Title>
            <ul>
              <li>
                A hurdle race is where the horses jump over obstacles called
                hurdles or flights that are over three and a half feet high.
              </li>
              <li>
                They are typically made of a series of panels made of brush and
                are flexible.
              </li>
              <li>
                Hurdle races always have a minimum of eight hurdles and a
                minimum distance of two miles (3000 meters)
              </li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={hh_racing} alt="hh_racing" />
          </div>
        </div>

        <div className={styles.infoWrapper}>
          <div>
            <Title>Steeplechase Horse Racing</Title>
            <ul>
              <li>
                A steeplechase is a distance horse race in which competitors are
                required to jump diverse fences and ditch obstacles.
              </li>
              <li>
                Horses have to run over large, fixed obstacles, in contrast to
                "hurdle" races where the obstacles are much smaller.
              </li>
              <li>
                The collective term "jump racing'' is used when referring to
                steeplechases and hurdle races collectively.
              </li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={sh_racing} alt="sh_racing" />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div>
            <Title>Thoroughbred Racing</Title>
            <ul>
              <li>
                Thoroughbred is a breed that is known for its horse racing.
              </li>
              <li>
                Thoroughbred horses are known for their agility, speed and
                sprint.
              </li>
              <li>These races range between 900 metres to 3000 meters.</li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={th_racing} alt="th_racing" />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div>
            <Title>Harness Horse Racing</Title>
            <ul>
              <li>
                Harness racing is a form of horse racing in which the horses
                race at a specific gait (a trot or a pace).
              </li>
              <li>
                They usually pull a two-wheeled cart called a sulky, or spider,
                occupied by a driver.
              </li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={hs_racing} alt="hs_racing" />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div>
            <Title>Monte Horse Racing</Title>
            <ul>
              <li>Monte races are part of harness racing.</li>
              <li>
                In Monte races, jockeys ride directly on saddled trotters (trot
                monté in French) instead of pulling a two-wheeled cart.
              </li>
              <li>These races are usually conducted in France.</li>
              <li>
                All the runners gather and mill around a starting area and then
                races start after the starters signal.
              </li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={mh_racing} alt="mh_racing" />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div>
            <Title>Standing Start Horse Racing</Title>
            <ul>
              <li>Standing start races are part of harness racing.</li>
              <li>
                These are the types of races that start differently according to
                countries.
              </li>
              <li>
                In Australia and New Zealand, there are tapes across the track
                and the horses stand stationary behind the tapes before the
                start.
              </li>
              <li>
                In France. All the runners gather and mill around a starting
                area and then races start after the starter gives the signal.
              </li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={ssh_racing} alt="ssh_racing" />
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div>
            <Title>Greyhound Dog Racing</Title>
            <ul>
              <li>
                In Greyhound races, all the runners gather behind a starting
                box, owing to its use of boxes to hold the greyhounds in place.
              </li>
              <li>
                Dogs are loaded from the rear, with a small window in the front
                door through which the dog can see the track and the mechanical
                lure.
              </li>
              <li>
                Once the lure has come around to a point a few meters behind the
                box, it passes a sensor which trips a switch to release the
                gates which swing upward to open, releasing the dogs.
              </li>
              <li>The opening gates start the race clock.</li>
            </ul>
          </div>
          <div className={styles.imageWrp}>
            <img src={do_racing} alt="do_racing" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenterPage;

const Title = styled.h2`
  width: fit-content;
  border-bottom: ${(p) => p.theme.borderHighlight};
`;

const MainTitle = styled.div`
  border-bottom: ${(p)=>p.theme.borderSecondary};
`