import { useRecoilValue } from "recoil";
import { getUniqueID } from "../common/helpers/validation.helper";
import { toWinPlaceBetKeyStr } from "../common/helpers/value.helper";
import { BetRequestDTO } from "../common/models/bet.model";
import { RaceTripDTO } from "../common/models/raceTrip.model";
import { AtomAggregatorObject } from "../store/auth/auth.store";
import { AtomSelectedRace } from "../store/races/races.store";
import { AtomLiveOdds, AtomPoolsData } from "../store/races/selectedRace.store";

const useBetCard = () => {
  const race = useRecoilValue(AtomSelectedRace);
  const liveOdds = useRecoilValue(AtomLiveOdds);
  const allPools = useRecoilValue(AtomPoolsData);
  const initObj = useRecoilValue(AtomAggregatorObject);

  const generateWinPlaceBet = (_pool: string, runner: RaceTripDTO) => {
    if (!race) return;
    let { selId } = runner.raceTripKey;
    let _bet = {} as BetRequestDTO;
    const pool = allPools[_pool];

    _bet.betKey = toWinPlaceBetKeyStr(race?.raceKeyString ?? "", _pool, selId);
    _bet.incBetAmt = pool?.incBetAmt ?? 0.1;
    _bet.selId = selId;
    _bet.selections = { [_bet.selId]: Number(_bet.singleBetAmt) };
    _bet.betId = getUniqueID(_pool.toLocaleLowerCase());
    _bet.runnerName = runner.horseName;
    _bet.raceKey = race.raceKey;
    _bet.poolKey = pool.poolKey;
    _bet.betAmount = Number(_bet.singleBetAmt);
    _bet.combinations = 1;

    _bet.minBetAmount = pool?.minBetAmt ?? 0.01;
    // TODO: Add init object
    if (initObj?.poolRates) {
      const _amt = initObj.poolRates[pool.currency] ?? 1;
      _bet.minBetAmount = _bet.minBetAmount * _amt;
    }

    _bet.singleBetAmt = _bet.minBetAmount.toString();

    let _odds = liveOdds[_pool]
      ? liveOdds[_pool][runner.raceTripKey.selId]
      : null;
    if (_odds) {
      _bet.winPlaceOdds = _odds;
      _bet.selectionOdds = { [_bet.selId]: _odds };
    }
    return _bet;
  };
  return { generateWinPlaceBet };
};
export default useBetCard;
