import styled from "styled-components";
import Avatar from "../../../../ui/avatar/avatar.component";
import styles from "./profile.module.scss";
import { MdLogout } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../common/routes/default.routes";
import { Auth } from "../../../../../common/configs/firebase.config";
import { signOut } from "firebase/auth";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  AtomAuthUser,
  SelectorResetAllAtoms,
} from "../../../../../store/auth/auth.store";

const ProfileComponent = () => {
  const [show, setShow] = useState(false);
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const user = useRecoilValue(AtomAuthUser);
  const resetAllAtoms = useResetRecoilState(SelectorResetAllAtoms);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) setShow(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onLogout = () => {
    signOut(Auth)
      .then((res) => {
        localStorage.clear();
        resetAllAtoms();
        navigate(ROUTES.LOGIN);
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <ProfileWrp
      className={styles.profileWrapper}
      onClick={() => setShow(!show)}
      ref={ref}
    >
      <div className={styles.profileInfoWrp}>
        <Avatar photoUrl={user?.photoURL} />
        <div className={styles.profileInfo}>
          <span>{user?.name}</span>
          <EmailWrp>{user?.email || user?.phoneNumber}</EmailWrp>
        </div>
      </div>
      {show ? (
        <DropDownList className={styles.dropDownWrp}>
          <InfoWrp className={styles.infoWrp}>
            <span className={styles.userName}>{user?.name}</span>
            <EmailWrp className={styles.emailWrp}>
              {user?.email || user?.phoneNumber}
            </EmailWrp>
          </InfoWrp>
          <BtnWrp onClick={onLogout}>
            <MdLogout size={15} style={{ marginRight: "0.5rem" }} />
            Logout
          </BtnWrp>
        </DropDownList>
      ) : (
        ""
      )}
    </ProfileWrp>
  );
};

export default ProfileComponent;

const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.bodyContrastDark};
`;

const BtnWrp = styled.button`
  color: ${(p) => p.theme.textContrast};
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  border: none;
  padding: 0.5rem 0;
  background-color: transparent;
  &:hover {
    background: ${(p) => p.theme.btnHoverDark};
  }
`;

const ProfileWrp = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
`;

const InfoWrp = styled.div`
  border-bottom: ${(p) => p.theme.borderPrimaryThick};
  background: ${(p) => p.theme.bodyContrastDark};
`;

const EmailWrp = styled.span`
  color: ${(p) => p.theme.textSecondary};
`;
