import styled from "styled-components";

// * Whole site wrapper
export const GLWrapper = styled.div`
  background: ${(p) => p.theme.body};
  color: ${(p) => p.theme.text};
  transition: var(--transition-default);
`;

// * Race snap header
export const RSHeaderWrapper = styled.div`
  background: ${(p) => p.theme.body};
  color: ${(p) => p.theme.textContrast};
  transition: var(--transition-default);
  position: sticky;
  top: 0;
  z-index: 100;
`;

// * Navigation Header Wrapper
export const NavHeaderWrapper = styled.div`
  background: ${(p) => p.theme.header};
  color: ${(p) => p.theme.textContrast};
  transition: var(--transition-default);

  /* padding: 0.5rem; */
`;

// * Race carousel action button
export const RSCarouselBtnWrapper = styled.div`
  background: ${(p) => p.theme.header};
  color: ${(p) => p.theme.textContrast};
  border-left: ${(p) => p.theme.borderPrimary};
  border-right: ${(p) => p.theme.borderPrimary};
  box-shadow: -2px 0px 3px black;
  button {
    color: inherit;
    padding: 0.15rem 0.5rem;
    &:first-child {
      border-bottom: ${(p) => p.theme.borderPrimary};
    }
    &:hover {
      background: ${(p) => p.theme.btnHoverDark};
      /* background: #41415a; */
    }
  }
`;
