export const Blogs = [
  {
    title: "HORSE RACING BOOKMAKER MANAGEMENT",
    description:
      "Generally, Horse racing bookmaker management involves overseeing the operations of a horse racing betting business...",
    id: "1",
    href: "https://protocolzone.in/horse-racing-bookmaker-management/",
  },
  // {
  //   title: "HORSE RACING BOOKMAKER MANAGEMENT",
  //   descriprtion:
  //     "Generally, Horse racing bookmaker management involves overseeing the operations of a horse racing betting business...",
  //   id: "1",
  //   href: "https://protocolzone.in/horse-racing-bookmaker-management/",
  // },
  // {
  //   title: "HORSE RACING BOOKMAKER MANAGEMENT",
  //   descriprtion:
  //     "Generally, Horse racing bookmaker management involves overseeing the operations of a horse racing betting business...",
  //   id: "1",
  //   href: "https://protocolzone.in/horse-racing-bookmaker-management/",
  // },
  // {
  //   title: "HORSE RACING BOOKMAKER MANAGEMENT",
  //   descriprtion:
  //     "Generally, Horse racing bookmaker management involves overseeing the operations of a horse racing betting business...",
  //   id: "1",
  //   href: "https://protocolzone.in/horse-racing-bookmaker-management/",
  // },
];
