import { atom } from "recoil";
import { NotificationDTO } from "../../common/models/notification.model";

export const AtomSidebar = atom<string | null>({
  key: "sidebarAtom",
  default: null,
});

export const AtomGlobalNotification = atom<NotificationDTO | null>({
  key: "atomGlobalNotification",
  default: null,
});
