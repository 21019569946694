import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { isMulti } from "../../../../../common/helpers/pool.helper";
import { floatToInteger } from "../../../../../common/helpers/value.helper";
import { BetCardDTO } from "../../../../../common/models/bet.model";
import { ElementChangeEvent } from "../../../../../common/types/general.type";
import { AtomBetAmounts } from "../../../../../store/bets/bets.store";
import { LKeys } from "../../../../../store/locale/locale.data";
import useTranslation from "../../../../../store/locale/useTranslation";
import InlineLoader from "../../../../ui/loaders/inline.loader";
import BetInput from "./betInput/bet.input";
import styles from "./_betCards.module.scss";

type Props = {
  betCard: BetCardDTO;
  placeBet: (bet: BetCardDTO) => void;
  onRemoveCard: () => void;
  isActive: boolean;
};

const ExoticsBetCard = ({
  betCard,
  placeBet,
  onRemoveCard,
  ...props
}: Props) => {
  const tTracks = useTranslation(LKeys.tracks);
  const tGeneral = useTranslation(LKeys.generals);
  const tProvinces = useTranslation(LKeys.countries);
  const [betAmounts, setBetAmounts] = useRecoilState(AtomBetAmounts);
  const [, setRoundedAmt] = useState<number | null>(null);

  const { bet, processing } = betCard;
  const { trackName, eventType, province, raceNo } = bet.raceKey;
  //   const [betCards, setBetCards] = useRecoilState(AtomBetCards);

  const changeInStake = ({ data }: ElementChangeEvent) => {
    const val = Number(data);
    let _amounts = { ...betAmounts };
    let actualFlex = val / bet.combinations / bet.minBetAmount;
    let flexPerc = floatToInteger(actualFlex).toFixed(2);
    let stake = val;
    let _roundedStake = Number(stake);
    if (actualFlex !== Number(flexPerc)) {
      _roundedStake = Number(flexPerc) * bet.minBetAmount * bet.combinations;
      setRoundedAmt(_roundedStake);
    } else setRoundedAmt(null);
    let stakePerCombo = (_roundedStake / bet.combinations).toFixed(2);

    _amounts[bet.betId] = { flexPerc, stakePerCombo, stake: stake.toString() };
    setBetAmounts(_amounts);
  };

  // * Flex percentage change in first input
  const changeInFlex = ({ data }: ElementChangeEvent) => {
    const val = Number(data);
    let _amounts = { ...betAmounts };
    let flexPerc = floatToInteger(val).toString();
    let stake = (
      Number(flexPerc) *
      bet.combinations *
      bet.minBetAmount
    ).toFixed(2);
    let stakePerCombo = (Number(stake) / bet.combinations).toFixed(2);
    _amounts[bet.betId] = { flexPerc, stakePerCombo, stake };
    setBetAmounts(_amounts);
  };

  const PositionTemplate = () => {
    if (!isMulti(bet.poolKey.poolType))
      return (
        <div className={styles.positionsWrapper}>
          {Object.keys(bet.positions ?? {}).map((pos) => {
            return (
              <div key={pos}>
                <Labels className={styles.positionKey}>
                  {tGeneral(pos)}:{" "}
                </Labels>
                {bet.positions &&
                  Object.values(bet.positions[pos] ?? {}).map((val) => {
                    return (
                      <span className={styles.positionVal} key={val}>
                        {val}
                      </span>
                    );
                  })}
              </div>
            );
          })}
        </div>
      );
    return (
      <div className={styles.positionsWrapper}>
        {Object.keys(bet.positions ?? {}).map((pos, index) => {
          return (
            <div className={styles.positionKey} key={pos}>
              <Labels className={styles.positionKey}>
                {" "}
                L{index + 1} - [R-{pos}] :{" "}
              </Labels>
              {bet.positions &&
                Object.values(bet.positions[pos] ?? {}).map((val) => {
                  return (
                    <span className={styles.positionVal} key={val}>
                      {val}
                    </span>
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const onPlaceBet = () => {
    placeBet({ bet, processing: true });
  };

  if (!props.isActive) return null;
  return (
    <BetCard className={styles.betCardWrapper}>
      <InlineLoader
        isVisible={processing}
        loadingText={tGeneral("processing")}
      />
      <BetCardHeader className={styles.betCardHeader}>
        <div className={styles.raceInfo}>
          <span className={`et32 ${eventType}-active`}></span>
          <div className={"pl05"}>
            <div className={styles.trackName}>{tTracks(trackName)}</div>
            <Labels className={styles.provinceInfo}>
              {tProvinces(province)} | {tGeneral("race")}
              <strong> {raceNo}</strong>
            </Labels>
          </div>
        </div>
        <div>
          <button onClick={onRemoveCard} className={styles.closeBtn}>
            <FaTimes />
          </button>
        </div>
      </BetCardHeader>
      {bet.resStatus && (
        <Status
          className={`${styles.statusWrapper} ${bet.resStatus} ${
            bet.betStatus !== "SUCCESS" ? "error" : "ok"
          }`}
        >
          {bet.resStatus}
        </Status>
      )}
      <PoolInfo>
        <strong>{bet.poolKey.poolType}</strong>
        <span>
          {tGeneral("costPerCombo")}: &nbsp;
          <strong>{betAmounts[bet.betKey]?.stakePerCombo ?? 0}</strong>
        </span>
      </PoolInfo>
      <div className={styles.exoticsBody}>{PositionTemplate()}</div>
      <div className={styles.exoticsFooter}>
        <BetInput
          value={betAmounts[bet.betId]?.flexPerc ?? ""}
          onChange={changeInFlex}
          disabled={bet?.resStatus === "SUCCESS" ? true : false}
          placeholder={"Flex "}
        />
        <Data>%</Data>
        <BetInput
          value={betAmounts[bet.betId]?.stake ?? ""}
          onChange={changeInStake}
          disabled={bet?.resStatus === "SUCCESS" ? true : false}
          placeholder={"Bet Amount"}
        />
        <Data>AUD</Data>
        <div style={{ display: "flex" }}>
          <BetButton
            disabled={
              bet?.resStatus === "SUCCESS"
                ? true
                : false || !betAmounts[bet.betId]?.flexPerc
                ? true
                : false || !betAmounts[bet.betId]?.stake
                ? true
                : false
            }
            onClick={() => onPlaceBet()}
            className={styles.betBtn}
          >
            {tGeneral("bet")}
          </BetButton>
        </div>
      </div>
    </BetCard>
  );
};

export default ExoticsBetCard;

// * Styled components
const Status = styled.div`
  background: ${(p) => p.theme.bodyContrast};
  font-size: 0.65rem;
  padding: 0.35rem;
  border-bottom: ${(p) => p.theme.borderContrast};
  color: ${(p) => p.theme.warning};
  &.error,
  &.FAILED {
    color: ${(p) => p.theme.danger};
  }
  &.SUCCESS {
    color: ${(p) => p.theme.success};
  }
`;
const BetButton = styled.button`
  background: ${(p) => p.theme.warning};
  color: ${(p) => p.theme.warningText};
  padding: 0.5rem;
  &:disabled {
    color: ${(p) => p.theme.textDull};
  }
`;
const PoolInfo = styled.div`
  background: ${(p) => p.theme.bodyContrast};

  /* border-radius: var(--radius-default); */
  padding: 0.25rem 0.25rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
// * Styled components
const Data = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
  width: 100px;
  border-radius: var(--radius-default);
  padding: 0.5rem;
  font-size: 0.75rem;
  text-align: center;

  border-right: 1px solid ${(p) => p.theme.bodyContrastLight};
`;
const BetCard = styled.div`
  background: ${(p) => p.theme.bodyContrastLight};
  box-shadow: ${(p) => p.theme.shadowDefault};
  border: ${(p) => p.theme.borderContrast};
`;

const BetCardHeader = styled.div`
  /* border-top-left-radius: var(--radius-default);
  border-top-right-radius: var(--radius-default); */
  background: ${(p) => p.theme.bodyContrastDark};
`;

const Labels = styled.label`
  color: ${(p) => p.theme.textSecondary};
`;
