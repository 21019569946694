import { memo, useEffect, useRef, useState } from "react";
import styles from "./raceStatus.module.scss";
import { getTimeObj } from "../../../common/helpers/dateTime.helper";
import { useRecoilValue } from "recoil";
import { AtomSelectedThemeData } from "../../../store/theme/theme.store";

type Props = {
  mtp?: string;
};

interface TimeState {
  timeStr: string;
  isNormal: boolean;
  isNegative: boolean;
  isNearToJump: boolean;
}

const RaceTimer = memo(({ mtp }: Props) => {
  const [time, setTime] = useState<TimeState | null>(() => getTimeObj(mtp));
  const intervalRef = useRef<any>(null);
  const theme = useRecoilValue(AtomSelectedThemeData);

  // Start interval for timer
  useEffect(() => {
    const startTimer = () => {
      const timeObj = getTimeObj(mtp);
      setTime(timeObj);
    };

    if (mtp) {
      intervalRef.current = setInterval(() => {
        startTimer();
      }, 1000);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mtp]);

  if (!time) return null;
  return (
    <div className={styles.raceTimerContainer}>
      <span
        style={{
          background: time?.isNearToJump
            ? theme?.nearMtp
            : time?.isNormal
            ? theme?.farMtp
            : theme?.postMtp,
        }}
        className={`${styles.timer} `}
      >
        {time.timeStr}
      </span>
    </div>
  );
});

RaceTimer.displayName = "RaceTimer";
export default RaceTimer;
