import { ReactNode } from "react";
import styled from "styled-components";
import styles from "./selectionBox.module.scss";

type Props = {
  children?: ReactNode;
  value?: boolean;
  onChange?: (bool: boolean, ...args: any) => void;
  disabled?: boolean;
  className?: string;
};

// * Single checkbox for runner selection for bet.
const SelectionBox = (props: Props) => {
  return (
    <Selection
      className={`${styles.selectionBox}  ${props.value ? "checked" : ""} ${
        props.className ?? ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        props.onChange && props.onChange(!props.value);
      }}
      disabled={props.disabled}
    >
      {props.children}
    </Selection>
  );
};

export default SelectionBox;

// * Styled Components
const Selection = styled.button`
  background: ${(p) => p.theme.bodyTernary};
  color: ${(p) => p.theme.textContrast};

  &.checked {
    background: ${(p) => p.theme.active};
    /* color: ${(p) => p.theme.textContrast}; */
  }
`;
