import { useRecoilValue } from "recoil";
import {
  AmericanCode,
  AustraliaCode,
  EuropeCode,
} from "../../../../common/helpers/templates/silkColors.helper";
import { AtomSelectedRace } from "../../../../store/races/races.store";
import {
  AtomSelectedRaceTips,
  AtomSelectedSilks,
} from "../../../../store/races/selectedRace.store";
import styles from "./raceTipComponent.module.scss";
import jokey from "../../../../assets/images/default_silk.png";
import styled from "styled-components";
import Card from "../../../ui/card/card.component";
import TipsImage from "../../../../assets/images/TipsImage.png";

const RaceTipsInline = () => {
  const tips = useRecoilValue(AtomSelectedRaceTips);
  const race = useRecoilValue(AtomSelectedRace);
  const silks = useRecoilValue(AtomSelectedSilks);

  const getDogBackground = (selId: string) => {
    if (!race?.raceKey) return "white";
    let _codes = EuropeCode;
    if (race.raceKey.province) {
      if (race.raceKey.province?.match(/^(US)$/)) {
        _codes = AmericanCode;
      } else if (race.raceKey.province.match(/^(AU|NZ)$/)) {
        _codes = AustraliaCode;
      }
    }
    const _color = _codes[selId]?.color ?? "White";
    return _color;
  };

  const getSortedTips = () => {
    return Object.entries(tips ?? {}).sort((a, b) => {
      return b[1] - a[1];
    });
  };

  if (!tips) return null;
  return (
    <Wrapper className={styles.inlineWrapper}>
      <Card bodyClass={styles.tipTitleCard}>
        <div className={styles.logoWrapper}>
          <img src={TipsImage} height={25} width={25} alt="PzoneLogo" />{" "}
        </div>
        <div>TIP</div>
      </Card>
      {getSortedTips().map((entry) => {
        const selId = entry[0];
        return (
          <TipCard className={styles.inlineTipCard} key={selId}>
            <div
              style={{
                background:
                  race?.raceKey?.eventType === "DO"
                    ? getDogBackground(selId)
                    : "#3F487A88",
              }}
              className={styles.inlineSilks}
            >
              {race?.raceKey.eventType !== "DO" && (
                <img src={silks[selId] ?? jokey} alt={""} />
              )}
            </div>
            <strong>{selId}</strong>
            <Prob>
              {tips[selId] ? (tips[selId] * 100).toFixed(2) + "%" : "-"}
            </Prob>
          </TipCard>
        );
      })}
    </Wrapper>
  );
};

export default RaceTipsInline;

const Prob = styled.div`
  background: ${(p) => p.theme.bodyContrastDark};
  font-size: 0.75rem;
  border-radius: var(--radius-default);
  padding: 0.25rem;
  margin-left: 0.25rem;
  color: ${(p) => p.theme.textSecondary};
  font-weight: 600;
`;
const Wrapper = styled.div`
  background: ${(p) => p.theme.bodyContrastLight};
`;

const TipCard = styled.div`
  background: ${(p) => p.theme.bodySecondaryDark};
  color: ${(p) => p.theme.textContrast};
  margin-left: 0.25rem;
`;
