import { atom } from "recoil";
import { __FavoriteDTO } from "../../common/types/dynamic.type";

// * Atom win place pools list for available live odds.
export const AtomPoolColsForOdds = atom<string[]>({
  key: "AtomPoolColsForOdds",
  default: [],
});

export const AtomFavoriteSelections = atom<__FavoriteDTO>({
  key: "atomFavoriteSelection",
  default: {},
});
