import { atom } from "recoil";
import { DefaultTheme } from "styled-components";

// * State to store selected theme data.
export const AtomSelectedThemeData = atom<DefaultTheme | null | undefined>({
  key: "atomSelectedThemeData",
  default: undefined, // undefined = Loading, null = No Data, else selected ThemeData
});

// * state to store all themes data.
export const AtomThemes = atom<DefaultTheme[]>({
  key: "atomThemes",
  default: [],
});

export const AtomSelectedTheme = atom<string>({
  key: "atomSelectedTheme",
  default: "lightBlue",
});
