import { useRecoilValue } from "recoil";
import { POOLS } from "../common/constants/pools.constant";
import { isExotics, isFOPool } from "../common/helpers/pool.helper";
import { AtomSelectedRace } from "../store/races/races.store";
import { AtomSelectedPoolName } from "../store/races/selectedRace.store";
import { AtomIsBoxed } from "../store/selection/selection.store";

// * Custom hook for pool management
const useSelected = () => {
  const pool = useRecoilValue(AtomSelectedPoolName);
  const race = useRecoilValue(AtomSelectedRace);
  const isBoxed = useRecoilValue(AtomIsBoxed);

  // * Return selected pool and boolean for its types.
  return {
    pool,
    race,
    raceStatus: race?.raceStatus,
    isExotics: isExotics(pool),
    isFo: isFOPool(pool),
    isBoxed,
    isWinPlace: pool === POOLS.WIN_PLACE,
  };
};

export default useSelected;
