import { ImCancelCircle } from "react-icons/im";
import styled from "styled-components";
import { LKeys } from "../../../store/locale/locale.data";
import useTranslation from "../../../store/locale/useTranslation";
import styles from "./_errors.module.scss";

type Props = {};

const CancelledRace = (props: Props) => {
  const t = useTranslation(LKeys.errors);

  return (
    <Bg className={styles.noRaceDataWrapper}>
      <ImCancelCircle size={30} />
      <div>{t("cancelledRace")}</div>
    </Bg>
  );
};

export default CancelledRace;

const Bg = styled.div``;
