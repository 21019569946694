import styled from "styled-components";
import { RESULTED } from "../../../../common/constants/general.constant";
import useSelected from "../../../../hooks/useSelectedPool";
import ExoticsFooter from "./exotics.footer";
import MultiFooter from "./multi.footer";
import styles from "./_raceFooters.module.scss";

type Props = {};

const RaceFooters = (props: Props) => {
  const { isExotics, isWinPlace, raceStatus } = useSelected();

  if (isWinPlace || raceStatus === RESULTED) return null;
  return (
    <FooterWrapper className={styles.footerWrapper}>
      {isExotics ? <ExoticsFooter /> : <MultiFooter />}
    </FooterWrapper>
  );
};

export default RaceFooters;

// * Styled Components
const FooterWrapper = styled.footer`
  background: ${(p) => p.theme.bodySecondary};
  color: ${(p) => p.theme.text};
  margin-top: 0.5rem;
  box-shadow: 0 -2px 3px #787a833c;
`;
